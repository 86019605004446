import { useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit, faEnvelope, faUserTimes, faUserClock, faPhone } from '@fortawesome/free-solid-svg-icons'
import { deleteData } from '../../../../services/deleteServices';
import { sendDataResponse } from '../../../../services/postServices';
import { getTemplates } from '../services';
import SendOneMessageModal from '../modals/sendOneMessageModal';
import SendOneMessageModalError from '../modals/sendOneMessageModalError';
import DeleteUserRequestModal from '../modals/deleteUserRequestModal';
import CallcenterModal from '../modals/callcenterModal';
import { isUserRole } from '../../../../helpers/user';
import ReactTooltip from 'react-tooltip'
import { ModalContext } from '../../../../context/ModalProvider';
import { useTranslation } from 'react-i18next'
/*
    render icons in leads dynamic table
    when click on some of them they can: 
    send a template message
    link to an edit lead page 
   
    
    if the user is admin he can delete a specific lead
    else the current user can send a request to delete a specific lead 
*/
export default function LeadActionsCellItem(tableInfo, setData) {
  const [modalShow, setModalShow] = useState(false);
  const [phoneModalShow, setPhoneModalShow] = useState(false);
  const [modalShowError, setModalShowError] = useState(false)
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [leadMsgTemplates, setLeadMsgTemplates] = useState([])
  const [templateId, setTemplateId] = useState(null);
  const [failedPayload, setFailedPayload] = useState(null);
  let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);

  const { t, i18n } = useTranslation();

  let history = useHistory();

  let lead = tableInfo.data[tableInfo.row.index]

  const deleteLead = async () => {
    try {
      let data = await deleteData(`leads/${lead.id}`, undefined, true)
      if (data.id) {
        toast.success(`${t('deleted')} ${t('lead')}`)
        setData(prevState => {
          const newData = [...prevState];
          newData.splice(newData.indexOf(i => lead.id === i.id), 1);
          return [...newData];
        }
        );
        setIsModalOpen(null)
      }

    } catch (error) {
      console.log(error)
    }
  }


  const sendTemplateMsg = async (leadId, templateId) => {
    if (!templateId) {
      toast.warn("בחר תבנית לשליחת הודעה")
      return;
    }
    try {
      const data = await sendDataResponse('sendOneMessage', {
        leadId,
        templateId: parseInt(templateId)
      }, undefined, true);

      if (!data || data.status === false) {
        setFailedPayload(data)
        toast.warning("הודעה נכשלה")
        setTemplateId(templateId)
        setModalShowError(true)
        setModalShow(false)
      } else if (data.status === true) {
        toast.success("הודעה נשלחה בהצלחה")
        setModalShow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sendCustomMsg = async (leadId, messageContent) => {
    try {
      const data = await sendDataResponse('sendOneMessageCustom', {
        leadId,
        messageContent
      }, undefined, true);
      if (!data || data.status === false) {
        setFailedPayload(data)
        toast.warning("הודעה נכשלה")
        setModalShowError(true)
        setModalShow(false)

      } else if (data.status === true) {
        toast.success("הודעה נשלחה בהצלחה")
        setModalShow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sendForceTemplateMsg = async (leadId, templateId) => {
    try {
      const data = await sendDataResponse('forceSendOneMessage', {
        leadId,
        templateId: parseInt(templateId)
      }, undefined, true);

      if (!data || data.status === false) {
        setFailedPayload(data);
        toast.warning("הודעה נכשלה");
        setModalShowError(true);
        setModalShow(false);
      } else if (data.status === true) {
        toast.success("הודעה נשלחה בהצלחה");
        setModalShowError(false);
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div className="d-flex justify-content-center align-items-center pb-3" >

      <SendOneMessageModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        title={`שליחת הודעה ליד ${lead.id}`}
        sendOneMsg={sendTemplateMsg}
        sendOneMsgCustom={sendCustomMsg}
        leadMsgTemplates={leadMsgTemplates}
        lead={lead}
      />
      <CallcenterModal
        modalShow={phoneModalShow}
        setModalShow={setPhoneModalShow}
        title={`שליחת חיוג לליד ${lead.id}`}
        lead={lead}
      />

      <SendOneMessageModalError
        modalShow={modalShowError}
        setModalShow={setModalShowError}
        title="שליחת הודעה נכשלה"
        leadId={lead.id}
        templateId={templateId}
        sendOneMsg={sendForceTemplateMsg}
        failedPayload={failedPayload}
      />

      <DeleteUserRequestModal
        modalShow={showDeleteUserModal}
        setModalShow={setShowDeleteUserModal}
        title={`בקשת מחיקה ליד ${lead.id}`}
        leadId={lead.id}
      />

      {
        isUserRole("Admin")
          ? (<div className="mx-1" onClick={() => setIsModalOpen({
            title: "מחיקה",
            msg: ` בטוח? `,
            action: () => deleteLead()
          })}>
            <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="מחק ליד" icon={faTrashAlt} className="delete_icon" size="lg" />
          </div>)
          : (<div className="mx-1" onClick={() => setShowDeleteUserModal(true)}>
            <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="שלח בקשה למחיקת ליד" icon={faUserTimes} className="text-warning" size="lg" />
          </div>)
      }

      <div className="mx-1" onClick={() => history.push(`/leads/edit/${lead.id}`)}>
        <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="ערוך ליד" icon={faEdit} className="edit_icon" size="lg" />
      </div>
      <div className="mx-1" onClick={() => {
        setModalShow(true)
        getTemplates(setLeadMsgTemplates)
      }}>
        <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="שליחת הודעה" icon={faEnvelope} className="send-message_icon" size="lg" />
      </div>
      <div className="mx-1" onClick={() => history.push(`/meetings/create/${lead.id}`)}>
        <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="יצירת פגישה" icon={faUserClock} className="fa-user-clock" size="lg" />
      </div>
      <div className="mx-1" onClick={() => {
        setPhoneModalShow(true)
      }}>
        {!isUserRole("Instructor") && <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="חיוג לליד" icon={faPhone} className="fa-phone" size="lg" />}
      </div>
      <ReactTooltip place="top" type="dark" effect="float" />
    </div>)
}



