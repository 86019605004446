import { accessGlobalState } from './stateServices'

export const setProgressBar = (status) => {
  switch (status) {
    case 'start':
      accessGlobalState().set('75')
      break;
    case 'complete':
      accessGlobalState().set('100')
      setTimeout(() => accessGlobalState().set('0'), 1300);
      break;
    case 'fail':
      accessGlobalState().set('err')
      break;
    default:
      break;
  }
};
