import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { updateData } from '../../../../services/putServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal';
import { Form, Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip'

// send a request to reject a task in dynamic tasks table 
export const TasksActionsCellItem = (tableInfo) => {
  const [modalShow, setModalShow] = useState(false);

  let history = useHistory();
  let textAreaRef = useRef()

  let rowData = tableInfo.data[tableInfo.row.index]

  const rejectTask = async () => {
    try {
      let data = await updateData(`tasks/reject/${rowData.id}`, {
        comment: textAreaRef.current.value
      })
      if (data.id) {
        toast.success("משימה נדחתה")
        let url = history.location.pathname + history.location.search
        history.replace(url)
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (

    <>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={`דחיית משימה ${rowData.id}`}
        onDoubleClick={(e) => { e.stopPropagation() }}
      >
        <h4 className="mb-3 mt-2">סיבת דחייה </h4>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control as="textarea" rows={3} ref={textAreaRef} placeholder="הערה" />
          </Form.Group>
          <Button variant="warning" onClick={() => rejectTask(rowData.id)} >שלח</Button>
        </Form>
      </VerticallyCenteredModal>


      <div className="d-flex justify-content-right" >
        <div className="mx-3" onClick={() => {
          if (rowData.status === 'available') {
            setModalShow(true)
          }
        }}>
          <FontAwesomeIcon icon={faTimesCircle} data-delay-hide='100' data-delay-show='500' data-tip="דחיית משימה" className={`delete_icon${rowData.status === 'available' ? "" : " disable"}`} size="lg" />
        </div>
        <ReactTooltip place="top" type="dark" effect="float" />
      </div>
    </>
  )
}