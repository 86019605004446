const formatAdress = (street, number, city) => {
    return `${street || ""} ${street ? number : ""}${street ? ", " + city : city}`;
}

const formatAdressDetails = (entrance, floor, apart) => {
    entrance = entrance !== "" ? `כניסה: ${entrance}` : "";
    floor = floor !== "" ? `קומה: ${floor}` : "";
    apart = apart !== "" ? `דירה: ${apart}` : "";
    const firstSpacing = entrance !== "" ?
        floor !== "" || apart !== "" ?
            `, `
            : ""
        : "";
    const secondSpacing = floor !== "" ?
        apart !== "" ?
            `, `
            : ""
        : "";
    return `${entrance}${firstSpacing}${floor}${secondSpacing}${apart}`;
}

const formatUpcomingMeeting = (meeting, fullName, history) => {
    if (meeting) {
        return `${meeting.meeting}`
    } else {
        return "לא";
    }
}

export {
    formatAdress,
    formatAdressDetails,
    formatUpcomingMeeting
}