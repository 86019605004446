import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, ListGroup, Form, Button, ButtonGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getData } from '../../services/getServices';
import { updateData } from '../../services/putServices';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import NotFoundPage from '../404page/notFoundPage';

export default function FieldsMatchingForm() {
    const [formObject, setFormObject] = useState(null);
    const [formFieldsState, setFormFieldsState] = useState(null);
    const [deliverAblePayload, setDeliverAblePayload] = useState(null);
    const [configArray, setConfigArray] = useState([]);
    const [doneLoading, setDoneLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const params = useParams();
    const { formSubmissionId } = params;
    const { t } = useTranslation();
    let history = useHistory()


    const aquireRelevantData = async () => {
        const data = await getData(`formsubmissions/${formSubmissionId}`)
        if (data?.response?.status === 404) {
            setIsNotFound(true);
        }
        if (data) {
            const formId = data.form.id;
            const formData = await getData(`forms/${formId}`, ((data) => setFormObject(data)));
            const { connection, connectionPolicy, formfields } = formData;
            if (connection !== "notDefined") {
                history.push(`/forms/submissions/update/${data.id}`);
                return;
            }

            const externalFormFields = await getData('settingconfigs/externalformfields');
            const externalFormFieldsConfig = externalFormFields.configJson.map((nameObj) => nameObj.name);

            let nameArr = [];
            externalFormFieldsConfig.forEach(formField => {
                nameArr.push({
                    fieldName: formField,
                    selected: false
                });
            })
            const nameLabelArray = formfields.map((formField, idx) => formField.name);
            setConfigArray(nameArr)
            setFormFieldsState(nameLabelArray)
            setDeliverAblePayload({ connection, connectionPolicy });
            setDoneLoading(true);
        } else {
            return;
        }
    }

    useEffect(() => {
        aquireRelevantData()
    }, [])

    const staticFields = ['connection', 'connectionPolicy'];
    const correspondingExternalFormFields = {
        connection: ['leads', 'meetings'],
        connectionPolicy: ['manual', 'strict', 'normal', 'fluid']
    };
    const validMeetingsPolicies = ['manual', 'fluid'];

    const handleStaticFormChange = (e, field) => {
        const val = e.target.value;
        if (field === 'connection') {
            if (val === 'leads') {
                resetConfigArray();
                let deliverAbleCpy = { ...deliverAblePayload };
                if (deliverAbleCpy?.meetingIdFieldName !== undefined) {
                    delete deliverAbleCpy.meetingIdFieldName;
                }
                deliverAbleCpy['configuredFields'] = [];
                setDeliverAblePayload({ ...deliverAbleCpy, [field]: val });
            } else if (val === 'notDefined') {
                let deliverAbleCpy = { ...deliverAblePayload };
                if (deliverAbleCpy?.configuredFields !== undefined) {
                    delete deliverAbleCpy.configuredFields;
                }
                if (deliverAbleCpy?.meetingIdFieldName !== undefined) {
                    delete deliverAbleCpy.meetingIdFieldName;
                }
                setDeliverAblePayload({ ...deliverAbleCpy, [field]: val, connectionPolicy: 'notDefined' });
            } else if (val === 'meetings') {
                let deliverAbleCpy = { ...deliverAblePayload };
                if (deliverAbleCpy?.configuredFields !== undefined) {
                    delete deliverAbleCpy.configuredFields;
                }
                deliverAbleCpy['meetingIdFieldName'] = null;
                setDeliverAblePayload({ ...deliverAbleCpy, [field]: val });
            }
        } else {
            setDeliverAblePayload({ ...deliverAblePayload, [field]: val });
        }

    }

    const handleConfigFieldsChange = (e, field) => {
        const val = e.target.value;
        if (val === 'notDefined') {
            if (deliverAblePayload?.configuredFields) {
                const foundConfigFieldIndex = deliverAblePayload.configuredFields.findIndex(configField => configField.field === field);

                const deliverableCpy = { ...deliverAblePayload }
                const configArrayCpy = [...configArray]
                const configName = deliverableCpy.configuredFields[foundConfigFieldIndex].name;
                if (configName) {
                    const foundConfigItemIndexTwo = configArrayCpy.findIndex(configItem => configItem.fieldName === configName);
                    configArrayCpy[foundConfigItemIndexTwo].selected = false;
                }
                delete deliverableCpy.configuredFields[foundConfigFieldIndex].name;
                setConfigArray(configArrayCpy);
                setDeliverAblePayload(deliverableCpy);
            } else if (deliverAblePayload?.meetingIdFieldName || deliverAblePayload?.meetingIdFieldName === null) {
                const deliverableCpy = { ...deliverAblePayload }
                deliverableCpy.meetingIdFieldName = null;
                setDeliverAblePayload(deliverableCpy);
            }
        } else {
            if (deliverAblePayload?.configuredFields) {
                const foundConfigFieldIndex = deliverAblePayload.configuredFields.findIndex(configField => configField.field === field);

                if (foundConfigFieldIndex === -1) {
                    const deliverableCpy = { ...deliverAblePayload }
                    const configArrayCpy = [...configArray]
                    const foundConfigItemIndex = configArrayCpy.findIndex(configItem => configItem.fieldName === val);
                    configArrayCpy[foundConfigItemIndex].selected = true;
                    deliverableCpy.configuredFields.push({ name: val, field });
                    setConfigArray(configArrayCpy);
                    setDeliverAblePayload(deliverableCpy);
                } else {
                    const deliverableCpy = { ...deliverAblePayload }
                    const configArrayCpy = [...configArray]
                    const foundConfigItemIndex = configArrayCpy.findIndex(configItem => configItem.fieldName === val);
                    const configName = deliverableCpy.configuredFields[foundConfigFieldIndex].name;
                    if (configName) {
                        const foundConfigItemIndexTwo = configArrayCpy.findIndex(configItem => configItem.fieldName === configName);
                        configArrayCpy[foundConfigItemIndexTwo].selected = false;
                    }
                    configArrayCpy[foundConfigItemIndex].selected = true;
                    deliverableCpy.configuredFields[foundConfigFieldIndex].name = val;
                    setConfigArray(configArrayCpy);
                    setDeliverAblePayload(deliverableCpy);
                }
            } else if (deliverAblePayload?.meetingIdFieldName || deliverAblePayload?.meetingIdFieldName === null) {
                setDeliverAblePayload({ ...deliverAblePayload, meetingIdFieldName: val })
            }
        }
    }

    const resetConfigArray = () => {
        const configArrayCpy = [...configArray]
        configArrayCpy.forEach((configObject, index) => {
            configArrayCpy[index].selected = false;
        });
        setConfigArray(configArrayCpy);
    }

    const renderNothing = (fieldObject, formFieldName) => {
        if (!fieldObject.selected) return false;
        const foundConfigField = deliverAblePayload.configuredFields.find(configField => configField.field === formFieldName);
        if (!foundConfigField) return true;
        if (foundConfigField?.name) {
            return fieldObject.fieldName === foundConfigField.name ? false : true;
        }
        return true;
    }

    const handleSub = async () => {
        const deliverAble = { ...deliverAblePayload, testSubmissionId: parseInt(formSubmissionId), formId: parseInt(formObject.id) }
        const res = await updateData(`forms/establishconnection/${formObject.id}`, deliverAble);
        if (res.id) {
            const backgroundProcess = res.connectionPolicy !== "manual" &&
                res.connectionTempStatus === 'active' && res.connectionStatus === 'flowing';
            toast.success(`${t('updated form connection')} ${res.id}`);
            backgroundProcess && toast.success(`${t('process happens in background')}`);
            history.push(`/forms/submissions/${formObject.id}`);
        } else if (res.statusCode && res.statusCode === 400) {
            if (res.message?.errorsArray && Array.isArray(res.message?.errorsArray)) {
                res.message?.errorsArray.forEach(error => toast.error(error?.errorMessage ? error.errorMessage : "error"));
            } else {
                toast.error(res.message?.invalidInfo ? res.message?.invalidInfo : "error");
            }
        }
    }
    if (isNotFound) {
        return <NotFoundPage />
    }

    return (
        <>
            {
                doneLoading &&
                <div className="container-fluid">
                    <div className="mx-auto mt-5" >
                        <div style={{ display: 'flex' }} >
                            <Card className="mx-auto" >
                                <Card.Header>התניית תנאים</Card.Header>
                                <Card.Body>
                                    <ListGroup variant='flush' >
                                        {staticFields && staticFields.map((field, fieldIndex) => (
                                            <ListGroup.Item key={fieldIndex} style={{ display: "flex", "flexDirection": "row-reverse", "justifyContent": "space-between" }}>
                                                <Form.Control disabled={field === 'connectionPolicy' && deliverAblePayload?.connection === 'notDefined'} as="select" custom onChange={((e) => handleStaticFormChange(e, field))}>
                                                    <option value={'notDefined'}>{`${t('select')} ${t(field)}`}</option>
                                                    {deliverAblePayload.connection === 'leads' || field === 'connection' ? correspondingExternalFormFields[field].map((title, index) => <option key={index} value={title}>{title}</option>)
                                                        : validMeetingsPolicies.map((title, index) => <option key={index} value={title}>{title}</option>)}
                                                </Form.Control>
                                                <label>{field}</label>
                                            </ListGroup.Item>))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                            <Card className="mx-auto">
                                <Card.Header>התאמת שדות</Card.Header>
                                <Card.Body>
                                    {deliverAblePayload?.configuredFields && configArray ? <ListGroup variant='flush' >
                                        {formFieldsState && formFieldsState.map((field, fieldIndex) => (
                                            <ListGroup.Item key={fieldIndex} style={{ display: "flex", "flexDirection": "row-reverse", "justifyContent": "space-between" }}>
                                                <Form.Control as="select" custom onChange={((e) => handleConfigFieldsChange(e, field))} >
                                                    <option value={'notDefined'}>שדה לא קיים בקובץ</option>
                                                    {configArray.map((fieldObject, index) => renderNothing(fieldObject, field) ? "" : <option key={index} value={fieldObject.fieldName}>{fieldObject.fieldName}</option>)}
                                                </Form.Control>
                                                <label>{field}</label>
                                            </ListGroup.Item>))}
                                    </ListGroup> : deliverAblePayload?.meetingIdFieldName || deliverAblePayload?.meetingIdFieldName === null ? <ListGroup variant='flush' >
                                        <ListGroup.Item style={{ display: "flex", "flexDirection": "row-reverse", "justifyContent": "space-between" }}>
                                            <Form.Control as="select" custom onChange={((e) => handleConfigFieldsChange(e, 'meetingIdFieldName'))}>
                                                <option value={'notDefined'}>שדה לא קיים בקובץ</option>
                                                {formFieldsState && formFieldsState.map((title, index) => <option key={index} value={title}>{title}</option>)}
                                            </Form.Control>
                                            <label>meetingIdFieldName</label>
                                        </ListGroup.Item>
                                    </ListGroup> : <h1>none</h1>}
                                </Card.Body>
                            </Card>
                        </div>
                        <ButtonGroup style={{ flexDirection: 'row-reverse' }}>
                            <Button disabled={!deliverAblePayload || (deliverAblePayload && (deliverAblePayload.connection === 'notDefined' || deliverAblePayload.connectionPolicy === 'notDefined' || (!deliverAblePayload?.meetingIdFieldName && !deliverAblePayload?.configuredFields)))} onClick={handleSub}>{t('submit')}</Button>
                            <Button variant="primary" onClick={() => history.push(`/forms/submissions/${formObject.id}`)}>{t('return')}</Button>
                        </ButtonGroup>
                    </div>
                </div>
            }
        </>
    )
}
