import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";
import { Button, ListGroup, Spinner } from "react-bootstrap";
import { getData } from '../../../services/getServices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const COLORS = [
  '#D41A0E',
  '#DE3A0F',
  '#C75517',
  '#DE760F',
  '#D48A0E'
];

const DateBox = ({ dateArray, handleSelectedDateChange, selectedDate, countArray }) => {
  return dateArray.map((date, idx) => (
    <motion.div
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}>
      <ListGroup.Item className='list-item' key={idx} onClick={(() => handleSelectedDateChange(date))} active={moment(date).isSame(moment(selectedDate), 'day') ? true : false}>
        <h5>{moment(date).format("DD-MM-YYYY")}</h5>
        <h6>{moment(date).format("dddd")}</h6>
        <div className='date-color-box-container'>{countArray ? new Array(countArray[idx]).fill(0).map((iterator, iteratorIndex) => <FontAwesomeIcon icon={faCircle} color={COLORS[iteratorIndex]} className='date-color-box'></FontAwesomeIcon>) : <Spinner animation="border" size="sm" />}</div>
      </ListGroup.Item>
    </motion.div>
  )).reverse();
};



const DateSlider = ({ handleSelectedDateChange, selectedDate, userId }) => {
  const [navigatedToDate, setNavigatedToDate] = useState(selectedDate);
  const [dateInputDate, setDateInputDate] = useState(navigatedToDate);
  const [dateArray, setDateArray] = useState(null);
  const [countArray, setCountArray] = useState(null);


  const findDateByShuffling = (dateToFind) => {
    const originalDate = moment().format();
    dateToFind = moment(dateToFind).startOf("day").format();
    const isBefore = moment(dateToFind).isBefore(originalDate);
    let foundDate = false, tryDate = originalDate;
    while (!foundDate) {
      if (moment(dateToFind).isSameOrAfter(moment(tryDate).startOf("day")) && moment(dateToFind).isSameOrBefore(moment(tryDate).add(4, "days").endOf("day"))) {
        foundDate = true;
        continue;
      }
      tryDate = isBefore ?
        moment(tryDate).subtract(5, "days").startOf("day").format()
        : moment(tryDate).add(5, "days").startOf("day").format();
    }
    return tryDate;
  }

  const handleNavigatedChange = async (newDate, setPassedDate) => {
    const newNavigate = findDateByShuffling(newDate);
    setNavigatedToDate(newNavigate);
    setDateInputDate(setPassedDate ? newDate : newNavigate);
    const newDateArray = [];
    for (let i = 0; i < 5; i++) {
      const thisDate = moment(newNavigate).add(i, "days").format();
      newDateArray.push(thisDate);
    }
    const newCountArray = await Promise.allSettled(newDateArray.map(async (date) => {
      try {
        const startOfDay = moment(date).startOf("day").toISOString();
        const endOfDay = moment(date).endOf("day").toISOString();
        const countData = await getData(
          `meetingleads/count?assigned_to=${userId}&specific_datetime_gt=${startOfDay}&specific_datetime_lt=${endOfDay}`
        );
        return countData;
      } catch (error) {
        console.error(error);
        return 0;
      }
    }));
    setDateArray(newDateArray);
    setCountArray(newCountArray.map(countPromise => countPromise.value));
  }

  useEffect(() => {
    handleNavigatedChange(navigatedToDate);
  }, []);

  useEffect(() => {
    handleNavigatedChange(navigatedToDate);
  }, [userId]);

  return (
    <div>
      {dateArray ? (
        <AnimatePresence>
          <div className='date-slider'>
            <div className='slider' >
              <motion.div
                initial={{
                  x: "150vw",
                  transition: { type: "spring", duration: 1 },
                }}
                animate={{
                  x: 0,
                  transition: { type: "spring", duration: .75 },
                }}
                whileHover={{
                  scale: 0.99,
                  transition: { type: "spring", duration: 0.3 },
                }}
                exit={{
                  x: "-60vw",
                  scale: [1, 0],
                  transition: { duration: 0.5 },
                  backgroundColor: "rgba(255,0,0,1)",
                }}
                className='dateArray'
                key={dateArray}

              >
                <ListGroup horizontal className='list-items-container'>
                  <DateBox dateArray={dateArray} handleSelectedDateChange={handleSelectedDateChange} selectedDate={selectedDate} countArray={countArray}></DateBox>
                </ListGroup>
              </motion.div>
              <div className='controllers'>
                <div className='dateslider-btns mb-5'>
                  <Button onClick={() => handleNavigatedChange(moment(dateInputDate).add(1, "month").startOf("month"), true)}>{'<---'}</Button>
                  <Button onClick={() => handleNavigatedChange(moment(dateInputDate).add(5, "days"))}>{'<-'}</Button>
                  <input type={'date'} className="p-2 ml-5 mr-5 form-control" value={moment(dateInputDate).format("YYYY-MM-DD")} onChange={(e) => handleNavigatedChange(e.target.value, true)} />
                  <Button onClick={() => handleNavigatedChange(moment(dateInputDate).subtract(5, "days"))}>{'->'}</Button>
                  <Button onClick={() => handleNavigatedChange(moment(dateInputDate).subtract(1, "month").startOf("month"), true)}>{'--->'}</Button>
                </div>
              </div>
            </div>
          </div>
        </AnimatePresence>
      ) : (
        <Spinner animation="border" />
      )}
    </div>
  );
};

//we can use connect method to connect this component with redux store
export default DateSlider;
