import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function InputTextareaSelect({ type, value, onChange, onBlur, selectArray, rows }) {
  const { t } = useTranslation();
  const input = () => <input value={value} onChange={onChange} onBlur={onBlur} type={type} />
  const textarea = () => <textarea value={value} onChange={onChange} onBlur={onBlur} rows={rows} style={{ width: "100%" }} />
  const select = () => (
    <select value={value} onChange={onChange} onBlur={onBlur}>
      {selectArray.map(option => <option value={option.value}>{t(option.Header)}</option>)}
    </select>)
    
  switch (type) {
    case 'textarea':
      return textarea()
    case 'select':
      return select()
    default:
      return input()
  }
}

export default InputTextareaSelect