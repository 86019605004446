import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'react-i18next'
import { getData } from '../../services/getServices';

export function WhatsappLink({ lead, isIcon, displayText }) {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState(null);

  const choosePhoneNumber = async () => {
    const phoneNumberConfig = await getData('settingconfigs/phoneNumberConfig');
    const phoneNumbersArray = phoneNumberConfig.configJson.lead;
    for (const phoneNumberKey of phoneNumbersArray) {
      if (phoneNumberKey && lead[phoneNumberKey] && typeof lead[phoneNumberKey] === "string") {
        setPhoneNumber(lead[phoneNumberKey]);
        break;
      }
    }
  }

  useEffect(() => {
    choosePhoneNumber();
  }, []);

  const createWhatsappLink = (phone) => {
    const baseWhatsappLink = `https://api.whatsapp.com/send?phone=`;
    return `${baseWhatsappLink}972${phone.slice(1)}`;
  }


  return (
    <>
      {
        !phoneNumber || phoneNumber.length < 10 ?
          <span>{t("Cant Gen Whatsapp")}</span> :
          <a href={createWhatsappLink(phoneNumber)}>{isIcon ? <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="שלח הודעת ווצאפ" icon={faWhatsapp} size="3x" /> :
            displayText ?
              displayText :
              phoneNumber}</a>

      }
    </>
  )
}