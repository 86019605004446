const translateCities = (arrayOfCities) => {
    const uniqueArray = [
        ...new Set(arrayOfCities.map((city) => city["שם_ישוב"]))
    ];
    const uniqueCities = uniqueArray.map((city) => {
        return { label: city, value: city };
    });

    return uniqueCities;
}

const translateStreets = (arrayOfStreets) => {
    const listOfStreets = arrayOfStreets.map((streetObject) => {
        return { label: streetObject["שם_רחוב"], value: streetObject["שם_רחוב"] };
    });

    return listOfStreets;
}

export {
    translateCities,
    translateStreets
}