import React from "react";
import { accessGlobalState } from '../../services/stateServices'

export default function LoadingBar(){
    const progressState = accessGlobalState().get()
    return (
    <>
      <div
        className={`loading-bar bg-primary text-primary w-${progressState === 'err' ? 0 : progressState}`}
        style={{ height: progressState === '0' ? '0px' : '5px' }}
      >&#65279;
      </div>
    </>
    )
}