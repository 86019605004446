import React, { useRef } from 'react'
import { updateData } from '../../../../services/putServices';
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

// open a task modal that show if lead address data is missing or incorrect, user can edit a lead address
const InvalidAddressModal = ({
  onHide,
  title,
  onDoubleClick,
  data
}) => {

  let history = useHistory()

  let primary_address_city_Ref = useRef()
  let street_c_Ref = useRef()
  let house_number_c_Ref = useRef()


  const handleSubmit = async () => {
    let bodyData = {
      primary_address_city: primary_address_city_Ref.current.value,
      street_c: street_c_Ref.current.value,
      house_number_c: house_number_c_Ref.current.value,
    }
    try {
      let updatedLead = await updateData(`leads/${data.lead.id}`, bodyData)
      if (updatedLead.id) {
        toast.success("הליד עודכן בהצלחה ")
        let url = history.location.pathname + history.location.search
        history.replace(url)

      }

    } catch (error) {
      console.log(error)
    }

    onHide()
  }

  return (<>
    {data && <VerticallyCenteredModal
      show={true}
      onHide={onHide}
      title={title}
      onDoubleClick={onDoubleClick}
    >
      <h2 className="my-3">טופס עדכון פרטים </h2>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlText1">
          <Form.Label>עיר</Form.Label>
          <Form.Control defaultValue={data.lead.primary_address_city || ""} type="text" ref={primary_address_city_Ref} placeholder="עיר" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlText2">
          <Form.Label>שם רחוב</Form.Label>
          <Form.Control defaultValue={data.lead.street_c || ""} type="text" ref={street_c_Ref} placeholder="שם רחוב" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlText3">
          <Form.Label>מספר בית</Form.Label>
          <Form.Control defaultValue={data.lead.house_number_c || ""} type="text" ref={house_number_c_Ref} placeholder="מספר בית" />
        </Form.Group>
        <Button variant="warning" onClick={() => handleSubmit()} >שלח</Button>
      </Form>
    </VerticallyCenteredModal>}
  </>)
}


export default InvalidAddressModal