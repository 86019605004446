import React, { useRef, useState } from 'react'
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// send template messages for selected leads rows in leads dynamic table 
export default function SendManyMessagesModal({
  modalShow,
  setModalShow,
  title,
  leadMsgTemplates = [],
  sendMessages,
  leadIdList,
  meetingleadIdList,
  sendCustomMsgs

}) {
  let selectRef = useRef()
  const [textState, setTextState] = useState('');
  const [pickedSelector, setPickedSelector] = useState('')
  const templateStyle = textState ? { pointerEvents: 'none', opacity: '0.2' } : { pointerEvents: 'auto', opacity: '1' };
  const textAreatemplateStyle = pickedSelector ? { pointerEvents: 'none', opacity: '0.2' } : { pointerEvents: 'auto', opacity: '1' };
  const { t, i18n } = useTranslation();
  const handleSend = (leadIdList, value, isCustom, meetingleadIdList) => {
    if (isCustom) {
      sendCustomMsgs(leadIdList, value, meetingleadIdList);
    } else {
      sendMessages(leadIdList, value, meetingleadIdList);
    }
    setTextState('');
    setPickedSelector('');
  }

  return (
    <VerticallyCenteredModal
      show={modalShow}
      onHide={() => {
        setTextState('');
        setPickedSelector('');
        setModalShow(false);
      }}
      title={title}
      onDoubleClick={(e) => { e.stopPropagation() }}
      size='xl'
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={`templates my-4`} style={templateStyle}>
          <h4>{t('message templates')}</h4>
          <select ref={selectRef} onChange={(e) => setPickedSelector(e.target.value)}>
            <option value="">{t('pick template')}</option>

            {leadMsgTemplates.map((item, i) => {
              return <option key={i} value={item.id}>{item.title}</option>
            })}
          </select>
        </div>
        <div className={`templates my-4`} style={{ flex: 1, marginRight: '10px' }}>
          <div style={textAreatemplateStyle}>
            <h4>{t('compose message')}</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control as="textarea" style={{ 'resize': 'none' }} rows={6} value={textState} onChange={(e) => setTextState(e.target.value)} />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <button className="btn btn-success" style={{ opacity: (textState || pickedSelector) ? 1 : 0.50, pointerEvents: (textState || pickedSelector) ? 'auto' : 'none' }} onClick={() => textState ? handleSend(leadIdList, textState, true, meetingleadIdList) : handleSend(leadIdList, selectRef.current.value, false, meetingleadIdList)}>{t('send')}</button>
    </VerticallyCenteredModal>
  )
}