import {
  LeadCellItem,
  ScheduledMeetingUserMeetingCellItem,
  CompletedUserMeetingCellItem,
  SentMessageCellItem,
  GenericCellItem,
  InboundCallCellItem,
  OutboundCallCellItem

} from './CustomCellTypes'
import { ImportActionsCellItem } from '../../../config/dynamicTable/imports/TableCells';
import { JoinedTextLinkCellItem, ToggleCompleteGroupedMeetingsCellItem, ButtonsGroupedMeetingLeadCellItem } from '../../../config/dynamicTable/meetingleads/TableCell/MeetingLeadsActions';
import LeadActionsCellItem from '../../../config/dynamicTable/leads/TableCell/LeadActionsCellItem';
import { MeetingsActionsCellItem } from '../../../config/dynamicTable/meetings/TableCells';
import { TemplateActionsCellItem } from '../../../config/dynamicTable/templates/TableCells';
import { TasksActionsCellItem } from '../../../config/dynamicTable/tasks/TableCells';
import FormSubmissionsActionsCellItem from '../../../config/dynamicTable/forms/TableCell/FormSubmissionsActionsCellItem';
import FormSubmissionsDownloadCellItem from '../../../config/dynamicTable/forms/TableCell/FormSubmissionFileDownloadCellItem';
import FormSubmissionsMultipleDownloadCellItem from '../../../config/dynamicTable/forms/TableCell/FormSubmissionFileMultipleDownloadCellItem';
import SearchCreateActionsCellItem from '../../../config/dynamicTable/meetingleads/TableCell/SearchCreateActionsCellItem'
import ReportFileDownloadCellItem from '../../../config/dynamicTable/reports/TableCell/ReportFileDownloadCellItem';
import organizeMeetingWazeSettlementLinkCustomCell from '../../../data_organizers/meeting_leads/meetingWazeSettlementLinkCustomCell';
import organizeGroupedFullAddressCustomCell from '../../../data_organizers/meeting_leads/groupedFullAddressCustomCell';
import organizeLeadWazeSettlementLinkCustomCell from '../../../data_organizers/leads/leadWazeSettlementLinkCustomCell';


// create a custom cell inside dynamic table
export const getCustomTableCell = (functionName, tableInfo, setData, extraData) => {
  const rowObject = tableInfo.data[tableInfo.row.index];
  switch (functionName) {
    case "defineActionType":
      // ** specific for history table, render an information text about a history action that was made 
      // with plain text and links 
      return actionType(tableInfo)
    case "joinedTextLink":
      // a custom table cell for dynamic meetings table in MeetingPage 
      return JoinedTextLinkCellItem(tableInfo, setData)
    case "meetingWazeSettlementLink":
      return organizeMeetingWazeSettlementLinkCustomCell(rowObject);
    case "toggleCompleteMeeting":
      return ToggleCompleteGroupedMeetingsCellItem(tableInfo, setData)
    case "groupedFullAddress":
      return organizeGroupedFullAddressCustomCell(rowObject);
    case "groupedMeetingLeadActions":
      return ButtonsGroupedMeetingLeadCellItem(tableInfo, setData)
    // all types with "Actions" suffix renders icons in each table row
    //  when user clicks on one of the icons, they preform an actions on the data "inside" the same row the icons are displayed     
    case "leadsActions":
      return LeadActionsCellItem(tableInfo, setData)
    case "leadWazeSettlementLink":
      return organizeLeadWazeSettlementLinkCustomCell(rowObject);
    case "importsActions":
      return ImportActionsCellItem(tableInfo, setData)
    case "meetingsActions":
      return MeetingsActionsCellItem(tableInfo, setData)
    // case "meetingsGroupedActions":
    //   return MeetingsGroupedActions(tableInfo, setData)
    case "templatesActions":
      return TemplateActionsCellItem(tableInfo, setData)
    case "tasksActions":
      return TasksActionsCellItem(tableInfo, setData)
    case "formSubmissionsActions":
      return FormSubmissionsActionsCellItem(tableInfo, setData, extraData)
    case "searchCreateActions":
      return SearchCreateActionsCellItem(tableInfo, setData)
    case "reportFileDownload":
      return ReportFileDownloadCellItem(tableInfo, setData)
    case "formsubmissionDownload":
      return FormSubmissionsDownloadCellItem(tableInfo, setData, extraData)
    case "formsubmissionMultipleDownloads":
      return FormSubmissionsMultipleDownloadCellItem(tableInfo, setData, extraData)

    default:
      return "";
  }
}

//extract the necessary information from each row data for creating a link or modify the current value  
const actionType = (tableInfo) => {

  let data = tableInfo.data[tableInfo.row.index];
  switch (data.type) {
    case "creation": return <LeadCellItem data={data} text="created lead" />
    case "update": return <LeadCellItem data={data} text="updated lead" />
    case "imported": return <LeadCellItem data={data} text="import created lead" />
    case "importedUpdate": return <LeadCellItem data={data} text="import updated lead" />
    case "formImport": return <LeadCellItem data={data} text="form created lead" />
    case "formImportUpdate": return <LeadCellItem data={data} text="form updated lead" />
    case "scheduledMeeting": return <ScheduledMeetingUserMeetingCellItem data={data} />
    case "completedMeeting": return <CompletedUserMeetingCellItem data={data} />
    case "sentMessage": return <SentMessageCellItem data={data} text="sentOne" />
    case "failedSentMessage": return <SentMessageCellItem data={data} text="failed sending" />
    case "autoSentMessage": return <SentMessageCellItem data={data} text="sentOne" />
    case "autoFailedSentMessage": return <SentMessageCellItem data={data} text="failed sending" />
    case "inboundCall": return <InboundCallCellItem data={data} />
    case "outboundCall": return <OutboundCallCellItem data={data} />
    case "changedIdentifier": return <GenericCellItem data={data} text="changed identifier field" />
    default: return ""
  }
}