import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, ListGroup, Form, Button, ButtonGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getData } from '../../services/getServices';
import { updateData } from '../../services/putServices';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import NotFoundPage from '../404page/notFoundPage';


export default function FieldsMatchingForm() {
    const [formObject, setFormObject] = useState(null);
    const [formFieldsState, setFormFieldsState] = useState(null);
    const [deliverAblePayload, setDeliverAblePayload] = useState({});
    const [configArray, setConfigArray] = useState([]);
    const [doneLoading, setDoneLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const params = useParams();
    const { formSubmissionId } = params;
    let history = useHistory()
    const { t } = useTranslation();


    const aquireRelevantData = async () => {
        const data = await getData(`formsubmissions/${formSubmissionId}`)
        if (data?.response?.status === 404) {
            setIsNotFound(true);
        }
        if (data) {
            const formId = data.form.id;
            const formData = await getData(`forms/${formId}`, ((data) => setFormObject(data)));
            const { connection, connectionPolicy, connectionFieldsConfig } = formData;

            if (connection === 'notDefined') {
                history.push(`/forms/submissions/connect/${data.id}`);
                return;
            } else if (connection === 'leads') {
                const externalFormFields = await getData('settingconfigs/externalformfields');
                const externalFormFieldsConfig = externalFormFields.configJson.map((nameObj) => nameObj.name);
                const filteredFieldsConfig = connectionFieldsConfig.filter(filteredObject => Object.keys(filteredObject).length === 2)
                let nameArr = [];
                externalFormFieldsConfig.forEach(formField => {
                    const foundExistingField = filteredFieldsConfig.find(fieldConfig => fieldConfig.name === formField);
                    nameArr.push({
                        fieldName: formField,
                        selected: foundExistingField ? true : false
                    });
                })
                setConfigArray(nameArr);
                setDeliverAblePayload({ ...deliverAblePayload, configuredFields: filteredFieldsConfig, connection, connectionPolicy })
            } else if (connection === 'meetings') {
                setDeliverAblePayload({ ...deliverAblePayload, meetingIdFieldName: connectionFieldsConfig.id, connection, connectionPolicy })
            }
            const nameLabelArray = formData.formfields.map((formField, idx) => formField.name);
            setFormFieldsState(nameLabelArray)
            setDoneLoading(true);
        } else {
            return
        }
    }

    useEffect(() => {
        aquireRelevantData()
    }, [])

    const staticFields = ['connection', 'connectionPolicy'];
    const correspondingExternalFormFields = {
        connection: ['leads', 'meetings'],
        connectionPolicy: ['manual', 'strict', 'normal', 'fluid']
    };
    const validMeetingsPolicies = ['manual', 'fluid'];

    const handleConfigFieldsChange = (e, field) => {
        const val = e.target.value;
        if (val === 'notDefined') {
            if (deliverAblePayload?.configuredFields) {
                const foundConfigFieldIndex = deliverAblePayload.configuredFields.findIndex(configField => configField.field === field);

                const deliverableCpy = { ...deliverAblePayload }
                const configArrayCpy = [...configArray]
                const configName = deliverableCpy.configuredFields[foundConfigFieldIndex].name;
                if (configName) {
                    const foundConfigItemIndexTwo = configArrayCpy.findIndex(configItem => configItem.fieldName === configName);
                    configArrayCpy[foundConfigItemIndexTwo].selected = false;
                }
                delete deliverableCpy.configuredFields[foundConfigFieldIndex].name;
                setConfigArray(configArrayCpy);
                setDeliverAblePayload(deliverableCpy);
            } else if (deliverAblePayload?.meetingIdFieldName || deliverAblePayload?.meetingIdFieldName === null) {
                const deliverableCpy = { ...deliverAblePayload }
                deliverableCpy.meetingIdFieldName = null;
                setDeliverAblePayload(deliverableCpy);
            }
        } else {
            if (deliverAblePayload?.configuredFields) {
                const foundConfigFieldIndex = deliverAblePayload.configuredFields.findIndex(configField => configField.field === field);

                if (foundConfigFieldIndex === -1) {
                    const deliverableCpy = { ...deliverAblePayload }
                    const configArrayCpy = [...configArray]
                    const foundConfigItemIndex = configArrayCpy.findIndex(configItem => configItem.fieldName === val);
                    configArrayCpy[foundConfigItemIndex].selected = true;
                    deliverableCpy.configuredFields.push({ name: val, field });
                    setConfigArray(configArrayCpy);
                    setDeliverAblePayload(deliverableCpy);
                } else {
                    const deliverableCpy = { ...deliverAblePayload }
                    const configArrayCpy = [...configArray]
                    const foundConfigItemIndex = configArrayCpy.findIndex(configItem => configItem.fieldName === val);
                    const configName = deliverableCpy.configuredFields[foundConfigFieldIndex].name;
                    if (configName) {
                        const foundConfigItemIndexTwo = configArrayCpy.findIndex(configItem => configItem.fieldName === configName);
                        configArrayCpy[foundConfigItemIndexTwo].selected = false;
                    }
                    configArrayCpy[foundConfigItemIndex].selected = true;
                    deliverableCpy.configuredFields[foundConfigFieldIndex].name = val;
                    setConfigArray(configArrayCpy);
                    setDeliverAblePayload(deliverableCpy);
                }
            } else if (deliverAblePayload?.meetingIdFieldName || deliverAblePayload?.meetingIdFieldName === null) {
                setDeliverAblePayload({ ...deliverAblePayload, meetingIdFieldName: val })
            }
        }
    }


    const handleStaticFormChange = (e, field) => {
        setDeliverAblePayload({ ...deliverAblePayload, [field]: e.target.value });
    }

    const renderNothing = (fieldObject, formFieldName) => {
        if (!fieldObject.selected) return false;
        const foundConfigField = deliverAblePayload.configuredFields.find(configField => configField.field === formFieldName);
        if (!foundConfigField) return true;
        if (foundConfigField?.name) {
            return fieldObject.fieldName === foundConfigField.name ? false : true;
        }
        return true;
    }
    const handleSub = async () => {
        const deliverAble = { ...deliverAblePayload, testSubmissionId: parseInt(formSubmissionId), formId: parseInt(formObject.id) }
        const res = await updateData(`forms/updateconnection/${formObject.id}`, deliverAble);
        if (res.id) {
            const backgroundProcess = res.connectionPolicy !== "manual" &&
                res.connectionTempStatus === 'active' && res.connectionStatus === 'flowing';
            toast.success(`${t('updated form connection')} ${res.id}`);
            backgroundProcess && toast.success(`${t('process happens in background')}`);
            history.push(`/forms/submissions/${formObject.id}`);
        } else if (res.statusCode && res.statusCode === 400) {
            if (res.message?.errorsArray && Array.isArray(res.message?.errorsArray)) {
                res.message?.errorsArray.forEach(error => toast.error(error?.errorMessage ? error.errorMessage : "error"));
            } else {
                toast.error(res.message?.invalidInfo ? res.message?.invalidInfo : "error");
            }
        }
    }

    if (isNotFound) {
        return <NotFoundPage />
    }
    return (
        <div className="container-fluid">
            {doneLoading &&
                <div className="mx-auto mt-5" >
                    <div style={{ display: 'flex' }} >
                        <Card className="mx-auto" >
                            <Card.Header>התניית תנאים</Card.Header>
                            <Card.Body>
                                <ListGroup variant='flush' >
                                    {staticFields && staticFields.map((field, fieldIndex) => (
                                        <ListGroup.Item key={fieldIndex} style={{ display: "flex", "flexDirection": "row-reverse", "justifyContent": "space-between" }}>
                                            <Form.Control as="select" defaultValue={formObject[field]} custom onChange={((e) => handleStaticFormChange(e, field))} disabled={field === 'connection'}  >
                                                {formObject.connection === 'leads' || field === 'connection' ? correspondingExternalFormFields[field].map((title, index) => <option key={index} value={title}>{title}</option>)
                                                    : validMeetingsPolicies.map((title, index) => <option key={index} value={title}>{title}</option>)}
                                            </Form.Control>
                                            <label>{field}</label>
                                        </ListGroup.Item>))}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                        <Card className="mx-auto">
                            <Card.Header>התאמת שדות</Card.Header>
                            <Card.Body>
                                {deliverAblePayload?.configuredFields && configArray ? <ListGroup variant='flush' >
                                    {formFieldsState && formFieldsState.map((field, fieldIndex) => (
                                        <ListGroup.Item key={fieldIndex} style={{ display: "flex", "flexDirection": "row-reverse", "justifyContent": "space-between" }}>
                                            <Form.Control defaultValue={deliverAblePayload.configuredFields.find(configField => configField.name && configField.field === field) ? deliverAblePayload.configuredFields.find(configField => configField.name && configField.field === field).name : "notDefined"} as="select" custom onChange={((e) => handleConfigFieldsChange(e, field))} >
                                                <option value={'notDefined'}>שדה לא קיים בקובץ</option>
                                                {configArray.map((fieldObject, index) => renderNothing(fieldObject, field) ? "" : <option key={index} value={fieldObject.fieldName}>{fieldObject.fieldName}</option>)}
                                            </Form.Control>
                                            <label>{field}</label>
                                        </ListGroup.Item>))}
                                </ListGroup>
                                    : deliverAblePayload?.meetingIdFieldName || deliverAblePayload?.meetingIdFieldName === null ? <ListGroup variant='flush' >
                                        <ListGroup.Item style={{ display: "flex", "flexDirection": "row-reverse", "justifyContent": "space-between" }}>
                                            <Form.Control defaultValue={deliverAblePayload?.meetingIdFieldName} as="select" custom onChange={((e) => handleConfigFieldsChange(e, 'meetingIdFieldName'))}>
                                                <option value={'notDefined'}>שדה לא קיים בקובץ</option>
                                                {formFieldsState && formFieldsState.map((title, index) => <option key={index} value={title}>{title}</option>)}
                                            </Form.Control>
                                            <label>{t('selected meeting field')}</label>
                                        </ListGroup.Item>
                                    </ListGroup> : <h1>none</h1>}
                            </Card.Body>
                        </Card>
                    </div>
                    <ButtonGroup style={{ flexDirection: 'row-reverse' }}>
                        <Button disabled={!deliverAblePayload?.meetingIdFieldName && !deliverAblePayload?.configuredFields} onClick={handleSub}>{t('submit')}</Button>
                        <Button variant="primary" onClick={() => history.push(`/forms/submissions/${formObject.id}`)}>{t('return')}</Button>
                    </ButtonGroup>
                </div>
            }
        </div>
    )
}
