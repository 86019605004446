import React from "react";

export function PhoneLink({ display, phoneNumber }) {
  // you can use display to make "Call Now" kind of links
  return (
    <>
      {phoneNumber ? (
        <a href={`tel:${phoneNumber}`}>{display || phoneNumber}</a>
      ) : (
        "אין"
      )}
    </>
  );
}
