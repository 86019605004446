import { getItemFromLocalStorage } from "../../helpers/auth";
import leadObject from "./main";

const organizeExpiringSoonModal = (lead) => {
  // set lead fields values
  Object.values(leadObject).forEach((field) => {
    if (field.accessor) {
      field.value = lead[field.accessor];
    }
    // boolean type nnd falsy
    if (field.custom) {
      field.customValue = () => field.custom(lead);
    }
    if (field.value === null || field.value === undefined) {
      field.value = "אין";
    }
  });

  const expiringSoonModalData = {
    expiringSoonModalOrganizerDemo: [
      {
        Header: "פרטי התקשרות",
        fields: [
          leadObject.first_name,
          leadObject.last_name,
          leadObject.phone_number,
          leadObject.email,
        ],
      },
      {
        Header: "פרטים נוספים",
        fields: [
          leadObject.city,
          leadObject.street,
          leadObject.house_number,
          leadObject.comment,
        ],
      },
    ],
    expiringSoonModalOrganizerFirstSteps: [{
      Header: "פרטי התקשרות",
      fields: [
        leadObject.first_name,
        leadObject.last_name,
        leadObject.phone_mobile,
        leadObject.phone_work,
        leadObject.email,
      ],
    },
    {
      Header: "פרטים נוספים",
      fields: [
        leadObject.primary_address_city,
        leadObject.street_c,
        leadObject.house_number_c,
        leadObject.sector_c,
        leadObject.babys_birth_date_c,
        leadObject.comment_c,
      ],
    },]
  };

  return expiringSoonModalData[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.lead.expiringSoonModalOrganizer : "expiringSoonModalOrganizerDemo"];
};

export default organizeExpiringSoonModal;
