
import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { updateData } from "../../services/putServices";
import { sendDataResponse } from "../../services/postServices";
import { getData } from '../../services/getServices';
import NotFoundPage from "../404page/notFoundPage";
import translateContent from '../../helpers/translateMarkup'
import templatesFormOrgnaizer from '../../data_organizers/templates/templateFormOrganizer'
import meetingExample from '../../components/form_grid/meetingExample.js'
import leadExample from '../../components/form_grid/leadExample.js';
import InputTextareaSelect from '../../components/input_textarea_select/inputTextareaSelect'
import Select from "react-select";
import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function TemplateForm({ setMessage }) {
  const { action, id } = useParams();
  let history = useHistory();
  const { t } = useTranslation();
  const [initialTemplate, setIntialTemplate] = useState({
    meeting_related: false,
    designated_to: null,
    title: "",
    content: ""
  });
  const [template, setTemplate] = useState({
    meeting_related: false,
    designated_to: null,
    title: "",
    content: ""
  });
  const [initialTemplateWords, setInitialTemplateWords] = useState([]);
  const [templateWords, setTemplateWords] = useState([]);
  const [isNotFound, setIsNotFound] = useState(false);
  const [leadPlaceholders, setLeadPlaceholders] = useState(null);
  const [meetingPlaceholders, setMeetingPlaceholders] = useState(null);
  const [doneLoading, setDoneLoading] = useState(false);


  const TEMPLATE = `templates/${id}`;
  const TEMPLATEWORDS = 'templatewords';

  const meetingRelatedDesignatedArr = [
    "lead",
    "meetingAssignee"
  ];
  const leadRelatedDesignatedArr = [
    "lead"
  ];

  const returnCorrectTemplateWords = (providedTemplateWords = initialTemplateWords) => {
    if (!template.meeting_related) {
      return providedTemplateWords.filter(word => word.meetingWord === false);
    }
    return providedTemplateWords;
  }

  const fetchData = async () => {
    if ((action !== 'edit' && action !== 'create')) {
      setIsNotFound(true)
      return;
    }
    await getData(TEMPLATEWORDS, (res) => {
      setInitialTemplateWords(res);
      setTemplateWords(returnCorrectTemplateWords(res));
    });
    setMeetingPlaceholders(await meetingExample());
    setLeadPlaceholders(await leadExample());
    if (action === 'create') {
      setDoneLoading(true);
      return
    };

    const data = await getData(TEMPLATE);
    if (data?.response?.status === 404) {
      setIsNotFound(true)
      return;
    }
    setIntialTemplate(data);
    setTemplate(data);
    setDoneLoading(true);
  }

  useEffect(() => {
    fetchData();
  }, [])


  useEffect(() => {
    if (![...(template.meeting_related ?
      meetingRelatedDesignatedArr :
      leadRelatedDesignatedArr)].find(label => label === template.designated_to)) {
      setTemplate({
        ...template,
        designated_to: null
      });
    }
    setTemplateWords(returnCorrectTemplateWords());
  }, [template.meeting_related])

  const putTemplate = async () => {
    try {
      const res = await updateData(`templates/${id}`, template)
      setMessage(`תבנית ${id} עודכנה בהצלחה!`)
      history.push(`/templates`)
    } catch (err) {
      console.log(err)
    }
  }
  const postTemplate = async () => {
    try {
      const res = await sendDataResponse(`templates`, template)
      const newId = res.data.id;
      setMessage(`תבנית ${newId} נוצרה בהצלחה!`)
      history.push(`/templates`)
    } catch (err) {
      toast.error("השתבש משהו");
      console.log(err)
    }
  }

  const formType = {}
  switch (action) {
    case 'edit':
      formType.header = `עריכה`;
      formType.func = putTemplate;
      formType.submitTxt = 'עדכן';
      break;
    case 'create':
      formType.header = `צור תבנית`
      formType.func = postTemplate;
      formType.submitTxt = 'צור';
      break;
    default:
    // do nothing
  }

  if (isNotFound) {
    return <NotFoundPage />
  }
  return (
    <>
      {doneLoading &&
        <div>
          <CardDeck style={{ padding: "1rem 5rem", display: "flex", justifyContent: "space-around" }}>

            <Card>
              <Card.Header>הגדרות</Card.Header>
              <Card.Body>
                <div className="form-check form-switch" >
                  <input onChange={() => {
                    setTemplate({
                      ...template,
                      meeting_related: template.meeting_related ? false : true
                    });
                  }} checked={template.meeting_related ? true : false} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                  <label className="form-check-label" for="flexSwitchCheckDefault">{t("meeting related")}</label>
                </div>
                <label>{t("send to")}</label>
                <Select
                  value={template.designated_to ? { value: template.designated_to, label: t(template.designated_to) } : null}
                  onChange={(selectedValueObject) => {
                    if (!selectedValueObject) {
                      setTemplate({
                        ...template,
                        designated_to: null
                      });
                      return;
                    }
                    setTemplate({
                      ...template,
                      designated_to: selectedValueObject.value
                    });
                  }}
                  isClearable
                  options={[...(template.meeting_related ?
                    meetingRelatedDesignatedArr :
                    leadRelatedDesignatedArr)].map((item) => ({ value: item, label: t(item) }))}
                  placeholder={t("send to")}
                >
                </Select>
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>{formType.header}</Card.Header>
              <Card.Body>
                {templatesFormOrgnaizer.map(field =>
                  <React.Fragment>
                    <label style={{ display: 'block' }}>{field.Header}</label>
                    <InputTextareaSelect
                      type={field.type}
                      rows="10"
                      value={template[field.accessor]}
                      onChange={(e) => setTemplate({ ...template, [field.accessor]: e.target.value })}
                      onBlur={() => { }}
                      selectArray={field.selectArray || []}
                    />

                  </React.Fragment>)}
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>מילים אפשרויות</Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item action onClick={() => { toast.success("אפשרות הועתקה ללוח!"); navigator.clipboard.writeText("{unsubscribe}") }}>
                    {"{unsubscribe}"}
                  </ListGroup.Item>
                  <ListGroup.Item action onClick={() => { toast.success("אפשרות הועתקה ללוח!"); navigator.clipboard.writeText("{link=LINK_URL}") }}>
                    {"{link=LINK_URL}"}
                  </ListGroup.Item>
                  {
                    templateWords.map(word => (
                      <ListGroup.Item action onClick={() => { toast.success("אפשרות הועתקה ללוח!"); navigator.clipboard.writeText(word.word) }}>
                        {word.word}
                      </ListGroup.Item>
                    ))
                  }
                </ListGroup>
              </Card.Body>


            </Card>
            <Card>
              <Card.Header>תצוגה מקדימה</Card.Header>
              <Card.Body>
                <h6>{template.title}</h6>
                {(leadPlaceholders && meetingPlaceholders && template.content && templateWords) && <textarea value={translateContent(leadPlaceholders, template.content, templateWords, meetingPlaceholders)} rows={10} disabled={true} style={{ width: "100%" }} />}
              </Card.Body>
            </Card>

          </CardDeck>
          <ButtonGroup style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse' }}>
            <Button
              disabled={!template.designated_to || !template.title || !template.content}
              onClick={() => formType.func()}>
              {formType.submitTxt}
            </Button>
            <Button onClick={() => history.goBack()}>ביטול</Button>
            <Button onClick={() => setTemplate(initialTemplate)}>איפוס</Button>
          </ButtonGroup>

        </div>
      }
    </>
  )
}

export default TemplateForm