import { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { sendDataResponse } from '../../../../services/postServices'
export default function SearchCreateActionsCellItem(tableInfo, setData) {
    const params = useParams();
    const { t, i18n } = useTranslation();

    let history = useHistory();
    const { leadId } = params;
    let meetingLead = tableInfo.data[tableInfo.row.index]

    const handleMeetingStaticInterval = async (timeDirectionBool) => {
        const { assigned_to, specific_datetime } = meetingLead;
        let timeValue = specific_datetime;
        if (timeDirectionBool) {
            timeValue = moment(specific_datetime).add(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
        } else {
            timeValue = moment(specific_datetime).subtract(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
        }
        let typeObj = {};
        if (meetingLead?.meeting_type_c) {
            typeObj['meeting_type_c'] = meetingLead.meeting_type_c
        }
        const res = await sendDataResponse(
            'meetingleads',
            { specific_datetime: timeValue, assigned_to: assigned_to.id, lead: leadId, ...typeObj }
        )
        if (res?.status === 400) {
            toast.warning(res.message)
        } else if (res?.data?.id) {
            toast.success(t('Created a meetinglead'))
            history.push(`/meetings/grouped/${res.data.id}`)
        }

    }


    return (
        <div className="d-flex justify-content-center align-items-center pb-3" >

            <div className="mx-1" >
                <FontAwesomeIcon onClick={(() => handleMeetingStaticInterval(false))} data-delay-hide='100' data-delay-show='500' data-tip="Set in 30 minutes before scheduled time" icon={faMinus} className="edit_icon" size="2x" transform='right-4' />
                <FontAwesomeIcon onClick={(() => handleMeetingStaticInterval(true))} data-delay-hide='100' data-delay-show='500' data-tip="Set in 30 minutes after scheduled time" icon={faPlus} className="edit_icon" size="2x" />
            </div>
            <ReactTooltip place="top" type="dark" effect="float" />
        </div>)
}



