import axios from "axios";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getItemFromLocalStorage } from "../../helpers/auth";
import { getJwt } from "../loginServices";
import * as engJson from './Languages/en.json';
import * as hebJson from './Languages/he.json';

const refreshTranslations = async () => {
    const translationsHash = getItemFromLocalStorage("translationsHash");
    const jwt = await getJwt();
    const translationsRes = await axios.get(`${process.env.REACT_APP_API_PATH}settingconfigs/translations`,
        {
            headers: {
                Authorization: `Bearer ${jwt}`,
                etag: translationsHash
            }
        }
    );
    if (!translationsRes?.data) {
        const translationsArray = getItemFromLocalStorage("translations");
        if (!translationsArray) {
            localStorage.removeItem("translations");
            localStorage.removeItem("translationsHash");
            refreshTranslations();
        }
        return translationsArray;
    }
    localStorage.setItem('translations', JSON.stringify(translationsRes?.data.translations));
    localStorage.setItem('translationsHash', translationsRes?.data.hashedTranslations);
    return translationsRes?.data.translations;
}

let lng;
if (getItemFromLocalStorage("lang")) {
    lng = getItemFromLocalStorage("lang");
} else {
    localStorage.setItem('lang', 'he');
    lng = 'he';
}
let resources = {
    en: engJson.default,
    he: hebJson.default
};

const langConfigs = getItemFromLocalStorage("translations");
if (langConfigs && langConfigs.length > 0) {
    langConfigs.forEach(langConfig => {
        if (langConfig?.configJson?.langName && langConfig?.configJson?.translations) {
            resources[langConfig?.configJson?.langName].translation = {
                ...resources[langConfig?.configJson?.langName].translation,
                ...langConfig?.configJson?.translations
            }
        }
    });
}


i18n
    .use(initReactI18next)
    .init({
        partialBundledLanguages: true,
        resources,
        lng,

        interpolation: {
            escapeValue: false
        }
    }, async () => {
        const langConfigs = await refreshTranslations();
        if (langConfigs && langConfigs.length > 0) {
            langConfigs.forEach(langConfig => {
                if (langConfig?.configJson?.langName && langConfig?.configJson?.translations) {
                    i18n.addResourceBundle(langConfig?.configJson?.langName, "translation", langConfig?.configJson?.translations, false, true);
                }
            });
        }
    });
export default i18n;
