import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import Login from './pages/login/login';
import Navbar from './components/navbar/Navbar';
import LoadingBar from './components/LoadingBar/LoadingBar';

import LeadPage from './pages/lead_page/leadPage';
import LeadForm from './pages/lead_form/leadForm';

import MeetingView from './pages/meetings_view/meetingView';
import NewMeetingForm from './pages/new_meeting_form/newMeetingForm';
import MeetingForm from './pages/meeting_form/meetingForm';
import MeetingPage from './pages/meeting_page/meetingPage';
import GroupedMeetingLeads from './pages/grouped_meeting_leads/groupedMeetingLeads'
import SpecificMeetingLead from './pages/specific_meeting_lead/specificMeetingLead'
import SearchCreateView from './pages/searchcreate_meeting_table/searchCreateView'

import ImportsView from './pages/imports_view/importsView';
import ImportPage from './pages/import_page/importPage';
import ImportForm from './pages/import_form/importForm';
import ImportLeadsFromFile from './pages/import_leads_from_file/importLeadsFromFile';

import TemplatesView from './pages/templates_view/templatesView';
import TemplateForm from './pages/template_form/templateForm';

import UserPage from './pages/user_page/userPage';
import UserForm from './pages/user_form/userForm';

import AdminScreen from './pages/admin_screen/adminScreen';
import NotFoundPage from './pages/404page/notFoundPage';
import ErrorPage from './pages/500page/errorPage';
import RestrictedAccessPage from './pages/403page/restrictedAccessPage'

//forms table components 
import FormsView from './pages/forms_page/formsPage';
import FormsSubmissionsPage from './pages/forms_submissions_page/formSubmissionPage';
import FieldsMatchingForm from './pages/forms_field_matching/fieldsMatchingForm';
import UpdateFieldsMatchingForm from './pages/update_forms_field_matching/fieldsMatchingForm';
import InduvidualSubmissonPage from './pages/forms_submisson_page/induvidualSubmissonPage'

import LeadsPage from '../src/pages/leads';
import LeadsourcesPage from '../src/pages/leadsources';

import InstructorPage from './pages/instructor-page/instructorPage'

import { ToastContainer } from 'react-toastify';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import './config/dynamicTable/css/dynamicTable.css'
import './pages/instructor-page/components/style.css'
import MessagesPage from "./pages/messages/messagesPage";
import ReportsPage from "./pages/reports/reportsPage";
import TasksPage from "./pages/tasks/tasksPage";
import ImportEdit from "./pages/importEdit/importEdit";
import Statistics from "./pages/statistics/statistics";
import { ModalProvider } from "./context/ModalProvider"
import { useGlobalState } from './services/stateServices'
import { useTranslation } from 'react-i18next';
import { getItemFromLocalStorage, grantPermissionForEndpoint, logOut, returnMainPath } from './helpers/auth';
import { encryptStorage } from './services/encryptedSessionStorage';
import { getResponse } from './services/getServices';
import decodeJWT from 'jwt-decode';
import Loader from './components/Loader/Loader';
import { refreshFeatureFlags } from './services/loginServices';


function App() {
  const { t } = useTranslation();
  const [globalMessage, setGlobalMessage] = useState(null);
  const [doneCheckingAuth, setDoneCheckingAuth] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [reneredPageCode, setReneredPageCode] = useState(200);
  const progressState = useGlobalState();
  let history = useHistory();

  const setMessage = (message) => {
    setGlobalMessage(message);
    setTimeout(() => setGlobalMessage(null), 10000);
  };


  const authHandler = async (history) => {
    const localToken = getItemFromLocalStorage("jwt");
    let localUser = getItemFromLocalStorage("user");
    let endpoints = encryptStorage.getItem('endpoints');
    const pathname = history.location.pathname;
    if (pathname === "/" && endpoints && Array.isArray(endpoints)) { //redirects to main page defined for this role incase there is no path
      history.push(returnMainPath(endpoints));
      return {
        loggedInStatus: localUser ? true : false,
        chagingLocation: true
      };
    }
    if (!localToken) {
      const routeToPushTo = await logOut();
      if (routeToPushTo !== history.location.pathname) {
        history.push(routeToPushTo);
      }
      return {
        loggedInStatus: false,
        chagingLocation: routeToPushTo !== history.location.pathname
      };
      // Checks if there is a jwt, if there isn't then it logs you out.
    } else {
      let decodedJWT = decodeJWT(localToken);
      let JWTExp = decodedJWT.exp
      if (Date.now() >= JWTExp * 1000) {
        const routeToPushTo = await logOut();
        if (routeToPushTo !== history.location.pathname) {
          history.push(routeToPushTo);
        }
        return {
          loggedInStatus: false,
          chagingLocation: routeToPushTo !== history.location.pathname
        };
      }
      refreshFeatureFlags();
      // If there is a jwt it checks if it's valid, if it isn't then it logs you out.
    }
    if (!localUser || (localUser && !localUser?.role)) {
      try {
        const res = await getResponse("users/me")

        if (!res.status === 200) {
          throw res;
        } else {
          localStorage.setItem("user", JSON.stringify(res.data))
          localUser = getItemFromLocalStorage("user");
        }
      } catch (err) {
        console.log(`err`, err);
        const routeToPushTo = await logOut();
        if (routeToPushTo !== history.location.pathname) {
          history.push(routeToPushTo);
        }
        return {
          loggedInStatus: false,
          chagingLocation: routeToPushTo !== history.location.pathname
        };
      }
      // If there isn't a user(or role) then it tries re-fetching the user and adding it to localstorage, logout on fail.
    }

    // Redirects to the corresponding main page.
    if (!endpoints || !Array.isArray(endpoints)) {
      try {
        const res = await getResponse("settingconfigs/rolerestrictions");

        if (!res.status === 200) {
          throw res;
        } else {
          encryptStorage.setItem('endpoints', res?.data);
          endpoints = res?.data;
          if (pathname === "/") { //redirects to main page defined for this role incase there is no path(after fixing endpoints)
            history.push(returnMainPath(endpoints));
            return {
              loggedInStatus: localUser ? true : false,
              chagingLocation: true
            };
          }
        }
      } catch (err) {
        console.log(`err`, err);
        const routeToPushTo = await logOut();
        if (routeToPushTo !== history.location.pathname) {
          history.push(routeToPushTo);
        }
        return {
          loggedInStatus: false,
          chagingLocation: routeToPushTo !== history.location.pathname
        };
      }
      // If there isn't a user(or role) then it tries re-fetching the user and adding it to localstorage, logout on fail.
    }
    const permissonToEndpoint = grantPermissionForEndpoint(pathname, endpoints);
    setReneredPageCode(permissonToEndpoint);

    return {
      loggedInStatus: true
    };
  };

  useEffect(() => {
    document.title = t('name');
    const authCheck = async () => {
      setDoneCheckingAuth(false);
      setReneredPageCode(200);
      const { loggedInStatus, chagingLocation } = await authHandler(history);
      setIsLoggedIn(loggedInStatus)
      setDoneCheckingAuth(!chagingLocation); //make sure to tell the app that we are not done checking the auth(due to a redirect)
    }

    authCheck();
  }, [history.location]) // Pretty much triggers on any location change in the browser, so we can check the auth of the user.




  return (
    <div className='App'>
      {progressState.get() && <LoadingBar />}
      {(doneCheckingAuth && //make sure to load navbar only when done checking the user
        isLoggedIn &&
        getItemFromLocalStorage("user")) && //second and third conditions are to see if the user logged in
        reneredPageCode === 200 && //only show navbar if there wasn't an error loading a page
        (history.location.pathname !== "/error-page" &&
          history.location.pathname !== "/restrict-page" &&
          history.location.pathname !== "/not-found-page") && //exclude this testing routes from showing navbar
        <Navbar user={getItemFromLocalStorage("user")} endpoints={encryptStorage.getItem('endpoints')} />}
      {globalMessage && <div>{globalMessage}</div>}
      {
        !doneCheckingAuth ?
          <Loader />
          :
          reneredPageCode === 403 ?
            <RestrictedAccessPage handleUnrestrictPage={() => setReneredPageCode(200)} />
            :
            <ModalProvider>
              <Switch>

                <Route exact path="/login">
                  <Login />
                </Route>

                <Route path='/leads' exact>
                  <LeadsPage doneCheckingAuth />
                </Route>
                <Route path='/leads/:id' exact>
                  <LeadPage />
                </Route>
                <Route path='/leads/:action/:id' exact>
                  <LeadForm setMessage={setMessage} />
                </Route>
                <Route path='/leads/:action/:id/relate/:relateId' exact>
                  <LeadForm setMessage={setMessage} />
                </Route>
                <Route path='/leadsources' exact>
                  <LeadsourcesPage />
                </Route>

                <Route path='/imports' exact>
                  <ImportsView />
                </Route>
                <Route path='/imports/:id' exact>
                  <ImportPage setMessage={setMessage} />
                </Route>
                <Route path='/imports/edit/:id' exact>
                  <ImportEdit setMessage={setMessage} />
                </Route>
                <Route path='/imports/create/new' exact>
                  <ImportForm setMessage={setMessage} />
                </Route>
                <Route path='/imports/import-from-file/:id' exact>
                  <ImportLeadsFromFile setMessage={setMessage} />
                </Route>

                <Route path='/meetings' exact>
                  <MeetingView />
                </Route>
                <Route path='/meetings/:id' exact>
                  <MeetingPage setMessage={setMessage} />
                </Route>
                <Route path='/meetings/create/:leadId' exact>
                  <NewMeetingForm setMessage={setMessage} />
                </Route>
                <Route path='/meetings/searchcreate/:leadId' exact>
                  <SearchCreateView setMessage={setMessage} />
                </Route>
                <Route path='/meetings/edit/:id' exact>
                  <MeetingForm setMessage={setMessage} />
                </Route>

                <Route path='/meetings/grouped/:meeting_id' exact>
                  <GroupedMeetingLeads setMessage={setMessage} />
                </Route>
                <Route path='/meetings/specific/:meeting_id' exact>
                  <SpecificMeetingLead setMessage={setMessage} />
                </Route>

                <Route path='/templates' exact>
                  <TemplatesView setMessage={setMessage} />
                </Route>
                <Route path='/templates/:action/:id' exact>
                  <TemplateForm setMessage={setMessage} />
                </Route>

                <Route path='/users/:id' exact>
                  <UserPage setMessage={setMessage} />
                </Route>
                <Route path='/users/edit/:id' exact>
                  <UserForm setMessage={setMessage} />
                </Route>

                <Route path='/messages' exact>
                  <MessagesPage setMessage={setMessage} />
                </Route>

                <Route path='/tasks' exact>
                  <TasksPage setMessage={setMessage} />
                </Route>
                <Route path='/instructorpanel' exact>
                  <InstructorPage />
                </Route>

                <Route path='/statistics' exact>
                  <Statistics setMessage={setMessage} />
                </Route>
                <Route path='/forms' exact>
                  <FormsView />
                </Route>
                <Route path='/forms/submissions/:formId' exact>
                  <FormsSubmissionsPage setMessage={setMessage} />
                </Route>
                <Route path='/forms/submissions/connect/:formSubmissionId' exact>
                  <FieldsMatchingForm setMessage={setMessage} />
                </Route>
                <Route path='/forms/submissions/update/:formSubmissionId' exact>
                  <UpdateFieldsMatchingForm setMessage={setMessage} />
                </Route>
                <Route path='/forms/submissions/page/:formSubmissionId' exact>
                  <InduvidualSubmissonPage setMessage={setMessage} />
                </Route>

                <Route path='/reports' exact>
                  <ReportsPage setMessage={setMessage} />
                </Route>

                <Route path='/admin' exact>
                  <AdminScreen setMessage={setMessage} />
                </Route>
                {/* test pages */}
                {/* error pages */}
                <Route path='/error-page' exact>
                  <ErrorPage setMessage={setMessage} />
                </Route>
                <Route path='/restrict-page' exact>
                  <RestrictedAccessPage />
                </Route>
                <Route path='/not-found-page' exact>
                  <NotFoundPage />
                </Route>
                <Route path="*" component={NotFoundPage} />

              </Switch>
            </ModalProvider>
      }
      <div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
