import React from 'react';
import { useState, useEffect } from 'react';
import { BiTimeFive } from "react-icons/bi"
import './TimePicker.css'

export function TimePicker({value, jump=1, min='00:00', max='23:59', onChange}) {
    const [minuteArray, setMinuteArray] = useState([]);
    const [hourArray, setHourArray] = useState([]);
    const [hour, setHour] = useState(null);
    const [minute, setMinute] = useState(null);
    
    const createMinuteOptions = () => {
        let tempOptions = []
        let minMinute = Number(min.split(":")[1]);
        for(let i = minMinute; i <= 59; i += jump){
            tempOptions.push(("0" + i).slice(-2))
        }
        setMinuteArray(tempOptions);
    }

    const createHourOptions = () => {
        let tempOptions = []
        let minHour = Number(min.split(":")[0]); 
        let maxHour = Number(max.split(":")[0]);
        for(let i = minHour; i <= maxHour; i++){
            tempOptions.push(("0" + i).slice(-2))
        }
        setHourArray(tempOptions);
    }

    const handleChange = (event) => {
        event.target.name === 'minutes' && setMinute(event.target.value)
        event.target.name === 'hours' && setHour(event.target.value)
    }

    useEffect(() => {
        createHourOptions()
        createMinuteOptions()
        setHour(value ? value.split(':')[0] : min.split(':')[0])
        setMinute(value ? value.split(':')[1] : min.split(':')[1])

    }, [])

    useEffect(() => {
        if (hour && minute)
        {
            if (value && (minute !== value.split(':')[1] || hour !== value.split(':')[0])){
                onChange(`${hour}:${minute}`)
                
            } else if(!value) {
                onChange(`${hour}:${minute}`)
            }
        }

    }, [minute, hour])

    return (
        <>
            <div className='time-picker-container'>

                <select 
                    value={minute}
                    className='time-select'
                    onChange={handleChange}
                    id='minutes'
                    name='minutes'>
                        {minuteArray.map(option => <option value={option}>{option}</option>)}
                </select>

                <select 
                    value={hour}
                    className='time-select'
                    onChange={handleChange}
                    id='hours'
                    name='hours'>
                        {hourArray.map(option => <option value={option}>{option}</option>)}
                </select>

                <BiTimeFive style={{marginRight: '0.25rem'}}/>
            </div>
        </>
    )
}