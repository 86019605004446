import React from "react";
import DynamicTableContainer from "../../components/dynamicTable/dynamicTableContainer";

import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export default function LeadsPage(props) {
	const { t } = useTranslation();
	let history = useHistory();

	const handleTableRowDoubleClick = (e, TableRow, history, blank) => {
		e.preventDefault();
		let leadId = TableRow.id;
		blank
			? window.open(window.location.href.split("?")[0] + `/${leadId}`, "_blank")
			: history.push("/leads/" + leadId);
	};


	return (
		<>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					margin: "10px auto",
					width: "18%",
				}}
			>
				<Button onClick={() => history.push(`/leadsources`)}>
					{t("leadsources")}
				</Button>
				<Button disabled={true}>{t("leads")}</Button>
			</div>
			<Button
				variant="primary"
				onClick={() => history.push(`/leads/create/new`)}
			>
				{t("create lead")}
			</Button>
			<DynamicTableContainer
				pagesRange={4}
				initialEndpoint="leads"
				perPage={50}
				handleTableRowDoubleClick={handleTableRowDoubleClick}
				schemaName={{
					TABLE_API: "leads/TableSchema?schema_name=leadsPage",
					FORM_API: "leads/filterSchema?schema_name=leadsPage",
				}}
			/>
		</>
	);
}
