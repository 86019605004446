import React,{useState} from "react";
import AlertModal from "../components/modal/AlertModal/AlertModal";

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
    const [IsModalOpen, setIsModalOpen] = useState(null);

  return (
    <Provider value={{IsModalOpen, setIsModalOpen}}>
      <AlertModal  ModalSettings={IsModalOpen} setOpen={setIsModalOpen} />
	  {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };