import axios from 'axios'
import { getJwt } from './loginServices'
import { setProgressBar } from './progressServices';
import { showToastError } from './toastServices';

export const deleteData = async (endPoint, callback, returnJustData) => {
	try {
		setProgressBar('start');
		const localToken = await getJwt();
		const res = await axios.delete(process.env.REACT_APP_API_PATH + endPoint, {
			headers: {
				Authorization: `Bearer ${localToken}`,
			},
		});

		if (callback) {
			callback(res);
		}
		setProgressBar('complete');
		return returnJustData ? res.data : res;
	} catch (err) {
		showToastError(err);
		setProgressBar('fail');
		return returnJustData ? err.response.data : err.response;
	}
}
