import React, { useState } from 'react'
import DynamicTableContainer from '../../components/dynamicTable/dynamicTableContainer';
import VerticallyCenteredModal from '../../components/modal/VerticallyCenteredModal';
import { Link } from 'react-router-dom';

export default function MessagesPage(props) {
  const [modalShow, setModalShow] = useState(false);
  const [data, setData] = useState(false)

  const handleTableRowDoubleClick = (e, data, history) => {
    setData(data)
    setModalShow(true)
  }

  const showMessageType = (type, data) => {

    if (type === "template_message") {
      if (data.template) {
        return "תבנית"
      } else {
        return "לא קיימת תבנית"
      }
    } else if (type === "written_message") {
      return "רגיל"
    }
  }

  return (
    <>

      {data && <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={`מספר הודעה ${data.id}`}
        onDoubleClick={(e) => { e.stopPropagation() }}
      >

        <h2 className="h5 my-3">פלאפון: {data.phone_number}</h2>
        <h3 className="h5 my-3">הודעה התקבלה: {data.received ? "כן" : "לא"}</h3>
        <h3 className="h5 my-3">סוג הודעה: {showMessageType(data.message_type, data)}</h3>
        {data?.template?.id &&
          <div>
            <Link to={`/templates/edit/${data.template.id}`}>קישור לתבנית</Link>
          </div>
        }
        <p className="h5 my-3 text-decoration-underline">תוכן
        </p>
        <pre className="border border-1 border-secondary p-3">
          <bdi>
            {data.content}
          </bdi>
        </pre>
      </VerticallyCenteredModal>
      }

      <DynamicTableContainer
        pagesRange={4}
        initialEndpoint="messages"
        perPage={50}
        handleTableRowDoubleClick={handleTableRowDoubleClick}
        schemaName={{ TABLE_API: "messages/TableSchema?schema_name=messagesPage", FORM_API: "messages/filterSchema?schema_name=messagesPage" }}
      />
    </>
  )
}

