import moment from "moment";
import { Link } from "react-router-dom";
import { WazeLink } from "../../components/WazeLink/WazeLink";
import { PhoneLink } from "../../components/PhoneLink/PhoneLink";
import { getItemFromLocalStorage } from "../../helpers/auth";

const leadObject = {
  leadOrganizerDemo: {
    full_name: {
      Header: "שם",
      type: "text",
      custom: (lead) => `${lead.first_name} ${lead.last_name}`,
    },
    first_name: {
      Header: "שם פרטי",
      accessor: "first_name",
      type: "text",
    },
    last_name: {
      Header: "שם משפחה",
      accessor: "last_name",
      type: "text",
    },
    phone_number: {
      Header: "טלפון אישי",
      accessor: "phone_number",
      type: "tel",
      custom: (lead) => {
        return PhoneLink({ phoneNumber: lead.phone_number });
      },
    },
    email: {
      Header: 'דוא"ל',
      accessor: "email",
      type: "email",
      custom: (lead) => {
        return lead.email ? (
          <a href={`mailto:${lead.email}`}>{lead.email}</a>
        ) : (
          "אין"
        );
      },
    },
    address: {
      Header: "כתובת",
      type: "text",
      custom: (lead) =>
        `${lead.street || ""} ${lead.house_number || ""}, ${lead.city || ""
        }`,
    },
    link_address: {
      Header: "פרטי כתובת",
      type: "text",
      custom: (lead) => {
        return (
          <WazeLink city={lead.city} street={lead.street} houseNumber={lead.house_number} displayText={lead.city} />
        );
      },
    },
    city: {
      Header: "ישוב",
      accessor: "city",
      type: "text",
    },
    street: {
      Header: "רחוב",
      accessor: "street",
      type: "text",
    },
    house_number: {
      Header: "מספר בית",
      accessor: "house_number",
      type: "text",
    },
    next_meeting: {
      Header: "הפגישה הקרובה",
      type: "date",
      accessor: "upcoming_meeting",
      custom: (lead) =>
        lead.upcoming_meeting ? (
          <Link to={`/meetings/grouped/${lead.upcoming_meeting.id}`}>
            {moment(lead.upcoming_meeting.specific_datetime).format("DD/MM/YY")}
          </Link>
        ) : (
          "אין"
        ),
    },
    removed_from_contact_sms: {
      Header: "הוסר/ה מדיוור(הודעות)",
      accessor: "removed_from_contact_sms",
      type: "boolean",
      custom: (lead) => (lead.removed_from_contact_sms ? "V" : "X"),
    },
    removed_from_contact_sms_at: {
      Header: "תאריך הסרה מדיוור(הודעות)",
      accessor: "removed_from_contact_sms_at",
      type: "date",
      custom: (lead) =>
        lead.removed_from_contact_sms_at
          ? `${moment(lead.removed_from_contact_sms_at).format("DD/MM/YY")}`
          : "אין",
    },
    removed_from_contact_email: {
      Header: "הוסר/ה מדיוור(אימייל)",
      accessor: "removed_from_contact_email",
      type: "boolean",
      custom: (lead) => (lead.removed_from_contact_email ? "V" : "X"),
    },
    removed_from_contact_email_at: {
      Header: "תאריך הסרה מדיוור(אימייל)",
      accessor: "removed_from_contact_email_at",
      type: "date",
      custom: (lead) =>
        lead.removed_from_contact_email_at
          ? `${moment(lead.removed_from_contact_email_at).format("DD/MM/YY")}`
          : "אין",
    },
    comment: {
      Header: "הערות",
      accessor: "comment",
      type: "textarea",
    },
    lead_source: {
      Header: "מקור הליד",
      accessor: "lead_source",
      type: "text",
    },
    create_by: {
      Header: 'נוצר ע"י',
      accessor: "create_by.username",
      type: "text",
      custom: (lead) => (
        <Link to={`/users/${lead.create_by?.id}`}>
          {lead.create_by?.username}
        </Link>
      ),
    },
    update_by: {
      Header: 'עדכון אחרון בוצע ע"י',
      accessor: "update_by.username",
      type: "text",
      custom: (lead) =>
        lead.update_by ? (
          <Link to={`/users/${lead.update_by.id}`}>
            {lead.update_by.username}
          </Link>
        ) : (
          "לא בוצעו עדכונים"
        ),
    },
    created_at: {
      Header: "נוצר בתאריך",
      accessor: "created_at",
      type: "date",
      custom: (lead) => `${moment(lead.created_at).format("DD/MM/YY")}`,
    },
    updated_at: {
      Header: "עודכן בתאריך",
      accessor: "updated_at",
      type: "date",
      custom: (lead) => `${moment(lead.updated_at).format("DD/MM/YY")}`,
    },
  },
  leadOrganizerFirstSteps: {
    full_name: {
      Header: "שם",
      type: "text",
      custom: (lead) => `${lead.first_name} ${lead.last_name}`,
    },
    first_name: {
      Header: "שם פרטי",
      accessor: "first_name",
      type: "text",
    },
    last_name: {
      Header: "שם משפחה",
      accessor: "last_name",
      type: "text",
    },
    phone_mobile: {
      Header: "טלפון אישי",
      accessor: "phone_mobile",
      type: "tel",
      custom: (lead) => {
        return PhoneLink({ phoneNumber: lead.phone_mobile });
      },
    },
    phone_work: {
      Header: "טלפון בית",
      accessor: "phone_work",
      type: "text",
      custom: (lead) => {
        return PhoneLink({ phoneNumber: lead.phone_work });
      },
    },
    email: {
      Header: 'דוא"ל',
      accessor: "email",
      type: "email",
      custom: (lead) => {
        return lead.email ? (
          <a href={`mailto:${lead.email}`}>{lead.email}</a>
        ) : (
          "אין"
        );
      },
    },
    identity_card: {
      Header: "תעודת זהות",
      accessor: "identity_card",
      type: "text",
    },
    sector_c: {
      Header: "מגזר",
      accessor: "sector_c",
      type: "select",
    },
    hospital: {
      Header: "בית חולים",
      accessor: "hospital",
      type: "select",
    },
    hmo: {
      Header: "קופת חולים",
      accessor: "hmo",
      type: "select",
    },
    babys_birth_date_c: {
      Header: "תאריך לידת התינוק",
      accessor: "babys_birth_date_c",
      type: "date",
      custom: (lead) =>
        lead.babys_birth_date_c
          ? `${moment(lead.babys_birth_date_c).format("DD/MM/YY")}`
          : "אין",
    },
    number_of_children: {
      Header: "מספר ילדים",
      accessor: "number_of_children",
      type: "number",
    },
    address: {
      Header: "כתובת",
      type: "text",
      custom: (lead) =>
        `${lead.street_c || ""} ${lead.house_number_c || ""}, ${lead.primary_address_city || ""
        }`,
    },
    link_address: {
      Header: "פרטי כתובת",
      type: "text",
      custom: (lead) => {
        return (
          <WazeLink city={lead.primary_address_city} street={lead.street_c} houseNumber={lead.house_number_c} displayText={lead.primary_address_city} />
        );
      },
    },
    primary_address_city: {
      Header: "ישוב",
      accessor: "primary_address_city",
      type: "text",
    },
    street_c: {
      Header: "רחוב",
      accessor: "street_c",
      type: "text",
    },
    house_number_c: {
      Header: "מספר בית",
      accessor: "house_number_c",
      type: "text",
    },
    entrance_c: {
      Header: "כניסה",
      accessor: "entrance_c",
      type: "text",
    },
    floor_c: {
      Header: "קומה",
      accessor: "floor_c",
      type: "text",
    },
    appartment_c: {
      Header: "דירה",
      accessor: "appartment_c",
      type: "text",
    },
    zip_code: {
      Header: "מיקוד",
      accessor: "zip_code",
      type: "text",
    },
    cluster_code_c: {
      Header: "קוד אשכול",
      accessor: "cluster_code_c",
      type: "text",
    },
    area_code_c: {
      Header: "קוד אזור",
      accessor: "area_code_c",
      type: "text",
    },
    next_meeting: {
      Header: "הפגישה הקרובה",
      type: "date",
      accessor: "upcoming_meeting",
      custom: (lead) =>
        lead.upcoming_meeting ? (
          <Link to={`/meetings/grouped/${lead.upcoming_meeting.id}`}>
            {moment(lead.upcoming_meeting.specific_datetime).format("DD/MM/YY")}
          </Link>
        ) : (
          "אין"
        ),
    },
    not_intrested_reason: {
      Header: "לא מתאים כעת",
      accessor: "not_intrested_reason",
      type: "select",
    },
    number_of_call_tries: {
      Header: "מספר נסיונות התקשרות",
      accessor: "number_of_call_tries",
      type: "strictSelect",
      strictOptions: ['0', '1', '2', '3', '4', '5']
    },
    removed_from_contact_sms: {
      Header: "הוסר/ה מדיוור(הודעות)",
      accessor: "removed_from_contact_sms",
      type: "boolean",
      custom: (lead) => (lead.removed_from_contact_sms ? "V" : "X"),
    },
    removed_from_contact_sms_at: {
      Header: "תאריך הסרה מדיוור(הודעות)",
      accessor: "removed_from_contact_sms_at",
      type: "date",
      custom: (lead) =>
        lead.removed_from_contact_sms_at
          ? `${moment(lead.removed_from_contact_sms_at).format("DD/MM/YY")}`
          : "אין",
    },
    removed_from_contact_email: {
      Header: "הוסר/ה מדיוור(אימייל)",
      accessor: "removed_from_contact_email",
      type: "boolean",
      custom: (lead) => (lead.removed_from_contact_email ? "V" : "X"),
    },
    removed_from_contact_email_at: {
      Header: "תאריך הסרה מדיוור(אימייל)",
      accessor: "removed_from_contact_email_at",
      type: "date",
      custom: (lead) =>
        lead.removed_from_contact_email_at
          ? `${moment(lead.removed_from_contact_email_at).format("DD/MM/YY")}`
          : "אין",
    },
    comment_c: {
      Header: "הערות",
      accessor: "comment_c",
      type: "textarea"
    },
    lead_source: {
      Header: "מקור הליד",
      accessor: "lead_source",
      type: "text",
    },
    create_by: {
      Header: 'נוצר ע"י',
      accessor: "create_by.username",
      type: "text",
      custom: (lead) => (
        <Link to={`/users/${lead.create_by?.id}`}>
          {lead.create_by?.username}
        </Link>
      ),
    },
    update_by: {
      Header: 'עדכון אחרון בוצע ע"י',
      accessor: "update_by.username",
      type: "text",
      custom: (lead) =>
        lead.update_by ? (
          <Link to={`/users/${lead.update_by.id}`}>
            {lead.update_by.username}
          </Link>
        ) : (
          "לא בוצעו עדכונים"
        ),
    },
    created_at: {
      Header: "נוצר בתאריך",
      accessor: "created_at",
      type: "date",
      custom: (lead) => `${moment(lead.created_at).format("DD/MM/YY")}`,
    },
    updated_at: {
      Header: "עודכן בתאריך",
      accessor: "updated_at",
      type: "date",
      custom: (lead) => `${moment(lead.updated_at).format("DD/MM/YY")}`,
    },
  }
};

const chosenLeadObject = leadObject[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.lead.name : "leadOrganizerDemo"];

export default chosenLeadObject;
