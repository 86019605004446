import moment from 'moment';
import { Link } from "react-router-dom";
import SelectMeetingAssignment from '../../components/select_meeting_assignment/selectMeetingAssignment'
const meetingObject = {
  assigned_to: {
    Header: "הקצאה",
    accessor: "assigned_to.username",
    type: 'text',
    custom: (meeting) => <Link to={`/users/${meeting.assigned_to?.id}`}>{meeting.assigned_to?.username}</Link>,
    customInput: (meeting, setMeeting) => (
      <SelectMeetingAssignment
        user={meeting.assigned_to ? meeting.assigned_to : ""} 
        onChange = {(newUser) => setMeeting({...meeting, assigned_to: newUser })}
        onBlur={() => {}}
      />),
  },
  date: {
    Header: "תאריך",
    accessor: "meeting_leads[0].specific_datetime",
    type: 'date',
    custom: (meeting) => `${meeting.meeting_leads ? moment(meeting.meeting_leads[0]?.specific_datetime).format("DD/MM/YY") : 'לא נקבעו פגישות'}`,
  },
  comments_c: {
    Header: "הערה כללית על הפגישה",
    accessor: "comments_c",
    type: 'textarea',
  },
};

export default meetingObject;