import React, { useRef, useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import VerticallyCenteredModal from "../../../components/modal/VerticallyCenteredModal";
import { ListGroup, Button, Spinner, Card, CardDeck } from "react-bootstrap";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { getData } from '../../../services/getServices'
import { ImageView, ImageList } from '../image_display/ImageView'
import { ImportCard } from '../../import_page/importPage'
import importPageOrganizer from '../../../data_organizers/imports/importPageOrganizer'
import DownloadFileLink from "../../../components/DownloadFileLink/DownloadFileLink";
import UploadFileModal from "../../../config/dynamicTable/imports/modals/uploadFileModal";
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

// open a task modal that show if lead address data is missing or incorrect, user can edit a lead address
export const LeadSourceImportModal = ({
    show,
    onHide,
    title,
    data,
}) => {
    const [modalShow, setModalShow] = useState(false)
    const { t } = useTranslation();
    const history = useHistory();
    const modalCardData = importPageOrganizer(data);
    return (
        <div>
            <VerticallyCenteredModal
                show={show}
                onHide={onHide}
                title={title}

            >
                < ImportCard
                    data={modalCardData}
                    title=""
                    _className="border border-primary"
                    width='43.5vw'
                >

                    <div className="d-flex justify-content-left align-items-center pb-3" >

                        <div className="mx-1" >
                            <Button onClick={() => history.push(`/imports/${data.id}`)}>{t("Go to import page")}</Button>
                        </div>
                        <div className="mx-1">
                            <DownloadFileLink downloadURL={data?.file?.url} >
                                <Button>Download</Button>
                            </DownloadFileLink>
                        </div>
                        <ReactTooltip place="top" type="dark" effect="float" />
                    </div>
                </ImportCard>
            </VerticallyCenteredModal>
            {
                modalShow &&
                <UploadFileModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    title={"העלאת קובץ לידים שנדחו"}
                    onDoubleClick={(e) => { e.stopPropagation() }
                    }
                    ImportId={data.id}
                />}
        </div>
    );
};


export const LeadSourceFormModal = ({
    show,
    onHide,
    title,
    data,
}) => {

    const { t } = useTranslation();
    const { id, form: formId } = data;
    const [formSubPayload, setFormSubPayload] = useState(undefined)

    useEffect(() => {
        if (!formId) return;
        getData(`forms/submissions/${formId}?id=${id}`, ((dataPayload) => setFormSubPayload(dataPayload[0])))
    }, [])


    const delArrayKeysFromObject = (arrayOfKeys = ['id', 'form', 'updated_at', 'leadsource', 'meetinglead', 'created_at', 'import_status', 'failed_log']) => {
        const formSubPayloadCpy = { ...formSubPayload };
        for (let fetchedKey of arrayOfKeys) {
            delete formSubPayloadCpy[fetchedKey]
        }
        return formSubPayloadCpy
    }

    return (

        <VerticallyCenteredModal
            show={show}
            onHide={onHide}
            title={title}
        >
            <>
                {formSubPayload ? <div style={{ display: 'flex', gap: '2rem' }}>

                    <Card >
                        <Card.Header>{t("Form Submission Fields")}:{" "}</Card.Header>
                        <Card.Body>
                            <ListGroup.Item>
                                <h6>{t("Form Submission Identifier")}</h6>
                                <ListGroup.Item>
                                    {formSubPayload.id
                                        ? formSubPayload.id
                                        : ""}
                                </ListGroup.Item>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h6>{t("Form Identifier")}</h6>
                                <ListGroup.Item>
                                    <Link to={`/forms/submissions/${formSubPayload.form}`} >{formSubPayload.form}</Link>
                                </ListGroup.Item>
                            </ListGroup.Item>

                            {formSubPayload.leadsource &&
                                <ListGroup.Item>
                                    <h6>{t("Lead Source Identifier")}</h6>
                                    <ListGroup.Item>
                                        {formSubPayload.leadsource}
                                    </ListGroup.Item>
                                </ListGroup.Item>}

                            {formSubPayload.meetinglead &&
                                <ListGroup.Item>
                                    <h6>{t("Lead Meeting Identifier")}</h6>
                                    <ListGroup.Item>
                                        {formSubPayload.meetinglead}
                                    </ListGroup.Item>
                                </ListGroup.Item>}

                            <ListGroup.Item>
                                <h6>{t("Created At")}</h6>
                                <ListGroup.Item>
                                    {formSubPayload.created_at
                                        ? moment(formSubPayload.created_at).format('HH:mm DD-MM-YYYY')
                                        : ""}
                                </ListGroup.Item>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h6>{t("Updated At")}</h6>
                                <ListGroup.Item>
                                    {formSubPayload.updated_at
                                        ? moment(formSubPayload.updated_at).format('HH:mm DD-MM-YYYY')
                                        : ""}
                                </ListGroup.Item>
                            </ListGroup.Item>
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Header>{t("Form Fields")}:{" "}</Card.Header>
                        <Card.Body>
                            {Object.entries(delArrayKeysFromObject()).map(([key, value]) => <ListGroup.Item>
                                <h6>{key}</h6>
                                <ListGroup.Item>
                                    {value ? Array.isArray(value) ? <ImageList imageObjectList={value} /> : typeof value === 'object' ? <ImageView imageObject={value} /> : value : ""}
                                </ListGroup.Item>
                            </ListGroup.Item>)}
                        </Card.Body>
                    </Card>
                </div> : <Spinner animation="border" />}
            </>
        </VerticallyCenteredModal>
    );
};

export const LeadHistoryModal = ({
    show,
    onHide,
    title,
    data,
}) => {

    const { t } = useTranslation();

    useEffect(() => {
    }, [])


    return (

        <VerticallyCenteredModal
            show={show}
            onHide={onHide}
            title={title}
        >
            <>
                {data.extra_data ? <div style={{ display: 'flex', gap: '2rem' }}>
                    {Object.entries(data.extra_data).map(([key, value], index) =>
                        <Card key={index}>
                            <Card.Header>{t(key)}:{" "}</Card.Header>
                            <Card.Body>
                                {Object.entries(value).map(([key, value], index) =>
                                    <ListGroup.Item key={index}>
                                        <h6>{key}</h6>
                                        <ListGroup.Item>
                                            {value}
                                        </ListGroup.Item>
                                    </ListGroup.Item>
                                )}
                            </Card.Body>
                        </Card>
                    )}
                </div> : <span>{t("no extra data")}</span>
                }
            </>
        </VerticallyCenteredModal>
    );
};
