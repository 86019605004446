import React, { useState } from "react";
import "./copyToClipboard.css";
import { useTranslation } from 'react-i18next';
function CopyToClipboard({ text, darkMode }) {
    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        setIsVisible(true);
        setTimeout(() => {
            setIsVisible(false);
        }, 400);
    }

    return (<div>
        {isVisible ? <div className='alert-text'>{t('text copied')}</div> : ''}
        <div className={darkMode ? 'copy-to-clipboard' : 'copy-to-clipboard color-mode'} onClick={copyToClipboard}>{text}</div>
    </div>
    )
}

export default CopyToClipboard;