import React from 'react';

export function WazeLink({ city, street, houseNumber, displayText }) {
  const createWazeLink = (city, street, houseNumber) => {
    let baseWazeLink = `https://waze.com/ul`;

    let keyWordsArray = [];
    if (city && typeof city === 'string') {
      keyWordsArray = [...city.split(' ')];
    }
    if (street && typeof street === 'string') {
      keyWordsArray = [...keyWordsArray, ...street.split(' ')];
    }
    if (houseNumber && Number(houseNumber)) {
      keyWordsArray = [...keyWordsArray, houseNumber];
    }

    keyWordsArray.forEach((keyWord, index) => {
      baseWazeLink += `${index === 0 ? "?q=" : "+"}${keyWord}`;
    });
    return baseWazeLink;
  }



  return (
    <>
      {
        displayText ?
          <><a href={createWazeLink(city, street, houseNumber)}>{displayText}</a></> :
          <></>
      }
    </>
  )
}