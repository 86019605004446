import { Modal, Button } from "react-bootstrap";

function VerticallyCenteredModal({ onHide, show, title, children,size,...rest }) {
  return (
    <Modal
	  size={size || "lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      {...rest}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="contained-modal-body">
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerticallyCenteredModal