import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import FileUploadButton from '../../../../components/fileUploadButton/fileUploadButton';
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal';
import { updateData } from '../../../../services/putServices';
import FileUploadDisclaimer from '../utils/fileUploadDisclaimer';

// update current import file in import table  
const UploadFileModal = ({
  show,
  onHide,
  title,
  onDoubleClick,
  ImportId
}) => {

  const [importFile, setImportFile] = useState(null)
  let history = useHistory();

  const updateImport = async () => {


    try {
      const data = new FormData();
      data.append('files.file', importFile, importFile.name);
      data.append('data', JSON.stringify({}));
      const res = await updateData(`imports/${ImportId}`, data)
      if (res?.id) {
        onHide()
        history.push(`/imports/${res.id}`)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  return <VerticallyCenteredModal
    show={show}
    onHide={onHide}
    title={title}
    onDoubleClick={onDoubleClick}
    className="container"
  >
    <FileUploadDisclaimer />
    <FileUploadButton
      defaultText="בחר קובץ"
      handleFile={(file) => setImportFile(file)}
    />
    <div className="mt-5">
      <Button variant="primary" onClick={() => updateImport()}>עדכן</Button>
    </div>
  </VerticallyCenteredModal>
}


export default UploadFileModal