import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const FileUploadButton = ({ handleFile, defaultText }) => {
  const { t, i18n } = useTranslation();
  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  let file = hiddenFileInput.current?.files[0]
  return (
    <>
      <Button variant="info" onClick={handleClick}>
        {file?.name ? t("update file") : defaultText}
        {file?.name}
      </Button>

      <input type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
};
export default FileUploadButton;