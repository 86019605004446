import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import { ImageView, ImageList } from '../../../../pages/lead_page/image_display/ImageView';
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal';
import { useState } from 'react'
export default function FormSubmissionsFileDownloadCellItem(tableInfo, setData, extraData) {
    const { t } = useTranslation();
    const [isDownloadManyModalShow, setIsDownloadManyModalShow] = useState(false)
    const rowData = tableInfo?.row?.original;
    const columnName = tableInfo?.column?.Header;
    let pressedFileField;
    for (const key in rowData) {
        if (key.includes(columnName) && key.length - columnName.length === 5) {
            pressedFileField = rowData[key];
        }
    }
    const downloadFile = () => {
        const fileObject = pressedFileField?.file;
        if (!fileObject) return;
        toast.success(`${t('downloaded file')} ${fileObject.name}`);
    }


    return (
        <div>
            <div>
                {pressedFileField?.file?.url ? (
                    <ImageView imageObject={pressedFileField} />
                ) : <Button onClick={downloadFile} variant="primary" className="me-2" disabled={true}>{`download ${extraData.fieldName} file`}</Button>}
            </div>
        </div>
    )
}



