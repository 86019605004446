import React from 'react'
import ChartItem from "./chartItem";

function ChartList(props) {
  let {telephonistsInfo,instructorsInfo} = props.data
  let  telephonistsAndInstructorsListInfo = telephonistsInfo.concat(instructorsInfo) 

  return (
    <div className="container-fluid">
      <div className="row p-4 mt-5">
        {
          props.data && (
            <>
              <div className="col-lg-6 px-4">
                <ChartItem title="leadsCreated" filterBy="leadsCreated" data={telephonistsInfo} />
              </div>
              <div className="col-lg-6 px-4">
                <ChartItem title="appointedMeetings" filterBy="appointedMeetings" data={telephonistsInfo} />
              </div>
              <div className="col-lg-6 px-4">
                <ChartItem title="finishedMeetings" filterBy="finishedMeetings" data={instructorsInfo} />
              </div>
              <div className="col-lg-6 px-4">
                <ChartItem title="avgActionsPerDay" filterBy="avgActionsPerDay" data={telephonistsAndInstructorsListInfo} />
              </div>
              <div className="col-lg-6 px-4 mx-auto">
                <ChartItem title="workDays" filterBy="workDays" data={telephonistsAndInstructorsListInfo} />
              </div>
            </>
          )
        }

      </div>
    </div>
  )
}

export default ChartList

