import { Link } from "react-router-dom";
const importObject = {

  import_name: {
    Header: "שם הייבוא",
    accessor: "import_name",
    custom: (importObj) => {
      if (!importObj.import_name) {
          return 'לא נמצא קובץ'
      }
      return importObj.import_name

    }
  },
  file_name: {
    id:"שם הקובץ",
    Header: "שם הקובץ",
    accessor: "file",
    custom: (importObj) => {
      if (!importObj.file) {
          return 'לא נמצא קובץ'
      }
      return importObj.file.name

    }
  },

  title_row_number: {
    Header: "שורת כותרות",
    accessor: "title_row_number",
     custom: (importObj) => {
       if (importObj.title_row_number === null ) {
          return "אין"
       }
       return  importObj.title_row_number + 1  
      }
  },
  start_row_number: {
    Header: "שורת התחלת לידים",
    accessor: "start_row_number",
     custom: (importObj) => {
       if (importObj.start_row_number === null) {
          return "אין"
       }
       return importObj.start_row_number + 1
      }
  },
  leads_uploaded: {
    Header: "לידים הועלו",
    accessor: "leads_uploaded",
     custom: (importObj) => {
       if (importObj.leads_uploaded === null) {
          return "אין"
       }
       return importObj.leads_uploaded ? "כן" : "לא"  
      }
  },
  leads_sent: {
    Header: "לידים שנשלחו",
    accessor: "leads_sent",
     custom: (importObj) => {
       if (importObj.leads_sent === null) {
          return "אין"
       }
       return importObj.leads_sent   
      }
  },
  leads_created: {
    Header: "לידים שנוצרו",
    accessor: "leads_created",
     custom: (importObj) => {
       if (importObj.leads_created === null) {
          return "אין"
       }
       return importObj.leads_created 
      }
  },
  leads_updated: {
    Header: "לידים שעודכנו",
    accessor: "leads_updated",
     custom: (importObj) => {
       if (importObj.leads_updated === null) {
          return "אין"
       }
       return importObj.leads_updated 
      }
  },
  leads_rejected: {
    Header: "לידים שנדחו",
    accessor: "leads_rejected",
     custom: (importObj) => {
       if (!importObj.leads_rejected) {
          return "אין"
       }
       return importObj.leads_rejected  
      }
  },

  create_by: {
    Header: 'יוצר ע"י',
    accessor: "create_by",
    custom: (importObj) => {
        if (!importObj.create_by) {
            return "אין"
        }
      return <Link to={`/users/${importObj.create_by.id}`}>{importObj.create_by.username}</Link>
    }
  },
}

export default importObject