
import react, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteData } from '../../../../services/deleteServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { ModalContext } from "../../../../context/ModalProvider";



// render a clickable icons that delete a template, lead to an edit template page 
export const TemplateActionsCellItem = (tableInfo, setData) => {
  let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);
  let history = useHistory();
  let rowData = tableInfo.data[tableInfo.row.index]

  const deleteTemplate = async () => {
    try {
      let data = await deleteData(`templates/${rowData.id}`, undefined, true)
      if (data.id) {
        toast.success("תבנית נמחקה בהצלחה")
        setData(prevState => {
          const newData = [...prevState];
          newData.splice(newData.findIndex(i => rowData.id === i.id), 1);
          return [...newData];
        }

        );
        setIsModalOpen(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="d-flex justify-content-right align-items-center pb-3" >

      <div className="ms-3" onClick={() => setIsModalOpen({
        title: "מחיקת תבנית",
        msg: ` אתה בטוח שברצונך למחוק את תבנית${rowData.id}? `,
        action: () => deleteTemplate()
      })}>
        <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="מחק תבנית" icon={faTrashAlt} className="delete_icon" size="lg" />
      </div>
      <div className="mx-1" onClick={() => history.push(`/templates/edit/${rowData.id}`)}>
        <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="ערוך תבנית" icon={faEdit} className="edit_icon" size="lg" />
      </div>
      <ReactTooltip place="top" type="dark" effect="float" />
    </div>)
}