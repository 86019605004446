export const buttonsInitialState = [
  {
    scope: "thisYear",
    title: "השנה הזאת",
    active: false
  },
  {
    scope: "lastYear",
    title: "השנה הקודמת",
    active: false
  },
  {
    scope: "thisMonth",

    title: "החודש הזה",
    active: true
  },
  {
    scope: "lastMonth",

    title: "החודש הקודם",
    active: false
  },
  {
    scope: "thisWeek",

    title: "השבוע הזה",
    active: false
  },
  {
    scope: "lastWeek",

    title: "השבוע הקודם",
    active: false
  },
]