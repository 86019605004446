import { Link, useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";

import organizeLeadPage from '../../data_organizers/leads/leadPageOrganizer'
import { getData } from '../../services/getServices';
import { deleteData } from '../../services/deleteServices';
import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import NotFoundPage from "../404page/notFoundPage";

import DynamicTableContainer from "../../components/dynamicTable/dynamicTableContainer";
import './lead-page.css'
import { toast } from "react-toastify";
import { ModalContext } from "../../context/ModalProvider";
import { LeadSourceImportModal, LeadSourceFormModal, LeadHistoryModal } from './modals/Modals'
import { useTranslation } from 'react-i18next';

function LeadPage() {

  const { id } = useParams();
  const [organizeLead, setOrganizeLead] = useState(null);
  const [openedModal, setOpenedModal] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const [currentDataPayload, setCurrentDataPayload] = useState(undefined);
  const { t } = useTranslation();
  let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);

  let history = useHistory();

  const getLead = async () => {
    try {

      const lead = await getData(`leads/${id}`)
      if (lead?.response?.status === 404) {
        setIsNotFound(true);
      } else {
        const organizedLead = organizeLeadPage(lead);
        setOrganizeLead(organizedLead)
      }

    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getLead()
  }, []);

  const deleteLead = async () => {
    try {

      let data = await deleteData(`leads/${id}`, undefined, true)
      if (data.id) {
        toast.success("ליד נמחק בהצלחה");
        history.push("/leads")
        setIsModalOpen(null)
      } else {
        throw data;
      }
    } catch (error) {
      console.log(error)
    }
  }
  const renderDataCards = () => {
    return organizeLead.map(parentField => {
      return (
        <Card border="primary" style={{ width: '18rem' }} key={parentField.Header}>
          <Card.Header>{parentField.Header}</Card.Header>
          <Card.Body>
            <ListGroup variant='flush' >
              {parentField.fields.map(field => {
                return (<ListGroup.Item key={field.Header}>
                  {`${field.Header}: `}
                  {field.customValue ? field.customValue() : `${field.value}`}
                </ListGroup.Item>)
              })}
            </ListGroup>
          </Card.Body>
        </Card>
      )
    })
  }

  const handleMeetingLeadsTableRowDoubleClick = (e, TableRow, history, blank) => {
    e.preventDefault()
    let meetingId = TableRow?.id;
    meetingId && blank ? window.open(`/meetings/grouped/${meetingId}`, '_blank') : history.push("/meetings/grouped/" + meetingId);
  }

  const handleHistoryTableRowDoubleClick = (e, TableRow) => {
    e.preventDefault()
    setCurrentDataPayload(TableRow);
    setOpenedModal('history')
  }

  const handleLeadSourceTableRowDoubleClick = (e, TableRow) => {
    e.preventDefault()
    let sourceType = TableRow.source_type;
    switch (sourceType) {
      case "form":
        if (TableRow?.formsubmission && Object.keys(TableRow.formsubmission).length) {
          setCurrentDataPayload(TableRow.formsubmission);
          setOpenedModal('form')
        } else {
          toast.warn('no form submission')
        }
        break;
      case "import":
        if (TableRow?.import && Object.keys(TableRow.import).length) {
          setCurrentDataPayload(TableRow.import);
          setOpenedModal('import')
        } else {
          toast.warn('no import payload')
        }
        break;
      case "anotherLead":
        if (TableRow?.related_lead && Object.keys(TableRow.related_lead).length) {
          setIsModalOpen({
            title: <Link target="_blank" to={`/leads/${TableRow.related_lead.id}`}>{`${t("another lead")}(${TableRow.related_lead.id})`}</Link>,
            msg: `${t("lead")} ${TableRow.related_lead.id} ${t("anotherLeadLeadSourceMessage")}`,
            action: "disabled"
          });
        } else {
          toast.warn('no related_lead payload')
        }
        break;
      default:
        toast.warn(`Type ${sourceType} doesn't return info page`)
        break;
    }
  }

  const handleOnHide = () => {
    setOpenedModal('');
    setCurrentDataPayload(undefined)
  }


  if (isNotFound) {
    return <NotFoundPage />
  }

  return (
    <>
      {organizeLead &&
        <div>
          {currentDataPayload &&
            <>
              <LeadSourceImportModal show={openedModal === 'import'} onHide={handleOnHide} title={t('import modal')} data={currentDataPayload} />
              <LeadSourceFormModal show={openedModal === 'form'} onHide={handleOnHide} title={t('form modal')} data={currentDataPayload} />
              <LeadHistoryModal show={openedModal === 'history'} onHide={handleOnHide} title={t('history modal')} data={currentDataPayload} />
            </>
          }

          <div className="container-fluid lead-page">
            <div className="menu row">
              <div className="left col">
                <Button className="float-start" variant="primary" onClick={() => history.push(`/meetings/create/${id}`)}>צור פגישה לליד זה</Button>
              </div>
              <div className="right col">
                <Button className="ms-3" variant="danger" onClick={() => setIsModalOpen({
                  title: "מחיקת ליד",
                  msg: ` אתה בטוח שברצונך למחוק את ליד ${id}?`,
                  action: () => deleteLead()
                })}>מחיקה</Button>
                <Button variant="primary" onClick={() => history.push(`/leads/edit/${id}`)}>עריכה</Button>
              </div>

            </div>

            {organizeLead &&
              <CardDeck className="lead-page_main">
                {renderDataCards()}
              </CardDeck>}
            <div className="lead-page_footer">
              <CardDeck className="lead-page_main">
                <Card border="primary" className="lead-page_history-card w-50">
                  <Card.Header>מקורות של הליד </Card.Header>
                  <Card.Body>
                    <DynamicTableContainer
                      pagesRange={4}
                      initialEndpoint="leadsources"
                      perPage={10}
                      schemaName={{ TABLE_API: "leadsources/TableSchema?schema_name=leadSourcesInLeadPage", FORM_API: "leadsources/filterSchema?schema_name=leadSourcesInLeadPage" }}
                      handleTableRowDoubleClick={handleLeadSourceTableRowDoubleClick}
                    />

                  </Card.Body>
                </Card>
                <Card border="primary" className="lead-page_history-card w-50">
                  <Card.Header>היסטוריית פעולות</Card.Header>
                  <Card.Body>
                    <DynamicTableContainer
                      pagesRange={4}
                      initialEndpoint="histories"
                      perPage={10}
                      schemaName={{ TABLE_API: "histories/TableSchema?schema_name=historyInLeadPage", FORM_API: "histories/filterSchema?schema_name=historyInLeadPage" }}
                      handleTableRowDoubleClick={handleHistoryTableRowDoubleClick}
                    />

                  </Card.Body>
                </Card>
              </CardDeck>
              <Card border="primary" className="lead-page_history-card w-100">
                <Card.Header>פגישות משוייכות</Card.Header>
                <Card.Body>
                  <DynamicTableContainer
                    pagesRange={4}
                    initialEndpoint="meetingleads"
                    perPage={10}
                    schemaName={{ TABLE_API: "meetingleads/TableSchema?schema_name=meetingLeadsInLeadPage", FORM_API: "meetingleads/filterSchema?schema_name=meetingLeadsInLeadPage" }}
                    handleTableRowDoubleClick={handleMeetingLeadsTableRowDoubleClick}
                  />

                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default LeadPage