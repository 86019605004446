import { motion } from "framer-motion";
import React, { useRef } from "react";

import organizeMeetingLeadInstructorCard from '../../../data_organizers/meeting_leads/meetingLeadsInstructorCardOrganizer'
import { IoCheckmarkDoneSharp, IoClose } from "react-icons/io5";
import "./style.css";
const InstructorCard = ({ meetingObject, handleCheckedMeeting }) => {
  const { completed } = meetingObject;
  const organizeMeetingLead = organizeMeetingLeadInstructorCard(meetingObject);

  return (
    <motion.div
      initial={{
        x: "150vw",
        transition: { type: "spring", duration: 2 },
      }}
      animate={{ x: 0, transition: { type: "spring", duration: 2 } }}
      whileHover={{
        scale: 0.9,
        transition: { type: "spring", duration: 0.1 },
      }}
      whileTap={{ scale: 0.97 }}
      exit={{
        x: "-60vw",
        scale: [1, 0],
        transition: { duration: 0.5 },
        backgroundColor: "rgba(255,0,0,1)",
      }}
      key={meetingObject.id}
      className='instructor-card'

      style={{
        background: completed ? `radial-gradient(
      circle,
      hsla(112, 86%, 38%, 0.9) 0%,
      hsla(94, 61%, 56%, 0.9) 100%
    )`: `radial-gradient(
      circle,
      hsla(0, 100%, 50%, 0.9) 0%,
      hsla(0, 100%, 65%, 0.9) 100%
    )`
      }}
    >
      <div className='instructor-card-text'>
        {organizeMeetingLead.map((parentField, index) => {
          return (
            <h4 key={index}>
              {parentField.fields.map(field => {
                return (<span style={{ margin: "6px" }} key={field.Header}>
                  {field.customValue ? field.customValue() : `${field.value}`}
                </span>)
              })}
            </h4>
          )
        })}
      </div>
      <motion.div className='instructor-checkmark-container' whileHover={{ scale: 0.9, transition: { type: "spring", duration: 0.1 } }} whileTap={{ scale: 0.97 }} onClick={((ev) => handleCheckedMeeting(ev, meetingObject))}>
        <IoCheckmarkDoneSharp
          size={55}
          className='instructor-checkmark'

        />
      </motion.div>
    </motion.div>
  );
};

export default InstructorCard;
