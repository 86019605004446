import chroma from "chroma-js";

const blockColor = "#FF5630"  
const chromaColor = chroma(blockColor)

const getStyleProps = ({isBlocked, isSelected, isFocused}) => {

  return isBlocked ? {
    color: blockColor,
    backgroundColor:
      isSelected
      ? blockColor
      : isFocused
      ? chromaColor.alpha(0.1).css()
      : undefined,
      ":active": {
        backgroundColor:
          isSelected
            ? blockColor
            : chromaColor.alpha(0.3).css()
      }, 
  } : {}
  
}

export const customStyles = {
  option: (provided, state) => {
    let styleProps = getStyleProps({isBlocked: state.data.isBlocked, ...state})
    return ({
    ...provided,
    ...styleProps,
    })
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.isBlocked ? chromaColor.alpha(0.1).css() : styles.backgroundColor
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.isBlocked ? blockColor : styles?.color
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.isBlocked ? blockColor : styles.color,
    ":hover": {
      backgroundColor: data.isBlocked ? blockColor : styles.backgroundColor,
      color: data.isBlocked ? "white" : styles.color
    }
  })
}