import { useTranslation } from 'react-i18next';

function FileUploadDisclaimer() {
    const { t } = useTranslation();
    const SUPPORTED_IMPORT_FILES = ['csv', 'xlsx'];

    return (
        <div className='file-disclaimer'>
            <h6>{t('Do Notice:')}</h6>
            <p>{t('Import File Sheet Warning')}</p>
            <p>{`${t('Import File Support Warning')} ${SUPPORTED_IMPORT_FILES}`}</p>
        </div>
    )

}

export default FileUploadDisclaimer;