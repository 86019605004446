import axios from 'axios';
import { getJwt } from './loginServices';
import { setProgressBar } from './progressServices';
import { showToastError } from './toastServices';

const getResponse = async (endPoint, callback, isData) => {
  try {
    setProgressBar('start');
    const localToken = await getJwt();
    const res = await axios.get(
      process.env.REACT_APP_API_PATH + endPoint,
      localToken ? {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      } : ''
    );
    setProgressBar('complete');

    if (callback) {
		console.log("res.data", res.data)
      callback(res.data);
    }
    return isData ? res.data : res;

  } catch (err) {
    showToastError(err);
    setProgressBar('fail');
    return (err);
  }
};

const getData = async(endPoint, callback) => {
  return await getResponse(endPoint, callback, true)
}

export {
  getResponse,
  getData
}