import React, { useState } from "react";
import moment from "moment";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import StartDatePicker from "../../pages/statistics/startDatePicker";
import EndDatePicker from "../../pages/statistics/endDatePicker";
import { useTranslation } from "react-i18next";
import "./buttonGroupDateScope.css";

const today = () => {
  let d = new Date();
  return d;
};

const yesterday = () => {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  return d;
};

export default function ButtonGroupDateScope({
  initialState,
  handleChangeButton,
  buttonClassName,
}) {
  const { t, i18n } = useTranslation();

  const [state, setState] = useState(initialState);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [customDate, setCustomDate] = useState({
    startDate: yesterday(),
    endDate: today(),
  });

  const handleOnClickButton = (btnIndx, dateScope) => {
    changeDateScope(btnIndx);
    handleChangeButton(dateScope);
  };

  const changeDateScope = (btnIndx) => {
    let newButtonsState = state.map((item, i) => {
      if (btnIndx === i) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    });
    setState(newButtonsState);
  };

  const submitAdjustDate = () => {
    let newScope = {
      startDate: moment(customDate.startDate).clone().startOf("day"),
      endDate: moment(customDate.endDate).clone().endOf("day"),
    };
    let scope = JSON.stringify(newScope) + "&isCustom=true";
    handleOnClickButton("custom", scope);
  };

  return (
    <>
      <ButtonGroup>
        {state.map((item, i) => {
          return (
            <Button
              key={item.title}
              active={item.active}
              onClick={() => {
                if (showDatePicker) {
                  setShowDatePicker(false);
                }
                handleOnClickButton(i, item.scope);
              }}
              className={buttonClassName}
            >
              {item.title}
            </Button>
          );
        })}
        <Button
          className={buttonClassName}
          onClick={() => {
            changeDateScope("custom");
            setShowDatePicker(!showDatePicker);
          }}
        >
          {t("custom")}
        </Button>
      </ButtonGroup>
      {showDatePicker && (
        <div className="container-fluid">
          <Form className="p-3">
            <div className="d-flex align-items-center">
              <div className="h5 ms-2 my-3">{t("from date")}</div>
              <div className="col-2">
                <StartDatePicker
                  maxDate={yesterday()}
                  customDate={customDate}
                  setCustomDate={setCustomDate}
                />
              </div>
              <div className="h5 ms-2 my-3">{t("to date")}</div>
              <div className="col-2">
                <EndDatePicker
                  maxDate={today()}
                  customDate={customDate}
                  setCustomDate={setCustomDate}
                />
              </div>
            </div>
            <Button className="mt-4" onClick={submitAdjustDate}>
              {t("send")}
            </Button>
          </Form>
        </div>
      )}
    </>
  );
}
