import axios from 'axios';
import { getItemFromLocalStorage, returnMainPath } from '../helpers/auth';
import { encryptStorage } from './encryptedSessionStorage';
import { getResponse } from './getServices';
import { sendDataResponse } from './postServices';
import { setProgressBar } from './progressServices';
import { showToastError } from './toastServices';


export const getJwt = async () => {
	const localToken = getItemFromLocalStorage("jwt");
	if (localToken) {
		return localToken;
	}
	else {
		return null;
	}
}

export const refreshFeatureFlags = async () => {
	const featureflagsHash = getItemFromLocalStorage("featureflagsHash");
	const jwt = await getJwt();
	const featureflagsRes = await axios.get(`${process.env.REACT_APP_API_PATH}settingconfigs/featureflags`,
		{
			headers: {
				Authorization: `Bearer ${jwt}`,
				etag: featureflagsHash
			}
		}
	);
	if (!featureflagsRes?.data) return;
	localStorage.setItem('featureflags', JSON.stringify(featureflagsRes?.data.featureFlags));
	localStorage.setItem('featureflagsHash', featureflagsRes?.data.hashedFeatureFlags);
}

export const login = async (email, password, setErrorMessage) => {
	setProgressBar('start');
	try {
		const loginRes = await sendDataResponse('auth/local', {
			identifier: email,
			password
		}, undefined, undefined, true);
		if (loginRes.status !== 200) {
			throw loginRes;
		}
		localStorage.setItem('jwt', loginRes.data.jwt);
		localStorage.setItem('user', JSON.stringify(loginRes.data.user));
		const endpointsRes = await getResponse("settingconfigs/rolerestrictions");
		if (endpointsRes.status !== 200) {
			throw endpointsRes;
		}
		await refreshFeatureFlags();

		encryptStorage.setItem('endpoints', endpointsRes?.data);
		setProgressBar('complete');
		return { success: true, routeToPush: returnMainPath(endpointsRes?.data) }
	} catch (err) {
		showToastError(err);
		setProgressBar('fail');
		if (err.response?.status === 400) {
			setErrorMessage('אחד מפרטי ההתחברות שגוי, אנא נסו שנית')
		}
		return (err);
	}

}