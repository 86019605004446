import { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { getData } from '../../services/getServices';
import { genTableColumnsSchemaList } from '../../helpers/dynamicTable/table/table';
import Table from '../../components/dynamicTable/table';
import { useTranslation } from 'react-i18next';
import { Card, Button, ListGroup, Spinner } from 'react-bootstrap'
import moment from 'moment';
import NotFoundPage from '../404page/notFoundPage';


const GroupedMeetingLeads = () => {
    const { t, i18n } = useTranslation();
    const [meetingLeads, setMeetingLeads] = useState(null);
    const [meetingLeadsSchema, setMeetingLeadsSchema] = useState(null);
    const [isNotFound, setIsNotFound] = useState(false);
    const params = useParams();
    const history = useHistory();
    const { meeting_id } = params;
    const getGropuedMeetingLeads = async () => {
        const res = await getData(`meetingleads/groupedfind/${meeting_id}`);
        if (Array.isArray(res)) {
            setMeetingLeads(res.map(dataObj => dataObj.id === parseInt(meeting_id) ? { ...dataObj, overrideStyle: true } : dataObj))
            getData(`meetingleads/tableschema?schema_name=meetingLeadsInGroupedPage`, ((res) => setMeetingLeadsSchema({ columns: genTableColumnsSchemaList(res.visible, '', setMeetingLeads, t), settings: res.settings })))
        } else if (res?.response?.status === 404) {
            setIsNotFound(true);
        }
    }

    useEffect((() => {
        getGropuedMeetingLeads()
    }), [])




    let instructorObject = meetingLeads && meetingLeads[0].assigned_to

    const deleteManyCallback = (rows) => {
        const dataCopy = [...meetingLeads];
        rows.map((rowToDelete) => {
            dataCopy.splice(meetingLeads.indexOf((e) => e.id === rowToDelete.original.id), 1);
        })
        setMeetingLeads(dataCopy);
    }
    if (isNotFound) {
        return <NotFoundPage />
    }

    return (
        <div>
            {(instructorObject && meetingLeadsSchema) ? <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px auto', width: '18%' }}  >
                    <Button disabled={true}>{t('grouped')}</Button>
                    <Button onClick={(() => history.push(`/meetings/specific/${meeting_id}`))}>{t('specific')}</Button>
                </div>
                <Button onClick={(() => history.push(`/meetings/edit/${meeting_id}`))}>{t('Edit Meetings')}</Button>
                <h2>{t('grouped page')}</h2>
                <ListGroup variant='flush' className='w-25'>
                    <ListGroup.Item>{t('assigned to')}:{' '} <Link to={`/users/${instructorObject.id}`}>{instructorObject.username}</Link></ListGroup.Item>
                    <ListGroup.Item>{t('date')}:{' '} {moment(meetingLeads[0].specific_datetime).format('DD-MM-YYYY')}</ListGroup.Item>
                </ListGroup>
                {meetingLeads && meetingLeadsSchema && <Card border="primary" className="m-auto w-75 mt-4">
                    <Card.Header className='text-center' >{t('Meetings')}</Card.Header>
                    <Card.Body>
                        <Table onlyTable={true} deleteManyCallback={deleteManyCallback} data={meetingLeads} columns={meetingLeadsSchema.columns} settings={meetingLeadsSchema.settings} history={history} handleTableRowDoubleClick={((e, row, history) => history.push(`/meetings/specific/${row.id}`))} />
                    </Card.Body>
                </Card>}
            </div> : <Spinner animation="border" />}
        </div>
    );
}

export default GroupedMeetingLeads;