import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getData } from '../../services/getServices';
import { sendDataResponse } from '../../services/postServices';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'
import Table from '../../components/dynamicTable/table';
import { genTableColumnsSchemaList } from '../../helpers/dynamicTable/table/table';
import NotFoundPage from "../404page/notFoundPage";

function ImportLeadsFromFile() {

  let history = useHistory();
  const { id } = useParams();
  const [importFields, setImportFields] = useState(null);
  const [fileTitles, setFileTitles] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [approvalPopup, setApprovalPopup] = useState(false);
  const [tableSchema, setTableSchema] = useState(null);
  const [dataObject, setDataObject] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);

  const IMPORTS_INFO = `imports/info/${id}`;
  const SCHEMA = `leads/TableSchema?schema_name=leadPreview`;


  useEffect(() => {
    fetchData()
    fetchSchema()
  }, [])

  const fetchData = async () => {

    try {
      let data = await getData(IMPORTS_INFO);
      if (data?.response?.status === 404) {
        setIsNotFound(true)
      } else if (data?.response?.status === 400) {
        throw data;
      }
      setFileTitles(data.titleRow);
      setFileData(data.startRow);
      setImportFields(data.importFields);
    } catch (error) {
      console.log(error);
      console.log(`ss`);
      history.push(`/imports/${id}`);
    }
  }

  const fetchSchema = async () => {

    try {
      let schema = await getData(SCHEMA);
      setTableSchema(genTableColumnsSchemaList(schema.visible));
    } catch (error) {
      console.log(error)
    }
  }

  const postLeads = async () => {
    try {
      const res = await sendDataResponse(`imports/leads`, { importFields: importFields, importId: parseInt(id) });
    } catch (err) {
      console.log(err);
    }
  }
  const fieldsMatchingForm = () => {
    return (
      <Card style={{ width: "fit-content", margin: "auto", display: approvalPopup ? 'none' : 'block' }}>
        <Card.Header>התאמת שדות</Card.Header>
        <Card.Body>
          <ListGroup variant='flush' >
            {importFields && importFields.map((field, fieldIndex) => (
              <ListGroup.Item key={fieldIndex} style={{ display: "flex", "flexDirection": "row-reverse", "justifyContent": "space-between" }}>
                <Form.Control defaultValue={field.position} as="select" custom onChange={(e) => {
                  const value = e.target.value
                  // ordArray[value.fieldIndex] = value.titleIndex;
                  const importFieldsCpy = [...importFields]
                  importFieldsCpy[fieldIndex].position = parseInt(value)
                  setImportFields(importFieldsCpy)
                }}>
                  <option value={null}>שדה לא קיים בקובץ</option>
                  {fileTitles.map((title, index) => <option key={index} value={index}>{title}</option>)}
                </Form.Control>
                <label>{field.name}</label>
              </ListGroup.Item>))}
          </ListGroup>
        </Card.Body>
        <Button style={{ width: "50%" }} onClick={() => { history.push(`/imports/${id}`) }}>חזור</Button>
        <Button style={{ width: "50%" }} onClick={() => { setApprovalPopup(true); createDataObject() }}>ייבוא</Button>
      </Card>
    )
  }

  const createDataObject = () => {
    const createdDataObject = importFields.map(importField => {
      const dataObj = {};
      if (importField.position === null) {
        dataObj[importField.name] = `לא הותאם שדה`
      } else {
        dataObj[importField.name] = fileData[importField.position]
      }
      return dataObj;
    }).reduce((acc, field) => { return { ...acc, ...field } });

    setDataObject([createdDataObject])
  }

  const ordArrayApproval = () => {
    return (
      <Card style={{ width: "100%", margin: "auto" }}>
        <Card.Body>
          <Card.Header>ליד לדוגמא</Card.Header>
          <Card.Body style={{ width: "100%" }}>
            <Table onlyTable={true} data={dataObject} columns={tableSchema} />
          </Card.Body>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button style={{ marginLeft: '5px' }} onClick={async () => {
              await postLeads()
              setApprovalPopup(false)
              history.push(`/imports/${id}`)
            }}
            >אישור</Button>

            <Button onClick={() => setApprovalPopup(false)}>חזרה להתאמת שדות</Button>
          </div>
        </Card.Body>
      </Card>
    )
  }
  if (isNotFound) {
    return <NotFoundPage />
  }
  return (
    <div>
      {(approvalPopup || fileTitles) &&
        <h1>ייבוא לידים מקובץ</h1>
      }
      {approvalPopup && ordArrayApproval()}
      {fileTitles && fieldsMatchingForm()}


    </div>
  )
}

export default ImportLeadsFromFile
