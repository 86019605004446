import { getItemFromLocalStorage } from "../../helpers/auth";
import leadObject from "./main";

const organizeFormGridAddress = (lead) => {
  // set lead fields values
  Object.values(leadObject).forEach((field) => {
    if (field.accessor) {
      field.value = lead[field.accessor];
    }
    // boolean type nnd falsy
    if (field.custom) {
      field.customValue = () => field.custom(lead);
    }
    if (field.value === null || field.value === undefined) {
      field.value = "אין";
    }
  });

  const formGridAddressOrganizer = {
    formGridAddressOrganizerDemo: {
      city: leadObject.city,
      street: leadObject.street
    },
    formGridAddressOrganizerFirstSteps: {
      city: leadObject.primary_address_city,
      street: leadObject.street_c
    },
  };

  return formGridAddressOrganizer[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.lead.formGridAddressOrganizer : "formGridAddressOrganizerDemo"];
};

export default organizeFormGridAddress;
