import React from "react";
import InstructorCard from "./InstructorCard";
import { AnimatePresence } from "framer-motion";
import { ListGroup } from 'react-bootstrap'
import './style.css'

const InstructorCardsView = ({ handleCardClick, meetingObjects, handleCheckedMeeting }) => {
  return (
    <div className="instructor-cards-view mt-3" >
      <ListGroup horizontal={'xl'} className='meeting-list-group' >
        <AnimatePresence >
          {
            meetingObjects.map((meetingObject, meetingObjectIndex) => {
              return (
                <ListGroup.Item className='meeting-card-item p-0' onClick={((ev) => {
                  ev.stopPropagation()
                  handleCardClick(meetingObject, meetingObjectIndex)
                }
                )}>
                  <InstructorCard
                    key={meetingObject.id}
                    meetingObject={meetingObject}
                    handleCheckedMeeting={handleCheckedMeeting}
                  />
                </ListGroup.Item>
              );
            })
          }
        </AnimatePresence>
      </ListGroup>
    </div>
  );
};

export default InstructorCardsView
