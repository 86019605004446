
import { useHistory, useParams, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { updateDataResponse } from "../../services/putServices";
import { getData } from '../../services/getServices';
import { sendDataResponse } from "../../services/postServices";

import organizeLeadForm from '../../data_organizers/leads/leadFormOrganizer'

import FormGrid from '../../components/form_grid/formGrid'

import { toast } from 'react-toastify'
import NotFoundPage from "../404page/notFoundPage";

function LeadForm({ setMessage }) {
  const { id, action, relateId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [leadWithNames, setLeadWithNames] = useState(null)
  const [lead, setLead] = useState(null)
  const [leadFormSchema, setLeadFormSchema] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorRes, setErrorRes] = useState(false)
  const [isNotFound, setIsNotFound] = useState(false);


  const putLead = async (updatedLead, idCard = null) => {
    try {
      const argumentID = idCard ? idCard : id;
      const url = relateId ? `leads/relate/${argumentID}/${relateId}` : `leads/${argumentID}`;
      const res = await updateDataResponse(url, updatedLead);
      if (res.status === 400 || res.status === 404) throw res;
      toast.success(`ליד ${argumentID} עודכן בהצלחה`)
      history.push(`/leads/${argumentID}`)
    } catch (error) {
      setErrorRes(error.data.message);
      console.log(error);
      return;
    }
  }
  const postLead = async (lead) => {
    try {
      const url = relateId ? `leads/relate/${relateId}` : `leads`;
      const res = await sendDataResponse(url, lead)
      if (res.status === 400 || res.status === 404) throw res;
      const newId = res.data.id;
      toast.success(`ליד ${newId} נוצר בהצלחה`);
      history.push(`/leads/${newId}`)
    } catch (error) {
      setErrorRes(error.data.message);
      console.log(error);
      return;
    }
  }

  const formType = {}
  switch (action) {
    case 'edit':
      formType.type = `edit`;
      formType.header = `עריכה`;
      formType.func = putLead;
      formType.submitTxt = 'עדכן';
      break;
    case 'create':
      formType.type = `create`;
      formType.header = `צור ליד`;
      formType.func = postLead;
      formType.submitTxt = 'צור';
      break;
    default:
    // do nothing
  }
  let fetchData = async () => {
    if (formType.type !== 'create' && formType.type !== 'edit') {
      setIsNotFound(true);
    }

    if (formType.type === 'create') {
      setLeadWithNames(organizeLeadForm({}));
    } else if (formType.type === 'edit') {
      let lead = await getData(`leads/${id}`);
      if (lead?.response?.status === 404) {
        setIsNotFound(true);
      }
      setLead(lead);
      setLeadWithNames(organizeLeadForm(lead))
    }
    const splitPathname = location.pathname.split("/");
    if (splitPathname.length >= 4 && splitPathname[4] === "relate") {
      const relatedLeadFound = await getData(`leads/count?id=${relateId}`);
      if (!relatedLeadFound) {
        setIsNotFound(true);
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchLeadFormSchema = async () => {
    try {
      setIsLoading(true)
      let leadFormSchema = await getData("leads/formSchema")
      setLeadFormSchema(leadFormSchema)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }

  }

  useEffect(() => {
    fetchLeadFormSchema()
  }, [])

  if (isNotFound) {
    return <NotFoundPage />
  }

  return (
    <>
      {!isLoading && leadWithNames &&
        <div style={{ padding: "1rem 5rem" }}>
          <h1>{formType.submitTxt === 'צור' ? formType.header : `${formType.header} (מספר זיהוי: ${id})`}</h1>
          {
            (!isLoading && leadWithNames) &&
            <FormGrid
              objectWithNames={leadWithNames}
              _objectForEdit={lead || {}}
              submitFunc={(payload, id) => {
                formType.func(payload)
              }}
              cancelFunc={() => history.push(`/leads/${id}`)}
              submitTxt={formType.submitTxt}
              leadFormSchema={leadFormSchema}
              errorRes={errorRes}
              submitPutFunc={putLead}
            />
          }
        </div>
      }
    </>
  )
}

export default LeadForm