import { getItemFromLocalStorage } from "../../helpers/auth";
import leadObject from "./main";

const organizeLeadWazeSettlementLinkCustomCell = (lead) => {
  // set lead fields values
  Object.values(leadObject).forEach((field) => {
    if (field.accessor) {
      field.value = lead[field.accessor];
    }
    // boolean type nnd falsy
    if (field.custom) {
      field.customValue = () => field.custom(lead);
    }
    if (field.value === null || field.value === undefined) {
      field.value = "אין";
    }
  });

  const leadWazeSettlementLinkCustomCell = {
    leadWazeSettlementLinkCustomCellDemo: leadObject.link_address,
    leadWazeSettlementLinkCustomCellFirstSteps: leadObject.link_address,
  };

  return leadWazeSettlementLinkCustomCell[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.lead.leadWazeSettlementLinkCustomCell : "leadWazeSettlementLinkCustomCellDemo"].customValue();
};

export default organizeLeadWazeSettlementLinkCustomCell;
