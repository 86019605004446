import React from "react";
import DatePicker from "react-datepicker";

export default function EndDatePicker({ customDate, setCustomDate,maxDate }) {

  const handleChange = (date) => {
    setCustomDate({
      ...customDate,
      endDate: date
    })
   
  }

  return (

    <DatePicker dateFormat="DD-MM-YYYY" maxDate={maxDate} selected={customDate.endDate} onChange={(date) => handleChange(date)} />
  )
}