import React from 'react'

export default function DownloadFileLink({downloadURL, className, children }){
  
  if (!downloadURL) {
    return children
  }
  let baseUrl =  `${process.env.REACT_APP_API_PATH}` + downloadURL.substring(1)
  
  return (
    <a href={`${baseUrl}` } className={className} download >
       {children}
    </a>
  )
}