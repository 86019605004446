//react bootstrap table function component
import React, { Component, useEffect } from 'react';
import { Table, Button, ToggleButton, Spinner, ModalFooter, Form, FormGroup, Label, Input, FormText } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from "moment";
import Select from 'react-select';
import { TimePicker } from '../TimePicker/TimePicker';
import { useTranslation } from 'react-i18next';

function MeetingLeads({ data, updateRow, isLoading, onDelete, meetingLeadsSchema }) {
    const { t, i18n } = useTranslation();

    const columns = ["ליד", "סוג פגישה", "הערה כללית", "שעה", "תאריך"];
    useEffect(() => {
    }, [updateRow])
    return (  //returns the table
    
        <Table>
            <thead>
                {columns.map((column, index) =>
                    <th key={index}>{column}</th>
                )}

            </thead>
            <tbody>

                {
                    data.map((row, rowIndex) => (
                        <>
                            <tr key={rowIndex}>
                                {/* <td>
                                 <ToggleButton
                                    className="mb-2"
                                    id="toggle-check" 
                                    type="checkbox"
                                    variant="primary"
                                    key={row.id}
                                    checked={row.completed}
                                    onChange={(e) => updateRow("completed", row.id, !row.completed)} />
                            </td> */}
                                <td>
                                    {row.lead && <Link to={`/leads/${row.lead.id}`}>{`${row.lead.first_name} ${row.lead.last_name}`}</Link>}
                                </td>
                                <td>
                                    <Select
                                        menuPlacement='auto'
                                        placeholder={`${t("Choose")} ${t("meeting_type_c")}`}
                                        value={row.meeting_type_c && {value:row.meeting_type_c, label:row.meeting_type_c}}
                                        options={meetingLeadsSchema.meeting_type_c.enum.map((option) => ({value: option, label: option}))}
                                        onChange={(selectedOption) => updateRow("meeting_type_c", row, selectedOption.value)}
                                    />
                                </td>
                                <td>
                                    <Form.Control
                                        as="textarea"
                                        size='sm'
                                        value={`${row.specific_comment || ""}`}
                                        onChange={(e) => updateRow("specific_comment", row, e.target.value)}
                                    />
                                </td>
                                <td>                          
                                    <TimePicker 
                                        value={`${row.specific_datetime && moment(row.specific_datetime).format("HH:mm") || ""}`}
                                        jump={15} 
                                        min={'09:00'}
                                        max={'15:00'} 
                                        onChange={(value) => updateRow("time", row, value)}
                                    />
                                </td>
                                <td>
                                    <Form.Control value={row.specific_datetime && moment(row.specific_datetime).format("YYYY-MM-DD")} type="date" onChange={(e) => updateRow("date", row, e.target.value)} />
                                </td>
                                <td>
                                    <Button variant={!row.staging ? "danger" : "warning"} onClick={() => onDelete(row)}>{!(isLoading === row.id) ? !row.staging ? "מחיקה" : "הסרה" :
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />}</Button>
                                </td>
                            </tr>
                        </>
                    ))}
            </tbody>
        </Table>
    )
}

export default MeetingLeads;


