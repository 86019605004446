import React from 'react'
import { useRowSelect, useSortBy, useTable } from 'react-table'
import { Checkbox } from '../../helpers/dynamicTable/table/checkbox'
import { getSelectManyActions } from '../../helpers/dynamicTable/table/selectManyActionsMiddleware'
import './table.css'
import { useTranslation } from 'react-i18next';
import { Badge, Alert } from 'react-bootstrap';

function Table(props) {
  let {
    setSorter,
    isLoadingData,
    sorter,
    tableSettings,
    allDataRecords,
    data,
    columns,
    deleteManyCallback,
    handleTableRowDoubleClick = false,
    onlyTable = false,
    history,
  } = props;

  const { t } = useTranslation();
  const tableHooks = hooks => {
    hooks.visibleColumns.push(columns => [
      //make a column for selection
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <Checkbox {...getToggleAllRowsSelectedProps()} />
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
      },
      ...columns
    ])
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    selectedFlatRows,
    prepareRow
  } = useTable({
    columns,
    data,
  },
    useSortBy,
    tableSettings?.selectMany ? useRowSelect : false,
    tableSettings?.selectMany ? tableHooks : false
  );

  const getSortByAndDirection = () => {
    const splitSorter = sorter?.split(':');
    const sortBy = splitSorter ? splitSorter[0] : undefined;
    const direction = splitSorter ? splitSorter[1] : undefined;
    return [sortBy, direction]
  }

  const defineNewDirection = (currentDirection) => {
    switch (currentDirection) {
      case undefined:
        return "asc";

      case "asc":
        return "desc";

      case "desc":
        return undefined;

      default:
        return undefined;
    }
  }

  const setSorterAndSend = (column) => {
    if (column?.sortAccessor) {
      const accessorName = column.sortAccessor;
      const [sortBy, direction] = getSortByAndDirection();
      const newDirection = defineNewDirection(sortBy !== undefined && sortBy === accessorName ? direction : undefined);
      setSorter(newDirection !== undefined ? `${accessorName}:${newDirection}` : undefined);
    }
  }
  //added psuedo column that is roughly the same size as the scrollbar allowing for better alignment 
  const WIDTH_OBJECT = {
    longText: "140px",
    doFunction: "120px",
    actions: "140px",
    defualtType: "90px",
  }
  return (
    <>
      <div style={{ overflowX: 'scroll', maxWidth: '100vw' }}>
        <table className="boot-table table table-striped table-responsive table-hover" {...getTableProps()} >
          <thead style={{ fontSize: '16px' }}>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} style={{ display: 'flex', justifyContent: 'space-between' }} >
                {headerGroup.headers.map(column => {
                  const columnType = column.type;
                  const columnId = column.id;
                  let width = "30px";
                  if (columnType) {
                    if (columnType === "longText") {
                      width = WIDTH_OBJECT[columnType];
                    } else if (columnType === "doFunction") {
                      width = WIDTH_OBJECT[columnType];
                    } else if (columnId === "actions") {
                      width = WIDTH_OBJECT[columnType];
                    } else width = WIDTH_OBJECT['defualtType'];
                  }

                  let sortBy, direction;
                  if (sorter && onlyTable === false) {
                    [sortBy, direction] = getSortByAndDirection();
                  }
                  return (
                    <th
                      scope="col"
                      style={{ overflowWrap: 'break-word', overflow: 'hidden', flexGrow: 1, cursor: (columnType !== 'doFunction' && columnType !== 'button' && onlyTable === false) ? 'pointer' : '' }}
                      onClick={(() => (columnType !== 'doFunction' && columnType !== 'button' && onlyTable === false) && setSorterAndSend(column))}
                      {...column.getHeaderProps({ width, textAlign: 'center' })}
                    >
                      {typeof column.render('Header') === 'string'
                        ? <>
                          {t(column.render('Header'))}
                          <span>
                            {column?.sortAccessor && sorter && column.sortAccessor === sortBy
                              ? direction === 'desc'
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                          </span>
                        </>
                        : column.render('Header')}
                    </th>
                  )
                })}

                <th style={{ width: '14px' }}></th>
              </tr>
            ))}
          </thead>
          <div className="table table-striped table-responsive table-hover" style={{ maxHeight: '50vh', overflowY: 'scroll', scrollbarWidth: 'thin' }}>
            <tbody {...getTableBodyProps()} style={{ display: 'flex', flexDirection: 'column', fontSize: '13px' }}>
              {isLoadingData && (
                <tr className="table__spinner__wrapper" >
                  <td colSpan="10000">
                    <div className="table__spinner"></div>
                  </td>
                </tr>
              )
              }
              {(allDataRecords === 0 && !isLoadingData) && (
                <tr>
                  <td colSpan="10000">
                    {t('no listings')}
                  </td>
                </tr>
              )
              }
              {selectedFlatRows?.length > 0
                &&
                <tr>
                  <td colSpan="10000">
                    <div className="select-many_actions">
                      {getSelectManyActions(tableSettings?.selectManyAction, selectedFlatRows, deleteManyCallback, data)}
                    </div>
                  </td>
                </tr>
              }
              {!isLoadingData &&
                <>
                  {allDataRecords > 0 &&
                    tableSettings?.showEntriesCount &&
                    <tr className="pb-3 pt-3" >
                      <Alert style={{ display: 'inline', backgroundColor: '#0353a4', color: '#fff' }}>
                        {t('found')} <Badge style={{ backgroundColor: '#003559' }} pill >{`${allDataRecords}`}</Badge>
                      </Alert>
                    </tr>

                  }
                  {rows.map(row => {
                    let onDoubleClick = handleTableRowDoubleClick ? { ...{ onDoubleClick: (e) => e.ctrlKey ? handleTableRowDoubleClick(e, row.original, history, true) : handleTableRowDoubleClick(e, row.original, history) } } : {}
                    prepareRow(row)
                    return (
                      <tr  {...row.getRowProps()}  {...onDoubleClick} className={handleTableRowDoubleClick ? "cursor-pointer" : ""} style={row.original?.overrideStyle ? { backgroundColor: 'lightblue', display: 'flex' } : { display: 'flex', justifyContent: 'space-between' }}>
                        {row.cells.map((cell, index) => {
                          const { column } = cell;
                          const columnType = column.type;
                          const columnId = column.id;
                          let width = "30px";
                          if (columnType) {
                            if (columnType === "longText") {
                              width = WIDTH_OBJECT[columnType];
                            } else if (columnType === "doFunction") {
                              width = WIDTH_OBJECT[columnType];
                            } else if (columnId === "actions") {
                              width = WIDTH_OBJECT[columnType];
                            } else width = WIDTH_OBJECT.defualtType;
                          }
                          return <td style={{ overflowWrap: 'break-word', overflow: 'hidden', flexGrow: 1 }} {...cell.getCellProps({ width, textAlign: 'center' })}>{cell.render('Cell')} </td>
                        })}
                      </tr>
                    )
                  })}
                </>
              }
            </tbody>
          </div>
        </table>
      </div >
    </>
  )
}

export default Table