import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faFileImport, faEdit, faFileUpload, faArrowAltCircleRight, faDownload } from '@fortawesome/free-solid-svg-icons'
import { deleteData } from "../../services/deleteServices";
import { getData } from '../../services/getServices'
import importPageOrganizer from '../../data_organizers/imports/importPageOrganizer'
import {
  ListGroup,
  Card,
  CardDeck,
  Button,
} from 'react-bootstrap'
import Loader from "../../components/Loader/Loader";
import UploadFileModal from "../../config/dynamicTable/imports/modals/uploadFileModal";
import DownloadFileLink from "../../components/DownloadFileLink/DownloadFileLink";
import ReactTooltip from 'react-tooltip'
import { ModalContext } from "../../context/ModalProvider";
import NotFoundPage from "../404page/notFoundPage";

export const ImportCard = ({ data, title, _className, children, width }) => {
  return (
    <Card className={_className} style={{ width }}>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <ListGroup variant='flush' >
          {data.map(el => {

            return <ListGroup.Item key={el.Header}>
              {`${el.Header}`}: {el.customValue ? el.customValue() : `${el.value}`}
            </ListGroup.Item>

          })}
        </ListGroup>
        {children}
      </Card.Body>
    </Card>
  )
}
function ImportPage() {
  const params = useParams();
  const [organizeImport, setOrganizeImport] = useState([])
  const [importData, setImportData] = useState(null)
  const [rejectedFileCardData, setRejectedFileCardData] = useState([])
  const [rejectedImportData, setRejectedImportData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [UploadModalShow, setUploadModalShow] = useState(false);
  const [modalShowRejectedImport, setModalShowRejectedImport] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);

  let history = useHistory();
  const { id } = params
  const IMPORT = `imports/${id}`;

  useEffect(() => {
    fetchData()
  }, [id]);

  const fetchData = async () => {
    setIsLoading(true)
    try {
      let data = await getData(IMPORT)
      if (data?.response?.status === 404) {
        setIsNotFound(true)
      }
      setOrganizeImport(importPageOrganizer(data))
      setImportData(data)
      if (data.rejected_rows_import) {
        setRejectedFileCardData(importPageOrganizer(data.rejected_rows_import))
        setRejectedImportData(data.rejected_rows_import)
      } else {

        setRejectedFileCardData([])
      }
      setIsLoading(false)

    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }


  const deleteImport = async () => {
    try {
      const res = await deleteData(`imports/${params.id}`, undefined, true)
      if (res.id) {
        history.push("/imports")
        setIsModalOpen(null)
      } else {
        throw res;
      }
    } catch (err) {
      console.log(err);
    }
  }

  if (isNotFound) {
    return <NotFoundPage />
  }

  if (isLoading) {
    return <Loader />
  }

  // onclick is an object for onClickEvents triggered by clicking on icons, only created by a valid condition 
  let onClickCreateLeadFromFile = rejectedImportData?.leads_uploaded === false ? { ...{ onClick: () => history.push(`/imports/import-from-file/${rejectedImportData.id}`) } } : {}
  let onClickEdit = rejectedImportData?.leads_uploaded === false ? { ...{ onClick: (e) => history.push(`/imports/edit/${rejectedImportData.id}`) } } : {}
  let onClickUploadFile = rejectedImportData?.leads_uploaded === false ? { ...{ onClick: (e) => setModalShowRejectedImport(true) } } : {}


  return (
    <div div className="lead-page">
      {
        UploadModalShow &&
        <UploadFileModal
          show={UploadModalShow}
          onHide={() => setUploadModalShow(false)}
          title={"העלאת קובץ"}
          onDoubleClick={(e) => { e.stopPropagation() }
          }
          ImportId={params.id}
        />}
      {
        modalShowRejectedImport &&
        <UploadFileModal
          show={modalShowRejectedImport}
          onHide={() => setUploadModalShow(false)}
          title={"העלאת קובץ לידים שנדחו"}
          onDoubleClick={(e) => { e.stopPropagation() }
          }
          ImportId={rejectedImportData.id}
        />}

      <div className="d-flex p-4">
        <Button variant="danger"
          className="me-2" onClick={() => setIsModalOpen({
            title: "אזהרה",
            msg: ` האם למחוק יבוא מספר ${params.id}? `,
            action: () => deleteImport()
          })}>מחיקה</Button>
        <Button variant="primary"
          disabled={importData?.leads_uploaded}
          className="me-2" onClick={() => history.push(`/imports/edit/${params.id}`)}>עריכה</Button>
        <Button variant="primary"
          disabled={importData?.leads_uploaded}
          className="me-2" onClick={() => setUploadModalShow(true)} >העלאת קובץ</Button>

        {
          importData?.file?.url ? (
            <DownloadFileLink downloadURL={importData?.file?.url} className="me-2 btn btn-primary">
              הורד קובץ
            </DownloadFileLink>
          ) : <Button variant="primary" className="me-2" disabled={true}>הורד קובץ</Button>


        }

        <Button variant="primary"
          onClick={() => history.push(`/imports/import-from-file/${params.id}`)}
          disabled={importData?.leads_uploaded}
          className="me-auto"
        >
          יצור לידים מקובץ
        </Button>
      </div>

      <div className="container-fluid">

        <div className="row justify-content-center">
          {organizeImport &&
            <CardDeck className="lead-page_main col-lg-3">
              <ImportCard data={organizeImport} title="מידע על הייבוא" />
            </CardDeck>}

          {rejectedFileCardData && rejectedFileCardData.length > 0 &&
            <CardDeck className="lead-page_main col-lg-3">
              < ImportCard
                data={rejectedFileCardData}
                title="לידים שנדחו"
                _className="border border-primary"
              >

                <div className="d-flex justify-content-left align-items-center pb-3" >

                  <div className="mx-1" onClick={() => history.push(`/imports/${rejectedImportData.id}`)}>
                    <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="עבור לדף מידע על לידים שנדחו" icon={faArrowAltCircleRight} className="edit_icon" size="lg" />
                  </div>
                  <div className="mx-1"  {...onClickCreateLeadFromFile} >
                    <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="ייבוא לידים מקובץ" icon={faFileImport} className={`edit_icon${rejectedImportData.leads_uploaded ? " disable" : ""}`} size="lg" />
                  </div>
                  <div className="mx-1" {...onClickEdit}>
                    <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="ערוך קובץ" icon={faEdit} className={`edit_icon${rejectedImportData.leads_uploaded ? " disable" : ""}`} size="lg" />
                  </div>
                  <div className="mx-1">
                    <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="העלאת קובץ" icon={faFileUpload} className={`edit_icon${rejectedImportData.leads_uploaded ? " disable" : ""}`} {...onClickUploadFile} size="lg" />
                  </div>
                  <div className="mx-1">
                    <DownloadFileLink downloadURL={rejectedImportData?.file?.url} >
                      <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="הורד קובץ" icon={faDownload} className={`edit_icon${!rejectedImportData?.file?.url ? " disable" : ""}`} size="lg" />
                    </DownloadFileLink>
                  </div>
                  <ReactTooltip place="top" type="dark" effect="float" />
                </div>
              </ImportCard>
            </CardDeck>}
        </div>

      </div>
    </div>
  )
}

export default ImportPage