import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";

export const LeadCellItem = ({ data, text }) => {
  const { t } = useTranslation();

  let user = data.action_user;
  return (
    <>
      {text && <span>{t(text)}{' '}</span>}
      {user && <span>{t('by')}{' '}</span>}
      {user && <Link to={`/users/${user.id}`}>{user.username ? user.username : t('user')}</Link>}
      {data?.import && <span>{' '}{t('from import')}{' '}</span>}
      {data?.import?.id && <Link to={`/imports/${data?.import?.id}`}>{data?.import?.name ? data?.import?.name : t('import')}</Link>}
      {data?.formsubmission && <span>{' '}{t('from form')}{' '}</span>}
      {data?.formsubmission?.form?.id && <Link to={`/forms/submissions/${data?.formsubmission?.form?.id}`}>{data?.formsubmission?.form?.name ? data?.formsubmission?.form?.name : t('form')}</Link>}
    </>
  )
}

export const ScheduledMeetingUserMeetingCellItem = ({ data }) => {
  const { t } = useTranslation();
  let assigned_to = data?.meetinglead?.assigned_to;
  let action_user = data?.action_user;
  let meetinglead = data?.meetinglead;
  return (
    <>
      <span>{t('scheduled')}{' '}</span>
      {meetinglead?.id ? <Link to={`/meetings/grouped/${meetinglead.id}`}>{t('meeting')}</Link> : <span>{t('meeting')}</span>}
      {assigned_to?.id && <span>{' '}{t('with')}{' '}</span>}
      {assigned_to?.id && <Link to={`/users/${assigned_to.id}`}>{assigned_to.username ? assigned_to.username : t('user')}</Link>}
      {action_user?.id && <span>{' '}{t('by')}{' '}</span>}
      {action_user?.id && <Link to={`/users/${action_user.id}`}>{action_user.username ? action_user.username : t('user')}</Link>}
    </>
  )
}

export const CompletedUserMeetingCellItem = ({ data }) => {
  const { t } = useTranslation();
  let action_user = data?.action_user;
  let meetinglead = data?.meetinglead;
  return (
    <>
      <span>{t('completed one')}{' '}</span>
      {meetinglead?.id ? <Link to={`/meetings/grouped/${meetinglead.id}`}>{t('meeting')}</Link> : <span>{t('meeting')}</span>}
      {action_user?.id && <span>{' '}{t('by')}{' '}</span>}
      {action_user?.id && <Link to={`/users/${action_user.id}`}>{action_user.username ? action_user.username : t('user')}</Link>}
    </>
  )
}

export const SentMessageCellItem = ({ data, text }) => {
  let action_user = data?.action_user;
  let message = data?.message;
  return (
    <>
      {text && <span>{t(text)}{' '}</span>}
      <span>{' '}{t('message')}{' '}</span>
      {message?.template?.id && <Link to={`/templates/edit/${message?.template?.id}`}>{t('template')}</Link>}
      {message?.template_message && <span>{' '}{t('automatic')}{' '}</span>}
      {action_user?.id && <span>{' '}{t('by')}{' '}</span>}
      {action_user?.id && <Link to={`/users/${action_user.id}`}>{action_user.username ? action_user.username : t('user')}</Link>}
    </>
  )
}

export const InboundCallCellItem = ({ data }) => {
  let callsLog = data?.calls_log;
  return (
    <>
      <span>{`${t(`inboundCall`)} ${t('by')} ${t('the lead')}`}</span>
      {!callsLog?.success && <span>{t('failed')}</span>}
    </>
  )
}

export const OutboundCallCellItem = ({ data }) => {
  const { t } = useTranslation();
  let action_user = data?.action_user;
  let callsLog = data?.calls_log;
  return (
    <>
      <span>{`${t(`outboundCall`)} ${t('by')} `}</span>
      {action_user?.id && <Link to={`/users/${action_user.id}`}>{action_user.username ? action_user.username : t('user')}</Link>}
      {!callsLog?.success && <span>{t('failed')}</span>}
    </>
  )
}

export const GenericCellItem = ({ data, text }) => {
  let action_user = data?.action_user;
  return (
    <>
      {text && <span>{t(text)}{' '}</span>}
      {action_user?.id && <span>{' '}{t('by')}{' '}</span>}
      {action_user?.id && <Link to={`/users/${action_user.id}`}>{action_user.username ? action_user.username : t('user')}</Link>}
    </>
  )
}
