const translateToHebrew = (text) => {
    switch (text) {
        case "":
            return "";
        case null:
            return "";
        case "italci":
            return "איטלקי";
        case "ichilov":
            return "איכילוב";
        case "angli":
            return "אנגלי";
        case "asaf_harofe":
            return "אסף הרופא";
        case "bikor_holim":
            return "ביקור חולים";
        case "belinson":
            return "בלינסון";
        case "bnei_zion":
            return "בני ציון";
        case "barzili":
            return "ברזילי";
        case "hilel_yafe":
            return "הלל יפה";
        case "har_hatzofim":
            return "הר הצופים";
        case "wolfson":
            return "וולפסון";
        case "ziv":
            return "זיו";
        case "yoseftal":
            return "יוספטל";
        case "karmel":
            return "כרמל";
        case "laniado":
            return "לניאדו";
        case "meir":
            return "מאיר";
        case "mayanei_hayeshoa":
            return "מעייני הישועה";
        case "naharia":
            return "נהריה";
        case "natzeret":
            return "נצרת";
        case "soroka":
            return "סורוקה";
        case "ein_kerem":
            return "עין כרם";
        case "emek":
            return "עמק";
        case "poria":
            return "פוריה";
        case "tzarfati":
            return "צרפתי";
        case "kaplan":
            return "קפלן";
        case "rambam":
            return "רמב\"ם";
        case "shaarei_tzedek":
            return "שערי צדק";
        case "tel_hashomer":
            return "תל השומר";
        case "house_visit":
            return "ביקור בית";
        case "home_activity":
            return "חוג בית - היריון";
        case "home_activity_religious":
            return "חוג בית - חרדי";
        case "home_activity_year_old":
            return "חוג בית לגיל שנה";
        case "home_activity_arabic":
            return "חוג בית - ערבי";
        case "development_consult":
            return "יעוץ התפתחותי";
        case "breastfeeding_consult":
            return "יעוץ הנקה";
        case "baby_massage":
            return "עיסוי תינוקות";
        case "nutrition_consult":
            return "יעוץ תזונה";
        case "sleeping_consult":
            return "יעוץ שינה";
        case "general":
            return "כללי";

        default:
            return text;
    }
}
const translateToEnglish = (text) => {
    switch (text) {
        case "איטלקי":
            return "italci";
        case "איכילוב":
            return "ichilov";
        case "אנגלי":
            return "angli";
        case "אסף הרופא":
            return "asaf_harofe";
        case "ביקור חולים":
            return "bikor_holim";
        case "בלינסון":
            return "belinson";
        case "בני ציון":
            return "bnei_zion";
        case "ברזילי":
            return "barzili";
        case "הלל יפה":
            return "hilel_yafe";
        case "הר הצופים":
            return "har_hatzofim";
        case "וולפסון":
            return "wolfson";
        case "זיו":
            return "ziv";
        case "יוספטל":
            return "yoseftal";
        case "כרמל":
            return "karmel";
        case "לניאדו":
            return "laniado";
        case "מאיר":
            return "meir";
        case "מעייני הישועה":
            return "mayanei_hayeshoa";
        case "נהריה":
            return "naharia";
        case "נצרת":
            return "natzeret";
        case "סורוקה":
            return "soroka";
        case "עין כרם":
            return "ein_kerem";
        case "עמק":
            return "emek";
        case "פוריה":
            return "poria";
        case "צרפתי":
            return "tzarfati";
        case "קפלן":
            return "kaplan";
        case "רמב\"ם":
            return "rambam";
        case "שערי צדק":
            return "shaarei_tzedek";
        case "תל השומר":
            return "tel_hashomer";
        case "ביקור בית":
            return "house_visit";
        case "חוג בית - היריון":
            return "home_activity";
        case "חוג בית - חרדי":
            return "home_activity_religious";
        case "חוג בית לגיל שנה":
            return "home_activity_year_old";
        case "חוג בית - ערבי":
            return "home_activity_arabic";
        case "יעוץ התפתחותי":
            return "development_consult";
        case "יעוץ הנקה":
            return "breastfeeding_consult";
        case "עיסוי תינוקות":
            return "baby_massage";
        case "יעוץ תזונה":
            return "nutrition_consult";
        case "יעוץ שינה":
            return "sleeping_consult";
        case "כללי":
            return "general";

        default:
            return text;
    }
}

export {
    translateToHebrew,
    translateToEnglish
}