import React, { useState, useEffect } from "react";
import { getData } from "../../services/getServices";
const SelectMeetingAssignment = ({onChange, onBlur, user}) => {
  const [assignedUser, setAssignedUser] = useState(null)
  const [users, setUsers] = useState([]) 

  useEffect(() => {
    setAssignedUser({...user})
  }, [user]);
  const getUsers = async () => {
    try {
      const res = await getData("users")
      setUsers(res)
    } catch (err) {
      console.log(err)
    }

  } 
  useEffect(() => {
    getUsers()
  },[])
  return (
    assignedUser && <select 
        value={assignedUser || ""} 
        onChange={(e) => {
          const newUser = JSON.parse(e.target.value);
          onChange(newUser)
          setAssignedUser(newUser)
        }} 
        onBlur={onBlur} 
        style={{display: "block"}}> 
        <option value={JSON.stringify(assignedUser) || ""}>{assignedUser.username || ""}</option>
        {users.map(currentUser => currentUser.id === assignedUser.id ? <React.Fragment key={currentUser.id}></React.Fragment> : <option key={currentUser.id} value={JSON.stringify(currentUser)}>{currentUser.username}</option>)}  
      </select>
  )
}

export default SelectMeetingAssignment