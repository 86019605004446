import React, { useState, useEffect } from "react";
import { getData } from "../../services/getServices";
import { Button, ButtonGroup, Form } from 'react-bootstrap'
import ChartList from "./chartList";
import Loader from "../../components/Loader/Loader";
import StartDatePicker from "./startDatePicker";
import EndDatePicker from "./endDatePicker";
import moment from 'moment'
import './statistics.css'
import { buttonsInitialState } from "../../helpers/resources";

const today = () => {
  let d = new Date();
  return d;

};

const yesterday = () => {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  return d;

};

export default function Statistics(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [customDate, setCustomDate] = useState({
    startDate: yesterday(),
    endDate: today()
  })
  const [data, setData] = useState(0)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [buttonsState, setButtonsState] = useState(buttonsInitialState)

  const submitAdjustDate = () => {

    let newScope = {
      startDate: moment(customDate.startDate).clone().startOf('day'),
      endDate: moment(customDate.endDate).clone().endOf('day')
    }
    let scope = JSON.stringify(newScope) + "&isCustom=true"
    changeDateScope(scope, "Custom")
  }

  const fetchData = async (scope) => {
    try {
      setIsLoading(true)
      let scopeQ = scope ? `?scope=${scope}` : ""
      let data = await getData(`stats${scopeQ}`)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setData(null)
      setIsLoading(false)
    }
  }

  const changeDateScope = (scope, btnIndx) => {
    let newButtonsState = buttonsState.map((item, i) => {
      if (btnIndx === i) {
        item.active = true
      } else {
        item.active = false

      }
      return item
    })
    setButtonsState(newButtonsState)

    fetchData(scope)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="statistics-page">
      <div className="statistics_menu container-fluid p-3">
        <ButtonGroup aria-label="statistics scope dates">
          {buttonsState.map((item, i) => {
            return <Button
              key={item.title}
              active={item.active}
              onClick={() => changeDateScope(item.scope, i)}
              variant="secondary">{item.title}</Button>

          })}

          <Button
            variant="secondary"
            className="p-3"
            onClick={() => setShowDatePicker(!showDatePicker)}
          >
            מותאם
          </Button>

        </ButtonGroup>
      </div>
      {showDatePicker &&

        <div className="container-fluid">

          <Form className="p-3">
            <div className="d-flex align-items-center">
              <div className="h5 ms-2 my-3">מתאריך</div>
              <div className="col-2">
                <StartDatePicker maxDate={yesterday()} customDate={customDate} setCustomDate={setCustomDate} />
              </div>
              <div className="h5 ms-2 my-3">עד תאריך</div>
              <div className="col-2">
                <EndDatePicker maxDate={today()} customDate={customDate} setCustomDate={setCustomDate} />
              </div>

            </div>
            <Button className="mt-4" onClick={submitAdjustDate}>שלח</Button>
          </Form>
        </div>
      }
      <div className="statistics_cards container-fluid my-2">
        <div className="row justify-content-around align-items-center">
          <div className="col-2 statistics_card">
            <h3 className="h5">avgAppointedMeetingsPerWorkday</h3>
            <h3 className="h5">{parseFloat(data.avgAppointedMeetingsPerWorkday).toFixed(1)}</h3>
          </div>
          <div className="col-2 statistics_card">
            <h3 className="h5">avgFinishedMeetingsPerWorkday</h3>
            <h3 className="h5">{parseFloat(data.avgFinishedMeetingsPerWorkday).toFixed(1)}</h3>
          </div>
          <div className="col-2 statistics_card">
            <h3 className="h5">avgLeadsPerWorkday</h3>
            <h3 className="h5">{parseFloat(data.avgLeadsPerWorkday).toFixed(1)}</h3>
          </div>
          <div className="col-2 statistics_card">
            <h3 className="h5">avgWorkplaceActionsPerWorkday</h3>
            <h3 className="h5">{parseFloat(data.avgWorkplaceActionsPerWorkday).toFixed(1)}</h3>
          </div>
          <div className="col-2 statistics_card">
            <h3 className="h5">createdMeetings</h3>
            <h3 className="h5">{data.createdMeetings}</h3>
          </div>
          <div className="col-2 statistics_card">
            <h3 className="h5">finishedMeetings</h3>
            <h3 className="h5">{data.finishedMeetings}</h3>
          </div>
          <div className="col-2 statistics_card">
            <h3 className="h5">newLeads</h3>
            <h3 className="h5">{data.newLeads}</h3>
          </div>
          <div className="col-2 statistics_card">
            <h3 className="h5">workDays</h3>
            <h3 className="h5">{data.workDays}</h3>
          </div>
          <div className="col-2 statistics_card">
            <h3 className="h5">totalAvgActionsPerUser</h3>
            <h3 className="h5">{parseFloat(data.totalAvgActionsPerUser).toFixed(1)}</h3>
          </div>
        </div>
      </div>

      {data &&
        <ChartList data={data} />
      }

    </div >
  )
}