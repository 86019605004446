import {useState,useContext} from 'react'
import { deleteData } from '../../../../services/deleteServices';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { ModalContext } from "../../../../context/ModalProvider";

export const TemplatesSelectManyActions = ({ selectedRows, deleteManyCallback }) => {
	let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);

  let history = useHistory();

  const deleteSelectedRows = async() => {
    try {
      let data = await Promise.allSettled(selectedRows.map(async (row) => {
        await deleteData(`templates/${row.original.id}`)
      }))
      let countSuccessDeleteLeads = 0
      
      data.forEach((resp) => {
        if (resp.status === "fulfilled") {
          countSuccessDeleteLeads++
        }
      });

      if (countSuccessDeleteLeads) {
        toast.success(`נמחקו ${countSuccessDeleteLeads} תבניות`)
		  setIsModalOpen(null)
		  deleteManyCallback();
      }
    } catch (error) {
      console.log(error)
    }
 }

  return (

    <>   
		  <button onClick={() => setIsModalOpen({
			  title: "אזהרה",
			  msg: ` האם למחוק ${selectedRows.length} שורות? `,
			  action: () => deleteSelectedRows()
		  })} className="btn btn-danger me-2">Delete {selectedRows.length} templates</button>
      
    </>
  )
}