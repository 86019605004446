
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FormData from 'form-data'
import {
  Form,
  Button
} from 'react-bootstrap'
import Loader from "../../components/Loader/Loader";
import { toast } from 'react-toastify';
import { getData } from "../../services/getServices";
import { updateData } from "../../services/putServices";
import { useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import FileUploadButton from "../../components/fileUploadButton/fileUploadButton";
import NotFoundPage from "../404page/notFoundPage";
import FileUploadDisclaimer from "../../config/dynamicTable/imports/utils/fileUploadDisclaimer";

function ImportEdit(props) {
  let params = useParams()
  let history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true)
  const [formData, setFormData] = useState({})
  const [importFile, setImportFile] = useState(null)
  const [importData, setImportData] = useState(null)
  const [importSchema, setImportSchema] = useState(null)
  const [isNotFound, setIsNotFound] = useState(false);


  const handleOnChange = (e) => {
    const formDataCpy = { ...formData };
    const importDataCpy = { ...importData };
    formDataCpy[e.target.name] = e.target.name === "title_row_number" || e.target.name === "start_row_number" ? parseInt(e.target.value) - 1 : e.target.value;
    importDataCpy[e.target.name] = e.target.name === "title_row_number" || e.target.name === "start_row_number" ? parseInt(e.target.value) - 1 : e.target.value;
    setFormData(formDataCpy);
    setImportData(importDataCpy);
  }

  useEffect(() => {
    getFile()
  }, [])

  const getFile = async () => {
    try {
      const data = await getData(`imports/${params.id}`);
      if (data?.response?.status === 404) {
        setIsNotFound(true)
      }
      const fetchedImportSchema = await getData(`imports/formschema`);
      setImportSchema(fetchedImportSchema)
      setImportData(data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  const updateImport = async () => {
    setIsLoading(true)

    try {
      let data;
      if (importFile) {
        data = new FormData();
        data.append('files.file', importFile, importFile.name);
        data.append('data', JSON.stringify(formData));
      } else {
        data = formData;
      }

      const res = await updateData(`imports/${params.id}`, data)
      if (res?.id) {
        toast.success(`${t('import')} ${res.id} ${t('updated successfully')}`);
        history.push(`/imports/${res.id}`)
      }
      setIsLoading(false)
    } catch (err) {
      console.log(err.message)
      setIsLoading(false)
    }
  }


  const showDefaultNumber = (data) => {
    if (!data && data !== 0) {
      return "";
    }
    return data + 1;
  }

  if (isNotFound) {
    return <NotFoundPage />
  }

  return (
    <>
      {
        isLoading && !importData ? <Loader /> :
          <div className="col-lg-3 p-4">
            <div className="my-3">
            <FileUploadDisclaimer />
              <FileUploadButton
                handleFile={(file) => setImportFile(file)}
                defaultText={importData?.file?.id ? t('update file') : t('choose file')}

              />
            </div>
            <div className="my-3">
              <label>{t('titles row')}</label>
              <Form.Control name="title_row_number" type="number" value={showDefaultNumber(importData?.title_row_number)} onChange={handleOnChange} />
            </div>
            <div className="my-3">
              <label>{t('start row')}</label>
              <Form.Control name="start_row_number" type="number" value={showDefaultNumber(importData?.start_row_number)} onChange={handleOnChange} />
            </div>
            <div className="my-3">
              <label>{t('import_name')}</label>
              <Form.Control name="import_name" type="text" value={importData?.import_name} onChange={handleOnChange} />
            </div>
            <div className="my-3">
              <label>{t('recurring_source')}</label>
              <br />
              <Form.Control name="recurring_source" as="select" value={importData?.recurring_source} custom onChange={handleOnChange} >
                {importSchema?.recurring_source?.enum.map((title, index) => <option key={index} value={title}>{title}</option>)}
              </Form.Control>
            </div>
            <Button onClick={updateImport}>{t('update')}</Button>
          </div>
      }
    </>
  )
}

export default ImportEdit