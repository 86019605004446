import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

import NewMeetingLeadForm from '../../components/new_meeting_lead_form/newMeetingLeadForm'
import { getData } from "../../services/getServices";
import NotFoundPage from "../404page/notFoundPage";

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { toast } from 'react-toastify'
import { sendDataResponse } from '../../services/postServices'
import { useTranslation } from 'react-i18next';

function NewMeetingForm({ setMessage }) {
  const { leadId } = useParams();
  const [meetingLeadObj, setMeetingLeadObj] = useState(isNaN(leadId) ? {} : { lead: Number(leadId) })
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    checkLeadId()
  }, [])

  const checkLeadId = async () => {
    const lead = await getData(`leads/${leadId}`)
    if (lead?.response?.status === 404) {
      setIsNotFound(true);
    } else {
      setIsLoading(false)
    }
  }

  const postMeeting = async () => {
    const res = await sendDataResponse('meetingleads', {
      ...meetingLeadObj
    });

    if (res?.status === 400) {
      toast.warning(res.message);
    } else if (res?.data?.id) {
      toast.success(`${t('created meeting')}  ${res?.data?.id}`);
      history.push(`/meetings/grouped/${res?.data?.id}`)
    }
  }

  if (isNotFound) {
    return <NotFoundPage />
  }

  return (
    <>
      {!isLoading &&
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px auto', width: '18%' }}  >
            <Button onClick={(() => history.push(`/meetings/searchcreate/${leadId}`))}>{t('search create')}</Button>
            <Button disabled={true}>{t('form create')}</Button>
          </div>
          <Card style={{ margin: "auto" }}>
            <Card.Header>צרו פגישה חדשה</Card.Header>
            <Card.Body>
              <NewMeetingLeadForm leadId={leadId} meetingLeadObj={meetingLeadObj} setMeetingLeadObj={setMeetingLeadObj} postFunc={postMeeting} />
            </Card.Body>
          </Card>
        </div>
      }
    </>
  )
}

export default NewMeetingForm