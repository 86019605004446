import axios from 'axios'
import { getJwt } from './loginServices'
import { setProgressBar } from './progressServices';
import { showToastError } from './toastServices';

const updateDataResponse = async (endpoint, data, callback, catchback, returnJustData) => {
	setProgressBar('start');
	try {
		const localToken = await getJwt();
		const res = await axios.put(process.env.REACT_APP_API_PATH + endpoint, data, {
			headers: {
				Authorization: `Bearer ${localToken}`,
			},
		});
		console.log(res);
		if (callback) {
			callback(res.data);
		}
		setProgressBar('complete');
		return returnJustData ? res.data : res;
	} catch (error) {
		//TODO ADD GLOBAL WARRING SYSTEM?
		if (catchback) {
			catchback(error);
		}
		showToastError(error);
		setProgressBar('fail');
		return returnJustData ? error.response.data : error.response;
	}

}

const updateData = async (endpoint, data, callback, catchback) => {
	return await updateDataResponse(endpoint, data, callback, catchback, true)
}

export {
	updateDataResponse,
	updateData
}