import React, { useEffect, useState } from 'react'
import DynamicTableContainer from '../../components/dynamicTable/dynamicTableContainer';
import { useParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { sendDataResponse } from '../../services/postServices';
import { getData } from '../../services/getServices';
import { updateData } from '../../services/putServices';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import CardDeck from 'react-bootstrap/CardDeck';
import ListGroup from 'react-bootstrap/ListGroup';
import NotFoundPage from '../404page/notFoundPage';
import "./formSubmissionPage.css";

export default function FormsSubmissionsPage() {
    const { t, i18n } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [fetchedPayload, setFetchedPayload] = useState({});
    const [isNotFound, setIsNotFound] = useState(false);
    const params = useParams();
    const { formId } = params;

    const handleTableRowDoubleClick = (e, TableRow, history) => {
        e.preventDefault()
        history.push(`/forms/submissions/page/${TableRow.id}`);
    }
    const aquireRelevantData = async () => {
        const formData = await getData(`forms/${formId}`);
        if (formData?.response?.status === 404) {
            setIsNotFound(true)
        }
        let payloadObject = formData;
        if (Array.isArray(formData.connectionFieldsConfig)) {
            payloadObject.connectionFieldsConfig = formData.connectionFieldsConfig.filter(filteredObject => Object.keys(filteredObject).length === 2);
        }
        setFetchedPayload(payloadObject);
    }
    useEffect(() => {
        aquireRelevantData();
    }, []);

    const handleManualSubmit = () => {
        sendDataResponse('forms/importsubmissions', { formId })
    }

    const updateconnectionSetTempStatus = async (connectionTempStatus) => {
        const updatedForm = await updateData(`forms/updateconnection/${formId}`, { connectionTempStatus });
        if (updatedForm?.connectionTempStatus) {
            let duplicatePayload = { ...fetchedPayload };
            duplicatePayload.connectionTempStatus = updatedForm?.connectionTempStatus;
            setFetchedPayload(duplicatePayload);
        }
    }
    const dataCard = fetchedPayload?.connectionFieldsConfig && fetchedPayload.connectionFieldsConfig.length > 0 && fetchedPayload.connectionFieldsConfig.map(connectionField => {
        return <ListGroup.Item key={connectionField.field}> {connectionField.field}: {connectionField.name}</ListGroup.Item>
    })
    if (isNotFound) {
        return <NotFoundPage />
    }
    return (
        <>
            <br />
            <h2>{fetchedPayload.name}</h2>
            {fetchedPayload.hasOwnProperty('id') &&
                <div className="container-fluid formsubmission-page">
                    <div className="menu row">
                        <div className="left col">
                            <ButtonGroup vertical className="float-start">
                                <Button disabled={fetchedPayload.connectionStatus === 'notDefined' || fetchedPayload.connectionTempStatus === 'disabledConnection'} variant="primary" onClick={() => updateconnectionSetTempStatus('disabledConnection')}>{t('Disable connection to table')}</Button>
                                <Button disabled={fetchedPayload.connectionStatus === 'notDefined' || fetchedPayload.connectionTempStatus === 'disabledAll'} className="mt-2" variant="primary" onClick={() => updateconnectionSetTempStatus('disabledAll')}>{t('Disable form')}</Button>
                                <Button disabled={fetchedPayload.connectionStatus === 'notDefined' || fetchedPayload.connectionTempStatus === 'active'} className="mt-2" variant="primary" onClick={() => updateconnectionSetTempStatus('active')}>{t('Enable form')}</Button>
                            </ButtonGroup>

                        </div>
                        <div className="right col">
                            <Button disabled={fetchedPayload.connectionPolicy !== 'manual'} variant="primary" onClick={handleManualSubmit}>{t('Manual Submit')}</Button>
                        </div>

                    </div>
                    <CardDeck className="formsubmission-page_main">
                        <Card border="primary" style={{ width: '18rem' }}>
                            <Card.Header>{t('field matching')}</Card.Header>
                            <Card.Body>
                                <ListGroup variant='flush' >
                                    {
                                        Array.isArray(fetchedPayload.connectionFieldsConfig) ? dataCard
                                            : fetchedPayload.connectionFieldsConfig ?
                                                <ListGroup.Item> {t('selected meeting field')} : {fetchedPayload.connectionFieldsConfig.id}</ListGroup.Item>
                                                : t('did not match data')

                                    }
                                </ListGroup>
                            </Card.Body>
                        </Card>
                        <Card border="primary" style={{ width: '18rem' }}>
                            <Card.Header>{t('form data')}</Card.Header>
                            <Card.Body>
                                <ListGroup variant='flush' >
                                    <ListGroup.Item > Connection: {fetchedPayload.connection}</ListGroup.Item>
                                    <ListGroup.Item > Connection Policy: {fetchedPayload.connectionPolicy}</ListGroup.Item>
                                    <ListGroup.Item > Connection Status: {fetchedPayload.connectionStatus}</ListGroup.Item>
                                    <ListGroup.Item > Connection Temp Status: {fetchedPayload.connectionTempStatus}</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                    <Card border="info" className="lead-page_history-card mx-auto mt-1" style={{ borderRadius: '30px', width: '95%' }} >
                        <Card.Header style={{ borderRadius: '30px' }}>{`${t('submissions of')} ${t('form id')}: ${formId}`}</Card.Header>
                        <Card.Body>
                            <DynamicTableContainer
                                pagesRange={4}
                                initialEndpoint={`forms/submissions/${formId}`}
                                perPage={50}
                                handleTableRowDoubleClick={handleTableRowDoubleClick}
                                schemaName={{ TABLE_API: `formsubmissions/tableschema/${formId}`, FORM_API: "formsubmissions/filterSchema", countOverride: `forms/submissions/count/${formId}` }}
                            />
                        </Card.Body>
                    </Card>
                </div>
            }
        </>
    )
}