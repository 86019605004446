import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Select from 'react-select';

import InputTextareaSelect from '../input_textarea_select/inputTextareaSelect'
import moment from "moment";

import meetingLeadsEditableColumns from '../../data_organizers/meeting_leads/meetingLeadsEditableColumns'

import { TimePicker } from "../TimePicker/TimePicker";
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import { useTranslation } from 'react-i18next';
import { getData } from '../../services/getServices';

function NewMeetingLeadForm({ leadId, meetingLeadObj, setMeetingLeadObj, postFunc }) {
  const { t, i18n } = useTranslation();
  const [userNameArray, setUserNameArray] = useState(null);
  const [meetingTypesArray, setMeetingTypesArray] = useState(null);
  const [invalidData, setInvalidData] = useState(true);
  const history = useHistory();

  const onChange = (field, value) => {
    let newMeetingLeadObj = {
      ...meetingLeadObj,
      [field]: value
    }
    if (field === 'date') {
      newMeetingLeadObj = {
        ...newMeetingLeadObj,
        specific_datetime: moment(`${value} ${meetingLeadObj.time}`).format()
      }
    } else if (field === 'time') {
      newMeetingLeadObj = {
        ...newMeetingLeadObj,
        specific_datetime: moment(`${meetingLeadObj.date} ${value}`).format()
      }
    }
    setMeetingLeadObj(newMeetingLeadObj);
    checkValidData(newMeetingLeadObj);
  }

  const checkValidData = (newMeetingLeadObj) => {
    let isValid = false;
    if (newMeetingLeadObj.meeting_type_c && newMeetingLeadObj.assigned_to && !isNaN(newMeetingLeadObj.assigned_to) &&
      newMeetingLeadObj.specific_datetime && moment(newMeetingLeadObj.specific_datetime).isValid()) isValid = true;
    setInvalidData(!isValid);
  }

  const getUserArray = async () => {
    const res = await getData('users?role.name=instructor');
    let userNameArray = [];
    if (Array.isArray(res)) {
      res.forEach(userObject => {
        userNameArray.push({ id: userObject.id, name: userObject.username })
      })
      setUserNameArray(userNameArray)
    }
  }

  const getMeetingTypesArray = async () => {
    await getData(`meetingleads/formschema`, (res) => {
      if (res?.meeting_type_c?.enum) {
        setMeetingTypesArray(res.meeting_type_c.enum)
      }
    });
  }

  useEffect(() => {
    getUserArray()
    getMeetingTypesArray()
  }, [])



  return (
    <div>

      <ListGroup>
        {leadId && <ListGroup.Item>{`ליד משויך: ${leadId}`}</ListGroup.Item>}
        {meetingLeadsEditableColumns({}).map(field =>
          field.editable ? <ListGroup.Item>
            <label>{`${field.Header}: `}</label>
            <InputTextareaSelect
              type={field.type}
              value={meetingLeadObj[field]}
              onChange={(e) => onChange(field.accessor, e.target.value)}
              onBlur={() => { }}
            />
          </ListGroup.Item> :
            <React.Fragment></React.Fragment>
        )
        }
        {Array.isArray(userNameArray) &&
          Array.isArray(meetingTypesArray) &&
          <div style={{ padding: '12px' }}>
            <TimePicker jump={15} min={'09:00'} max={'15:00'} onChange={(newTime) => onChange("time", newTime)} />

            <label>{t("Choose Meeting Type")}:</label>
            <Select
              menuPlacement="auto"
              placeholder={t("Choose Meeting Type")}
              options={meetingTypesArray.map((option) => ({ value: option, label: t(option) }))}
              onChange={selectedMeetingType => { onChange('meeting_type_c', selectedMeetingType.value) }}
            />
            <label>{t("Choose User")}:</label>
            <Select
              menuPlacement="auto"
              placeholder={t("Choose User")}
              options={userNameArray.map((item) => ({ value: item.name, label: t(item.name) }))}
              onChange={selectedOption => {
                let userIndex = userNameArray.findIndex(object => { return object.name === selectedOption.value })
                onChange('assigned_to', Number(userNameArray[userIndex].id))
              }
              }
            />
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => history.goBack()}>ביטול</Button>
          <Button disabled={invalidData} onClick={postFunc}>צור</Button>
        </div>
      </ListGroup>
    </div>
  )
}

export default NewMeetingLeadForm