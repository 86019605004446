import { Link, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ListGroup, Card, CardDeck, Button } from 'react-bootstrap'
import { getData } from "../../services/getServices";
import "./admin_screen.css"
import DynamicTableContainer from "../../components/dynamicTable/dynamicTableContainer";
import AdminDeleteTaskModal from "../../config/dynamicTable/tasks/modals/adminDeleteTaskModal";
import { useTranslation } from 'react-i18next';

function AdminScreen() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null)
  const [tasksData, setTasksData] = useState(null)
  const [showAdminDeleteTaskModal, setShowAdminDeleteTaskModal] = useState(false)
  let history = useHistory();
  useEffect(() => {
    fetchData()
  }, [])


  const fetchData = async () => {
    try {
      let data = await getData("stats")
      setData(data)
    } catch (error) {
      console.log(error)
    }
  }

  const UserNameLink = ({ user, errMsg }) => {
    if (!user?.username) {
      return errMsg
    }

    return <Link to={`/users/${user.id}`}>{user.username}</Link>
  }

  const handleTableRowDoubleClick = (e, TableRow) => {
    let { task_type, status } = TableRow
    if (status === "available") {
      setTasksData(TableRow)
      switch (task_type) {
        case "delete_request":
          setShowAdminDeleteTaskModal(true)
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className="admin-page">


      {
        data
        && <>
          {showAdminDeleteTaskModal
            && <AdminDeleteTaskModal
              show={showAdminDeleteTaskModal}
              onHide={() => setShowAdminDeleteTaskModal(false)}
              title={`בקשת מחיקה ליד ${tasksData?.lead.id}`}
              onDoubleClick={(e) => { e.stopPropagation() }}
              data={tasksData}
            />}

          <div className="container">

            <Card style={{ width: "100%" }}>
              <Card.Header>tasks</Card.Header>

              <DynamicTableContainer
                pagesRange={4}
                initialEndpoint="tasks"
                perPage={10}
                handleTableRowDoubleClick={handleTableRowDoubleClick}
							  schemaName={{ TABLE_API: "tasks/TableSchema?schema_name=adminTasksPage", FORM_API: "tasks/filterSchema?schema_name=adminTasksPage" }}
              />
            </Card>
          </div>


          <CardDeck style={{ display: "flex", justifyContent: "space-around" }}>
            <Card>
              <Card.Header>{t('statistics')}</Card.Header>
              <Card.Body>
                <Card >
                  <Card.Header>{t('telephonistOfTheMonth')}</Card.Header>
                  <Card.Body>
                    <UserNameLink user={data.telephonistOfTheMonth} errMsg="משתמש החודש לא קיים" />
                  </Card.Body>
                </Card>
                <Card >
                  <Card.Header>{t('instructorOfTheMonth')}</Card.Header>
                  <Card.Body>
                    <UserNameLink user={data.instructorOfTheMonth} errMsg="משתמש החודש לא קיים" />
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>{t("newLeads")}</Card.Header>
                  <Card.Body>
                    {data.newLeads}
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>{t("createdMeetings")}</Card.Header>
                  <Card.Body>
                    {data.createdMeetings}
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>{t("finishedMeetings")}</Card.Header>
                  <Card.Body>
                    {data.finishedMeetings}
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>{t("workDays")}</Card.Header>
                  <Card.Body>
                    {data.workDays}
                  </Card.Body>
                </Card>
              </Card.Body>
              <Card.Footer>
                <Button onClick={() => history.push("/statistics")} className="w-100">{t('statistics view')}</Button>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Header>{t('settings')}</Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item>?</ListGroup.Item>
                  <ListGroup.Item>?</ListGroup.Item>
                  <ListGroup.Item>?</ListGroup.Item>
                  <ListGroup.Item>?</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </CardDeck>
        </>
      }
    </div>
  )
}

export default AdminScreen