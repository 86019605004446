import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'

export default function leadScenario({ failedObject, msg }) {
    return (
        <div>
            <Card style={{ width: "18rem" }}>
                <Card.Body>
                    <Card.Title>
                        <span style={{ fontSize: '0.8rem' }}>{msg}</span>
                        :
                        <Link to={`leads/${failedObject.leadId}`}>{failedObject.leadId}</Link>

                    </Card.Title>
                    <Card.Text>
                        {failedObject.messageContent}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}
