import React from "react";
import DynamicTableContainer from "../../components/dynamicTable/dynamicTableContainer";

function MeetingsView() {
  const handleTableRowDoubleClick = (e, TableRow, history,blank) => {
    e.preventDefault()
    let meetingId = TableRow?.id;
    meetingId &&  blank ? window.open(window.location.href.split("?")[0] + `/grouped/${meetingId}`, '_blank') : history.push("/meetings/grouped/" + meetingId);
  }

  return (
    <div>
      {/* <Button variant="primary" onClick={() => history.push(`meetings/create/new`)}>פגישה חדשה</Button> */}
      <DynamicTableContainer
        pagesRange={4}
        initialEndpoint="meetingleads"
        perPage={100}
        handleTableRowDoubleClick={handleTableRowDoubleClick}
        tableSchemaUrl
        schemaName={{ TABLE_API: "meetingleads/TableSchema?schema_name=meetingLeadsPage", FORM_API: "meetingleads/filterSchema?schema_name=meetingLeadsPage" }}
      />
    </div>
  )
}

export default MeetingsView