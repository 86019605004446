import userDataObject from "./main";
const organizeUserPage = (user) => {
  // set user fields values
  const userDetailsObject = userDataObject.userDetailsObject;
  Object.values(userDetailsObject).forEach((field) => {
    if (field.accessor) {
      field.value = user[field.accessor];
    }
    // boolean type nnd falsy
    if (field.custom) {
      field.customValue = () => field.custom(user);
    }
    if (field.value === null || field.value === undefined) {
      field.value = "אין";
    }
  });
  const userPageData = {
    Header: "מידע על המשתמש",
    fields: [
      userDetailsObject.full_name,
      userDetailsObject.phone_number,
      userDetailsObject.email,
      userDetailsObject.link_address,
      userDetailsObject.role,
      userDetailsObject.blocked,
      userDetailsObject.created_at,
    ],
  };
  return userPageData;
};

export default organizeUserPage;

export const userStatsOrganizerCardData = (data) => {
  let keysArray = [];
  for (const key in data) {
    keysArray.push(key);
  }
  const userStatsObject = userDataObject.userStatsObject;

  const statisticsCardData = keysArray.map((key) => {
    const field = userStatsObject[key];
    if (field?.custom) {
      field.value = field.custom(data);
    } else {
      field.value = data[field.accessor];
    }

    return field;
  });
  return statisticsCardData;
};
