import { genTableColumnSchema } from "./tableCell/tableCell";
/*
buttonColumnFunctions - is an object with a ctx property(a React context), for creating another column with Button that preform an action on each row data,
the context must wrap the DynamicTableContainer component, so the action function can extract from useContext hook
the function name that passed with the context in must be the same as the columnsSchema "functionName" property name
*/

// generate regular and custom data columns to create a dynamic table
export const genTableColumnsSchemaList = (columnsSchemaList, buttonColumnFunctions, setData, t) => {
  return columnsSchemaList.map((columnsSchema, i,) => {
    const schemaList = {
      ...genTableColumnSchema(columnsSchema, buttonColumnFunctions, setData, t, columnsSchemaList, i)
    }
    return schemaList
  })
}
