import React from "react";

import organizeMeetingLeadPage from '../../data_organizers/meeting_leads/meetingLeadsPageOrganizer'
import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'

function SpecificMeetingDetails({ meetingLead }) {
  const organizedMeetingLead = organizeMeetingLeadPage(meetingLead);

  const renderDataCards = () => {
    return organizedMeetingLead.map(parentField => {
      return (
        <Card border="primary" style={{ width: '18rem' }} key={parentField.Header}>
          <Card.Header>{parentField.Header}</Card.Header>
          <Card.Body>
            <ListGroup variant='flush' >
              {parentField.fields.map(field => {
                return (<ListGroup.Item key={field.Header}>
                  {`${field.Header}: `}
                  {field.customValue ? field.customValue() : `${field.value}`}
                </ListGroup.Item>)
              })}
            </ListGroup>
          </Card.Body>
        </Card>
      )
    })
  }


  return (
    <CardDeck style={{ margin: "auto", padding: "2rem", display: "flex", justifyContent: "space-around" }}>
      {renderDataCards()}
    </CardDeck>
  )
}

export default SpecificMeetingDetails;