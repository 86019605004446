import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import FormGrid from '../../../../components/form_grid/formGrid';
import organizeLeadForm from '../../../../data_organizers/leads/leadFormOrganizer';
import { Modal, Button, Card, ListGroup } from 'react-bootstrap';
import { updateDataResponse } from '../../../../services/putServices';
import { deleteData } from '../../../../services/deleteServices';
import { getData } from '../../../../services/getServices';
const dupeLeadIdHebTitle = 'מספר זיהוי לליד הכפול';
const leadIdHebTitle = 'מספר זיהוי הליד המקורי'


//The disputed lead ids and the modal display bools are sent from tasksPage via props
export default function DuplicateLeadModal({
    leadId,
    duplicateLeadId,
    onHide,
}) {
    const idHashMap = {
        [leadId]: duplicateLeadId,
        [duplicateLeadId]: leadId,
    };
    const history = useHistory();
    const [leadWithNames, setLeadWithNames] = useState(null);
    const [dupeLeadWithNames, setDupeLeadWithNames] = useState(null);
    const [lead, setLead] = useState(null);
    const [duplicateLead, setDuplicateLead] = useState(null);
    const [leadFormSchema, setLeadFormSchema] = useState(null);
    const [forceSubmit, setForceSubmit] = useState(false);
    const [forceDelete, setForceDelete] = useState(false);
    const [leadErrorRes, setLeadErrorRes] = useState(false)
    const [duplicateLeadErrorRes, setDuplicateLeadErrorRes] = useState(false)


    const fetchData = async () => {
        let lead = await getData(`leads/${leadId}`);
        let duplicateLead = await getData(`leads/${duplicateLeadId}`);
        let leadFormSchema = await getData('leads/formSchema');

        setLead(lead);
        setDuplicateLead(duplicateLead);
        setLeadWithNames(organizeLeadForm(lead));
        setDupeLeadWithNames(organizeLeadForm(duplicateLead));
        setLeadFormSchema(leadFormSchema);
    };

    const handleSubmitDelete = async (payload, submittedLeadId) => {
        try {
            const updateUrl = `leads/${submittedLeadId}`;
            const updateRes = await updateDataResponse(updateUrl, payload);
            if (updateRes.status === 400 || updateRes.status === 404) throw updateRes;
            toast.success(`ליד ${submittedLeadId} עודכן בהצלחה`)

            const deleteUrl = `leads/${idHashMap[submittedLeadId]}`;
            const deleteRes = await deleteData(deleteUrl);
            if (deleteRes.status === 400 || deleteRes.status === 404) throw deleteRes;
            toast.success(`ליד ${idHashMap[submittedLeadId]} נמחק בהצלחה`);
            history.push(history.location.pathname + history.location.search);
        } catch (error) {
            if (lead?.id === submittedLeadId) {
                setLeadErrorRes(error.data.message);
            } else {
                setDuplicateLeadErrorRes(error.data.message);
            }
            console.log(error);
            return;
        }
    };

    const handleDelete = async (submittedLeadId) => {
        try {
            const url = `leads/${submittedLeadId}`;
            const res = await deleteData(url);
            if (res.status === 400 || res.status === 404) throw res;
            toast.success(`ליד ${submittedLeadId} נמחק בהצלחה`);
            history.push(history.location.pathname + history.location.search);
        } catch (error) {
            if (lead?.id === submittedLeadId) {
                setLeadErrorRes(error.data.message);
            } else {
                setDuplicateLeadErrorRes(error.data.message);
            }
            console.log(error);
            return;
        }
    };

    const handleSubmit = async (payload, submittedLeadId) => {
        try {
            const url = `leads/${submittedLeadId}`;
            const res = await updateDataResponse(url, payload);
            if (res.status === 400 || res.status === 404) throw res;
            history.push(history.location.pathname + history.location.search);
            toast.success(`ליד ${submittedLeadId} עודכן בהצלחה`)
        } catch (error) {
            if (lead?.id === submittedLeadId) {
                setLeadErrorRes(error.data.message);
            } else {
                setDuplicateLeadErrorRes(error.data.message);
            }
            console.log(error);
            return;
        }
    };

    const getButtonArray = () => [
        {
            text: (id) => `מחק ליד זה (${id}) בלבד`,
            submit: (leadLoad, id) => handleDelete(id),
            color: "danger"
        },
        {
            text: (id) => `עדכן ליד זה (${id}) בלבד`,
            submit: (leadLoad, id) => handleSubmit(leadLoad, id),
        },
        {
            text: (id) => `עדכן ליד זה (${id}) ומחק את השני (${idHashMap[id]})`,
            submit: (leadLoad, id) => handleSubmitDelete(leadLoad, id),
            color: "warning"
        },
    ];

    //OnComponentMount the required data is fetched

    useEffect(() => {
        fetchData();
    }, []);

    //in order to avoid accessing files that are still loading asynchronously we use this bool
    //to specify loading

    const bool =
        leadWithNames &&
        dupeLeadWithNames &&
        lead &&
        duplicateLead &&
        leadFormSchema;

    //The returned JSX is a modal with two lead update forms and the user can either update
    //the lead or update the lead and remove overlappingt counterpart

    return (
        <div>
            {bool ? (
                <Modal
                    size={'xl'}
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                    show={true}
                    onHide={onHide}
                >
                    <Modal.Dialog style={{ maxWidth: '100%' }}>
                        <Modal.Header closeButton>
                            <Modal.Title>לידים כפולים</Modal.Title>
                        </Modal.Header>

                        <Modal.Body className='modal-body'>
                            <ListGroup horizontal>
                                <ListGroup.Item>
                                    <h4>{leadIdHebTitle}: <Link to={`/leads/${lead.id}`}>{lead.id}</Link></h4>
                                    <FormGrid
                                        objectWithNames={leadWithNames}
                                        _objectForEdit={lead}
                                        submitFunc={(payload, id) => {
                                            handleSubmit(payload, id)
                                        }}
                                        cancelFunc={() =>
                                            history.push(`/leads/${lead.id}`)
                                        }
                                        submitTxt={'עדכן'}
                                        leadFormSchema={leadFormSchema}
                                        buttonArray={getButtonArray(lead, duplicateLead)}
                                        forceSubmit={forceSubmit}
                                        forceDelete={forceDelete}
                                        errorRes={leadErrorRes}
                                    />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <h4>{dupeLeadIdHebTitle}: <Link to={`/leads/${duplicateLead.id}`}>{duplicateLead.id}</Link></h4>
                                    <FormGrid
                                        objectWithNames={dupeLeadWithNames}
                                        _objectForEdit={duplicateLead}
                                        submitFunc={(payload, id) => {
                                            handleSubmit(payload, id)
                                        }}
                                        cancelFunc={() =>
                                            console.log('unexcepted function trigger')
                                        }
                                        submitTxt={'עדכן'}
                                        leadFormSchema={leadFormSchema}
                                        buttonArray={getButtonArray(duplicateLead, lead)}
                                        forceSubmit={forceSubmit}
                                        forceDelete={forceDelete}
                                        errorRes={duplicateLeadErrorRes}
                                    />
                                </ListGroup.Item>
                            </ListGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={onHide}>
                                ביטול
                            </Button>
                            <Button
                                variant='primary'
                                onClick={() => setForceSubmit(true)}
                            >
                                עדכן את שני הלידים
                            </Button>
                            <Button
                                variant='danger'
                                onClick={() => setForceDelete(true)}
                            >
                                מחק את שני הלידים
                            </Button>
                        </Modal.Footer>
                    </Modal.Dialog>
                </Modal>
            ) : (
                ''
            )}
        </div>
    );
}
