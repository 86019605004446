import { createState, useState, State } from '@hookstate/core';

const globalState = createState('0');
const rolePermissionsState = createState('0');

const wrapState = (progressState) => ({
    get: () => progressState.value,
    set: (status) => progressState.set(status)
})

export const accessGlobalState = () => wrapState(globalState)
export const useGlobalState = () => wrapState(useState(globalState))
export const accessRolePermissionsState = () => wrapState(rolePermissionsState)