import { useHistory } from "react-router-dom";
import React from "react";

import CopyToClipboard from "../../components/copyToClipboard/copyToClipboard";
import "./errorPage.css";

function ErrorPage() {
    let history = useHistory();
    let supportEmail = 'Aviv657@gmail.com';
    let errorMessage = history.location.state;


    const goBack = () => {
        history.goBack();
    }

    return (
        <div className='not-found-image'>
            <div className="h-100 p-5 bg-light border rounded-3">
                <div className="container-fluid py-5">
                    <h1 className="display-5 fw-bold">משהו השתבש!</h1>
                    <p className="col-md-8 fs-4">הבעיה רשומה למטה, לחצו על הטקסט כדי להעתיק ושלחו לצוות התמיכה ב-
                        <CopyToClipboard text={supportEmail} darkMode={false} />
                    </p>
                    <p className="col-md-8 fs-4">לחץ על כפתור "חזור" כדי לחזור לעמוד הקודם.</p>
                    <button onClick={goBack} className="btn btn-primary btn-lg" type="button">חזור</button>
                </div>
            </div>
            <div className="h-100 p-5 error-message border rounded-3">
                <CopyToClipboard text={errorMessage} darkMode={true} />
            </div>
        </div>
    )

}

export default ErrorPage;