import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import { ImageView, ImageList } from '../../../../pages/lead_page/image_display/ImageView';
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal';
import { useState } from 'react'
export default function FormSubmissionFileMultipleDownloadCellItem(tableInfo, setData, extraData) {
    const { t } = useTranslation();
    const [isDownloadManyModalShow, setIsDownloadManyModalShow] = useState(false)
    const rowData = tableInfo?.row?.original;
    const columnName = tableInfo?.column?.Header;
    let pressedFileField;
    for (const key in rowData) {
        if (key.includes(columnName) && key.length - columnName.length === 5) {
            pressedFileField = rowData[key];
        }
    }


    return (
        <div>
            <Button onClick={(() => setIsDownloadManyModalShow(true))} disabled={rowData?.["randomFiles:files"] && rowData["randomFiles:files"].length > 0 ? false : true}>{`download ${extraData.fieldName} files`}</Button>
            {rowData?.["randomFiles:files"] && rowData["randomFiles:files"].length > 0 && <VerticallyCenteredModal onHide={(() => setIsDownloadManyModalShow(false))} show={isDownloadManyModalShow}> <ImageList imageObjectList={rowData["randomFiles:files"]} isGrid={true} lineCount={3} /> </VerticallyCenteredModal>}
        </div>
    )
}



