// return value by action, pass it to helper function
import moment from "moment";


export const mutateValueByAction = (action) => {

  switch (action.type) {
    case 'getTime':
      return moment(action.payload).format('HH:mm')
    case "getDate":
      return moment(action.payload).format('DD-MM-YYYY')
    case 'getDateTime':
      return moment(action.payload).format('DD-MM-YYYY HH:mm')
    case 'getDayOfWeek':
      let outputWord = moment(action.payload).format('dddd');
      switch (outputWord) {

        case 'Sunday':
          return 'ראשון';

        case 'Monday':
          return 'שני';

        case 'Tuesday':
          return 'שלישי';

        case 'Wednesday':
          return 'רביעי';

        case 'Thursday':
          return 'חמישי';

        case 'Friday':
          return 'שישי';

        case 'Saturday':
          return 'שבת';
        default: return action.payload
      }
    default: return action.payload
  }
}
