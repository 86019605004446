import React, { useState } from 'react'
import DynamicTableContainer from '../../components/dynamicTable/dynamicTableContainer';
import InvalidAddressModal from '../../config/dynamicTable/tasks/modals/InvalidAddressModal';
import ExpiringSoonModal from '../../config/dynamicTable/tasks/modals/ExpiringSoonModal';
import DuplicateLeadModal from '../../config/dynamicTable/tasks/modals/duplicateLeadModal';
import { getItemFromLocalStorage } from '../../helpers/auth';

export default function TasksPage(props) {
  const [modalShowInvalidAddress, setModalShowInvalidAddress] = useState(false);
  const [modalShowExpiringSoon, setModalShowExpiringSoon] = useState(false);
  const [modalShowDuplicateLead, setModalShowDuplicateLead] = useState(false);
  const [data, setData] = useState(null);

  const handleTableRowDoubleClick = (e, TableRow) => {
    let { task_type, status } = TableRow;
    if (status === "available") {
      setData(TableRow);
      switch (task_type) {
        case "invalid_address":
          // This kind of tasks are not created for now, but their modal is halfway ready(need client based fields rendering).
          setModalShowInvalidAddress(true);
          break;
        case "expiring_soon":
          const expiringSoonFeatureFlag = getItemFromLocalStorage("featureflags")?.features?.expiringSoonTask;
          expiringSoonFeatureFlag && setModalShowExpiringSoon(true);
          break;
        case "duplicate_lead":
          setModalShowDuplicateLead(true);
          break;
        default:
          break;
      }
    }
  }


  return (
    <>
      {data && modalShowInvalidAddress && <InvalidAddressModal
        onHide={() => setModalShowInvalidAddress(false)}
        title={`${data.task_type}`}
        onDoubleClick={(e) => { e.stopPropagation() }}
        data={data}
      />
      }
      {data && modalShowExpiringSoon && <ExpiringSoonModal
        onHide={() => setModalShowExpiringSoon(false)}
        title={`${data.task_type}`}
        onDoubleClick={(e) => { e.stopPropagation() }}
        data={data.lead}
      />
      }
      {data && modalShowDuplicateLead && <DuplicateLeadModal
        onHide={() => setModalShowDuplicateLead(false)}
        leadId={data.lead.id}
        duplicateLeadId={data.duplicate_lead.id}
      />
      }

      <DynamicTableContainer
        pagesRange={4}
        initialEndpoint="tasks"
        perPage={50}
        handleTableRowDoubleClick={handleTableRowDoubleClick}
        schemaName={{ TABLE_API: "tasks/TableSchema?schema_name=tasksPage", FORM_API: "tasks/filterSchema?schema_name=tasksPage" }}
      />
    </>
  )
}

