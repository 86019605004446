import React, { useState, useEffect, useMemo, memo } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import {
	createFetchCountQueries,
	removeSuffix,
	checkPageNumber,
	checkSortValue,
	createCleanUrlQuery,
	formatFormDataKeys,
	formatFormDataDefaultValues,
	formatFormDataEmptyValues,
	translateUrlValues,
} from "../../helpers/dynamicTable/form/form";
import { genTableColumnsSchemaList } from "../../helpers/dynamicTable/table/table";
import Loader from "../Loader/Loader";
import { getData } from "../../services/getServices";
import { useTranslation } from "react-i18next";
import Table from "./table";
import TablePagination from "./tablePagination";
import SearchForm from "./searchForm";

const DynamicTableContainer = ({
	initialEndpoint,
	pagesRange,
	perPage,
	handleTableRowDoubleClick,
	schemaName,
	columnButtonFunctions,
}) => {
	const { FORM_API, TABLE_API } = schemaName;
	const { t } = useTranslation();
	const countOverride = schemaName?.countOverride
		? schemaName.countOverride
		: false;

	let history = useHistory();

	const location = useLocation();


	const urlParams = useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	);
	let paramsObj = useParams();
	const [isLoadingStructure, setIsLoadingStructure] = useState(true);
	const [isLoadingData, setIsLoadingData] = useState(true);
	const [filterFormSchema, setFilterFormSchema] = useState(undefined);
	const [filterFormSettings, setFilterFormSettings] = useState(undefined);
	const [tableSettings, setTableSettings] = useState(undefined);
	const [defaultFilters, setDefaultFilters] = useState(undefined);
	const [allDataRecords, setAllDataRecords] = useState(0);
	const [formData, setFormData] = useState(undefined);
	const [initialFormData, setInitialFormData] = useState(undefined);
	const [data, setData] = useState([]);
	const [pagesNumber, setPagesNumber] = useState(null);
	const [currentPage, setCurrentPage] = useState(
		Number(urlParams.get("page")) || 1
	);
	const [rawQuery, setRawQuery] = useState("");
	const [tableColumnsSchema, setTableColumnsSchema] = useState([]);
	const [sorter, setSorter] = useState(
		urlParams.get("_sort") ? urlParams.get("_sort") : undefined
	);

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		initWithInitialFormData();
	}, [currentPage, sorter]);

	const urlQueryToFormData = (formSchema, formSettings) => {
		let initFormData = {};
		let search = location.search.substring(1);
		if (search.length === 0 || formSettings.urlFiltering === false) {
			formSchema.forEach((formField) => {
				const formDataKeys = formatFormDataKeys(formField);
				formDataKeys.forEach(formDataKey => {
					if (
						(formField.defaultValue && !Array.isArray(formField.defaultValue)) ||
						(formField.defaultValue && Array.isArray(formField.defaultValue) && formField.defaultValue.length) ||
						(formField?.dynamicDefaultValue?.value)
					) {
						initFormData = formatFormDataDefaultValues(formField, formDataKey, initFormData);
					} else {
						initFormData = formatFormDataEmptyValues(formField, formDataKey, initFormData);
					}
				});
			});
		} else {
			const urlFiltersArray = decodeURI(search).split("&");
			formSchema.forEach((formField) => {
				const formDataKeys = formatFormDataKeys(formField);
				formDataKeys.forEach(formDataKey => {
					const foundUrlFilters = urlFiltersArray.filter(urlFilter =>
						urlFilter.split("=")[0] &&
						removeSuffix(urlFilter.split("=")[0]) === removeSuffix(formDataKey));
					if (foundUrlFilters.length) {
						foundUrlFilters.forEach(urlFilter => {
							const [key, value] = urlFilter.split("=");
							initFormData = translateUrlValues(formField, key, value, initFormData);
						});
					} else {
						initFormData = formatFormDataEmptyValues(formField, formDataKey, initFormData);
					}
				});
			});
		}
		setFormData(initFormData);
		setInitialFormData(initFormData);
		return initFormData;
	};

	const handleFetchedFormConfig = (formConfig) => {
		setFilterFormSchema(formConfig.newSchema);
		setFilterFormSettings(formConfig.settings);
		return urlQueryToFormData(formConfig.newSchema, formConfig.settings);
	};

	const handleFetchedTableConfig = (tableConfig) => {
		setTableSettings(tableConfig.settings);
		setDefaultFilters(tableConfig.defaultFilters);
		setTableColumnsSchema(
			genTableColumnsSchemaList(
				tableConfig.visible,
				columnButtonFunctions,
				setData,
				t
			)
		)
	};

	const init = async () => {
		try {
			setIsLoadingStructure(true);
			setIsLoadingData(true);
			const fetchedFormConfig = await getData(FORM_API);
			const initFormData = handleFetchedFormConfig(fetchedFormConfig);
			const fetchedTableConfig = await getData(TABLE_API);
			handleFetchedTableConfig(fetchedTableConfig);
			setIsLoadingStructure(false);

			const { partialFetchQuery, countQuery } = createFetchCountQueries(initFormData, fetchedTableConfig.defaultFilters, fetchedFormConfig.newSchema, paramsObj, initialEndpoint, countOverride);

			const dataRecords = await getData(countQuery);
			const pagesNumber = Math.ceil(dataRecords / perPage) || 1;
			setAllDataRecords(dataRecords);
			setPagesNumber(pagesNumber);
			const pageNumber = checkPageNumber(currentPage, pagesNumber);
			setCurrentPage(pageNumber);
			let fetchQuery = `${partialFetchQuery}_start=${(pageNumber - 1) * perPage}&_limit=${perPage}&`;

			const sortValue = checkSortValue(sorter, fetchedFormConfig.newSchema);
			setSorter(sortValue);
			fetchQuery = sortValue ? `${fetchQuery}_sort=${sortValue}` : fetchQuery.slice(0, -1);
			setRawQuery(fetchQuery);
			let tableRecords = await getData(fetchQuery);
			setData(tableRecords);
			setIsLoadingData(false);
			if (location.search.substring(1).length > 0 && fetchedFormConfig.settings.urlFiltering === true) {
				const newUrlQuery = createCleanUrlQuery(initFormData, fetchedFormConfig.newSchema, pageNumber, sortValue);
				window.history.replaceState(null, "", history.location.pathname + newUrlQuery)
			}
		} catch (error) {
			console.log("error", error);
			setIsLoadingStructure(false);
			setIsLoadingData(false);
		}
	};

	const initWithInitialFormData = async (countChnaged = false) => {
		try {
			setIsLoadingData(true);
			const { partialFetchQuery, countQuery } = createFetchCountQueries(initialFormData, defaultFilters, filterFormSchema, paramsObj, initialEndpoint, countOverride);

			let pageNumber;
			if (countChnaged) {
				const dataRecords = await getData(countQuery);
				const pagesNumber = Math.ceil(dataRecords / perPage) || 1;
				setAllDataRecords(dataRecords);
				setPagesNumber(pagesNumber);
				pageNumber = checkPageNumber(currentPage, pagesNumber);
				setCurrentPage(pageNumber);
			} else {
				pageNumber = currentPage;
			}

			let fetchQuery = `${partialFetchQuery}_start=${(pageNumber - 1) * perPage}&_limit=${perPage}&`;
			fetchQuery = sorter ? `${fetchQuery}_sort=${sorter}` : fetchQuery.slice(0, -1);
			setRawQuery(fetchQuery);
			let tableRecords = await getData(fetchQuery);
			setData(tableRecords);
			setIsLoadingData(false);
			if (filterFormSettings.urlFiltering === true) {
				const newUrlQuery = createCleanUrlQuery(initialFormData, filterFormSchema, pageNumber, sorter);
				window.history.replaceState(null, "", history.location.pathname + newUrlQuery)
			}
		} catch (error) {
			console.log("error", error);
			setIsLoadingData(false);
		}
	};

	const deleteManyCallback = () => {
		initWithInitialFormData(true);
	};

	return isLoadingStructure ? (
		<Loader />
	) : (
		<>
			{filterFormSchema?.length > 0 && (
				<SearchForm
					setFormData={setFormData}
					setInitialFormData={setInitialFormData}
					setIsLoadingData={setIsLoadingData}
					setData={setData}
					setAllDataRecords={setAllDataRecords}
					setPagesNumber={setPagesNumber}
					setCurrentPage={setCurrentPage}
					setSorter={setSorter}
					setRawQuery={setRawQuery}
					formSchema={filterFormSchema}
					formSettings={filterFormSettings}
					formData={formData}
					initialFormData={initialFormData}
					defaultFilters={defaultFilters}
					tableSettings={tableSettings}
					sorter={sorter}
					paramsObj={paramsObj}
					rawQuery={rawQuery}
					perPage={perPage}
					countOverride={countOverride}
					initialEndpoint={initialEndpoint}
					history={history}
				/>
			)}
			<TablePagination
				setPage={setCurrentPage}
				pagesNumber={pagesNumber}
				range={pagesRange}
				currentPage={currentPage}
			/>
			<Table
				setSorter={setSorter}
				isLoadingData={isLoadingData}
				sorter={sorter}
				tableSettings={tableSettings}
				allDataRecords={allDataRecords}
				data={data}
				columns={tableColumnsSchema}
				deleteManyCallback={deleteManyCallback}
				handleTableRowDoubleClick={handleTableRowDoubleClick}
				history={history}
			/>
		</>
	);
};

export default memo(DynamicTableContainer);