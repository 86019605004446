import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { deleteData } from "../../services/deleteServices";
import { sendDataResponse } from "../../services/postServices";
import { getData } from "../../services/getServices";
import { Spinner } from 'react-bootstrap';

import moment from "moment";
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import MeetingLeadsTable from "../../components/MeetingLeadsTable/MeetingLeadsTable";
import DynamicTableContainer from "../../components/dynamicTable/dynamicTableContainer";
import NotFoundPage from "../404page/notFoundPage";
import { genTableColumnsSchemaList } from "../../helpers/dynamicTable/table/table";
import { toast } from 'react-toastify';
import { createContext } from "react";
import { useTranslation } from 'react-i18next';

export const meetingFormContext = createContext(null);


function MeetingForm({ setMessage }) {
	const { id } = useParams();
	const [Loading, setLoading] = useState(false)
	const [associatedIDs, setAssociatedIDs] = useState([])
	const [associatedMeetingLeads, setAssociatedMeetingLeads] = useState([])
	const [meetingLeadsToPut, setMeetingLeadsToPut] = useState([])
	const [meetingLeadsToPost, setMeetingLeadsToPost] = useState([])
	const [isNotFound, setIsNotFound] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [currentUserId, setCurrentUserId] = useState(null);
	const [meetingLeadsSchema, setMeetingSchema] = useState(null)

	let history = useHistory();
	const { t, i18n } = useTranslation();

	let TableColumnsButtonsFunctions = {
		ctx: meetingFormContext
	}
	const generateId = () => {
		const meetingsAmount = 1000;
		if (associatedMeetingLeads.length >= meetingsAmount) {
			toast.warning(`Can't create more than ${meetingsAmount} meetings`)
			return false
		}
		let randomId = Math.floor(Math.random() * meetingsAmount + 1);
		randomId = Number(randomId);
		return associatedIDs.includes(randomId) ? generateId() : randomId
	}
	const addMeeting = (row, buttonTitle) => {
		let timeValue;
		if (buttonTitle === "add_after") {
			timeValue = moment(associatedMeetingLeads[associatedMeetingLeads.length - 1].specific_datetime).add(30, 'minutes').format()
		} else if (buttonTitle === "add_before") {
			timeValue = moment(associatedMeetingLeads[associatedMeetingLeads.length - 1].specific_datetime).subtract(30, 'minutes').format()
		}
		const newLead = {
			staging: true,
			id: generateId(),
			lead: {
				...row
			},
			specific_comment: null,
			meeting_type_c: null,
			specific_datetime: timeValue,
			time: moment(timeValue).format('HH:mm'),
			date: moment(timeValue).format('YYYY MM DD')
		}
		if (!newLead.id) {
			return;
		}
		setAssociatedMeetingLeads([...associatedMeetingLeads, newLead]);
		setAssociatedIDs(prev => {
			prev = [...prev, newLead.id]
			return prev;
		})
		setMeetingLeadsToPost(prev => {
			prev = [...prev, {
				tempId: newLead.id,
				lead: row.id,
				time: moment(timeValue).format('HH:mm'), date: moment(timeValue).format('YYYY MM DD'), specific_datetime: timeValue,
				assigned_to: currentUserId
			}
			];
			return prev;
		})
		toast.success("נוספה פגישה")
	}

	const updateMeetingLead = (field, rowData, value) => {
		const meetingLeadID = rowData.id;

		setMeetingLeadsToPut(prev => {
			let meetingIndex = prev.findIndex(meetingLead => meetingLead.id === meetingLeadID);
			if (meetingIndex === -1) {
				return prev;
			}
			prev[meetingIndex][field] = value;
			let currentMeetingLeadIndex = associatedMeetingLeads.findIndex(meetingLead => meetingLead.id === meetingLeadID)
			if (field === 'meeting_type_c' && !value) {
				delete prev[meetingIndex][field];
			} else if (field === 'date') {
				let time = prev[meetingIndex].time;
				time = time ? time : moment(associatedMeetingLeads[currentMeetingLeadIndex].specific_datetime).format('HH:mm');
				prev[meetingIndex]['specific_datetime'] = moment(`${value} ${time}`).format();
			} else if (field === 'time') {
				let date = prev[meetingIndex].date;
				date = date ? date : moment(associatedMeetingLeads[currentMeetingLeadIndex].specific_datetime).format('YYYY MM DD');
				prev[meetingIndex]['specific_datetime'] = moment(`${date} ${value}`).format();
			}
			return prev;
		})

		setMeetingLeadsToPost(prev => {
			let meetingIndex = prev.findIndex(meetingLead => meetingLead.tempId === meetingLeadID);
			if (meetingIndex === -1) {
				return prev;
			}
			prev[meetingIndex][field] = value;
			if (field === 'date') {
				let time = prev[meetingIndex].time;
				prev[meetingIndex]['specific_datetime'] = moment(`${value} ${time}`).format();
			} else if (field === 'time') {
				let date = prev[meetingIndex].date;
				prev[meetingIndex]['specific_datetime'] = moment(`${date} ${value}`).format();
			} else if (field === 'meeting_type_c' && !value) {
				delete prev[meetingIndex][field];
			}
			return prev;

		})

		setAssociatedMeetingLeads(prev => {
			let currentMeetingLeadIndex = prev.findIndex(meetingLead => meetingLead.id === meetingLeadID)
			if (currentMeetingLeadIndex !== -1) {
				prev[currentMeetingLeadIndex][field] = value;
				if (field === 'date') {
					let time = moment(prev[currentMeetingLeadIndex].specific_datetime).format('HH:mm');
					prev[currentMeetingLeadIndex].specific_datetime = moment(`${value} ${time}`).format();
				} else if (field === 'time') {
					let date = moment(prev[currentMeetingLeadIndex].specific_datetime).format('YYYY MM DD');
					prev[currentMeetingLeadIndex].specific_datetime = moment(`${date} ${value}`).format();
				}
				return [...prev];
			} else {
				prev = [...prev, { id: meetingLeadID, [field]: value }];
				return [...prev];
			}
		})
	}

	const fetchData = async () => {
		const error = await getData(`meetingleads/groupedfind/${id}`, (res) => {
			setAssociatedMeetingLeads(res)
			setCurrentUserId(res[0].assigned_to.id)
			let formatedData = [];
			let ids = [];
			res.forEach(meetingLead => {
				ids.push(meetingLead.id)
				formatedData.push({ id: meetingLead.id });
			})
			setAssociatedIDs(ids)
			setMeetingLeadsToPut(formatedData)
		})
		await getData(`meetingleads/formschema`, (res) => {
			setMeetingSchema(res)
		})
		setIsLoading(false);
		if (error?.statusCode === 404) {
			setIsNotFound(true);
		}
	}


	const updateMeetingLeads = async () => {
		let message = `פגישות עודכנו בהצלחה!`;
		const endpoint = `meetingleads/updatemany`;

		setLoading(true);
		const res = await sendDataResponse(endpoint, { meeting_leads: [...meetingLeadsToPost, ...meetingLeadsToPut].filter(payloadObject => !(payloadObject['id'] && Object.keys(payloadObject).length === 1)) });
		setLoading(false);


		if (res?.status === 400) {
			toast.warning(res.message)
		} else {
			const foundMeetingLead = meetingLeadsToPut.find(meetingLead => meetingLead.id === Number(id));
			if (foundMeetingLead) {
				history.push(`/meetings/grouped/${id}`)
			} else {
				const randId = meetingLeadsToPut.filter(meetingLead => meetingLead?.id)[0].id;
				history.push(`/meetings/grouped/${randId}`)
			}
			toast.success(message)
		}
	}

	const deleteMeetingLead = async (rowData) => {
		const meetingLeadID = rowData.id;
		setAssociatedIDs(prev => {
			const checker = prev.indexOf(meetingLeadID);
			if (checker !== -1) {
				prev.splice(checker, 1);
			}
			return [...prev]
		})
		setMeetingLeadsToPost(prev => {
			const checker = prev.findIndex(postLead => meetingLeadID === postLead.tempId);
			if (checker !== -1) {
				prev.splice(checker, 1);
			}
			return [...prev]
		})

		setMeetingLeadsToPut(prev => {
			const checker = prev.findIndex(postLead => meetingLeadID === postLead.id);
			if (checker !== -1) {
				prev.splice(checker, 1);
			}
			return [...prev]
		})

		const assoIndex = associatedMeetingLeads.findIndex(postLead => (meetingLeadID === postLead.id));
		if (!associatedMeetingLeads[assoIndex].staging) {
			setLoading(associatedMeetingLeads[assoIndex].id);
			const endPoint = `meetingleads/${meetingLeadID};`
			await deleteData(endPoint, (res) => {
				if (res.status === 200) {

					setLoading(false);

				} else
					toast.warning(`שגיאה ${res.status}`);
			});
		}
		setAssociatedMeetingLeads(prev => {
			prev.splice(assoIndex, 1);
			return [...prev]
		})
	}

	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		meetingLeadsToPut === [] && history.push(`/meetings`)
	}, [meetingLeadsToPut])

	if (isNotFound) {
		return <NotFoundPage />
	}

	return (
		<>
			{!isLoading &&
				<div>
					<h1>עריכת פגישה</h1>
					<h2>כל הלידים</h2>
					<meetingFormContext.Provider value={{ addMeeting }} >
						<DynamicTableContainer
							genTableColumnsSchemaList={genTableColumnsSchemaList}
							pagesRange={4}
							initialEndpoint="leads"
							perPage={50}
							columnButtonFunctions={TableColumnsButtonsFunctions}
							schemaName={{ TABLE_API: "leads/TableSchema?schema_name=meetingEditLeads", FORM_API: "leads/filterSchema?schema_name=meetingEditLeads" }}

						/>
					</meetingFormContext.Provider >

					<h2>פגישות משוייכות</h2>
					{!Loading ? <>
						{associatedMeetingLeads.length ?
							<>
								<MeetingLeadsTable
									meetingLeadsSchema={meetingLeadsSchema}
									updateRow={updateMeetingLead}
									data={associatedMeetingLeads}
									isLoading={Loading}
									onDelete={deleteMeetingLead}
								/>


							</>
							:
							<h5 style={{ textAlign: "right" }}>אין פגישות משוייכות כעת</h5>
						}</>
						:
						<div className="text-center h-3">
							<Spinner

								as="span"
								animation="border"
								size="lg"
								textAlign="center"
								role="status"
								aria-hidden="true"
							/>
						</div>
					}
					<ButtonGroup style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse' }}>
						<Button disabled={Loading} onClick={updateMeetingLeads}>עדכון</Button>
						<Button onClick={() => history.push(`/meetings/${id}`)}>ביטול</Button>
					</ButtonGroup>
				</div>
			}
		</>
	)
}

export default MeetingForm;