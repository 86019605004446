import meetingLeadObject from "./main"

const meetingLeadObjectShallowCopy = { ...meetingLeadObject }

const meetingLeadsEditableColumns = (updateMeetingLead) => {
  const res = [
    meetingLeadObjectShallowCopy.date,
    meetingLeadObjectShallowCopy.specific_comment,
    meetingLeadObjectShallowCopy.lead,
    
  ].reverse().map(field => {
    const temp = { ...field }
    if (temp.customInput) {
      temp.Cell = ({ row }) => temp.customInput(row.original, updateMeetingLead)
    }
    return temp
  });


  return res
}



export default meetingLeadsEditableColumns