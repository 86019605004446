import { Link } from "react-router-dom";
import routesPath from "../../../../../config/dynamicTable/routes.json";
import ShowMoreText from "../../../../../components/showMoreText/ShowMoreText";
import { mutateValueByAction } from "./date";
import { Button } from "react-bootstrap";
import { useContext } from "react";
import { findDeepValue } from "../../../utils";
import { useTranslation } from 'react-i18next';



/*INFO:
tableInfo.value get the data from accessor,  obj[accessor]
for getting specific cell data -> tableInfo.data[tableInfo.row.index];
Cell property is what render as cell in react table, as default he get a tableInfo object
that has all the information about the table
*/


export const getCellLinkType = (tableInfo, columnSchema) => {
  if (!tableInfo.value) return null
  //  for every link we must add it in routes.json
  let link = routesPath[columnSchema.linkTo];
  if (!link) return null;
  let initialTextValue = findDeepValue(columnSchema.displayValue, tableInfo.value), cellTextValue;
  if (!initialTextValue) cellTextValue = `${tableInfo.title} link`;
  else cellTextValue = initialTextValue;

  let linkValue = findDeepValue(columnSchema.id, tableInfo.value);
  if (isNaN(Number(linkValue))) linkValue = "";
  return (

    <Link to={`${link}${linkValue}`}>
      {columnSchema?.action && initialTextValue
        ? mutateValueByAction({ type: columnSchema.action, payload: cellTextValue })
        : cellTextValue}
    </Link>
  )
}


export const getCellBooleanType = (tableInfo, t) => {
  if (!tableInfo.value === null) { return "לא ידוע" }
  let cellTextValue = t(tableInfo.value ? "yes" : "no")
  return cellTextValue
}


export const getCellLongTextType = (tableInfo) => {
  let cellTextValue = tableInfo.value
  if (!cellTextValue) return null

  return (
    <ShowMoreText text={cellTextValue} limit={30} />
  )
}

export const getCellInnerFieldType = (tableInfo, columnSchema) => {
  let initialTextValue = findDeepValue(columnSchema.displayValue, tableInfo.value);
  if (!initialTextValue) {
    return null
  } else if (columnSchema?.action) {
    initialTextValue = mutateValueByAction({ type: columnSchema.action, payload: initialTextValue })
  }
  return initialTextValue;
}

export const getCellJoinedTextType = (tableInfo, columnSchema) => {

  let cellData = tableInfo.data[tableInfo.row.index];
  let cellTextValue = columnSchema.name.split(" ").map(key => cellData[key]).join(" ")
  return cellTextValue
}

export const CellTextItem = (tableInfo, columnSchema) => {
  const { t } = useTranslation();
  let cellTextValue
  if (tableInfo.value === 0) {
    cellTextValue = "0"
  } else if (!tableInfo.value) {
    return null
  } else {
    cellTextValue = columnSchema.translateValue ? t(tableInfo.value) : tableInfo.value
  }
  cellTextValue = columnSchema?.action ? mutateValueByAction({ type: columnSchema.action, payload: cellTextValue })
    : cellTextValue

  return (<span>
    {cellTextValue}
  </span>)
}
export const CellButtonItem = (tableInfo, columnSchema, buttonColumnFunctions) => {
  const { t } = useTranslation();

  let row = tableInfo.row.original
  let { [columnSchema.functionName]: handleOnClick } = useContext(buttonColumnFunctions.ctx)

  return (
    <Button onClick={() => handleOnClick(row, columnSchema.title)}>{t(columnSchema.innerText)}</Button>
  )
}
