import { getData } from "../../../services/getServices"

export const getTemplates = async (setLeadMsgTemplates) => {
    try {
        let leadMsgTemplates = await getData(`templates?meeting_related=true`)
        if (leadMsgTemplates?.length > 0) {
            setLeadMsgTemplates(leadMsgTemplates)
        }

    } catch (error) {
        console.log(error)
    }
}
