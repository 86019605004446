import React from "react";
import Pagination from 'react-bootstrap/Pagination'


export default function TablePagination(props) {
	let {
		setPage,
		pagesNumber,
		range,
		currentPage,
	} = props;

	const paginationItemsBeforeCurrentPage = range < 2 ? Number(range) : 2;
	const paginationItemsAfterCurrentPage = Number(range);

	const PaginationItemsBuilder = ({ index, page }) => (<Pagination.Item
		key={index}
		onClick={() => {
			setPage(page)
		}}
	>{page}</Pagination.Item>)

	const PaginationItemsFromCurrentPage = ({ currentPage, numOfItems, totalPages, showNextItems }) => {
		const start = 1;
		if (showNextItems && currentPage + numOfItems > totalPages) {
			numOfItems = Math.max(totalPages - currentPage, 0)
		} else if (!showNextItems && (currentPage - numOfItems < start)) {
			numOfItems = Math.max(currentPage - start, 0)
		}
		let direction = showNextItems ? 1 : -1
		var paginationItemsList = [];
		for (var i = 0; i < numOfItems; i++) {
			let page = ((currentPage + direction * start) + i * direction)
			paginationItemsList.push(<PaginationItemsBuilder page={page} index={i} />);
		}
		paginationItemsList = direction < 0 ? paginationItemsList.reverse() : paginationItemsList
		return paginationItemsList
	}

	return (
		<Pagination className="py-4" style={{ margin: "auto", width: "fit-content", direction: "ltr" }}>
			<Pagination.Item
				onClick={() => setPage(1)}
				disabled={currentPage === 1}
			>{"<<"}</Pagination.Item>

			<Pagination.Item
				disabled={currentPage <= 1}
				onClick={() => setPage(currentPage - 1)}
			>{"<"}</Pagination.Item>

			{(currentPage > paginationItemsBeforeCurrentPage + 1) && <Pagination.Item
				onClick={() => setPage(1)}
			>{1}</Pagination.Item>}

			{(currentPage - paginationItemsBeforeCurrentPage - 1 > 1) && <Pagination.Item disabled>...</Pagination.Item>}

			<PaginationItemsFromCurrentPage currentPage={currentPage} start={1} numOfItems={paginationItemsBeforeCurrentPage} totalPages={pagesNumber} showNextItems={false} />

			<Pagination.Item active >{currentPage}</Pagination.Item>

			<PaginationItemsFromCurrentPage currentPage={currentPage} start={1} numOfItems={paginationItemsAfterCurrentPage} totalPages={pagesNumber} showNextItems={true} />

			{currentPage < pagesNumber - paginationItemsAfterCurrentPage && <Pagination.Item disabled>...</Pagination.Item>}

			{(currentPage < pagesNumber - paginationItemsAfterCurrentPage) && <Pagination.Item
				active={currentPage === pagesNumber}
				onClick={() => setPage(pagesNumber)}
			>{pagesNumber}</Pagination.Item>}

			<Pagination.Item
				disabled={currentPage >= pagesNumber}
				onClick={() => setPage(currentPage + 1)}
			>{">"}</Pagination.Item>

			<Pagination.Item
				disabled={currentPage >= pagesNumber}
				onClick={() => setPage(pagesNumber)}
			>{">>"}</Pagination.Item>
		</Pagination>
	)
}
