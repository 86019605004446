import { encryptStorage } from "../services/encryptedSessionStorage";
import { getResponse } from "../services/getServices";

// This is just a list of base endpoints for every used route, this way we can indetify which path is 403 and which is 404(not in the list at all).
const globalEndpointsList = [
  "/leads",
  "/leadsources",
  "/meetings",
  "/messages",
  "/tasks",
  "/imports",
  "/templates",
  "/admin",
  "/users",
  "/forms",
  "/reports",
  "/instructorpanel",
  "/statistics",
  "/login",
  "/error-page",
  "/restrict-page",
  "/not-found-page"
];

const getItemFromLocalStorage = (itemName) => {
  const itemValue = localStorage.getItem(itemName);
  if (!itemValue || itemValue === "undefined") return undefined;
  try {
    return JSON.parse(itemValue);
  } catch (error) {
    return itemValue;
  }
}

const grantPermissionForEndpoint = (pathname, endpoints) => {
  if (!globalEndpointsList.find(endpoint => pathname.includes(endpoint))) {
    return 404;
  }
  if (!endpoints.find(endpoint => pathname.includes(endpoint.route))) {
    return 403;
  }
  return 200;
}

const logOut = async () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
  try {
    const res = await getResponse("settingconfigs/rolerestrictions");

    if (!res.status === 200) {
      throw res;
    }
    encryptStorage.setItem('endpoints', res?.data);

    return returnMainPath(res?.data);
  } catch (err) {
    console.log(err);
    console.log("Couldn't fetch default endpoints for some reason.");
  }
}

const returnMainPath = (endpoints = encryptStorage.getItem('endpoints')) => {
  let returnedRoute = endpoints.find(endpoint => endpoint.isMainPage)?.route;
  if (!returnedRoute) returnedRoute = endpoints[0].route;
  return returnedRoute;
}


export {
  logOut,
  grantPermissionForEndpoint,
  returnMainPath,
  getItemFromLocalStorage
}