import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getData } from "../../services/getServices";
import organizeUserPage, {
  userStatsOrganizerCardData,
} from "../../data_organizers/users/userPageOrganizer";

import DynamicTableContainer from "../../components/dynamicTable/dynamicTableContainer";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import ButtonGroupDateScope from "../../components/buttonGroupDate/buttonGroupDateScope";
import NotFoundPage from "../404page/notFoundPage";
import { buttonsInitialState } from "../../helpers/resources";
import { useTranslation } from "react-i18next";
import "../lead_page/lead-page.css";

function UserPage() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [organizeUser, setOrganizeUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [userStatistics, setUserStatistics] = useState([]);
  const [userByParam, setUserByParam] = useState(null);
  const [isUsersRolesValid, setIsUsersRolesValid] = useState(false);
  const [buttonsState, setButtonsState] = useState(buttonsInitialState);
  const [isNotFound, setIsNotFound] = useState(false);

  let history = useHistory();

  const fetchData = async () => {
    let userByParam = await getData(`users/${id}`);
    if (userByParam?.response?.status === 204 || userByParam?.response?.status === 404 || !userByParam) {
      setIsNotFound(true);
      return;
    }
    let user = JSON.parse(localStorage["user"]);

    // don't display statistics if user id by url param role is admin, however show statistics if user role is
    // Instructor or Telephonist
    if (
      user.role.name === "Admin" &&
      (userByParam.role.name === "Instructor" ||
        userByParam.role.name === "Telephonist")
    ) {
      let userStatisticsData = await getData(`userStats/${id}`);
      setUserStatistics(userStatsOrganizerCardData(userStatisticsData));
      setIsUsersRolesValid(true);
    }
    setUserByParam(userByParam);
    setCurrentUser(user);
    setOrganizeUser(organizeUserPage(userByParam));
  };

  const fetchUserStatisticsDataByDate = async (scope) => {
    try {
      let scopeQ = scope ? `?scope=${scope}` : "";
      let userStatisticsData = await getData(`userStats/${id}/${scopeQ}`);
      setUserStatistics(userStatsOrganizerCardData(userStatisticsData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      setUserStatistics([]);
    };
  }, [id]);

  const handleTableRowDoubleClick = (e, TableRow, history, blank) => {
    e.preventDefault()
    let meetingId = TableRow?.id;
    meetingId && blank ? window.open(`/meetings/grouped/${meetingId}`, '_blank') : history.push(`/meetings/grouped/${meetingId}`);
  }

  const renderDataCard = () => {
    return (
      <Card
        border="primary"
        style={{ width: "18rem" }}
        key={organizeUser.Header}
      >
        <Card.Header>{organizeUser.Header}</Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            {organizeUser.fields.map((field) => {
              return (
                <ListGroup.Item key={field.Header}>
                  {`${field.Header}: `}
                  {field.customValue ? field.customValue() : `${field.value}`}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          {(id == currentUser.id || currentUser.role.name === "Admin") && (
            <Button
              onClick={() => history.push(`/users/edit/${currentUser.id}`)}
            >
              עריכת פרטי משתמש
            </Button>
          )}
        </Card.Body>
      </Card>
    );
  };

  const UserStatisticCard = () => {
    return (
      <Card border="primary" style={{ width: "18rem" }}>
        <Card.Header>סטטיסטיקות על המשתמש</Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            {userStatistics.map((el) => (
              <ListGroup.Item key={el.Header}>{`${t(el.Header)}: ${t(
                el.value || 0
              )}`}</ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    );
  };
  if (isNotFound) {
    return <NotFoundPage />
  }
  return (
    <>
      {userByParam &&
        <div className="container-fluid lead-page">
          {isUsersRolesValid && (
            <div className="p-4">
              <ButtonGroupDateScope
                initialState={buttonsState}
                buttonClassName="btn btn-secondary"
                handleChangeButton={fetchUserStatisticsDataByDate}
              />
            </div>
          )}

          {organizeUser && (
            <CardDeck className="lead-page_main">
              {isUsersRolesValid &&
                userStatistics.length > 0 &&
                UserStatisticCard()}
              {renderDataCard()}
            </CardDeck>
          )}
          {(userByParam && (userByParam.role.name === 'Instructor' || userByParam.role.name === 'Telephonist')) && (
            <Card border="primary" className="lead-page_history-card w-100">
              <Card.Header>היסטוריית פגישות</Card.Header>
              <Card.Body>
                <DynamicTableContainer
                  pagesRange={4}
                  initialEndpoint="meetingleads"
                  perPage={10}
                  schemaName={{
                    TABLE_API:
                      `meetingleads/TableSchema?schema_name=meetingLeadsInUserPage${userByParam.role.name}`,
                    FORM_API:
                      `meetingleads/filterSchema?schema_name=meetingLeadsInUserPage${userByParam.role.name}`,
                  }}
                  handleTableRowDoubleClick={handleTableRowDoubleClick}
                />
              </Card.Body>
            </Card>
          )}
        </div>
      }
    </>
  );
}

export default UserPage;
