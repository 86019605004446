import React from 'react'
import DynamicTableContainer from '../../components/dynamicTable/dynamicTableContainer';

export default function ReportsPage(props) {

  return (
    <DynamicTableContainer
      pagesRange={4}
      initialEndpoint="reports"
      perPage={50}
      handleTableRowDoubleClick={() => { }}
      schemaName={{ TABLE_API: "reports/TableSchema?schema_name=reportsPage", FORM_API: "reports/filterSchema?schema_name=reportsPage" }}
    />
  )
}

