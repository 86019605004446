import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal'
import { deleteData } from '../../../../services/deleteServices'

export default function AdminDeleteTaskModal({
  show,
  onHide,
  title,
  onDoubleClick,
  data
}) {

  let history = useHistory();

  const deleteTask = async () => {
    try {
      
      let resp = await deleteData(`leads/${data.lead.id}`, undefined, true);
      if (resp.id) {
        toast.success("משימה נמחקה בהצלחה ")
        let url = history.location.pathname + history.location.search
        history.replace(url)
      }
      onHide() 
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div>
      <VerticallyCenteredModal
        show={show}
        onHide={onHide}
        title={title}
        onDoubleClick={onDoubleClick}
      >

        <h3 className="h5 my-4">תוכן סיבת מחיקה</h3>
        <div className="p-4 border border-1">
          <pre>
            <bdi>
              {data.comment}
            </bdi>
          </pre>
        </div>
        <Button variant="danger" className="my-3" onClick={() => deleteTask()} >מחק</Button>
      </VerticallyCenteredModal>
    </div>
  )
}