import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { login } from '../../services/loginServices'
import './login.css'
import { toast } from 'react-toastify'

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null)
  const [tryingLogin, setTryingLogin] = useState(false)

  let history = useHistory();

  const authErrorToast = () => {
    toast.warning('פרטי התחברות שגויים');
    setErrorMessage(null);
  }

  const loginHandler = async (e) => {
    setTryingLogin(true);
    e.preventDefault();
    const loginRes = await login(email, password, setErrorMessage);
    if (loginRes.success) {
      history.push(loginRes.routeToPush);
    }
    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 1500);
    });
    setTryingLogin(false);
  }


  return (
    <div className="login vh-100">
      <form className="card  mt-5 mb-5 p-3 text-center py-4 mx-auto" >
        <h3 className="h4 px-3 mb-5">התחברות</h3>
        <img src="https://www.first-steps.co.il/wp-content/uploads/2017/05/logoNew.png" width="70" alt="logo" />
        <div className="px-sm-4 mt-3 px-3">
          <input className="form-control mt-3" name="email" type="email" placeholder='מזהה' onChange={(e) => setEmail(e.target.value)} value={email} />
          <input className="form-control mt-3" name="password" type="password" placeholder="סיסמא" onChange={(e) => setPassword(e.target.value)} value={password} />
        </div>
        <div className="px-5 mt-4">
          <button className="btn btn-primary w-100 text-uppercase" disabled={tryingLogin} onClick={(e) => { loginHandler(e) }}>התחברות</button>
        </div>
        <div>
          {errorMessage && authErrorToast()}
        </div>

      </form>
    </div>
  )
}

export default Login