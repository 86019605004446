import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faFileImport, faEdit, faFileUpload, faDownload } from '@fortawesome/free-solid-svg-icons'
import { deleteData } from '../../../../services/deleteServices';
import { toast } from 'react-toastify';
import UploadFileModal from '../modals/uploadFileModal'
import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';
import ReactTooltip from 'react-tooltip';
import { ModalContext } from '../../../../context/ModalProvider';
import { useTranslation } from 'react-i18next';

export const ImportActionsCellItem = (tableInfo, setData) => {
  const { t, i18n } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  let { setIsModalOpen } = useContext(ModalContext);

  let history = useHistory();
  let importData = tableInfo.data[tableInfo.row.index]

  const deleteImport = async () => {
    try {
      let data = await deleteData(`imports/${importData.id}`, undefined, true);
      if (data.id) {
        toast.success(t('import del'));
        setData(prevState => {
          const newData = [...prevState];
          newData.splice(newData.findIndex(i => importData.id === i.id), 1);
          return [...newData];
        }

        );
        setIsModalOpen(null)
      }

    } catch (error) {
      console.log(error)
    }
  }

  // onclick is an object for onClickEvents triggered by clicking on icons, only created by a valid condition 
  let onClickImport = !importData?.leads_uploaded ? { ...{ onClick: (e) => history.push(`/imports/import-from-file/${importData.id}`) } } : {}
  let onClickEdit = !importData?.leads_uploaded ? { ...{ onClick: (e) => history.push(`/imports/edit/${importData.id}`) } } : {}
  let onClickUploadFile = !importData?.leads_uploaded ? { ...{ onClick: (e) => setModalShow(true) } } : {}

  return (

    <>
      {
        modalShow &&
        <UploadFileModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={"העלאת קובץ"}
          onDoubleClick={(e) => { e.stopPropagation() }
          }
          ImportId={importData.id}
        />}
      <div className="d-flex justify-content-left align-items-center pb-3"  >
        <div className="mx-1" onClick={() => setIsModalOpen({
          title: `מחק את יבוא מספר ${importData.id}`,
          msg: ` בטוח? `,
          action: () => deleteImport()
        })}>
          <FontAwesomeIcon icon={faTrashAlt} data-delay-hide='100' data-delay-show='500' data-tip="מחק קובץ" className="delete_icon" size="lg" />
        </div>
        <div className="mx-1" {...onClickImport} >
          <FontAwesomeIcon icon={faFileImport} data-delay-hide='100' data-delay-show='500' data-tip="ייבוא לידים מקובץ" className={`edit_icon${importData.leads_uploaded ? " disable" : ""}`} size="lg" />
        </div>
        <div className="mx-1" {...onClickEdit} >
          <FontAwesomeIcon icon={faEdit} data-delay-hide='100' data-delay-show='500' data-tip="ערוך קובץ" className={`edit_icon${importData.leads_uploaded ? " disable" : ""}`} size="lg" />
        </div>
        <div className="mx-1">
          <FontAwesomeIcon icon={faFileUpload} data-delay-hide='100' data-delay-show='500' data-tip="העלאת קובץ" className={`edit_icon${importData.leads_uploaded ? " disable" : ""}`} {...onClickUploadFile} size="lg" />
        </div>
        <div className="mx-1">
          <DownloadFileLink downloadURL={importData?.file?.url} >
            <FontAwesomeIcon icon={faDownload} data-delay-hide='100' data-delay-show='500' data-tip="הורד קובץ" className={`edit_icon${!importData?.file?.url ? " disable" : ""}`} size="lg" />
          </DownloadFileLink>
        </div>
      </div>
      <ReactTooltip place="top" type="dark" effect="float" />
    </>
  )
}