import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getData } from '../../services/getServices';
import { genTableColumnsSchemaList } from '../../helpers/dynamicTable/table/table';
import Table from '../../components/dynamicTable/table';
import MessageModal from '../../components/meeting_message_modal/MessageModal';

import { useTranslation } from 'react-i18next';
import { Card, Button, ListGroup, Spinner, CardDeck } from 'react-bootstrap'
import moment from 'moment';
import { LeadSourceImportModal, LeadSourceFormModal } from '../lead_page/modals/Modals'

import NotFoundPage from '../404page/notFoundPage';
import SpecificMeetingDetails from '../../components/SpecificMeetingDetails/SpecificMeetingDetails';

const SpecificMeetingLead = () => {
    const { t } = useTranslation();
    const [meetingLead, setMeetingLead] = useState(null);
    const [tableSchema, setTableSchema] = useState(undefined);
    const [dataObject, setDataObject] = useState(undefined);
    const [doneLoading, setDoneLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const params = useParams();
    const history = useHistory();
    const { meeting_id } = params;


    const getGropuedMeetingLeads = async () => {
        const res = await getData(`meetingleads/${meeting_id}`);
        if (res?.id) {
            setMeetingLead(res)
            if (res.formsubmissions.length) {
                await fetchSchema()
            }
        } else if (res?.response?.status === 404) {
            setIsNotFound(true);
        }
        setDoneLoading(true)
    }
    useEffect((() => {
        getGropuedMeetingLeads()
    }), [])

    const fetchSchema = async () => {
        try {
            let schema = await getData('tableconfigs/formsubmissionInMeetinglead');
            setTableSchema(genTableColumnsSchemaList(schema.configJson.visible.map(columnName => { return { title: columnName, name: columnName, type: 'text' } })));
        } catch (error) {
            console.log(error)
        }
    }
    const handleTableRowDoubleClick = (e, TableRow, history) => {
        e.preventDefault()
        setDataObject({ id: TableRow.id, form: TableRow.form })
    }

    const handleOnHide = () => {
        setDataObject(undefined)
    }

    if (isNotFound) {
        return <NotFoundPage />
    }
    return (
        <div>
            {meetingLead ? <div>
                {dataObject && <LeadSourceFormModal show={dataObject} onHide={handleOnHide} title={t('form modal')} data={dataObject} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px auto', width: '18%' }}  >
                    <Button onClick={(() => history.push(`/meetings/grouped/${meeting_id}`))}>{t('grouped')}</Button>
                    <Button disabled={true}>{t('specific')}</Button>
                </div>
                <MessageModal meetingCardObject={meetingLead} />

                <SpecificMeetingDetails meetingLead={meetingLead} />

                <Card style={{ width: '75vw', margin: 'auto', marginBottom: '1rem' }} border='primary'>
                    <Card.Header>{t('meeting form submissions')}</Card.Header>
                    <Card.Body>
                        <ListGroup variant='flush' >
                            <ListGroup.Item>
                                {doneLoading ?
                                    tableSchema && meetingLead ?
                                        <Table
                                            onlyTable={true}
                                            handleTableRowDoubleClick={handleTableRowDoubleClick}
                                            columns={tableSchema}
                                            data={meetingLead.formsubmissions.map(formSub => {
                                                return { ...formSub, created_at: moment(formSub.created_at).format('YYYY-MM-DD HH:mm:ss') }
                                            })}
                                        />
                                        : <span>{t('no listings')}</span>
                                    : <Spinner animation="border" />}

                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body >
                </Card>
            </div> : <Spinner animation="border" />}
        </div>
    );
}

export default SpecificMeetingLead;