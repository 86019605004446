import { toast } from 'react-toastify';

const showToastError = (error) => {
    let errorMessage = `something went wrong`;
    const errorCode = error?.response?.status ?
        error.response.status : error?.response?.data?.statusCode ?
            error.response.data.statusCode : undefined;
    if (errorCode === 400 && error?.response?.data?.message) {
        errorMessage = error.response.data.message;
    } else if (errorCode === 404) {
        errorMessage = `not found`;
    }
    toast.warning(errorMessage);
}

export {
    showToastError
}