import React, { useState } from 'react'

function ShowMoreText({ text = "", limit = 30 }) {

  const [open, setOpen] = useState(false);

  return (
    <>
      {(text.length > limit) ?

        (
          <>
            <div>
                {!open ? `${text.substring(0, limit)}` : text}
            </div>

            {
              !open
                ? <span className="text-info" style={{ cursor: "pointer" }} onClick={() => setOpen(prev => !prev)}>...ראי\ה עוד</span>
                : <span className="text-info" style={{ cursor: "pointer" }} onClick={() => setOpen(prev => !prev)}>{' '}ראי\ה פחות</span>
            }

          </>
        )
        : text
      }
    </>
  );
}

export default ShowMoreText
