import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from 'react-bootstrap'
import './navbar.css'
import { useTranslation } from 'react-i18next';
import { langFlagPics } from '../../services/i18n/languageServices/languageService'
import logo from './spring_logo.svg'
import { getItemFromLocalStorage, logOut, returnMainPath } from '../../helpers/auth';

function MyNavbar({ user, endpoints }) {
  const [navbarEndpoints, setNavbarEndpoints] = useState([]);
  const { t, i18n } = useTranslation();
  let history = useHistory()

  const logOutInNavbar = async () => {
    const routeToPushTo = await logOut();
    if (routeToPushTo !== history.location.pathname) {
      history.push(routeToPushTo);
    }
  }

  useEffect(() => {
    const filteredEndpoints = endpoints.filter(endpoint => endpoint.showInNavbar);
    setNavbarEndpoints(filteredEndpoints);
  }, [])

  const changeLanguageFlag = getItemFromLocalStorage("lang") === 'he' ? 'en' : 'he';
  const handleLanguageChange = () => {
    i18n.changeLanguage(changeLanguageFlag);
    localStorage.setItem('lang', changeLanguageFlag)
  }
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top"
      style={{ display: "flex", justifyContent: "space-between", direction: "ltr" }}
    >
      <ul className="navbar-nav" style={{ display: "flex", flexDirection: "row-reverse" }}>
        { //jajsndjasnkdjasdnasldjasljdas
          navbarEndpoints && navbarEndpoints.map(endpoint => {
            return (<li className='nav-item active'>
              <Link className='nav-link' to={endpoint.route}>
                {t(endpoint.name)}<span className='sr-only'></span>
              </Link>
            </li>)
          })
        }
      </ul>
      <Link className="navbar-brand" to={returnMainPath()} >
        {t('name')}
      </Link>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Link className="navbar-brand" to={returnMainPath()}>
          <img
            alt=""
            src={logo}
            width="100"
            height="45"
            className="d-inline-block align-top"
          />{' '}
        </Link>
        <Dropdown title="Link" id="navbarScrollingDropdown" className="d-inline-block align-top m-2" >
          <Dropdown.Toggle >
            <i className="fas fa-user-circle"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ textAlign: 'center' }}>
            {`${t("role name")}: ${user.role.name}`}
            <Dropdown.Divider />
            {`${t("username")}: ${user.username}`}
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => history.push(`/users/${user?.id}`)}>{t('user details')}</Dropdown.Item>
            <Dropdown.Item onClick={logOutInNavbar}>{t('logout')}</Dropdown.Item>
            <Dropdown.Item onClick={handleLanguageChange}>{langFlagPics[changeLanguageFlag]}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
  )
}

export default MyNavbar