import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteData } from '../../../../services/deleteServices';
import { sendDataResponse } from '../../../../services/postServices';
import { getTemplates } from '../services';
import SendManyMessagesModal from '../modals/sendManyMessagesModal';
import SendManyMessagesModalError from '../modals/sendManyMessagesModalError';
import { isUserRole } from '../../../../helpers/user';
import { ModalContext } from '../../../../context/ModalProvider';
import { useTranslation } from 'react-i18next'

// create a buttons that send template messages to leads
//if the current user is an admin create a button that delete all selected leads rows  
export const LeadPageTableSelectManyActions = ({ selectedRows, deleteManyCallback }) => {
  const { t, i18n } = useTranslation();

  let history = useHistory()

  const [modalShow, setModalShow] = useState(false);
  const [modalShowError, setModalShowError] = useState(false)
  const [leadMsgTemplates, setLeadMsgTemplates] = useState([])
  const [templateId, setTemplateId] = useState(null)
  const [failedSentLeads, setFailedSentLeads] = useState([])
  const [blockedSentLeads, setBlockedSentLeads] = useState([])
  const [missingFieldsSentLeads, setMissingFieldsSentLeads] = useState([])
  const [bestWorstLead, setBestWorstLead] = useState(null)
  let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);



  const SendMessages = async (leadIdList, templateId) => {
    if (!templateId) {
      toast.warn(t('choose template'))
      return;
    }
    try {
      const data = await sendDataResponse('SendMultipleMessages', {
        leadIds: leadIdList,
        templateId: parseInt(templateId)
      }, undefined, true);

      if (data?.success?.length > 0) {
        toast.success(data.success.length !== 1 ?
          `${t('sentMany')} ${data.success.length} ${t('messages')}`
          : t('message sent'));
      }

      if (data?.failed?.length > 0 || data?.missingFieldsLeads?.length > 0 || data?.blocked?.length > 0) {
        data?.missingFieldsLeads?.length && toast.warning(`${t('missing fields toast')}${data.missingFieldsLeads.length} ${t('leads')}`);
        (data?.failed?.length || data?.blocked?.length) && toast.error(`${t('send fail')} ${data.failed.length + data?.blocked?.length} ${t('messages')}`);
        setMissingFieldsSentLeads(data?.missingFieldsLeads);
        setBestWorstLead(data?.bestWorstLead);
        setFailedSentLeads(data.failed);
        setBlockedSentLeads(data.blocked);
        setTemplateId(templateId);
        setModalShowError(true);
      }
      setModalShow(false);

    } catch (error) {
      console.log(error);
    }

  }

  const sendCustomMsgs = async (leadIds, messageContent) => {
    try {
      const data = await sendDataResponse('sendMultipleMessagesCustom', {
        leadIds,
        messageContent
      }, undefined, true);

      if (data?.success?.length > 0) {
        toast.success(data.success.length !== 1 ?
          `${t('sentMany')} ${data.success.length} ${t('messages')}`
          : t('message sent'));
      }

      if (data?.failed?.length > 0 || data?.blocked?.length > 0) {
        toast.error(`${t('send fail')} ${data.failed.length + data.blocked.length} ${t('messages')}`);
        setMissingFieldsSentLeads(null);
        setBestWorstLead(null);
        setFailedSentLeads(data.failed);
        setBlockedSentLeads(data.blocked);
        setTemplateId(null);
        setModalShowError(true);
      }
      setModalShow(false);
    } catch (error) {
      console.log(error)
    }
  }

  const SendForceMessages = async (leadIds, templateId) => {
    try {
      const data = await sendDataResponse('forceSendMultipleMessages', {
        leadIds,
        templateId: parseInt(templateId)
      }, undefined, true);

      if (data?.success?.length > 0) {
        toast.success(data.success.length !== 1 ?
          `${t('sentMany')} ${data.success.length} ${t('messages')}`
          : t('message sent'));
      }

      if (data?.failed?.length > 0 || data?.blocked?.length > 0) {
        toast.error(`${t('send fail')} ${data.failed.length + data.blocked.length} ${t('messages')}`);
        setMissingFieldsSentLeads(null);
        setBestWorstLead(null);
        setFailedSentLeads(data.failed);
        setBlockedSentLeads(data.blocked);
        setTemplateId(null);
        setModalShowError(true);
      } else {
        setModalShowError(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteSelectedRows = async () => {
    try {

      let countSuccessDeleteLeads = 0
      let data = await Promise.allSettled(selectedRows.map(async (row) => {
        await deleteData(`leads/${row.original.id}`)
      }))


      data.forEach((resp) => {
        if (resp.status === "fulfilled") {
          countSuccessDeleteLeads++
        }
      });

      if (countSuccessDeleteLeads) {
        toast.success(`${t('deleted')} ${countSuccessDeleteLeads} ${t('leads')}`)
        deleteManyCallback();
        setIsModalOpen(null);
      }
    } catch (error) {
      console.log(error);
      setIsModalOpen(null);
    }
  }


  return (

    <>
      <SendManyMessagesModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        title="שליחת הודעות לידים"
        sendMessages={SendMessages}
        sendCustomMsgs={sendCustomMsgs}
        leadMsgTemplates={leadMsgTemplates}
        leadIdList={selectedRows.map(row => row.original.id)}
      />


      <SendManyMessagesModalError
        modalShow={modalShowError}
        setModalShow={setModalShowError}
        title="שליחת הודעה נכשלה למספר לידים"
        sendMessages={SendForceMessages}
        failedLeadIds={failedSentLeads}
        blockedLeadIds={blockedSentLeads}
        templateId={templateId}
        missingFieldsSentLeads={missingFieldsSentLeads}
        bestWorstLead={bestWorstLead}
      />



      <button onClick={() => {
        setModalShow(true)
        getTemplates(setLeadMsgTemplates)
      }} className="btn btn-success">Send {selectedRows.length} Messages</button>
      {
        isUserRole("Admin")
        && <button onClick={(e) => setIsModalOpen({
          title: "אזהרה",
          msg: ` האם למחוק  ${selectedRows.length} לידים`,
          action: () => deleteSelectedRows()
        })} className="btn btn-danger me-2">Delete {selectedRows.length} leads</button>
      }

    </>
  )
}