import React, { useRef } from 'react'
import { Button, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal'
import { sendDataResponse } from '../../../../services/postServices'
import { useTranslation } from 'react-i18next'

export default function DeleteUserRequestModal({
  modalShow,
  setModalShow,
  title,
  leadId
}) {
  const { t, i18n } = useTranslation();

  let textAreaRef = useRef()

  const deleteUserRequest = async () => {
    try {
      let data = await sendDataResponse('leads/deleterequest', {
        leadId,
        comment: textAreaRef.current.value
      }, undefined, true)
      if (data?.id) {
        toast.success(t('delete_request') + ' ' + t('sentMany'))
      }
      setModalShow(false)
    } catch (error) {
      console.log(error)
    }
  }




  return (
    <VerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      title={title}
      onDoubleClick={(e) => { e.stopPropagation() }}
    >
      <h3>סיבת מחיקה</h3>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control as="textarea" rows={3} ref={textAreaRef} placeholder="הערה" />
        </Form.Group>
        <Button variant="warning" onClick={() => deleteUserRequest()} >{t('send')}</Button>
      </Form>
    </VerticallyCenteredModal>
  )
}