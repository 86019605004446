import React from 'react';
import { Modal, Button } from "react-bootstrap";


function AlertDialog({ ModalSettings, setOpen }) {
  const handleClose = () => {
    setOpen(null);
  };

  return (
    <div>
      <Modal
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={ModalSettings !== null && ModalSettings.action}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {ModalSettings?.title || "סכנה"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="contained-modal-body">
          {ModalSettings?.msg || "הודעה"}
        </Modal.Body>
        <Modal.Footer >
          {ModalSettings?.buttonArray ?
            <div>
              {ModalSettings.buttonArray.map((buttonData, index) => buttonData ? <Button key={index} onClick={buttonData.submit} style={{ marginLeft: '0.5rem' }}>{buttonData.text}</Button> : '')}
              <Button onClick={handleClose}>ביטול</Button>
            </div> :
            <div>
              {ModalSettings?.action !== "disabled" && <Button variant={ModalSettings?.actionColor ? ModalSettings?.actionColor : "danger"} onClick={() => ModalSettings.action()} style={{ marginLeft: '0.5rem' }}>אישור</Button>}
              <Button onClick={handleClose}>ביטול</Button>
            </div>}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AlertDialog