import { useHistory } from "react-router-dom";
import React from "react";
import { useTranslation } from 'react-i18next';
import "./notFoundPage.css";
import { returnMainPath } from "../../helpers/auth";

function NotFoundPage() {
    let history = useHistory();
    const { t } = useTranslation();

    let wrongPath = history.location.pathname;

    const goBack = () => {
        history.goBack();
    }

    const goToHome = () => {
        history.push(returnMainPath());
    }

    return (
        <div className='not-found-image'>
            <div className="h-100 p-5 bg-light border rounded-3">
                <div className="container-fluid py-5">
                    <h1 className="display-5 fw-bold">{t('Page Not Found')}</h1>
                    <p className="col-md-8 fs-4">{t('The Page You Were Looking For')} ({wrongPath.slice(1)}) {t('Was Not Found')}.</p>
                    <p className="col-md-8 fs-4">{t('go back error message')}</p>
                    <p className="col-md-8 fs-4">{t('go to home page error message')}</p>
                    <button onClick={goBack} className="col-1 offset-1 btn btn-primary btn-lg" type="button">{t('back')}</button>
                    <button onClick={goToHome} className="col-1 offset-1 btn btn-primary btn-lg" type="button">{t('home')}</button>
                </div>
            </div>
        </div>
    )

}

export default NotFoundPage;