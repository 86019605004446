import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom';
import { getData } from '../../services/getServices'
import { ImageView, ImageList } from '../lead_page/image_display/ImageView';
import { Container, Row, Col, ListGroup, Spinner, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import NotFoundPage from '../404page/notFoundPage';
import moment from 'moment'

export default function InduvidualSubmissonPage() {
    const params = useParams();
    const [dataLoad, setDataLoad] = useState(undefined)
    const [formSubLoad, setFormSubLoad] = useState(undefined)
    const [dynamicDataLoad, setDynamicDataLoad] = useState(undefined)
    const { formSubmissionId } = params;
    const [isNotFound, setIsNotFound] = useState(false);
    const { t } = useTranslation()
    const aquireRelevantData = async () => {
        const formSubmissonData = await getData(`formsubmissions/${formSubmissionId}`)
        if (formSubmissonData?.response?.status === 404) {
            setIsNotFound(true);
        }
        if (formSubmissonData?.id) {
            const submittedClientData = await getData(`forms/submissions/${formSubmissonData.form.id}?id=${formSubmissionId}`);
            if (submittedClientData[0]?.id) {
                const cleanObject = delArrayKeysFromObject(submittedClientData[0]);
                setDynamicDataLoad(cleanObject)
                setDataLoad(submittedClientData[0])
                setFormSubLoad(formSubmissonData)
            }
        }
    }

    useEffect(() => {
        aquireRelevantData()
    }, [])


    const delArrayKeysFromObject = (inquiredObject, arrayOfKeys = ['id', 'form', 'updated_at', 'leadsource', 'meetinglead', 'created_at', 'import_status', 'failed_log']) => {
        const formSubPayloadCpy = { ...inquiredObject };
        let imgObj = {};
        let imgArr = {};
        Object.entries(inquiredObject).forEach(([key, value]) => {
            if (arrayOfKeys.find(delKey => delKey === key)) {
                delete formSubPayloadCpy[key]
            } else if (Array.isArray(value) && value.length > 0) {
                delete formSubPayloadCpy[key]
                imgArr[key] = value;
            } else if (typeof value === 'object') {
                delete formSubPayloadCpy[key]
                imgObj[key] = value
            }
        })

        return { formSubPayloadCpy, imgArr, imgObj }
    }
    if (isNotFound) {
        return <NotFoundPage />
    }
    return (
        <>
            {dataLoad && formSubLoad && dynamicDataLoad ? <Container fluid >
                <h4 style={{ textAlign: 'center' }}>{t('form submission page')}</h4>
                <Row  >
                    <Col>
                        <Card style={{ width: "100%" }}>
                            <Card.Header>{t('Form Submission Fields')}:</Card.Header>
                            <ListGroup.Item>
                                <h6>{t("Form Submission Identifier")}</h6>
                                <ListGroup.Item>
                                    {dataLoad.id
                                        ? dataLoad.id
                                        : ""}
                                </ListGroup.Item>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h6>{t("Form Identifier")}</h6>
                                <ListGroup.Item>
                                    <Link to={`/forms/submissions/${dataLoad.form}`} >{dataLoad.form}</Link>
                                </ListGroup.Item>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h6>{t("Form Submission Status")}</h6>
                                <ListGroup.Item>
                                    {dataLoad.import_status}
                                </ListGroup.Item>
                            </ListGroup.Item>

                            {dataLoad.failed_log && typeof dataLoad.failed_log === "object" &&
                                <ListGroup.Item>
                                    <h6>{t("Form Submission Import Failed Log")}</h6>
                                    <ListGroup.Item>
                                        {JSON.stringify(dataLoad.failed_log)}
                                    </ListGroup.Item>
                                </ListGroup.Item>}

                            {dataLoad?.leadsource &&
                                <ListGroup.Item>
                                    <h6>{t("Lead Identifier")}</h6>
                                    <ListGroup.Item>
                                        <Link to={`/leads/${formSubLoad.leadsource.lead}`}>{formSubLoad.leadsource.lead}</Link>
                                    </ListGroup.Item>
                                </ListGroup.Item>}

                            {dataLoad.leadsource &&
                                <ListGroup.Item>
                                    <h6>{t("Lead Source Identifier")}</h6>
                                    <ListGroup.Item>
                                        {dataLoad.leadsource.id
                                            ? dataLoad.leadsource.id
                                            : ""}

                                    </ListGroup.Item>
                                </ListGroup.Item>}

                            {dataLoad.meetinglead &&
                                <ListGroup.Item>
                                    <h6>{t("Lead Meeting Identifier")}</h6>
                                    <ListGroup.Item>
                                        <Link to={`/leads/${formSubLoad.meetinglead.id}`}>{dataLoad.meetinglead.id}</Link>
                                    </ListGroup.Item>
                                </ListGroup.Item>}

                            <ListGroup.Item>
                                <h6>{t("Created At")}</h6>
                                <ListGroup.Item>
                                    {dataLoad.created_at
                                        ? moment(dataLoad.created_at).format('HH:mm DD-MM-YYYY')
                                        : ""}
                                </ListGroup.Item>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <h6>{t("Updated At")}</h6>
                                <ListGroup.Item>
                                    {dataLoad.updated_at
                                        ? moment(dataLoad.updated_at).format('HH:mm DD-MM-YYYY')
                                        : ""}
                                </ListGroup.Item>
                            </ListGroup.Item>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '100%' }}>
                            <Card.Header>{t('Form Fields')}:</Card.Header>
                            {Object.entries(dynamicDataLoad.formSubPayloadCpy).map(([key, value]) => <ListGroup.Item>
                                <h6>{key}</h6>
                                <ListGroup.Item>
                                    {value ? Array.isArray(value) ? <ImageList imageObjectList={value} /> : typeof value === 'object' ? <ImageView imageObject={value} /> : value : ""}
                                </ListGroup.Item>
                            </ListGroup.Item>)}
                        </Card>
                    </Col>
                </Row>
                {dynamicDataLoad.imgObj && <Row md={3}>
                    {Object.keys(dynamicDataLoad.imgObj).length > 0 && <Col>
                        <h6>{Object.keys(dynamicDataLoad.imgObj)[0]}</h6>
                        <ImageView imageObject={Object.values(dynamicDataLoad.imgObj)[0]} />
                    </Col>}
                </Row>}
                {dynamicDataLoad.imgArr.length > 0 && Object.values(dynamicDataLoad.imgArr)[0].length > 0 && <>
                    <h6>{Object.keys(dynamicDataLoad.imgArr)[0]}</h6>
                    <ImageList isGrid={true} imageObjectList={Object.values(dynamicDataLoad.imgArr)[0]} />
                </>}
            </Container> : <Spinner animation="border" />}
        </>
    )
}
