import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import DownloadFileLink from '../../../../components/DownloadFileLink/DownloadFileLink';


export default function ReportFileDownloadCellItem(tableInfo, setData) {
    const { t, i18n } = useTranslation();


    const downloadFile = () => {
        const rowData = tableInfo?.row?.original;
        const file = rowData?.file;
        if (!file) return;
        toast.success(`${t('downloaded file')} ${file.name}`);
    }


    return (
        <div onClick={downloadFile}>
            {tableInfo?.row?.original?.file?.url ? (
                <DownloadFileLink downloadURL={tableInfo?.row?.original?.file?.url} className="me-2 btn btn-primary" >
                    {t('download file')}
                </DownloadFileLink>
            ) : <Button variant="primary" className="me-2" disabled={true}>{t('download file')}</Button>}
        </div>
    )
}



