import { useState } from 'react';
import { Link, useHistory } from "react-router-dom"
import { Button } from 'react-bootstrap';
import { updateData } from '../../../../services/putServices'
import { deleteData } from '../../../../services/deleteServices'
import MessageModal from '../../../../components/meeting_message_modal/MessageModal'
import CallcenterModal from '../../leads/modals/callcenterModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPhone } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
// a custom cell for dynamic meetings table 

export function JoinedTextLinkCellItem(tableInfo, setData) {
  let data = tableInfo.data[tableInfo.row.index]

  let { lead } = data

  if (!lead || !lead.id) {
    return null
  }

  let leadName = "קישור לליד"

  if (lead.first_name && lead.last_name) {
    leadName = `${lead.first_name} ${lead.last_name}`
  } else if (lead.first_name) {
    leadName = lead.first_name
  } else if (lead.last_name) {
    leadName = lead.last_name
  }

  return (
    <div >
      <Link to={`/leads/${lead.id}`} style={{ marginRight: '25px' }}>{leadName}</Link>
    </div>
  )
}

export function ToggleCompleteGroupedMeetingsCellItem(tableInfo, setData) {
  const { t } = useTranslation();

  let data = tableInfo.data[tableInfo.row.index]

  const toggleComplete = async () => {
    const res = await updateData(`meetingleads/${data.completed ? "togglestatusoff" : "togglestatuson"}/${data.id}`)

    if (res?.id) {
      const meetingLeadIndex = tableInfo.data.findIndex(meetingLead => meetingLead.id === res.id);
      let dataArrayCpy = [...tableInfo.data];
      dataArrayCpy[meetingLeadIndex].completed = !dataArrayCpy[meetingLeadIndex].completed
      setData(dataArrayCpy)
    }
  }

  return (
    <div>
      <Button onClick={toggleComplete}>{data.completed ? t('Uncomplete meeting') : t('Complete meeting')}</Button>
    </div>
  )
}

export const ButtonsGroupedMeetingLeadCellItem = (tableInfo, setData) => {
  const history = useHistory();
  const [phoneModalShow, setPhoneModalShow] = useState(false);
  const lead = tableInfo.row.original.lead;
  const delMeeting = async () => {
    const res = await deleteData(`meetingleads/${tableInfo.row.original.id}`, undefined, true);
    if (res?.id) {
      const meetingLeadIndex = tableInfo.data.findIndex(meetingLead => meetingLead.id === res.id);
      let dataArrayCpy = [...tableInfo.data];
      dataArrayCpy.splice(meetingLeadIndex, 1)
      if (dataArrayCpy.length < 1) {
        history.push(`/meetings`)
      } else {
        dataArrayCpy[0]['overrideStyle'] = true;
        setData(dataArrayCpy)
        history.push(`/meetings/grouped/${dataArrayCpy[0].id}`)
      }
    }
  }


  return (
    <div className="d-flex justify-content-center align-items-center pb-3">
      <CallcenterModal
        modalShow={phoneModalShow}
        setModalShow={setPhoneModalShow}
        title={`שליחת חיוג לליד ${lead.id}`}
        lead={lead}
      />
      <Button onClick={delMeeting} variant="none">
        <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="מחק פגישה" icon={faTrashAlt} className="delete_icon" size="lg" />
      </Button>
      <MessageModal meetingCardObject={tableInfo.row.original} isIcon={true} />
      <Button onClick={() => {
        setPhoneModalShow(true)
      }} variant="none">
        <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="חיוג לליד" icon={faPhone} className="fa-phone" size="lg" />
      </Button>
    </div>
  )
}


