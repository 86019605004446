import axios from 'axios'
import { getJwt } from './loginServices'
import { setProgressBar } from './progressServices';
import { showToastError } from './toastServices';

const sendDataResponse = async (endPoint, data, callback, returnJustData, noJwt) => {
	setProgressBar('start');
	try {
		let headers = {};
		if (!noJwt) {
			const localToken = await getJwt();
			headers.Authorization = `Bearer ${localToken}`;
		}
		const res = await axios.post(
			process.env.REACT_APP_API_PATH + endPoint,
			data, {
			headers,
		});

		if (callback) {
			callback(res.data);
		}
		setProgressBar('complete');
		return returnJustData ? res.data : res;
	} catch (err) {
		showToastError(err);
		setProgressBar('fail');
		return returnJustData ? err.response.data : err.response;
	}

}

export {
	sendDataResponse
}