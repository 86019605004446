import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import meetingObjectForm from '../../data_organizers/meetings/meetingFormOrganizer'

import ListGroup from 'react-bootstrap/ListGroup'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

function MeetingForm({ initialMeetingObj, updateOrPostFunc, updateOrPostText }) {

  const [meetingObj, setMeetingObj] = useState({ ...initialMeetingObj })

  const renderInputOrTextarea = (type, value, onChange, onBlur) => {
    return type === 'textarea' ?
      <textarea
        type={type}
        value={value}
        onChange={onChange}
      /> :
      <input
        value={value}
        onChange={onChange}
      />
  }
  return (
    <ListGroup>
      {meetingObjectForm.map(field => {
        return (
          <ListGroup.Item key={field.Header}>
            <label style={{ display: "block" }}>{field.Header}</label>
            {field.customInput ?
              field.customInput(meetingObj, setMeetingObj.bind(meetingObj)) :
              renderInputOrTextarea(
                field.type,
                meetingObj[field.accessor],
                (e) => setMeetingObj({ ...meetingObj, [field.accessor]: e.target.value }),
                () => { }
              )
            }
          </ListGroup.Item>)
      })}
      <ButtonGroup>
        <Button onClick={() => updateOrPostFunc(meetingObj)}>{updateOrPostText}</Button>
        <Button onClick={() => setMeetingObj(initialMeetingObj)}>איפוס</Button>
      </ButtonGroup>
    </ListGroup>
  )
}

export default MeetingForm