
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteData } from '../../../../services/deleteServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MessageModal from '../../../../components/meeting_message_modal/MessageModal'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip'
import { ModalContext } from '../../../../context/ModalProvider';
import { useTranslation } from 'react-i18next';

// create icons that affect on a selected meeting row in dynamic meeting table
// delete icon that delete a specific meeting and a button and edit icon that lead to a edit meeting page 
export const MeetingsActionsCellItem = (tableInfo, setData) => {
  const { t, i18n } = useTranslation();

  let { setIsModalOpen } = useContext(ModalContext);

  let history = useHistory();
  let rowData = tableInfo.data[tableInfo.row.index]

  const deleteMeeting = async () => {
    try {
      let data = await deleteData(`meetingLeads/${rowData.id}`, undefined, true);
      if (data.id) {
        toast.success(`${t('meeting')} ${t('deleted')}`)
        setData(prevState => {
          const newData = [...prevState];
          newData.splice(newData.findIndex(i => rowData.id === i.id), 1);
          return [...newData];
        }

        );
        setIsModalOpen(null)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="d-flex justify-content-center align-items-center pb-3" >
      <div className="mx-3" data-delay-hide='100' data-delay-show='500' data-tip="מחיקת פגישה" onClick={() => setIsModalOpen({
        title: `מחק את פגישה מספר ${rowData.id}`,
        msg: ` בטוח? `,
        action: () => deleteMeeting()
      })}>
        <FontAwesomeIcon icon={faTrashAlt} className="delete_icon" size="lg" />
      </div>
      <div className="mx-1" data-delay-hide='100' data-delay-show='500' data-tip="עריכת פגישה" onClick={() => history.push(`/meetings/edit/${rowData.id}`)}>
        <FontAwesomeIcon icon={faEdit} className="edit_icon" size="lg" />
      </div>
      <MessageModal meetingCardObject={tableInfo.row.original} isIcon={true} />
      <ReactTooltip place="top" type="dark" effect="float" />
    </div>)
}
