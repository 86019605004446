import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons'
import RelatedLeadTableModal from '../../config/dynamicTable/leads/modals/relatedLeadTableModal';

export default function RelateLead({ relatedLeadId, isIcon }) {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);


  return <div className="mb-1 mt-1">
    <RelatedLeadTableModal
      modalShow={modalShow}
      setModalShow={setModalShow}
      title={`${t('Relate to lead title')} ${relatedLeadId}`}
      relatedLeadId={relatedLeadId}
    />

    <Button variant={isIcon ? 'none' : 'primary'} onClick={(() => {
      setModalShow(true);
    })} >
      {isIcon ? <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="שייך הודעה" icon={faCompressAlt} className="send-message_icon" size="lg" /> : t('relate lead')}
    </Button>
  </div>;
}
