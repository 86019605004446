import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faTrashAlt, faEdit, faEnvelope, faUserTimes, faPlug } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

export default function FormSubmissionsActionsCellItem(tableInfo, setData, extraData) {


    const { t, i18n } = useTranslation();

    let history = useHistory();

    let formSubmission = tableInfo.data[tableInfo.row.index];
    const connectedForm = extraData?.form.connection !== "notDefined" ? true : false;

    const handleEstablishConnection = () => {
        if (!connectedForm) {
            history.push(`connect/${formSubmission.id}`)
        }
    }

    const handleUpdateConnection = () => {
        if (connectedForm) {
            history.push(`update/${formSubmission.id}`)
        }
    }

    return (
        <div className="d-flex justify-content-center align-items-center pb-3" >

            <div className="mx-1" >
                <FontAwesomeIcon onClick={handleEstablishConnection} data-delay-hide='100' data-delay-show='500' data-tip="חבר טופס על פי רשומה זו" icon={faPlug} className={`edit_icon${connectedForm ? " disable" : ""}`} size="lg" />
                <FontAwesomeIcon onClick={handleUpdateConnection} data-delay-hide='100' data-delay-show='500' data-tip="ערוך טופס על פי רשומה זו" icon={faEdit} className={`edit_icon${!connectedForm ? " disable" : ""}`} size="lg" />
            </div>
            <ReactTooltip place="top" type="dark" effect="float" />
        </div>)
}



