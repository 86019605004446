import { faOutdent } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal'

// send a messages to a lead that have lack in details for sending a template message
export default function SendOneMessageModalError({
  modalShow,
  setModalShow,
  title,
  leadId,
  templateId,
  sendOneMsg,
  failedPayload
}) {


  return (
    <div>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={title}
        onDoubleClick={(e) => { e.stopPropagation() }}
      >
        {
          failedPayload?.type === "missing-words" &&
          <>
            <div className="text-danger my-3" >חסרים מספר משתנים בהודעה שבחרת, ככה תראה ההודעה:</div>
            <div className="my-3" >{failedPayload?.messageContent ? failedPayload.messageContent : ''}</div>
          </>
        }
        {
          failedPayload?.type === "critical-fail" &&
          <>
            <div className="text-danger my-3" >משהו השתבש בשליחת הודעה, פנה לתמיכה</div>
          </>
        }
        {
          failedPayload?.type === "sms-blocked" &&
          <>
            <div className="text-danger my-3" >הליד שאליו רצית לשלוח הודעה חסם תקשורת בהודעות.</div>
          </>
        }

        {
          failedPayload?.type === "missing-words" && <button className="btn btn-warning" onClick={() => sendOneMsg(leadId, templateId)}>שלח בכל זאת</button>
        }
      </VerticallyCenteredModal>
    </div>
  )
}