import React, { useRef, useState, useEffect } from 'react'
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Card, Button } from 'react-bootstrap';
import { getData } from '../../../../services/getServices';
import { sendDataResponse } from '../../../../services/postServices';
import { toast } from 'react-toastify';
import Select, { createFilter } from 'react-select';
import { Spinner } from 'react-bootstrap';

// send a template message for specific lead 
export default function CallcenterModal({
  modalShow,
  setModalShow,
  title,
  lead
}) {
  const { t, i18n } = useTranslation();
  const [phoneNumbers, setPhoneNumbers] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [callcenterSettings, setCallcenterSettings] = useState(undefined);

  const handleCall = async () => {
    const callCenterResStatus = await sendDataResponse('callSelectedNumber', { phoneNumber: phoneNumber.value }, undefined, true);
    if (callcenterSettings.openLeadCard) {
      window.open(`/leads/${lead.id}`, '_blank')
    }
    if (callCenterResStatus) {
      setModalShow(false);
      toast.success(t('callCenterSuccess'));

    } else {
      setModalShow(false);
      toast.error(t('callCenterError'));
    }
  }


  const getCallcenterConfig = async () => {
    const callCenterConfig = await getData('settingconfigs/callCenterConfig');
    const phoneNumberConfig = await getData('settingconfigs/phoneNumberConfig');
    const configSettings = callCenterConfig.configJson['settings'];
    const phoneNumbersKeys = phoneNumberConfig.configJson['lead'];
    setPhoneNumbers(phoneNumbersKeys.map(phoneNumberKey => { return { value: lead[phoneNumberKey], key: phoneNumberKey } }));
    setCallcenterSettings(configSettings);
  }

  useEffect(() => { modalShow && getCallcenterConfig() }, [modalShow])
  return (
    <VerticallyCenteredModal
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      title={title}
      onDoubleClick={(e) => { e.stopPropagation() }}
      size='xl'
    >

      <Card className='w-100'>
        <Card.Body >
          <Card.Title>{title}</Card.Title>
          {phoneNumbers ? <>
            <div style={{ display: "inline-block" }}>
              <Select
                value={!phoneNumber ? null : { value: phoneNumber.value, label: phoneNumber.label }}
                name={title}
                onChange={((selectedValueObject) => {
                  setPhoneNumber(selectedValueObject);
                })}
                isClearable
                options={phoneNumbers.map((item) => ({ value: item.value, label: t(item.key) }))}
                placeholder={t('pick phone')}>
              </Select>
            </div>
            {phoneNumber && <div style={{ display: "inline-block", marginRight: "2vw" }}>{`${t("selected")}: ${phoneNumber.value ? phoneNumber.value : t("empty value")}`}</div>}

          </> : <Spinner animation="border" />}
        </Card.Body>
      </Card>
      <Button disabled={!phoneNumber || (phoneNumber && !phoneNumber.value)} onClick={handleCall}>{t('call selected number')}</Button>
    </VerticallyCenteredModal >
  )
}