import moment from "moment";
const customDateCreation = (timeUnit, timeUnitAction, timeUnitLength) => {
  return timeUnitAction === 'sub' ?
    moment().subtract(timeUnitLength, timeUnit).format('YYYY-MM-DD') :
    moment().add(timeUnitLength, timeUnit).format('YYYY-MM-DD');
}

const createFormDataUrlQueryPiece = (filterObject, formDataKey, formDataValue) => {
  let queryPiece = "";
  if (filterObject.filterType === "enumMany" ||
    filterObject.filterType === "enumManyUser" ||
    filterObject.filterType === "textMany") {
    formDataValue.forEach(element => {
      queryPiece += `${formDataKey}=${element.value}&`;
    });
    return queryPiece;
  }
  if (filterObject.filterType === "dynamicNumber" && Array.isArray(formDataValue)) {
    formDataValue.forEach(element => {
      queryPiece += `${formDataKey}=${element}&`;
    });
    return queryPiece;
  }
  queryPiece += `${formDataKey}=${formDataValue}&`;
  return queryPiece;
}

const createFormDataQueryPiece = (filterObject, formDataKey, formDataValue) => {
  let queryPiece = "";
  if (filterObject.filterType === "enumMany" ||
    filterObject.filterType === "enumManyUser" ||
    filterObject.filterType === "textMany") {
    formDataValue.forEach(element => {
      queryPiece += `${formDataKey}=${element.value}&`;
    });
    return queryPiece;
  }
  if (filterObject.filterType === "dynamicDate") {
    if (formDataKey.endsWith("_custom")) {
      const foundCustomFieldObj = filterObject.enumArray.find(customFieldObj => customFieldObj.id === formDataValue);
      switch (foundCustomFieldObj.type) {
        case "single":
          queryPiece += `${filterObject.name}${foundCustomFieldObj.date_suffix}=${customDateCreation(foundCustomFieldObj.time_unit, foundCustomFieldObj.time_unit_action, foundCustomFieldObj.time_unit_length)}&`;
          break;

        case "many":
          for (let i = 0; i < foundCustomFieldObj.date_suffix.length; i++) {
            queryPiece += `${filterObject.name}${foundCustomFieldObj.date_suffix[i]}=${customDateCreation(foundCustomFieldObj.time_unit[i], foundCustomFieldObj.time_unit_action[i], foundCustomFieldObj.time_unit_length[i])}&`;
          }
          break;

        case "range":
          for (let i = 0; i < 2; i++) {
            queryPiece += `${filterObject.name}${foundCustomFieldObj.date_suffix[i]}=${customDateCreation(foundCustomFieldObj.time_unit[i], foundCustomFieldObj.time_unit_action[i], foundCustomFieldObj.time_unit_length[i])}&`;
          }
          break;

        default:
          queryPiece += "";
          break;
      }
      return queryPiece;
    }
  }
  if (filterObject.filterType === "dynamicNumber" && Array.isArray(formDataValue)) {
    formDataValue.forEach(element => {
      queryPiece += `${formDataKey}=${element}&`;
    });
    return queryPiece;
  }
  queryPiece += `${formDataKey}=${formDataValue}&`;
  return queryPiece;
}

const createDefaultFilterQueryPiece = (defaultFilter, params) => {
  switch (defaultFilter.type) {
    case "static":
      return `${defaultFilter.name}=${defaultFilter.staticValue}&`;

    case "dynamic":
      return `${defaultFilter.name}=${params[defaultFilter.paramValue]}&`;

    default:
      return "";
  }
}

export const translateUrlValues = (filterObject, urlKeyName, urlValue, formDataObj, allUrlFilters) => {
  if (filterObject.filterType === "enumMany" || filterObject.filterType === "enumManyUser" || filterObject.filterType === "textMany") {
    if (Array.isArray(formDataObj[urlKeyName])) return { ...formDataObj, [urlKeyName]: [...formDataObj[urlKeyName], { label: urlValue, value: urlValue }] };
    else return { ...formDataObj, [urlKeyName]: [{ label: urlValue, value: urlValue }] };
  }
  if (filterObject.filterType === "dynamicDate") {
    if (urlKeyName.endsWith("_custom")) {
      if (filterObject.enumArray.find(customFieldObj => customFieldObj.id === urlValue)) {
        return { ...formDataObj, [urlKeyName]: urlValue };
      }
      return { ...formDataObj, [urlKeyName]: "" };
    }
  }
  if (filterObject.filterType === "dynamicNumber") {
    if (!urlKeyName.endsWith("_gt") && !urlKeyName.endsWith("_lt")) {
      if (Array.isArray(formDataObj[urlKeyName])) return { ...formDataObj, [urlKeyName]: [...formDataObj[urlKeyName], urlValue] };
      else return { ...formDataObj, [urlKeyName]: [urlValue] };
    }
  }
  return { ...formDataObj, [urlKeyName]: urlValue };
}

export const formatFormDataEmptyValues = (filterObject, key, formDataObj) => {
  if (filterObject.filterType === "enumMany" ||
    filterObject.filterType === "enumManyUser" ||
    filterObject.filterType === "textMany") {
    return { ...formDataObj, [key]: [] };
  }
  return { ...formDataObj, [key]: "" };
};

export const formatFormDataDefaultValues = (filterObject, initialKeyName, formDataObj) => {
  if (filterObject.filterType === "enumMany" || filterObject.filterType === "enumManyUser" || filterObject.filterType === "textMany") {
    const listOfValues = filterObject.defaultValue;
    return {
      ...formDataObj, [initialKeyName]: listOfValues.map(value => {
        return { label: value, value: value };
      })
    };
  }
  if (filterObject.filterType === "dynamicDate") {
    switch (filterObject.dynamicDefaultValue.selectedFilter) {
      case "range":
        const [firstVal, secondVal] = filterObject.dynamicDefaultValue.value.split(",");
        return { ...formDataObj, [initialKeyName + "_gt"]: firstVal, [initialKeyName + "_lt"]: secondVal };
      case "equals":
        return { ...formDataObj, [initialKeyName]: filterObject.dynamicDefaultValue.value };
      case "lt":
        return { ...formDataObj, [initialKeyName + "_lt"]: filterObject.dynamicDefaultValue.value };
      case "gt":
        return { ...formDataObj, [initialKeyName + "_gt"]: filterObject.dynamicDefaultValue.value };
      case "custom":
        if (filterObject.enumArray.find(customFieldObj => customFieldObj.id === filterObject.dynamicDefaultValue.value)) {
          return { ...formDataObj, [initialKeyName + "_custom"]: filterObject.dynamicDefaultValue.value };
        }
        return { ...formDataObj, [initialKeyName]: "" };

      default:
        return { ...formDataObj, [initialKeyName]: "" };
    }
  }
  if (filterObject.filterType === "dynamicNumber") {
    switch (filterObject.dynamicDefaultValue.selectedFilter) {
      case "range":
        const [firstVal, secondVal] = filterObject.dynamicDefaultValue.value.split(",");
        return { ...formDataObj, [initialKeyName + "_gt"]: firstVal, [initialKeyName + "_lt"]: secondVal };

      case "lt":
        return { ...formDataObj, [initialKeyName + "_lt"]: filterObject.dynamicDefaultValue.value };

      case "gt":
        return { ...formDataObj, [initialKeyName + "_gt"]: filterObject.dynamicDefaultValue.value };

      case "many":
        return { ...formDataObj, [initialKeyName]: filterObject.dynamicDefaultValue.value.split(",") };

      default:
        return { ...formDataObj, [initialKeyName]: "" };
    }
  }
  return { ...formDataObj, [initialKeyName]: filterObject.defaultValue };
};

export const formatFormDataKeys = (filterObject) => {
  if (filterObject.filterType === "text" || filterObject.filterType === "textMany") {
    return filterObject.isPartial ? [filterObject.name + "_contains"] : [filterObject.name];
  }
  if (filterObject.filterType === "isNull") return [filterObject.name + "_null"];
  if (filterObject.filterType === "selectCityAndStreet") {
    return [filterObject.name, filterObject.relatedStreetFieldName];
  }
  return [filterObject.name];
};

export const createDefaultFormData = (formSchema) => {
  let defaultFormData = {};
  formSchema.forEach((formField) => {
    const formDataKeys = formatFormDataKeys(formField);
    formDataKeys.forEach(formDataKey => {
      if (
        (formField.defaultValue && !Array.isArray(formField.defaultValue)) ||
        (formField.defaultValue && Array.isArray(formField.defaultValue) && formField.defaultValue.length) ||
        (formField?.dynamicDefaultValue?.value)
      ) {
        defaultFormData = formatFormDataDefaultValues(formField, formDataKey, defaultFormData);
      } else {
        defaultFormData = formatFormDataEmptyValues(formField, formDataKey, defaultFormData);
      }
    });
  });
  return defaultFormData;

}

export const createFetchCountQueries = (formDataObj, defaultFilters, formSchema, params, initialEndpoint, countOverride) => {
  let url = `?`;
  for (const key in formDataObj) {
    const value = formDataObj[key];
    if (!value || (value && Array.isArray(value) && value.length < 1)) continue;

    const filterObject = findFilterObjectFromKey(removeSuffix(key), formSchema);
    url += createFormDataQueryPiece(filterObject, key, value);
  }
  defaultFilters.forEach(defaultFilter => {
    url += createDefaultFilterQueryPiece(defaultFilter, params);
  });

  return {
    partialFetchQuery: `${initialEndpoint}${url}`,
    countQuery: `${countOverride ? countOverride : initialEndpoint + "/count"}${url.slice(0, -1)}`
  }
}

export const removeFieldVariationsFromObject = (obj, fieldBaseName) => {
  let newObj = obj;
  for (const key in obj) {
    if (removeSuffix(key) === fieldBaseName) {
      delete newObj[key];
    }
  }
  return newObj;
};

export const checkPageNumber = (currentPageNumber, maxPage) => {
  if (isNaN(Number(currentPageNumber))) {
    currentPageNumber = 1;
  } else if (currentPageNumber > maxPage) {
    currentPageNumber = maxPage;
  } else if (currentPageNumber < 1) {
    currentPageNumber = 1;
  }
  return currentPageNumber;
};

export const checkSortValue = (sortValue, filtersArray) => {
  if (
    sortValue &&
    filtersArray.find(filterObj => filterObj.name === sortValue?.split(":")[0]) &&
    (sortValue.endsWith(":asc") || sortValue.endsWith(":desc"))
  ) {
    return sortValue;
  }
  return undefined;
};

export const createCleanUrlQuery = (formData, formSchema, page, sort) => {
  let queryUrl = `?page=${page}&`;
  for (const key in formData) {
    const value = formData[key];
    if (!value || (value && Array.isArray(value) && value.length < 1)) continue;

    const filterObject = findFilterObjectFromKey(removeSuffix(key), formSchema);
    queryUrl += createFormDataUrlQueryPiece(filterObject, key, value);
  }

  if (sort) {
    queryUrl += `_sort=${sort}&`;
  }

  return queryUrl.slice(0, -1);
}

export const removeSuffix = (key) => {
  let possible_suffix = [
    "_contains",
    "_ncontains",
    "_lt",
    "_lte",
    "_gt",
    "_gte",
    "_in",
    "_nin",
    "_eq",
    "_ne",
    "_null",
    "_custom"
  ];

  for (let i = 0; i < possible_suffix.length; i++) {
    if (key.endsWith(possible_suffix[i])) {
      return key.replace(possible_suffix[i], "");
    }
  }
  return key;
};

export const findFilterObjectFromKey = (key, formSchema) => {
  if (formSchema.find((e) => e?.relatedStreetFieldName === key)) {
    return formSchema.find((e) => e?.relatedStreetFieldName === key);
  }
  return formSchema.find((e) => e.name === key);
};