import { getItemFromLocalStorage } from "../../helpers/auth";
import meetingLeadObject from "./main";

const organizeMeetingLeadInstructorCard = (meetinglead) => {
  // set meetinglead fields values
  Object.values(meetingLeadObject).forEach((field) => {
    if (field.accessor) {
      field.value = meetinglead[field.accessor];
    }
    // boolean type nnd falsy
    if (field.custom) {
      field.customValue = () => field.custom(meetinglead);
    }
    if (field.value === null || field.value === undefined) {
      field.value = "אין";
    }
  });

  const meetingLeadInstructorCardData = {
    meetingleadInstructorCardOrganizerDemo: [
      {
        fields: [
          meetingLeadObject.full_name,
          meetingLeadObject.time,
          meetingLeadObject.meeting_type_c,
        ],
      },
      {
        fields: [
          meetingLeadObject.phone_number,
          meetingLeadObject.city,
          meetingLeadObject.street,
          meetingLeadObject.house_number,
        ],
      },
    ],
    meetingleadInstructorCardOrganizerFirstSteps: [
      {
        fields: [
          meetingLeadObject.full_name,
          meetingLeadObject.time,
          meetingLeadObject.meeting_type_c,
        ],
      },
      {
        fields: [
          meetingLeadObject.phone_mobile,
          meetingLeadObject.primary_address_city,
          meetingLeadObject.street_c,
          meetingLeadObject.house_number_c,
        ],
      },
    ]
  };

  return meetingLeadInstructorCardData[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.meetinglead.instructorCardOrganizer : "meetingleadInstructorCardOrganizerDemo"];
};

export default organizeMeetingLeadInstructorCard;
