import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getData } from '../../services/getServices';
import { genTableColumnsSchemaList } from '../../helpers/dynamicTable/table/table';
import Table from '../../components/dynamicTable/table';
import DynamicTableContainer from '../../components/dynamicTable/dynamicTableContainer';
import NotFoundPage from "../404page/notFoundPage";
import { useTranslation } from 'react-i18next';
import { Card, Button } from 'react-bootstrap'

const SearchCreateView = () => {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const history = useHistory();
    const { leadId } = params
    const [isNotFound, setIsNotFound] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      checkLeadId()
    }, [])
    
    const checkLeadId = async () => {
      const lead = await getData(`leads/${leadId}`)
      if (lead?.response?.status === 404) {
        setIsNotFound(true);
      } else {
        setIsLoading(false)
      }
    }

    if (isNotFound) {
      return <NotFoundPage/>
    }

    return (
      <>
        {!isLoading &&

          <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px auto', width: '18%' }}  >
                  <Button disabled >{t('search create')}</Button>
                  <Button onClick={(() => history.push(`/meetings/create/${leadId}`))}>{t('form create')}</Button>
              </div>
              <Card border="primary" className="m-auto w-75 mt-4">
                  <Card.Header className='text-center' >{t('Meetings')}</Card.Header>
                  <Card.Body>
                      <DynamicTableContainer
                          pagesRange={4}
                          initialEndpoint="meetingleads"
                          perPage={10}
                          schemaName={{ TABLE_API: "meetingleads/TableSchema?schema_name=searchCreateMeetingLead", FORM_API: "meetingleads/filterSchema?schema_name=searchCreateMeetingLead" }}
                      />
                  </Card.Body>
              </Card>
          </div>
        }
      </>
    );
}

export default SearchCreateView;