
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FormData from 'form-data'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Loader from "../../components/Loader/Loader";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { getData } from "../../services/getServices";
import { sendDataResponse } from "../../services/postServices";
import FileUploadButton from "../../components/fileUploadButton/fileUploadButton";
import FileUploadDisclaimer from "../../config/dynamicTable/imports/utils/fileUploadDisclaimer";

function ImportForm() {
  const { t } = useTranslation();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(true)
  const [formData, setFormData] = useState({})
  const [importFile, setImportFile] = useState(null)
  const [importSchema, setImportSchema] = useState(null)

  const handleOnChange = (e, fieldName) => {
    const formDataCpy = { ...formData };
    if (fieldName === 'recurring_source' && e.target.value === 'notDefined') {
      delete formDataCpy.recurring_source;
    } else {
      formDataCpy[fieldName] = fieldName === "title_row_number" || fieldName === "start_row_number" ? parseInt(e.target.value) - 1 : e.target.value;
    }
    setFormData(formDataCpy);
  }

  useEffect(() => {
    getSchema();
  }, [])

  const getSchema = async () => {
    try {
      const fetchedImportSchema = await getData(`imports/formschema`);
      setImportSchema(fetchedImportSchema)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const disableButton = () => {
    if (formData.start_row_number === undefined || formData.title_row_number === undefined || !formData.import_name || !formData.recurring_source || !importFile) {
      return true;
    }
    return false;
  }


  const postImport = async () => {
    setIsLoading(true)
    try {
      let data;
      data = new FormData();
      data.append('files.file', importFile, importFile.name);
      data.append('data', JSON.stringify(formData));

      const res = await sendDataResponse(`imports`, data, undefined, true);
      if (res?.id) {
        toast.success(`${t('import')} ${res.id} ${t('created successfully')}`);
        history.push(`/imports/${res.id}`)
      }
      setIsLoading(false)
    } catch (err) {
      console.log("err", err)
      setIsLoading(false)
    }
  }
  
  const showDefaultNumber = (data) => {
    if (!data && data !== 0) {
      return "";
    }
    return data + 1;
  }

  return (
    <>
      {
        isLoading ? <Loader /> :
          <div className="col-lg-3 p-4">
            <div className="my-3">
            <FileUploadDisclaimer />
              <FileUploadButton
                handleFile={(file) => setImportFile(file)}
                defaultText={t('choose file')}
              />
            </div>
            <div className="my-3">
              <label>{t('titles row')}</label>
              <Form.Control type="number" value={showDefaultNumber(formData?.title_row_number)} onChange={(e) => handleOnChange(e, "title_row_number")} />
            </div>
            <div className="my-3">
              <label>{t('start row')}</label>
              <Form.Control type="number" value={showDefaultNumber(formData?.start_row_number)} onChange={(e) => handleOnChange(e, "start_row_number")} />
            </div>
            <div className="my-3">
              <label>{t('import_name')}</label>
              <Form.Control type="text" value={formData?.import_name} onChange={(e) => handleOnChange(e, "import_name")} />
            </div>
            <div className="my-3">
              <label>{t('recurring_source')}</label>
              <br />
              <Form.Control as="select" value={formData?.recurring_source} custom onChange={(e) => handleOnChange(e, "recurring_source")} >
                <option value={'notDefined'}>{t('field_doesnt_exist')}</option>
                {importSchema?.recurring_source?.enum.map((title, index) => <option key={index} value={title}>{title}</option>)}
              </Form.Control>
            </div>
            <Button disabled={disableButton()} onClick={postImport}>{t('create')}</Button>
          </div>
      }
    </>
  )
}

export default ImportForm