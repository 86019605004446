import moment from 'moment';
import { Card, CardDeck, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal';
import organizeExpiringSoonModal from '../../../../data_organizers/leads/expiringSoonModalOrganizer';

// open a modal for task in dynamic table that her date to do the task is expiring soon 
const ExpiringSoonModal = ({
  onHide,
  title,
  onDoubleClick,
  data
}) => {

  const organizedLead = organizeExpiringSoonModal(data);

  const renderDataCards = () => {
    return organizedLead.map(parentField => {
      return (
        <Card border="primary" style={{ width: '18rem' }} key={parentField.Header}>
          <Card.Header>{parentField.Header}</Card.Header>
          <Card.Body>
            <ListGroup variant='flush' >
              {parentField.fields.map(field => {
                return (<ListGroup.Item key={field.Header}>
                  {`${field.Header}: `}
                  {field.customValue ? field.customValue() : `${field.value}`}
                </ListGroup.Item>)
              })}
            </ListGroup>
          </Card.Body>
        </Card>
      )
    })
  }

  return (<>
    {data && <VerticallyCenteredModal
      show={true}
      onHide={onHide}
      title={title}
      onDoubleClick={onDoubleClick}
      className="container"
    >
      <div className="d-flex justify-content-start my-3">
        <Link className="btn btn-primary" to={`/meetings/create/${data.id}`}>הוספה לפגישה חדשה</Link>
        <Link className="btn btn-primary me-3" to={`/meetings/add-lead/${data.id}`}>הוספה לפגישה קיימת</Link>
      </div>
      <div className="row mt-4">
        <CardDeck style={{ margin: "auto", padding: "2rem", display: "flex", justifyContent: "space-around" }}>
          {renderDataCards()}
        </CardDeck>
      </div>
    </VerticallyCenteredModal>}
  </>)
}


export default ExpiringSoonModal