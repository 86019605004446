import moment from 'moment';
import { Link } from "react-router-dom";
const templateObject = {
  title: {
    Header: 'כותרת',
    type: 'text',
    accessor: "title",
  },
  content: {
    Header: 'תוכן',
    type: 'textarea',
    accessor: "content",
  },
  create_by: {
    Header: "נוצר ע\"י",
    accessor: "create_by.username",
    type: 'text',
    custom: (template) => <Link to={`/users/${template.create_by?.id}`}>{template.create_by?.username}</Link>,
  },
  created_at : {
    Header: "נוצר בתאריך",
    accessor: "created_at",
    type: 'date',
    custom: (template) => `${moment(template.created_at).format("DD/MM/YY")}`,
  },
  updated_at : {
    Header: "עודכן בתאריך",
    accessor: "updated_at",
    type: 'date',
    custom: (template) => `${moment(template.updated_at).format("DD/MM/YY")}`,
  },
}

export default templateObject;