import userDataObject from './main'
const userDetailsObject = userDataObject.userDetailsObject;
const userFormOrgnaizer = [
  userDetailsObject.first_name,
  userDetailsObject.last_name,
  userDetailsObject.phone_number,
  userDetailsObject.email,
  userDetailsObject.primary_address_city,
  userDetailsObject.street_c,
  userDetailsObject.house_number_c
];

export default userFormOrgnaizer;