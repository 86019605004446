import { Bar } from 'react-chartjs-2';
import { getUserFullName } from '../../helpers/user';

const ChartItem = (props) => {

  const data = {
    labels: [...props.data.map((item => {
      if (!item.user.contact) return
      let { first_name, last_name } = item.user.contact
      return getUserFullName(first_name, last_name)

    }))],
    datasets: [
      {
        label: props.title,
        data: props.data.map((item => item[props.filterBy])),
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };


  return (

    <div className='chart_header' >
      <h2 className='title'>{props.title}</h2>
      <Bar data={data} option={options} />
    </div >
  )
}

export default ChartItem