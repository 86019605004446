import importObject from './main'

const importPageOrganizer = (data) => {

  let ar = [
    importObject.import_name,
    importObject.file_name,
    importObject.leads_uploaded,
    importObject.title_row_number,
    importObject.start_row_number,
    importObject.leads_sent,
    importObject.leads_created,
    importObject.leads_updated,
    importObject.leads_rejected,
    importObject.create_by,
  ].map(field => {

    let newField = {
      Header: field.Header,
      accessor: field.accessor
    }
    if (field.custom) { newField.customValue = () => field.custom(data)}
    else { newField.value = data[field.accessor] }
    return newField;
  })

  return ar
}


export default importPageOrganizer;