import { ImportSelectManyActions } from "../../../config/dynamicTable/imports/selectManyRow"
import { LeadPageTableSelectManyActions } from "../../../config/dynamicTable/leads/selectManyRow"
import { MeetingSelectManyActions } from "../../../config/dynamicTable/meetings/selectManyRow"
import { TemplatesSelectManyActions } from "../../../config/dynamicTable/templates/selectManyRow"
import { GroupedPageTableSelectManyActions } from "../../../config/dynamicTable/meetingleads/selectManyRow/index"

// generate buttons inside a new row in table with actions that affect on all selected rows
export const getSelectManyActions = (type, selectedRows, deleteManyCallback, data) => {
	switch (type) {
		case "leadsPage": return <LeadPageTableSelectManyActions selectedRows={selectedRows} deleteManyCallback={deleteManyCallback} />
		case "importsPage": return <ImportSelectManyActions selectedRows={selectedRows} deleteManyCallback={deleteManyCallback} />
		case "meetingsPage": return <MeetingSelectManyActions selectedRows={selectedRows} deleteManyCallback={deleteManyCallback} />
		case "templatesPage": return <TemplatesSelectManyActions selectedRows={selectedRows} deleteManyCallback={deleteManyCallback} />
		case "groupedPage": return <GroupedPageTableSelectManyActions selectedRows={selectedRows} deleteManyCallback={deleteManyCallback} data={data} />
		default: return null
	}
}