import { useState, useContext } from 'react';
import { deleteData } from '../../../../services/deleteServices';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { ModalContext } from '../../../../context/ModalProvider';
import { useTranslation } from 'react-i18next'
// create a button that delete all selected imports rows in dynamic imports table 
export const ImportSelectManyActions = ({ selectedRows, deleteManyCallback }) => {
  let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);
  const { t, i18n } = useTranslation();

  let history = useHistory()

  const deleteSelectedRows = async () => {
    try {
      let data = await Promise.allSettled(selectedRows.map(async (row) => {
        await deleteData(`imports/${row.original.id}`)
      }))
      let countSuccessDeleteLeads = 0

      data.forEach((resp) => {
        if (resp.status === "fulfilled") {
          countSuccessDeleteLeads++
        }
      });

      if (countSuccessDeleteLeads) {
        toast.success(`${t('deleted')} ${countSuccessDeleteLeads} ${t('imports')}`)
        deleteManyCallback();
        setIsModalOpen(null);
      }


    } catch (error) {
      console.log(error)
    }
  }

  return (

    <>

      <button onClick={() => setIsModalOpen({
        title: t('warning'),
        msg: ` ${t('delete')} ${selectedRows.length} ${t('those lines')} `,
        action: () => deleteSelectedRows()
      })} className="btn btn-danger me-2">Delete {selectedRows.length} imports</button>
    </>
  )
}