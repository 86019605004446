import { getItemFromLocalStorage } from "../../helpers/auth";
import leadObject from "./main";

const organizeLeadForm = (lead) => {
  // set lead fields values
  Object.values(leadObject).forEach((field) => {
    if (field.accessor) {
      field.value = lead[field.accessor];
    }
    // if (field.custom) { field.customValue = () => field.custom(lead) }
  });

  const leadFormData = {
    leadFormOrganizerDemo: [
      {
        Header: "מידע אישי",
        fields: [
          leadObject.first_name,
          leadObject.last_name,
          leadObject.phone_number,
          leadObject.email,
        ],
      },
      {
        Header: "מידע על הכתובת",
        fields: [
          leadObject.city,
          leadObject.street,
          leadObject.house_number,
        ],
      },
      {
        Header: "מידע על הליד",
        fields: [
          leadObject.comment,
          leadObject.removed_from_contact_email,
          leadObject.removed_from_contact_sms,
        ],
      },
    ],
    leadFormOrganizerFirstSteps: [
      {
        Header: "מידע אישי",
        fields: [
          leadObject.first_name,
          leadObject.last_name,
          leadObject.phone_mobile,
          leadObject.phone_work,
          leadObject.email,
          leadObject.identity_card,
          leadObject.babys_birth_date_c,
          leadObject.number_of_children,
          leadObject.hospital,
          leadObject.hmo,
          leadObject.sector_c,
        ],
      },
      {
        Header: "מידע על הכתובת",
        fields: [
          leadObject.primary_address_city,
          leadObject.street_c,
          leadObject.house_number_c,
          leadObject.entrance_c,
          leadObject.floor_c,
          leadObject.appartment_c,
          leadObject.zip_code,
        ],
      },
      {
        Header: "מידע על הליד",
        fields: [
          leadObject.comment_c,
          leadObject.not_intrested_reason,
          leadObject.number_of_call_tries,
          leadObject.removed_from_contact_email,
          leadObject.removed_from_contact_sms,
        ],
      },
    ]
  };

  return leadFormData[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.lead.formOrganizer : "leadFormOrganizerDemo"];
};

export default organizeLeadForm;
