import { getItemFromLocalStorage } from "../../helpers/auth";
import leadObject from "./main";

const organizeLeadPage = (lead) => {
  // set lead fields values
  Object.values(leadObject).forEach((field) => {
    if (field.accessor) {
      field.value = lead[field.accessor];
    }
    // boolean type nnd falsy
    if (field.custom) {
      field.customValue = () => field.custom(lead);
    }
    if (field.value === null || field.value === undefined) {
      field.value = "אין";
    }
  });

  const leadPageData = {
    leadPageOrganizerDemo: [
      {
        Header: "מידע אישי",
        fields: [
          leadObject.first_name,
          leadObject.last_name,
          leadObject.phone_number,
          leadObject.email,
        ],
      },
      {
        Header: "מידע על הכתובת",
        fields: [
          leadObject.link_address,
          leadObject.street,
          leadObject.house_number,
        ],
      },
      {
        Header: "מידע על הליד",
        fields: [
          leadObject.next_meeting,
          leadObject.comment,
          leadObject.removed_from_contact_email,
          leadObject.removed_from_contact_email_at,
          leadObject.removed_from_contact_sms,
          leadObject.removed_from_contact_sms_at,
          leadObject.create_by,
          leadObject.update_by,
          leadObject.created_at,
          leadObject.updated_at,
        ],
      },
    ],
    leadPageOrganizerFirstSteps: [
      {
        Header: "מידע אישי",
        fields: [
          leadObject.first_name,
          leadObject.last_name,
          leadObject.phone_mobile,
          leadObject.phone_work,
          leadObject.email,
          leadObject.identity_card,
          leadObject.babys_birth_date_c,
          leadObject.number_of_children,
          leadObject.hospital,
          leadObject.hmo,
          leadObject.sector_c,
        ],
      },
      {
        Header: "מידע על הכתובת",
        fields: [
          leadObject.link_address,
          leadObject.street_c,
          leadObject.house_number_c,
          leadObject.entrance_c,
          leadObject.floor_c,
          leadObject.appartment_c,
          leadObject.zip_code,
          leadObject.area_code_c,
          leadObject.cluster_code_c,
        ],
      },
      {
        Header: "מידע על הליד",
        fields: [
          leadObject.next_meeting,
          leadObject.comment_c,
          leadObject.not_intrested_reason,
          leadObject.number_of_call_tries,
          leadObject.removed_from_contact_email,
          leadObject.removed_from_contact_email_at,
          leadObject.removed_from_contact_sms,
          leadObject.removed_from_contact_sms_at,
          leadObject.create_by,
          leadObject.update_by,
          leadObject.created_at,
          leadObject.updated_at,
        ],
      },
    ]
  };

  return leadPageData[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.lead.pageOrganizer : "leadPageOrganizerDemo"];
};

export default organizeLeadPage;
