import { useState, useContext } from 'react';
import { deleteData } from '../../../../services/deleteServices';
import { toast } from 'react-toastify';
import { ModalContext } from "../../../../context/ModalProvider";
import { useTranslation } from 'react-i18next';
import { getData } from '../../../../services/getServices';
import { sendDataResponse } from '../../../../services/postServices';
import SendManyMessagesModal from '../../leads/modals/sendManyMessagesModal';
import SendManyMessagesModalError from '../../leads/modals/sendManyMessagesModalError';
// create a button that delete all selected Meeting rows in Meeting table 
export const MeetingSelectManyActions = ({ selectedRows, deleteManyCallback }) => {
  const { t, i18n } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  const [modalShowError, setModalShowError] = useState(false)
  const [leadMsgTemplates, setLeadMsgTemplates] = useState([])
  const [templateId, setTemplateId] = useState(null)
  const [failedSentLeads, setFailedSentLeads] = useState([])
  const [blockedSentLeads, setBlockedSentLeads] = useState([])
  const [missingFieldsSentLeads, setMissingFieldsSentLeads] = useState([])
  const [missingFieldsSentMeetingleads, setMissingFieldsSentMeetingleads] = useState([])
  const [bestWorstLead, setBestWorstLead] = useState(null)
  let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);

  const getTemplates = async (setLeadMsgTemplates) => {
    try {
      let leadMsgTemplates = await getData(
        `templates?meeting_related=true`
      );
      if (Array.isArray(leadMsgTemplates) && leadMsgTemplates.length) {
        setLeadMsgTemplates(leadMsgTemplates);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SendMessages = async (leadIds, templateId, meetingIds) => {
    if (!templateId) {
      toast.warn(t('choose template'))
      return;
    }
    try {
      const data = await sendDataResponse('SendMultipleMessages', {
        leadIds,
        templateId: parseInt(templateId),
        meetingIds
      }, undefined, true);

      if (data?.success?.length > 0) {
        toast.success(data.success.length !== 1 ?
          `${t('sentMany')} ${data.success.length} ${t('messages')}`
          : t('message sent'));
      }

      if (data?.failed?.length > 0 || data?.missingFieldsLeads?.length > 0 || data?.blocked?.length > 0) {
        data?.missingFieldsLeads?.length && toast.warning(`${t('missing fields toast')}${data.missingFieldsLeads.length} ${t('leads')}`);
        (data?.failed?.length || data?.blocked?.length) && toast.error(`${t('send fail')} ${data.failed.length + data?.blocked?.length} ${t('messages')}`);
        setMissingFieldsSentLeads(data?.missingFieldsLeads);
        const meetingleadsMissingFields = data?.missingFieldsLeads.reduce((acc, leadId) => {
          const foundLeadIdIndex = leadIds.findIndex(id => leadId === id);
          if (foundLeadIdIndex !== -1) {
            acc.push(meetingIds[foundLeadIdIndex]);
          }
          return acc;
        }, []);
        setMissingFieldsSentMeetingleads(meetingleadsMissingFields);
        setBestWorstLead(data?.bestWorstLead);
        setFailedSentLeads(data.failed);
        setBlockedSentLeads(data.blocked);
        setTemplateId(templateId);
        setModalShowError(true);
      }
      setModalShow(false);

    } catch (error) {
      console.log(error);
    }

  }

  const sendCustomMsgs = async (leadIds, messageContent, meetingIds) => {
    try {
      const data = await sendDataResponse('sendMultipleMessagesCustom', {
        leadIds,
        messageContent,
        meetingIds
      }, undefined, true);

      if (data?.success?.length > 0) {
        toast.success(data.success.length !== 1 ?
          `${t('sentMany')} ${data.success.length} ${t('messages')}`
          : t('message sent'));
      }

      if (data?.failed?.length > 0 || data?.blocked?.length > 0) {
        toast.error(`${t('send fail')} ${data.failed.length + data.blocked.length} ${t('messages')}`);
        setMissingFieldsSentLeads(null);
        setBestWorstLead(null);
        setFailedSentLeads(data.failed);
        setBlockedSentLeads(data.blocked);
        setTemplateId(null);
        setModalShowError(true);
      }
      setModalShow(false);
    } catch (error) {
      console.log(error)
    }
  }

  const SendForceMessages = async (leadIds, templateId, meetingIds) => {
    try {
      const data = await sendDataResponse('forceSendMultipleMessages', {
        leadIds,
        templateId: parseInt(templateId),
        meetingIds
      }, undefined, true);

      if (data?.success?.length > 0) {
        toast.success(data.success.length !== 1 ?
          `${t('sentMany')} ${data.success.length} ${t('messages')}`
          : t('message sent'));
      }

      if (data?.failed?.length > 0 || data?.blocked?.length > 0) {
        toast.error(`${t('send fail')} ${data.failed.length + data.blocked.length} ${t('messages')}`);
        setMissingFieldsSentLeads(null);
        setMissingFieldsSentMeetingleads(null);
        setBestWorstLead(null);
        setFailedSentLeads(data.failed);
        setBlockedSentLeads(data.blocked);
        setTemplateId(null);
        setModalShowError(true);
      } else {
        setModalShowError(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteSelectedRows = async () => {
    try {
      let data = await Promise.allSettled(selectedRows.map(async (row) => {
        await deleteData(`meetingLeads/${row.original.id}`)
      }))
      let countSuccessDeleteLeads = data.filter(data => data.status === "fulfilled").length;

      if (countSuccessDeleteLeads) {
        setIsModalOpen(null);
        toast.success(`${t('deleted')} ${countSuccessDeleteLeads} ${t('meetings')}`)
        deleteManyCallback(selectedRows);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (

    <>
      <SendManyMessagesModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        title="שליחת הודעות לידים"
        sendMessages={SendMessages}
        sendCustomMsgs={sendCustomMsgs}
        leadMsgTemplates={leadMsgTemplates}
        leadIdList={selectedRows.map(row => row.original.lead.id)}
        meetingleadIdList={selectedRows.map(row => row.original.id)}
      />

      <SendManyMessagesModalError
        modalShow={modalShowError}
        setModalShow={setModalShowError}
        title="שליחת הודעה נכשלה למספר לידים"
        sendMessages={SendForceMessages}
        failedLeadIds={failedSentLeads}
        blockedLeadIds={blockedSentLeads}
        templateId={templateId}
        missingFieldsSentLeads={missingFieldsSentLeads}
        missingFieldsSentMeetingleads={missingFieldsSentMeetingleads}
        bestWorstLead={bestWorstLead}
      />
      <button onClick={() => {
        setModalShow(true)
        getTemplates(setLeadMsgTemplates)
      }} className="btn btn-success">Send {selectedRows.length} Messages</button>

      <button onClick={() => setIsModalOpen({
        title: t('delete'),
        msg: t('sure'),
        action: () => deleteSelectedRows()
      })} className="btn btn-danger me-2">Delete {selectedRows.length} meetings</button>

    </>
  )
}