import { Link } from "react-router-dom";
import moment from "moment";
import { Button } from "react-bootstrap";
import { PhoneLink } from "../../components/PhoneLink/PhoneLink";
import { WazeLink } from "../../components/WazeLink/WazeLink";
import { getItemFromLocalStorage } from "../../helpers/auth";
import { formatAdress } from "../../helpers/textFormatters";

const meetingLeadObject = {
  meetingleadOrganizerDemo: {
    meeting_type_c: {
      Header: "סוג הפגישה",
      accessor: "meeting_type_c",
      type: 'select'
    },
    specific_datetime: {
      Header: "שעת הפגישה",
      accessor: "specific_datetime",
      type: 'time',
      custom: (meetingLead) => meetingLead.specific_datetime ?
        `${moment(meetingLead.specific_datetime).format("DD/MM/YY")} ${moment(meetingLead.specific_datetime).format("HH:mm")}` : 'אין',
      editable: true,
    },
    date: {
      Header: "תאריך",
      accessor: "date",
      type: 'date',
      custom: (meetingLead) => meetingLead.specific_datetime ?
        `${moment(meetingLead.specific_datetime).format("YYYY-MM-DD")}` : '',
      editable: true,
    },
    time: {
      Header: "שעה",
      accessor: "time",
      type: 'time',
      custom: (meetingLead) => meetingLead.specific_datetime ?
        `${moment(meetingLead.specific_datetime).format("HH:mm")}` : ''
    },
    completed: {
      Header: "סטטוס",
      accessor: "completed",
      type: 'text',
      custom: (meetingLead) => meetingLead.completed ? "completed" : "in-progress"
    },
    specific_comment: {
      Header: "הערה לפגישה זאת",
      accessor: "specific_comment",
      type: 'textarea',
      editable: true,
    },
    create_by: {
      Header: 'נוצר ע"י',
      accessor: "create_by.username",
      type: "text",
      custom: (meetingLead) => (
        <Link to={`/users/${meetingLead.create_by?.id}`}>
          {meetingLead.create_by?.username}
        </Link>
      ),
    },
    update_by: {
      Header: 'עדכון אחרון בוצע ע"י',
      accessor: "update_by.username",
      type: "text",
      custom: (meetingLead) =>
        meetingLead.update_by ? (
          <Link to={`/users/${meetingLead.update_by.id}`}>
            {meetingLead.update_by.username}
          </Link>
        ) : (
          "לא בוצעו עדכונים"
        ),
    },
    full_name: {
      Header: "שם מלא",
      accessor: "full_name",
      custom: (meetingLead) => `${meetingLead.lead?.first_name || ""} ${meetingLead.lead?.last_name || ""}`,
    },
    phone_number_link: {
      Header: "טלפון אישי",
      accessor: "phone_number",
      type: "tel",
      custom: (meetingLead) => {
        return PhoneLink({ phoneNumber: meetingLead?.lead.phone_number });
      },
    },
    phone_number: {
      Header: "טלפון אישי",
      accessor: "phone_number",
      type: "tel",
      custom: (meetingLead) => {
        return meetingLead?.lead.phone_number;
      },
    },
    link_address: {
      Header: "כתובת",
      type: "text",
      custom: (meetingLead) => {
        return (
          <WazeLink city={meetingLead?.lead.city} street={meetingLead?.lead.street} houseNumber={meetingLead?.lead.house_number} displayText={meetingLead?.lead.city} />
        );
      },
    },
    link_address_detailed: {
      Header: "כתובת",
      type: "text",
      custom: (meetingLead) => {
        return (
          <WazeLink city={meetingLead?.lead.city} street={meetingLead?.lead.street} houseNumber={meetingLead?.lead.house_number} displayText={formatAdress(meetingLead.lead.street, meetingLead.lead.house_number, meetingLead.lead.city)} />
        );
      },
    },
    city: {
      Header: "ישוב",
      accessor: "lead.city",
      custom: (meetingLead) => `${meetingLead.lead.city || "אין עיר"}`,
      type: "text",
    },
    street: {
      Header: "רחוב",
      accessor: "lead.street",
      custom: (meetingLead) => `${meetingLead.lead.street || "אין רחוב"}`,
      type: "text",
    },
    house_number: {
      Header: "מספר בית",
      accessor: "lead.house_number",
      custom: (meetingLead) => `${meetingLead.lead.house_number || "אין מספר בית"}`,
      type: "text",
    },
    lead: {
      Header: "ליד",
      accessor: "lead",
      custom: (meetingLead) => <Link to={`/leads/${meetingLead.lead?.id}`}>{`${meetingLead.lead?.first_name} ${meetingLead.lead?.last_name}` || ''}</Link>,
      customInput: (meetingLead, updateMeetingLead) => <Link to={`/leads/${meetingLead.lead?.id}`}>{`${meetingLead.lead?.first_name} ${meetingLead.lead?.last_name}` || ''}</Link>,
      editable: false,
    },
  },
  meetingleadOrganizerFirstSteps: {
    meeting_type_c: {
      Header: "סוג הפגישה",
      accessor: "meeting_type_c",
      type: 'select'
    },
    specific_datetime: {
      Header: "שעת הפגישה",
      accessor: "specific_datetime",
      type: 'time',
      custom: (meetingLead) => meetingLead.specific_datetime ?
        `${moment(meetingLead.specific_datetime).format("DD/MM/YY")} ${moment(meetingLead.specific_datetime).format("HH:mm")}` : 'אין',
      editable: true,
    },
    date: {
      Header: "תאריך",
      accessor: "date",
      type: 'date',
      custom: (meetingLead) => meetingLead.specific_datetime ?
        `${moment(meetingLead.specific_datetime).format("YYYY-MM-DD")}` : '',
      editable: true,
    },
    time: {
      Header: "שעה",
      accessor: "time",
      type: 'time',
      custom: (meetingLead) => meetingLead.specific_datetime ?
        `${moment(meetingLead.specific_datetime).format("HH:mm")}` : ''
    },
    completed: {
      Header: "סטטוס",
      accessor: "completed",
      type: 'text',
      custom: (meetingLead) => meetingLead.completed ? "completed" : "in-progress"
    },
    specific_comment: {
      Header: "הערה לפגישה זאת",
      accessor: "specific_comment",
      type: 'textarea',
      editable: true,
    },
    filled_survey: {
      Header: "סקר מולא",
      accessor: "filled_survey",
      type: "boolean",
      custom: (meetingLead) => (meetingLead.filled_survey ? "V" : "X"),
    },
    custom_survey_url: {
      Header: "קישור לסקר",
      accessor: "custom_survey_url",
      type: 'text'
    },
    create_by: {
      Header: 'נוצר ע"י',
      accessor: "create_by.username",
      type: "text",
      custom: (meetingLead) => (
        <Link to={`/users/${meetingLead.create_by?.id}`}>
          {meetingLead.create_by?.username}
        </Link>
      ),
    },
    update_by: {
      Header: 'עדכון אחרון בוצע ע"י',
      accessor: "update_by.username",
      type: "text",
      custom: (meetingLead) =>
        meetingLead.update_by ? (
          <Link to={`/users/${meetingLead.update_by.id}`}>
            {meetingLead.update_by.username}
          </Link>
        ) : (
          "לא בוצעו עדכונים"
        ),
    },
    full_name: {
      Header: "שם מלא",
      accessor: "full_name",
      custom: (meetingLead) => `${meetingLead.lead?.first_name || ""} ${meetingLead.lead?.last_name || ""}`,
    },
    phone_mobile_link: {
      Header: "טלפון אישי",
      accessor: "phone_mobile",
      type: "tel",
      custom: (meetingLead) => {
        return PhoneLink({ phoneNumber: meetingLead?.lead.phone_mobile });
      },
    },
    phone_work_link: {
      Header: "טלפון בית",
      accessor: "phone_work",
      type: "text",
      custom: (meetingLead) => {
        return PhoneLink({ phoneNumber: meetingLead?.lead.phone_work });
      },
    },
    phone_mobile: {
      Header: "טלפון אישי",
      accessor: "phone_mobile",
      type: "tel",
      custom: (meetingLead) => {
        return meetingLead?.lead.phone_mobile;
      },
    },
    phone_work: {
      Header: "טלפון בית",
      accessor: "phone_work",
      type: "text",
      custom: (meetingLead) => {
        return meetingLead?.lead.phone_mobile;
      },
    },
    link_address: {
      Header: "כתובת",
      type: "text",
      custom: (meetingLead) => {
        return (
          <WazeLink city={meetingLead?.lead.primary_address_city} street={meetingLead?.lead.street_c} houseNumber={meetingLead?.lead.house_number_c} displayText={meetingLead?.lead.primary_address_city} />
        );
      },
    },
    link_address_detailed: {
      Header: "כתובת",
      type: "text",
      custom: (meetingLead) => {
        return (
          <WazeLink city={meetingLead?.lead.primary_address_city} street={meetingLead?.lead.street_c} houseNumber={meetingLead?.lead.house_number_c} displayText={formatAdress(meetingLead.lead.street_c, meetingLead.lead.house_number_c, meetingLead.lead.primary_address_city)} />
        );
      },
    },
    primary_address_city: {
      Header: "ישוב",
      accessor: "lead.primary_address_city",
      custom: (meetingLead) => `${meetingLead.lead.primary_address_city || "אין עיר"}`,
      type: "text",
    },
    street_c: {
      Header: "רחוב",
      accessor: "lead.street_c",
      custom: (meetingLead) => `${meetingLead.lead.street_c || "אין רחוב"}`,
      type: "text",
    },
    house_number_c: {
      Header: "מספר בית",
      accessor: "lead.house_number_c",
      custom: (meetingLead) => `${meetingLead.lead.house_number_c || "אין מספר בית"}`,
      type: "text",
    },
    babys_birth_date_c: {
      Header: "תאריך לידת התינוק",
      accessor: "babys_birth_date_c",
      type: "date",
      custom: (meetingLead) =>
        meetingLead?.lead.babys_birth_date_c
          ? `${moment(meetingLead?.lead.babys_birth_date_c).format("DD/MM/YY")}`
          : "אין",
    },
    hospital: {
      Header: "בית חולים",
      accessor: "hospital",
      type: "select",
    },
    hmo: {
      Header: "קופת חולים",
      accessor: "hmo",
      type: "select",
    },
    lead: {
      Header: "ליד",
      accessor: "lead",
      custom: (meetingLead) => <Link to={`/leads/${meetingLead.lead?.id}`}>{`${meetingLead.lead?.first_name} ${meetingLead.lead?.last_name}` || ''}</Link>,
      customInput: (meetingLead, updateMeetingLead) => <Link to={`/leads/${meetingLead.lead?.id}`}>{`${meetingLead.lead?.first_name} ${meetingLead.lead?.last_name}` || ''}</Link>,
      editable: false,
    },
  }
}
const chosenMeetingLeadObject = meetingLeadObject[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.meetinglead.name : "meetingleadOrganizerDemo"];

export default chosenMeetingLeadObject;
