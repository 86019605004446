import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import moment from "moment";
import { getData } from "../../services/getServices";
import { updateData } from "../../services/putServices";
import { useTranslation } from 'react-i18next';

import Select from "react-select";
import InstructorCardsView from "./components/InstructorCardsView";
import DateSlider from "./components/DateSlider";
import MeetingInfo from "./components/MeetingInfo";
import "./components/style.css";
import { isUserRole } from "../../helpers/user";
import Loader from "../../components/Loader/Loader";
import { getItemFromLocalStorage } from "../../helpers/auth";

function InstructorPage() {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedCardObject, setSelectedCardObject] = useState(null);
    const [cardArray, setCardArray] = useState(null);
    const [userId, setUserId] = useState(null);
    const [usersArray, setUsersArray] = useState(null);
    const [doneLoading, setDoneLoading] = useState(false);
    const { t } = useTranslation();

    const handleCardClick = (cardObject) => {
        setSelectedCardObject(cardObject);
    };

    const handleCheckedMeeting = async (ev, meetingObject) => {
        ev.stopPropagation();
        const meetingObjectIndex = cardArray.findIndex(meetingCard => meetingCard.id === meetingObject.id)
        const cardArrayCpy = [...cardArray];
        const res = await updateData(`meetingleads/${meetingObject.completed ? "togglestatusoff" : "togglestatuson"}/${meetingObject.id}`)
        if (res.id) {
            cardArrayCpy.splice(meetingObjectIndex, 1, { ...meetingObject, completed: !meetingObject.completed })
            setCardArray(cardArrayCpy)
        }
    };

    const handleSelectedDateChange = async (newDate, searchUser = userId) => {
        newDate = moment(newDate);
        setSelectedDate(newDate);
        const cardArrayData = await getData(
            `meetingleads?_sort=specific_datetime:ASC&assigned_to=${searchUser
            }&specific_datetime_gt=${newDate
                .startOf("day")
                .format(
                    "YYYY-MM-DDTHH:mm:ss"
                )}&specific_datetime_lt=${newDate
                    .endOf("day")
                    .format("YYYY-MM-DDTHH:mm:ss")}`
        );
        setCardArray(cardArrayData);
    };

    const init = async () => {
        setDoneLoading(false);
        const fetchedUsers = await getData("users?_sort=blocked:ASC&role.name=instructor");
        let userIdChosen;
        if (isUserRole("Instructor")) {
            userIdChosen = getItemFromLocalStorage("user").id;
        } else {
            userIdChosen = fetchedUsers?.length ? fetchedUsers[0].id : null;
        }
        setUserId(userIdChosen);
        setUsersArray(fetchedUsers);
        await handleSelectedDateChange(moment().format(), userIdChosen);
        setDoneLoading(true);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        doneLoading ?
            <div>
                {
                    selectedCardObject ?
                        <MeetingInfo meetingCardObject={selectedCardObject} setCard={setSelectedCardObject} /> :
                        <>
                            {!isUserRole("Instructor") &&
                                <div style={{ width: "20%" }}>
                                    <Select
                                        menuPlacement="auto"
                                        placeholder={t("Choose User")}
                                        value={userId && { value: userId, label: usersArray.find(user => user.id === userId).contact.first_name }}
                                        options={usersArray.map((user) => ({ value: user.id, label: user.contact.first_name }))}
                                        onChange={(selectedOption) => {
                                            setUserId(selectedOption ? selectedOption.value : null);
                                            handleSelectedDateChange(selectedDate, selectedOption ? selectedOption.value : null);
                                        }}
                                        isClearable
                                    />
                                </div>
                            }
                            <motion.div
                                initial={{ y: 1000 }}
                                animate={{ y: 0 }}
                                transition={{ type: "spring", duration: 1 }}
                            >
                                <DateSlider handleSelectedDateChange={handleSelectedDateChange} selectedDate={selectedDate} userId={userId} />
                                <InstructorCardsView
                                    handleCardClick={handleCardClick}
                                    meetingObjects={cardArray}
                                    handleCheckedMeeting={handleCheckedMeeting}
                                />
                            </motion.div>
                        </>
                }
            </div>
            : <Loader />

    );
}

export default InstructorPage;
