import { getCustomTableCell } from "../CustomTableCell";
import { getCellLinkType, getCellBooleanType, getCellLongTextType, getCellInnerFieldType, getCellJoinedTextType, CellTextItem, CellButtonItem } from "./actions/cellType";

const handleDuplicateTitles = (title, columns, titleIndex) => {
  // Adds to the title a duplicate indication(both for the bug fix and for the user to notice).
  const duplicateTitles = columns.filter((column, index) => index < titleIndex && title === column.title);
  if (duplicateTitles.length) {
    return `${title}(${duplicateTitles.length})`;
  }
  return title;
}

// generate a cell data for react-table column using a switch  
export const genTableColumnSchema = (columnSchema, buttonColumnFunctions, setData, t, columnsSchemaList, i) => {
  // all cell must have unique id property, by default the "id" cell property value is set to the cell "Header" property value 
  columnSchema.title = handleDuplicateTitles(columnSchema.title, columnsSchemaList, i);

  switch (columnSchema.type) {
    case "boolean": return {
      type: columnSchema.type,
      id: columnSchema.title,
      Header: columnSchema.title,
      accessor: columnSchema.name,
      sortAccessor: columnSchema.name,
      Cell: (tableInfo) => getCellBooleanType(tableInfo, t)
    }
    case "longText": return {
      type: columnSchema.type,
      id: columnSchema.title,
      Header: columnSchema.title,
      accessor: columnSchema.name,
      sortAccessor: columnSchema.name,
      Cell: (tableInfo) => getCellLongTextType(tableInfo)
    }
    case "link": return {
      type: columnSchema.type,
      id: columnSchema.title,
      Header: columnSchema.title,
      accessor: columnSchema.name,
      sortAccessor: `${columnSchema.name}.${columnSchema.displayValue}`,
      Cell: (tableInfo) => getCellLinkType(tableInfo, columnSchema)
    }

    case "innerField": return {
      type: columnSchema.type,
      id: columnSchema.title,
      Header: columnSchema.title,
      accessor: columnSchema.name,
      sortAccessor: `${columnSchema.name}.${columnSchema.displayValue}`,
      Cell: (tableInfo) => getCellInnerFieldType(tableInfo, columnSchema)
    }
    case "joinedText": return {
      id: columnSchema.title,
      type: columnSchema.type,
      Header: columnSchema.title,
      Cell: (tableInfo) => getCellJoinedTextType(tableInfo, columnSchema)
    }
    case "text": return {
      type: columnSchema.type,
      id: columnSchema.title,
      Header: columnSchema.title,
      accessor: columnSchema.name,
      sortAccessor: columnSchema.name,
      Cell: (tableInfo) => CellTextItem(tableInfo, columnSchema)
    }
    case "button": return {
      type: columnSchema.type,
      id: columnSchema.title,
      Header: columnSchema.title,
      accessor: columnSchema.title,
      Cell: (tableInfo) => CellButtonItem(tableInfo, columnSchema, buttonColumnFunctions)
    }
    case "doFunction": return {
      type: columnSchema.type,
      id: columnSchema.title,
      Header: columnSchema.title,
      accessor: columnSchema.title,
      Cell: (tableInfo) => getCustomTableCell(columnSchema.functionName, tableInfo, setData, columnSchema.extraData)
    }

    default: return {
      Header: columnSchema.title,
      accessor: columnSchema.title,
    }

  }
}
