import { useState, useEffect } from 'react';
import { getData } from '../../../services/getServices'
import DownloadFileLink from '../../../components/DownloadFileLink/DownloadFileLink'
import { Image, Spinner, ListGroup, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import '../lead-page.css'
import { toast } from 'react-toastify';
export const ImageView = ({
    imageObject,
    wrap = true
}) => {
    const { t } = useTranslation()
    const downloadFile = (pressedFileField) => {
        const fileObject = pressedFileField?.file;
        if (!fileObject) return;
        toast.success(`${t('downloaded file')} ${fileObject.name}`);
    }
    return (
        <>
            {imageObject?.file?.url && wrap ? <ListGroup variant="flush">
                <ListGroup.Item>                <div>
                    <DownloadFileLink downloadURL={imageObject?.file?.url} className='inner'>
                        <FontAwesomeIcon onClick={(() => downloadFile(imageObject))} icon={faDownload} data-delay-hide='100' data-delay-show='500' data-tip="הורד קובץ" className={`edit_icon${!imageObject?.file?.url ? " disable" : ""}`} size="lg" />
                    </DownloadFileLink>
                    <Image thumbnail src={`${process.env.REACT_APP_API_PATH}${imageObject.file.url.substring(1)}`} />
                </div>
                    <h6>{imageObject.file.name}</h6>
                    <h6>{t('dimensions')}:{imageObject.file.width}X{imageObject.file.height}</h6>
                    <span>{t('size')}:{parseInt(imageObject.file.size)}kb</span></ListGroup.Item>
            </ListGroup> :
                imageObject?.file && imageObject?.file?.url ? <>
                    <div>
                        <DownloadFileLink downloadURL={imageObject?.file?.url} className='inner'>
                            <FontAwesomeIcon onClick={(() => downloadFile(imageObject))} icon={faDownload} data-delay-hide='100' data-delay-show='500' data-tip="הורד קובץ" className={`edit_icon${!imageObject?.file?.url ? " disable" : ""}`} size="lg" />
                        </DownloadFileLink>
                        <Image thumbnail src={`${process.env.REACT_APP_API_PATH}${imageObject?.file?.url.substring(1)}`} />
                    </div>
                    <h6>{imageObject?.file?.name}</h6>
                    <h6>{t('dimensions')}:{imageObject?.file?.width}X{imageObject?.file?.height}</h6>
                    <span>{t('size')}:{parseInt(imageObject?.file?.size)}kb</span>
                </>
                    : <><p>{t('No file was found')}</p></>
            }
        </>
    )

}

export const ImageList = ({
    imageObjectList,
    isGrid,
    lineCount = 4
}) => {
    return (
        <>
            {isGrid ? <ListGroup variant='flush'>
                <Row md={lineCount} >
                    {
                        imageObjectList.map(imageObject =>
                            <Col>
                                <ListGroup.Item>

                                    <ImageView imageObject={imageObject} wrap={false} />
                                </ListGroup.Item>
                            </Col>
                        )
                    }
                </Row>
            </ListGroup> : <ListGroup variant='flush'>
                {imageObjectList.map(imageObject =>
                    <ListGroup.Item>

                        <ImageView imageObject={imageObject} wrap={false} />
                    </ListGroup.Item>
                )}
            </ListGroup>
            }
        </>
    )

}