import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Button, ButtonGroup } from 'react-bootstrap'
import Select from "react-select";
import leadExample from './leadExample.js'
import { convertToLeadObject } from '../../helpers/utils';
import moment from "moment";
import { getData } from '../../services/getServices';
import axios from "axios";
import './formGrid.css'
import { toast, ToastContainer } from 'react-toastify'
import AlertDialog from '../../components/modal/AlertModal/AlertModal'
import { useTranslation } from 'react-i18next';
import { translateCities, translateStreets } from "./cityStreet.helper"
import organizeFormGridAddress from "../../data_organizers/leads/formGridAddressOrganizer";

function FormGrid({ objectWithNames, _objectForEdit, submitFunc, cancelFunc, submitTxt, leadFormSchema, errorRes = false, submitPutFunc, buttonArray = null, forceSubmit = false, forceDelete = false }) {
  const { t, i18n } = useTranslation();
  const ENDPOINT = `settingconfigs/identifyingFields`;
  const [formData, setFormData] = useState({});
  const [objectForEdit, setObjectForEdit] = useState(_objectForEdit);
  const [identifyingFields, setIdentifyingFields] = useState(null);
  const [allowModal, setAllowModal] = useState(true);

  const [placeholders, setPlaceholders] = useState(null);
  const [listOfCities, setListOfCities] = useState(null);
  const [listOfStreets, setListOfStreets] = useState(null);
  const [currentListOfCities, setCurrentListOfCities] = useState(null);
  const [currentListOfStreets, setCurrentListOfStreets] = useState(null);

  // const { isErrorToggled, message, key, type } = errorRes;
  const RESOURCE_ID = `9ad3862c-8391-4b2f-84a4-2d4c68625f4b`;
  const LIMIT = 20;

  const organizedAddress = organizeFormGridAddress(_objectForEdit);

  const fetchPlaceholders = async () => {
    const fetchedPlaceholders = await leadExample();
    setPlaceholders(fetchedPlaceholders)
  }

  useEffect(() => {
    getData(ENDPOINT, setIdentifyingFields);
    fetchPlaceholders();
    fetchCities();
  }, [])
  useEffect(() => {
    renderInputs();
    triggerToastError();
    setAllowModal(true);
  }, [errorRes])
  useEffect(() => {
    if (forceSubmit || forceDelete) {
      if (buttonArray) {
        buttonArray[forceSubmit ? 1 : 0].submit(formData, objectForEdit.id)
      } else {
        submitFunc({ ...formData })
      }
    }
  }, [forceSubmit, forceDelete])


  // **********************************START_OF_CITY_STREET_FUNCTIONS**********************************
  const fetchCities = async () => {
    let encodedUrl;
    const defaultUrlUnencoded = `https://data.gov.il/api/3/action/datastore_search?resource_id=${RESOURCE_ID}&limit=${60000}&offset=0&fields=שם_ישוב&distinct=true&sort=שם_ישוב&include_total=true`;
    encodedUrl = encodeURI(defaultUrlUnencoded);
    try {
      const res = await axios.get(encodedUrl);
      const thisListOfCities = translateCities(res.data.result.records);
      setListOfCities(thisListOfCities);
      const filteredArray = thisListOfCities.filter((cityObject) =>
        cityObject.value.includes("")
      );
      setCurrentListOfCities(filteredArray.slice(0, LIMIT));
      if (objectForEdit[organizedAddress.city.accessor]) {
        fetchStreets(objectForEdit[organizedAddress.city.accessor]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const fetchStreets = async (cityName) => {
    var data = JSON.stringify({
      resource_id: "9ad3862c-8391-4b2f-84a4-2d4c68625f4b",
      q: "",
      filters: {
        שם_ישוב: [cityName]
      },
      include_total: true,
      send_analytics_event: true,
      limit: 10000,
      offset: 0
    });
    try {
      const res = await axios.post(
        "https://data.gov.il/api/3/action/datastore_search",
        data
      );
      const thisListOfStreets = translateStreets(res.data.result.records);
      setListOfStreets(thisListOfStreets);
      const filteredArray = thisListOfStreets.filter((cityObject) =>
        cityObject.value.includes("")
      );
      setCurrentListOfStreets(filteredArray.slice(0, LIMIT));
    } catch (err) {
      console.log(err);
    }
  }

  const loadCities = (inputValue) => {
    if (Array.isArray(listOfCities)) {
      const filteredArray = listOfCities.filter((cityObject) =>
        cityObject.value.includes(inputValue)
      );
      setCurrentListOfCities(filteredArray.slice(0, LIMIT));
    }
  }

  const loadStreets = (inputValue) => {
    if (Array.isArray(listOfStreets)) {
      const filteredArray = listOfStreets.filter((streetObject) =>
        streetObject.value.includes(inputValue)
      );
      setCurrentListOfStreets(filteredArray.slice(0, LIMIT));
    }
  }

  const handleChange = (inputData, inputMetaData) => {
    let objectForEditCpy = { ...objectForEdit }, formDataCpy = { ...formData };
    if (inputMetaData?.name === organizedAddress.city.accessor) {
      if (inputMetaData.action === "clear") {
        delete objectForEditCpy[organizedAddress.city.accessor];
        delete objectForEditCpy[organizedAddress.street.accessor];
        formDataCpy[organizedAddress.city.accessor] = null;
        formDataCpy[organizedAddress.street.accessor] = null;
        setCurrentListOfCities(listOfCities.slice(0, LIMIT));
        setListOfStreets(null);
        setCurrentListOfStreets(null);
      } else if (inputMetaData.action === "select-option") {
        const selectedCity = objectForEdit[organizedAddress.city.accessor];
        if (selectedCity && selectedCity !== inputData.value) {
          delete objectForEditCpy[organizedAddress.street.accessor];
          formDataCpy[organizedAddress.street.accessor] = null;
        }
        objectForEditCpy[organizedAddress.city.accessor] = inputData.value;
        formDataCpy[organizedAddress.city.accessor] = inputData.value;
        fetchStreets(inputData.value);
      }
    } else if (inputMetaData?.name === organizedAddress.street.accessor) {
      if (inputMetaData.action === "clear") {
        delete objectForEditCpy[organizedAddress.street.accessor];
        formDataCpy[organizedAddress.street.accessor] = null;
      } else if (inputMetaData.action === "select-option") {
        objectForEditCpy[organizedAddress.street.accessor] = inputData.value;
        formDataCpy[organizedAddress.street.accessor] = inputData.value;
      }
    }
    setObjectForEdit(objectForEditCpy)
    setFormData(formDataCpy)
  }

  // **********************************END_OF_CITY_STREET_FUNCTIONS**********************************


  const triggerToastError = () => {
    if (errorRes) {
      toast.warning(errorRes.responseMessage);
    }
  }
  const FormInput = (field, errorBool = false) => {
    return (
      <React.Fragment key={field.Header}>
        <label>{t(field.accessor)}</label>
        <Form.Control
          value={objectForEdit[field.accessor]}
          onChange={(e) => {
            setObjectForEdit({ ...objectForEdit, [field.accessor]: e.target.value })
            setFormData({ ...formData, [field.accessor]: e.target.value })
          }
          }
          type={field.type}
          placeholder={placeholders[field.accessor]}
          className={errorBool ? 'border-danger' : ''}
        />
      </React.Fragment>
    )
  }
  const FormSelect = (field, customEnum) => {
    const iteratedEnum = customEnum ? customEnum : leadFormSchema[field.accessor].enum
    return <React.Fragment key={field.Header}>
      <label>{t(field.accessor)}</label>
      <Select
        value={(objectForEdit[field.accessor] || objectForEdit[field.accessor] === 0) && { value: objectForEdit[field.accessor], label: t(objectForEdit[field.accessor]) }}
        onChange={(selectedValueObject) => {
          if (!selectedValueObject) {
            const objectForEditCpy = { ...objectForEdit };
            delete objectForEditCpy[field.accessor]
            const formDataCpy = { ...formData }
            formDataCpy[field.accessor] = null
            setObjectForEdit(objectForEditCpy)
            setFormData(formDataCpy)
          } else {
            const { value } = selectedValueObject
            setObjectForEdit({ ...objectForEdit, [field.accessor]: value })
            setFormData({ ...formData, [field.accessor]: value })
          }
        }}
        isClearable
        options={[...iteratedEnum].map((item) => ({ value: item, label: t(item) }))}
        placeholder={placeholders[field.accessor]}
      >
      </Select>


    </React.Fragment >
  }

  const checkNoDate = (inputValue) => inputValue === '' ? null : inputValue

  const FormInputDate = (field, errorBool = false) => {
    return <React.Fragment key={field.Header}>
      <label>{t(field.accessor)}</label>
      <Form.Control
        value={objectForEdit[field.accessor] ? moment(objectForEdit[field.accessor]).format("yy-MM-DD") : ''}
        onChange={(e) => {
          setObjectForEdit({ ...objectForEdit, [field.accessor]: checkNoDate(e.target.value) })
          setFormData({ ...formData, [field.accessor]: checkNoDate(e.target.value) })
        }
        }
        type={field.type}
        className={errorBool ? 'border-danger' : ''}
      />
    </React.Fragment>
  }

  const FormInputText = (field, errorBool) => {
    return <React.Fragment key={field.Header}>
      <label>{t(field.accessor)}</label>
      <Form.Control
        value={objectForEdit[field.accessor]}
        onChange={(e) => {
          setObjectForEdit({ ...objectForEdit, [field.accessor]: e.target.value })
          setFormData({ ...formData, [field.accessor]: e.target.value })
        }
        }
        placeholder={placeholders[field.accessor]}
        type="text"
        className={errorBool ? 'border-danger' : ''}
      />
    </React.Fragment>
  }

  const FormInputCityStreet = (fields, errorBool) => {
    return (
      <>
        {
          organizedAddress?.city?.accessor && organizedAddress?.street?.accessor &&
          < React.Fragment key={fields[0].Header}>
            <label>{t(fields[0].accessor)}</label>
            {currentListOfCities && (
              <Select
                name={fields[0].accessor}
                value={objectForEdit[fields[0].accessor] ?
                  { label: objectForEdit[fields[0].accessor], value: objectForEdit[fields[0].accessor] } : null
                }
                isClearable
                onInputChange={(value) => {
                  loadCities(value);
                }}
                options={currentListOfCities}
                defaultOptions
                onChange={handleChange}
                placeholder={placeholders[fields[0].accessor]}
              />
            )}
            <label>{t(fields[1].accessor)}</label>
            {currentListOfCities && (
              <Select
                isDisabled={currentListOfStreets !== null ? false : true}
                name={fields[1].accessor}
                value={objectForEdit[fields[1].accessor] ?
                  { label: objectForEdit[fields[1].accessor], value: objectForEdit[fields[1].accessor] } : null
                }
                isClearable
                onInputChange={(value) => {
                  loadStreets(value);
                }}
                options={currentListOfStreets}
                defaultOptions
                onChange={handleChange}
                placeholder={placeholders[fields[1].accessor]}
              />
            )}
          </React.Fragment>
        }
      </>
    )
  }
  const FormInputTextArea = (field, errorBool = false) => {
    return (
      <React.Fragment key={field.Header}>
        <label>{t(field.accessor)}</label>
        <Form.Control as="textarea" rows={3}
          value={objectForEdit[field.accessor]}
          onChange={(e) => {
            setObjectForEdit({ ...objectForEdit, [field.accessor]: e.target.value })
            setFormData({ ...formData, [field.accessor]: e.target.value })
          }
          }
          placeholder={placeholders[field.accessor]}
          className={errorBool ? 'border-danger' : ''}
        />
      </React.Fragment>
    )
  }

  const FormInputRadio = (field, errorBool = false) => {
    return <div className="my-3">
      <h3 className="h5 mb-3">{t(field.accessor)}</h3>

      <Form.Group className="my-3">
        <Form.Check
          value={true}
          onChange={(e) => {
            setObjectForEdit({ ...objectForEdit, [field.accessor]: true })
            setFormData({ ...formData, [field.accessor]: true })
          }
          }
          checked={objectForEdit[field.accessor]}
          type="radio"
          label={t('yes')}

          name={[field.accessor] + " radio"}
          style={{ paddingLeft: 0 }}
        />
        <Form.Check
          value={false}
          onChange={(e) => {
            setObjectForEdit({ ...objectForEdit, [field.accessor]: false })
            setFormData({ ...formData, [field.accessor]: false })
          }
          }
          checked={(typeof objectForEdit[field.accessor] !== "undefined" && !objectForEdit[field.accessor] && objectForEdit[field.accessor] !== null)}
          type="radio"
          label={t('no')}
          name={[field.accessor] + " radio"}
          style={{ paddingLeft: 0 }}

        />
        <Form.Check
          value={''}
          onChange={(e) => {
            setObjectForEdit({ ...objectForEdit, [field.accessor]: null })
            setFormData({ ...formData, [field.accessor]: null })
          }
          }
          checked={typeof objectForEdit[field.accessor] === "undefined" || objectForEdit[field.accessor] === null}
          type="radio"
          label={t('irrelevant')}
          name={[field.accessor] + "radio"}
          style={{ paddingLeft: 0 }}

        />

      </Form.Group>
    </div>
  }
  const getErrorBool = (fieldAccessor) => {
    if (errorRes) {
      if ((errorRes.type === "requiredFields" || errorRes.type === "identifierFields") && errorRes.keysArray) {
        const isIdentifierField = errorRes.keysArray.find((errorField) => errorField === fieldAccessor);
        if (identifyingFields && isIdentifierField) {
          return true;
        }
      } else if (errorRes.type === "existingLead") {
        return false;
      } else {
        return fieldAccessor === errorRes.key;
      }
    } else {
      return false;
    }
  };




  const renderInputs = () => {
    return (
      (identifyingFields && currentListOfCities &&
        <Row className="form_grid">
          {objectWithNames.map((parentField, i) => {

            return (
              <div className="col-lg-4 px-2" key={parentField.Header + i}>
                <h5 className="mt-3">{parentField.Header}</h5>
                {parentField.fields.map((field, i) => {
                  const errorBool = getErrorBool(field.accessor)
                  switch (field.type) {
                    case 'text': {
                      if (field.accessor === organizedAddress.city.accessor) {
                        const streetField = parentField.fields.find(field => field.accessor === organizedAddress.street.accessor);
                        return FormInputCityStreet([field, streetField], errorBool)
                      } else if (field.accessor === organizedAddress.street.accessor) {
                        return <></>
                      } else {
                        return FormInputText(field, errorBool)
                      }
                    }
                    case 'boolean': return FormInputRadio(field, errorBool)
                    case 'textarea': return FormInputTextArea(field, errorBool)
                    case 'date': return FormInputDate(field, errorBool)
                    case 'select': return FormSelect(field, errorBool)
                    case 'strictSelect': return FormSelect(field, field.strictOptions)
                    default: return FormInput(field, errorBool)
                  }

                })}
              </div>
            )
          })}
        </Row>)
    )
  }
  const modalAction = () => {
    return allowModal
  }


  const modalPayload = errorRes.type === "existingLead" ? {
    title: <Link target="_blank" to={`/leads/${errorRes.existingLead.id}`}>{`${t('similar lead')}${errorRes.existingLead.id})`}</Link>,
    msg: t('dupe lead disclaimer'),
    buttonArray: [
      { submit: (() => submitFunc({ ...formData, skipAlreadyExist: true }, objectForEdit.id)), text: `${t(submitTxt)} ${t('anyway')}` },
      submitTxt === 'צור' ?
        { submit: (() => submitPutFunc({ ...formData, skipAlreadyExist: true }, errorRes.existingLead.id)), text: ` ${t('update lead num')}${errorRes.existingLead.id} ${t('with vals')}` } : null
    ],
    action: modalAction,
  } : {}

  return (
    <div>
      {errorRes && errorRes.type === "existingLead" && allowModal &&
        <AlertDialog ModalSettings={modalPayload} setOpen={setAllowModal}></AlertDialog>
      }
      <Form >
        {placeholders && renderInputs()}
        <div>
          {!buttonArray || !buttonArray[0] ? <ButtonGroup style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse', marginLeft: '0.5rem', marginTop: '1rem' }}>
            <Button onClick={() => submitFunc({ ...formData })} >{t(submitTxt === 'צור' ? 'create' : 'update')}</Button>
            <Button type="button" onClick={cancelFunc}>{t('cancel')}</Button>
            <Button type="button" onClick={() => setObjectForEdit(convertToLeadObject(objectWithNames))}>{t('reset')}</Button>
          </ButtonGroup> : buttonArray.map(button => <Button variant={!button?.color ? "primary" : button.color} onClick={() => button.submit(formData, objectForEdit.id)} style={{ marginLeft: '0.5rem', marginTop: '1rem' }}>{button.text(objectForEdit.id)}</Button>)}
        </div>
      </Form>
    </div>
  )
}

export default FormGrid