import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { updateData } from "../../services/putServices";
import { getData } from '../../services/getServices';

import userFormOrgnaizer from '../../data_organizers/users/userFormOrgnaizer'
import NotFoundPage from "../404page/notFoundPage";

import InputTextareaSelect from '../../components/input_textarea_select/inputTextareaSelect'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { getItemFromLocalStorage } from "../../helpers/auth";
function UserForm({ setMessage }) {
  const { id } = useParams();
  let history = useHistory();

  const [user, setUser] = useState(null)
  const [isNotFound, setIsNotFound] = useState(false);
  const [initialUser, setInitialUser] = useState(null)
  const USERS = `users/${id}`;

  const checkIfCurrentUser = async () => {
    const user = getItemFromLocalStorage("user");
    if (!user || (user.id !== Number(id) && user.role.name !== "Admin")) {
      setIsNotFound(true);
    }
  }

  const fetchData = async () => {
    const error = await getData(USERS, (res) => {
      setInitialUser(res);
      setUser({ ...res });
    });
    if (error?.response?.status === 404) {
      setIsNotFound(true);
    }
  }

  useEffect(() => {
    checkIfCurrentUser()
    fetchData()

  }, []);

  const putUser = async () => {
    const contact = user.contact;
    const userForSend = { ...user }
    userForSend.contact = user.contact.id;
    try {
      await updateData(`users/${id}`, userForSend);
      await updateData(`contacts/${contact.id}`, contact)
      setMessage(`משתמש ${id} עודכן בהצלחה!`)
      history.push(`/users/${id}`)
    } catch (err) {
      console.log(err)
    }
  }

  const fieldToUpdate = (accessor, obj) => {
    if (accessor.includes('.')) {
      return obj[accessor.split('.')[0]][accessor.split('.')[1]]
    } else {
      return obj[accessor]
    }
  }

  const onChange = (obj, setObj, accessor, value) => {
    if (accessor.includes('.')) {
      const [parentField, subField] = accessor.split('.');
      const temp = { ...obj };
      setObj({ ...temp, [parentField]: { ...temp[parentField], [subField]: value } })
    } else {
      setObj({ ...obj, [accessor]: value })
      return obj[accessor]
    }

  }
  if (isNotFound) {
    return <NotFoundPage />
  }
  return (
    <div>
      {user && <Card>
        <Card.Header>עריכת פרטי משתמש</Card.Header>
        <Card.Body>
          {userFormOrgnaizer.map(field =>
            <React.Fragment>
              <label style={{ display: 'block' }}>{field.Header}</label>
              <InputTextareaSelect
                type={field.type}
                value={fieldToUpdate(field.accessor, user)}
                onChange={(e) => onChange(user, setUser, field.accessor, e.target.value)}
                onBlur={() => { }}
                selectArray={field.selectArray || []}
              />
            </React.Fragment>)}
        </Card.Body>
        <Card.Footer>
          <ButtonGroup style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse' }}>
            <Button onClick={putUser}>עדכון</Button>
            <Button onClick={() => history.goBack()}>ביטול</Button>
            <Button onClick={() => setUser(initialUser)}>איפוס</Button>
          </ButtonGroup>
        </Card.Footer>
      </Card>}

    </div>
  )
}

export default UserForm