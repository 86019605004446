import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button'
import DynamicTableContainer from "../../components/dynamicTable/dynamicTableContainer";
function ImportsView() {
	let history = useHistory();

	const handleTableRowDoubleClick = (e, { id }, history, blank) => {
		blank ? window.open(window.location.href.split("?")[0] + `/${id}`, '_blank') : history.push(`/imports/${id}`)
	}

	return (
		<div>
			<Button variant="primary" onClick={() => history.push(`/imports/create/new`)}>ייבוא חדש</Button>
			<DynamicTableContainer
				pagesRange={4}
				initialEndpoint="imports"
				perPage={50}
				handleTableRowDoubleClick={handleTableRowDoubleClick}
				schemaName={{ TABLE_API: "imports/TableSchema?schema_name=importsPage", FORM_API: "imports/filterSchema?schema_name=importsPage" }}

			/>
		</div>
	)
}

export default ImportsView