import React, { useRef, useState, useEffect } from 'react'
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DynamicTableContainer from '../../../../components/dynamicTable/dynamicTableContainer';
import { Link } from 'react-router-dom';

// send a template message for specific lead 
export default function RelatedLeadTableModal({
  modalShow,
  setModalShow,
  title,
  relatedLeadId
}) {
  const { t } = useTranslation();

  const handleRelatedLeadTableRowDoubleClick = (e, TableRow, history, blank) => {
    e.preventDefault()
    let leadId = TableRow?.id;
    blank ? window.open(`/leads/edit/${leadId}/relate/${relatedLeadId}`, '_blank') : history.push(`/leads/edit/${leadId}/relate/${relatedLeadId}`);
  }

  return (
    <VerticallyCenteredModal
      show={modalShow}
      onHide={() => {
        setModalShow(false);
      }}
      title={title}
      onDoubleClick={(e) => { e.stopPropagation() }}
      size='xl'
    >
      <div>
        <p>{t('Relate lead modal instructions')}</p>
        <Link class="btn btn-primary" to={`/leads/create/new/relate/${relatedLeadId}`}>{t("create")}</Link>
        <DynamicTableContainer
          pagesRange={4}
          initialEndpoint="leads"
          perPage={10}
          schemaName={{ TABLE_API: "leads/TableSchema?schema_name=relatedLeadModal", FORM_API: "leads/filterSchema?schema_name=relatedLeadModal" }}
          handleTableRowDoubleClick={handleRelatedLeadTableRowDoubleClick}
        />
      </div>
    </VerticallyCenteredModal >
  )
}