import React from "react";
import { useHistory } from "react-router-dom";
import DynamicTableContainer from "../../components/dynamicTable/dynamicTableContainer";
import Button from 'react-bootstrap/Button'

function TemplatesView() {

  let history = useHistory();

  const handleTableRowDoubleClick = (e, { id }, history, blank) => {
    blank ? window.open(window.location.href.split("?")[0] + `/edit/${id}`, '_blank') : history.push(`/templates/edit/${id}`)
  }

  return (
    <div>
      <Button variant="primary" onClick={() => history.push(`/templates/create/new`)}>יצירת תבנית חדשה</Button>
      <DynamicTableContainer
        pagesRange={4}
        initialEndpoint="templates"
        perPage={50}
        handleTableRowDoubleClick={handleTableRowDoubleClick}
        schemaName={{ TABLE_API: "templates/TableSchema?schema_name=templatesPage", FORM_API: "templates/filterSchema?schema_name=templatesPage" }}
      />
    </div>
  )
}

export default TemplatesView