import React, { useRef, useState, useEffect } from 'react'
import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { WhatsappLink } from '../../../../components/WhatsappLink/WhatsappLink';

// send a template message for specific lead 
export default function SendOneMessageModal({
  modalShow,
  setModalShow,
  title,
  leadMsgTemplates = [],
  sendOneMsg,
  lead,
  phoneNumber,
  sendOneMsgCustom
}) {
  const { t, i18n } = useTranslation();
  const leadId = lead.id;
  let selectRef = useRef()
  const [textState, setTextState] = useState('');
  const [pickedSelector, setPickedSelector] = useState('')
  const templateStyle = textState ? { pointerEvents: 'none', opacity: '0.2' } : { pointerEvents: 'auto', opacity: '1' };
  const textAreatemplateStyle = pickedSelector ? { pointerEvents: 'none', opacity: '0.2' } : { pointerEvents: 'auto', opacity: '1' };
  const handleSend = (leadId, value, isCustom) => {
    if (isCustom) {
      sendOneMsgCustom(leadId, value);
    } else {
      sendOneMsg(leadId, value)
    }
    setTextState('');
    setPickedSelector('');
  }
  return (
    <VerticallyCenteredModal
      show={modalShow}
      onHide={() => {
        setTextState('');
        setPickedSelector('');
        setModalShow(false);
      }}
      title={title}
      onDoubleClick={(e) => { e.stopPropagation() }}
      size='xl'
    >
      <h4>{t('Message Using Whatsapp')}</h4>
      <WhatsappLink lead={lead} isIcon={false} displayText={t("Send Whatsapp Message")} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={`templates my-4`} style={templateStyle}>
          <h4>{t('message templates')}</h4>
          <select ref={selectRef} onChange={(e) => setPickedSelector(e.target.value)}>
            <option value=''>{t('pick template')}</option>

            {leadMsgTemplates.map((item, i) => {
              return <option key={i} value={item.id}>{item.title}</option>
            })}
          </select>
        </div>
        <div className={`templates my-4`} style={{ flex: 1, marginRight: '10px' }}>
          <div style={textAreatemplateStyle}>
            <h4>{t('compose message')}</h4>
            <Form>
              <Form.Group className="mb-3">
                <Form.Control as="textarea" style={{ 'resize': 'none' }} rows={6} value={textState} onChange={(e) => setTextState(e.target.value)} />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <button className="btn btn-success" style={{ opacity: (textState || pickedSelector) ? 1 : 0.50, pointerEvents: (textState || pickedSelector) ? 'auto' : 'none' }} onClick={() => textState ? handleSend(leadId, textState, true) : handleSend(leadId, selectRef.current.value, false)}>{t('send')}</button>
    </VerticallyCenteredModal >
  )
}