import { getItemFromLocalStorage } from "../../helpers/auth";
import meetingLeadObject from "./main";

const organizeMeetingWazeSettlementLinkCustomCell = (meetinglead) => {
  // set meetinglead fields values
  Object.values(meetingLeadObject).forEach((field) => {
    if (field.accessor) {
      field.value = meetinglead[field.accessor];
    }
    // boolean type nnd falsy
    if (field.custom) {
      field.customValue = () => field.custom(meetinglead);
    }
    if (field.value === null || field.value === undefined) {
      field.value = "אין";
    }
  });

  const meetingWazeSettlementLinkCustomCell = {
    meetingWazeSettlementLinkCustomCellDemo: meetingLeadObject.link_address,
    meetingWazeSettlementLinkCustomCellFirstSteps: meetingLeadObject.link_address,
  };

  return meetingWazeSettlementLinkCustomCell[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.meetinglead.meetingWazeSettlementLinkCustomCell : "meetingWazeSettlementLinkCustomCellDemo"].customValue();
};

export default organizeMeetingWazeSettlementLinkCustomCell;
