import moment from 'moment';

const actionOnWord = (action, word) => {
	let outputWord;
	switch (action) {
		case 'getTime':
			outputWord = moment(word).format('HH:mm')
			break;
		case 'getDate':
			outputWord = moment(word).format('DD-MM-YYYY')
			break;
		case 'getDateTime':
			outputWord = moment(word).format('DD-MM-YYYY HH:mm')
			break;
		case 'getDayOfWeek':
			outputWord = moment(word).format('dddd');
			switch (outputWord) {
				case 'Sunday':
					outputWord = 'ראשון';
					break;
				case 'Monday':
					outputWord = 'שני';
					break;
				case 'Tuesday':
					outputWord = 'שלישי';
					break;
				case 'Wednesday':
					outputWord = 'רביעי';
					break;
				case 'Thursday':
					outputWord = 'חמישי';
					break;
				case 'Friday':
					outputWord = 'שישי';
					break;
				case 'Saturday':
					outputWord = 'שבת';
					break;
				default:
					break;
			}
			break;
		default:
			break;
	}
	return outputWord;
}

// At some point need to replace the find nested value with some logic in the function below.
// Needs to be implemented in the backend too though to make it as similar as possible.

// const translateMarkup = (meetingExample, leadExample, template, wordsData) => {
// 	let textTemplate = template;
// 	let cleaningRegex = /\[.*?\]/g;
// 	let templateWords = textTemplate.match(cleaningRegex);
// 	if (templateWords !== null) {
// 		for (let i = 0; i < templateWords.length; i++) {
// 			let thisWord = templateWords[i]
// 			let pushedWord;
// 			let caseIndex = wordsData.findIndex((wordCase) => thisWord === wordCase.word);

// 			let lead = wordsData[caseIndex]?.meetingWord ? meetingExample : leadExample;
// 			if (caseIndex !== -1) {
// 				if (wordsData[caseIndex].context.includes('.')) {
// 					let nestedLevels = wordsData[caseIndex].context.split('.').length;
// 					let nestedPath = wordsData[caseIndex].context.split('.');
// 					let nestedObject = lead;
// 					for (let i = 0; i < nestedLevels; i++) {
// 						if (nestedObject == undefined) return;
// 						if (i === nestedLevels - 1) {
// 							pushedWord = nestedObject[nestedPath[i]];

// 						} else {
// 							nestedObject = nestedObject[nestedPath[i]];
// 						}
// 					}
// 					if (wordsData[caseIndex].isComplex) {
// 						pushedWord = actionOnWord(wordsData[caseIndex].action, pushedWord);
// 					}
// 					textTemplate = textTemplate.replace(thisWord, pushedWord);
// 				}
// 				else {
// 					pushedWord = lead[wordsData[caseIndex].context];
// 					if (wordsData[caseIndex].isComplex) {
// 						pushedWord = actionOnWord(wordsData[caseIndex].action, pushedWord);
// 					}
// 					textTemplate = textTemplate.replace(thisWord, pushedWord);
// 				}
// 			}

// 		}
// 		return textTemplate;

// 	} else {
// 		return template;
// 	}

// }

const findNestedValue = (pathToValue, obj) => {
	if (!pathToValue || typeof pathToValue !== 'string') {
		throw new Error('Invalid pathToValue parameter');
	}

	if (!obj || typeof obj !== 'object') {
		throw new Error('Invalid obj parameter');
	}

	const keys = pathToValue.split('.');
	const key = keys[0];
	const remainingKeys = keys.slice(1);

	if (Object.prototype.hasOwnProperty.call(obj, key)) {
		const value = obj[key];
		if (remainingKeys.length === 0) {
			return value;
		}
		if (value instanceof Object) {
			return findNestedValue(remainingKeys.join('.'), value);
		}
	}

	throw new Error('Invalid pathToValue');
}

const translateMarkup = (lead, template, dictionaryWords, meeting) => {
	const leadWords = dictionaryWords.filter(word => !word.meetingWord);
	if (meeting) {
		const meetingWords = dictionaryWords.filter(word => word.meetingWord);
		for (let j = 0; j < meetingWords.length; j++) {
			let thisWord = meetingWords[j];
			const replacingRegex = new RegExp('\\' + thisWord.word, 'g');
			let pushedWord = findNestedValue(thisWord.context, meeting);
			if (!pushedWord) {
				pushedWord = thisWord.word;
			} else if (thisWord.isComplex) {
				pushedWord = actionOnWord(thisWord.action, pushedWord);
			}
			template = template.replace(replacingRegex, pushedWord);
		}
	}
	for (let i = 0; i < leadWords.length; i++) {
		let thisWord = leadWords[i];
		const replacingRegex = new RegExp('\\' + thisWord.word, 'g');
		let pushedWord = lead[thisWord.context];
		if (!pushedWord) {
			pushedWord = "";
		} else if (thisWord.isComplex) {
			pushedWord = actionOnWord(thisWord.action, pushedWord);
		}
		template = template.replace(replacingRegex, pushedWord);
	}
	return template;
}

const translateLinks = (message) => {
	let newMessage = message;
	const findingRegex = /\{link=(.*?)\}/g;
	const linksArray = message.match(findingRegex);
	if (!linksArray) return newMessage;
	const newLinksArray = [];
	linksArray.forEach(() => {
		newLinksArray.push(`http://sameLinkAsInserted/shortLink`);
	});

	linksArray.forEach((link, index) => {
		const indexOfText = newMessage.indexOf(link);
		newMessage = `${newMessage.slice(0, indexOfText + link.length - 1)}${index}${newMessage.slice(indexOfText + link.length - 1)}`;
		linksArray[index] = `${linksArray[index].slice(0, linksArray[index].length - 1)}${index}}`;
	});

	for (let i = 0; i < linksArray.length; i++) {
		let thisLink = newLinksArray[i];
		const replacingRegex = new RegExp('\{link=(.*?)' + i + '\}', 'g');
		newMessage = newMessage.replace(replacingRegex, thisLink);
	}
	return newMessage;
}

const translateLinkActions = (message) => {
	let newMessage = message;
	const findingRegex = /\{unsubscribe}/g;
	const actionsArray = message.match(findingRegex);
	if (!actionsArray) return newMessage;

	const replacer = `http://unsubscribe/shortLink`;


	for (let i = 0; i < actionsArray.length; i++) {
		newMessage = newMessage.replace(findingRegex, replacer);
	}
	return newMessage;
}

const translateContent = (exampleLead, content, dictionaryWords, meeting) => {
	let message;
	if (meeting) {
		message = translateMarkup(exampleLead, content, dictionaryWords, meeting);
	} else {
		message = translateMarkup(exampleLead, content, dictionaryWords);
	}
	message = translateLinkActions(message);
	message = translateLinks(message);
	return message;
}

export default translateContent;