import React, { useContext, useState, useEffect } from 'react'
import { FormContext } from './formContext'
import { ButtonGroup, Button } from 'react-bootstrap'
// import moment from 'moment'
import { customStyles } from "./customStyles.js"
import Select, { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable'
import { useTranslation } from 'react-i18next';
import { translateCities, translateStreets } from "../../../components/form_grid/cityStreet.helper"
import { getData } from '../../../services/getServices';
import axios from 'axios'
import { removeFieldVariationsFromObject } from './form';

export default function FormField({ field, setFormData }) {
  const { t } = useTranslation();
  switch (field.filterType) {
    case 'text':
      return <GenericFilter field={field} type='text' setFormData={setFormData} isPartial={field.isPartial} t={t} />
    case 'number':
      return <GenericFilter field={field} type='number' setFormData={setFormData} t={t} />
    case 'enumOne':
      return <EnumOneFilter t={t} field={field} setFormData={setFormData} />
    case 'selectCityAndStreet':
      return <SelectCityAndStreetFilter t={t} field={field} setFormData={setFormData} />
    case 'enumMany':
      return <EnumManyFilter field={field} setFormData={setFormData} t={t} />
    case 'dynamicNumber':
      return <DynamicNumberFilter t={t} field={field} setFormData={setFormData} />
    case 'textMany':
      return <TextManyFilter field={field} setFormData={setFormData} isPartial={field.isPartial} t={t} />
    case 'enumManyUser':
      return <EnumManyFilter t={t} field={field} setFormData={setFormData} isEnumUser={true} />
    case 'dynamicDate':
      return <DynamicDateFilter field={field} setFormData={setFormData} t={t} />
    case 'boolToggle':
      return <BoolToogleFilter field={field} type='radio' setFormData={setFormData} t={t} />
    case 'isNull':
      return <BoolToogleFilter field={field} type='radio' setFormData={setFormData} isNull={true} t={t} />
    default:
      return ""
  }

}

const GenericFilter = ({ field, type, setFormData, isPartial = false, t }) => {
  let { name, viewText } = field;
  const { formData } = useContext(FormContext)
  const nameValue = isPartial ? name + "_contains" : name

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  return (

    <div className="col-6 col-md-4 col-lg-2 mb-1">
      <label className="form-label" htmlFor={nameValue}>{t(viewText)}</label>
      <input type={type} className=" p-2 form-control" value={formData[nameValue]} name={nameValue} onChange={handleChange} placeholder={`${t('input')} "${t(viewText)}"`} />
    </div>
  )
}

const EnumOneFilter = ({ field, setFormData, t }) => {

  let { name, viewText } = field;
  const { formData } = useContext(FormContext)

  return (
    <div className="col-6 col-md-4 col-lg-2 mb-1">
      <label className="form-label" htmlFor={name}>{t(viewText)}</label>
      <Select value={!formData[name] ? null : { value: formData[name], label: formData[name] }} name={name} onChange={((selectedValueObject) => {
        if (!selectedValueObject) {
          setFormData({ ...formData, [name]: "" });
        } else {
          setFormData({ ...formData, [name]: selectedValueObject.value })
        }
      })} options={field.enumArray.map((item) => ({ value: item, label: t(item) }))} placeholder={`${t('Choose')} "${t(viewText)}"`} isClearable>
      </Select>
    </div>
  )
}

const SelectCityAndStreetFilter = ({ field, setFormData, t }) => {

  let { name, viewText, relatedStreetFieldName } = field;
  const { formData } = useContext(FormContext)
  const [listOfCities, setListOfCities] = useState(undefined);
  const [currentListOfCities, setCurrentListOfCities] = useState(undefined);
  const [listOfStreets, setListOfStreets] = useState(undefined);
  const [currentListOfStreets, setCurrentListOfStreets] = useState(undefined);

  const RESOURCE_ID = `9ad3862c-8391-4b2f-84a4-2d4c68625f4b`;

  const fetchCities = async () => {
    let encodedUrl;
    const defaultUrlUnencoded = `https://data.gov.il/api/3/action/datastore_search?resource_id=${RESOURCE_ID}&limit=${60000}&offset=0&fields=שם_ישוב&distinct=true&sort=שם_ישוב&include_total=true`;
    encodedUrl = encodeURI(defaultUrlUnencoded);
    try {
      const res = await axios.get(encodedUrl);
      const thisListOfCities = translateCities(res.data.result.records);
      setListOfCities(thisListOfCities);
      setCurrentListOfCities(thisListOfCities.slice(0, 20));
    } catch (err) {
      console.log(err);
    }
  }

  const fetchStreets = async (cityName) => {
    var data = JSON.stringify({
      resource_id: "9ad3862c-8391-4b2f-84a4-2d4c68625f4b",
      q: "",
      filters: {
        שם_ישוב: [cityName]
      },
      include_total: true,
      send_analytics_event: true,
      limit: 10000,
      offset: 0
    });
    try {
      const res = await axios.post(
        "https://data.gov.il/api/3/action/datastore_search",
        data
      );
      const thisListOfStreets = translateStreets(res.data.result.records);
      setListOfStreets(thisListOfStreets);
      setCurrentListOfStreets(thisListOfStreets.slice(0, 20));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchCities()
  }, [])

  const getStreets = (cityname) => {
    if (cityname) {
      fetchStreets(cityname)
    }
  }
  const setCurrentList = (type, input) => {
    if (type === 'city') {
      setCurrentListOfCities(listOfCities.filter(cityNameObject => cityNameObject.value.includes(input)).slice(0, 20))
    } else {
      if (listOfStreets) {
        setCurrentListOfStreets(listOfStreets.filter(streetName => streetName.value.includes(input)).slice(0, 20))
      }
    }
  }
  return (
    <div className="col-6 col-md-4 col-lg-2 mb-1">
      <label className="form-label" htmlFor={name}>{t(viewText)}</label>
      <CreatableSelect
        value={!formData[name] ? null : { value: formData[name], label: formData[name] }}
        name={name}
        onChange={(selectedValueObject) => {
          if (!selectedValueObject) {
            setListOfStreets(undefined);
            setCurrentListOfStreets(undefined);
            setFormData({ ...formData, [name]: "", [relatedStreetFieldName]: "" });
          } else {
            setFormData({ ...formData, [name]: selectedValueObject.value });
            getStreets(selectedValueObject.value);
          }
        }}
        options={currentListOfCities}
        placeholder={`${t('Choose')} "${t(name)}"`}
        isClearable
        filterOption={createFilter({ ignoreAccents: false })}
        onInputChange={((inputValue) => {
          setCurrentList('city', inputValue)
        })}>
      </CreatableSelect>
      <CreatableSelect
        value={formData[relatedStreetFieldName] ? { value: formData[relatedStreetFieldName], label: formData[relatedStreetFieldName] } : null}
        name={relatedStreetFieldName}
        onChange={((selectedValueObject) => {
          if (!selectedValueObject) {
            setFormData({ ...formData, [relatedStreetFieldName]: "" });
          } else {
            setFormData({ ...formData, [relatedStreetFieldName]: selectedValueObject.value });
          }
        })} options={currentListOfStreets} placeholder={`${t('Choose')} "${t(relatedStreetFieldName)}"`} isClearable onInputChange={((inputValue) => {
          setCurrentList('street', inputValue)
        })}>
      </CreatableSelect>
    </div>
  )
}
const EnumManyFilter = ({ t, field, setFormData, isEnumUser }) => {

  let { name, viewText } = field
  const [fieldArray, setFieldArray] = useState(field?.enumArray);
  const { formData } = useContext(FormContext);

  useEffect(() => {
    if (isEnumUser) {
      getData('contacts', ((data) => setFieldArray(data.map(contactObject => { return { username: contactObject.user.username, id: contactObject.user.id, isBlocked: contactObject.user.blocked } }))))
    }
  }, [])

  const currentFieldArray = Array.isArray(fieldArray) && isEnumUser ? fieldArray.map((val, idx) => ({ content: `${val.username} ID:#${val.id}`, value: val.id, isBlocked: val.isBlocked })) : fieldArray
  let options = ["Select All"].concat(currentFieldArray).map((item) => ({ value: item?.value || item, label: t(item?.content || item), isBlocked: item?.isBlocked }))
  options.sort((a, b) => Number(a.isBlocked) - Number(b.isBlocked));

  return (
    <>
      {fieldArray && <div className="col-6 col-md-4 col-lg-2 mb-1">
        <label className="form-label" htmlFor={name}>{t(viewText)}</label>
        <div>
          <Select
            styles={customStyles}
            closeMenuOnSelect={false}
            value={!formData[name] ? null : formData[name]}
            name={name}
            isMulti
            onChange={selectedValueObject => {
              selectedValueObject.length &&
                selectedValueObject[selectedValueObject.length - 1].value === options[0]['value']
                ? setFormData({ ...formData, [name]: options.slice(1) })
                : setFormData({ ...formData, [name]: selectedValueObject })
            }}
            options={options}
            placeholder={`${t('Choose')} "${t(viewText)}"`}
            isClearable
          >
          </Select>
        </div>
      </div>}
    </>
  )
}


const DynamicDateFilter = ({ field, setFormData, t }) => {
  function objectFlip(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => (acc[value] = key, acc), {});
  }

  let { name, viewText, enumArray } = field;
  const SUFFIXMAP = { "גדול מתאריך": "gt", "קטן מתאריך": "lt", "בחר תאריך": "equals", "בחר טווח": "range" };
  const reversedSuffixMap = objectFlip(SUFFIXMAP);
  const NAMEARRAY = ["קטן מתאריך", "גדול מתאריך", "בחר תאריך", "בחר טווח"];
  const { formData, initialFormData } = useContext(FormContext);
  const [selectedOption, setSelectedOption] = useState(undefined);

  const selectedOptionInitLogic = () => {
    let foundOption;
    if (initialFormData[name + "_gt"] !== undefined && initialFormData[name + "_lt"] !== undefined) {
      foundOption = reversedSuffixMap["range"];
    } else if (initialFormData[name + "_gt"] !== undefined) {
      foundOption = reversedSuffixMap["gt"];
    } else if (initialFormData[name + "_lt"] !== undefined) {
      foundOption = reversedSuffixMap["lt"];
    } else if (initialFormData[name + "_custom"] !== undefined) {
      foundOption = enumArray.find(obj => obj.id === initialFormData[name + "_custom"]).header;
    } else if (initialFormData[name]) {
      foundOption = reversedSuffixMap["equals"];
    }
    setSelectedOption(foundOption ? { value: foundOption, label: foundOption } : null);
  }

  useEffect(() => {
    selectedOptionInitLogic()
  }, [initialFormData])

  const handleSelectedOptionChange = (selectedValueObject) => {
    if (selectedValueObject?.value === selectedOption?.value) return;
    let newFormData = removeFieldVariationsFromObject(formData, name);
    if (!selectedValueObject) {
      setSelectedOption(null);
      newFormData = { ...newFormData, [name]: "" };
      setFormData(newFormData);
    } else {
      setSelectedOption(selectedValueObject)
      switch (selectedValueObject.value) {
        case reversedSuffixMap["equals"]:
          newFormData = { ...newFormData, [name]: "" };
          setFormData(newFormData);
          break;
        case reversedSuffixMap["range"]:
          newFormData = { ...newFormData, [name + "_lt"]: "", [name + "_gt"]: "" };
          setFormData(newFormData);
          break;
        case reversedSuffixMap["lt"]:
        case reversedSuffixMap["gt"]:
          newFormData = { ...newFormData, [`${name}_${SUFFIXMAP[selectedValueObject.value]}`]: "" };
          setFormData(newFormData);
          break;

        default:
          const foundCustom = enumArray.find(obj => obj.id === selectedValueObject.value)?.id;
          if (foundCustom) {
            newFormData = { ...newFormData, [`${name}_custom`]: selectedValueObject.value };
            setFormData(newFormData);
          }
          break;
      }
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  return (

    <div className="col-6 col-md-4 col-lg-2 mb-1">
      <label className="form-label" htmlFor={name}>{viewText}</label>
      <div>
        <Select
          value={selectedOption}
          name={name}
          onChange={handleSelectedOptionChange}
          options={[...NAMEARRAY.map((item) => ({ value: item, label: t(item) })), ...enumArray.map((item) => ({ value: item.id, label: t(item.header) }))]}
          placeholder={`${t('Choose')} ${t('Search option')}`}
          isClearable />
        <div>
          {selectedOption && SUFFIXMAP[selectedOption.value] === 'equals' && <>
            <input type={'date'} className="p-2 form-control" onChange={handleChange} value={formData[name]} name={name} />
          </>}
          {selectedOption && SUFFIXMAP[selectedOption.value] === 'range' && <>
            <input type={'date'} className="p-2 form-control" onChange={handleChange} value={formData[name + '_gt']} name={name + '_gt'} />
            <input type={'date'} className="p-2 form-control" onChange={handleChange} value={formData[name + '_lt']} name={name + '_lt'} />
          </>}
          {selectedOption && (SUFFIXMAP[selectedOption.value] === 'lt' || SUFFIXMAP[selectedOption.value] === 'gt') && <>
            <input type={'date'} className="p-2 form-control" onChange={handleChange} value={formData[`${name}_${SUFFIXMAP[selectedOption.value]}`]} name={`${name}_${SUFFIXMAP[selectedOption.value]}`} />
          </>}
        </div>
      </div>
    </div>
  )
}


const BoolToogleFilter = ({ field, type, setFormData, isNull, t }) => {
  let { name, viewText } = field;
  const { formData } = useContext(FormContext)
  const nameVal = isNull ? name + '_null' : name;
  const trueBool = formData[nameVal] === 'true' ? true : false;
  const falseBool = formData[nameVal] === 'false' ? true : false;
  let irrelevantBool = trueBool || falseBool ? false : true
  const handleIrrelevant = () => {
    irrelevantBool = true;
    setFormData({ ...formData, [nameVal]: "" })
  };
  return (

    <div className="col-6 col-md-4 col-lg-2 mb-1">
      <label className="form-label" htmlFor={nameVal}>{t(viewText)}</label>
      <br></br>
      <span style={{ marginLeft: '15px' }}>
        {t("yes")}<input type={type} value={formData[nameVal]} name={nameVal} checked={trueBool} onClick={() => setFormData({ ...formData, [nameVal]: "true" })} />
      </span>
      <span style={{ marginLeft: '15px' }}>
        {t("no")}<input type={type} value={formData[nameVal]} name={nameVal} checked={falseBool} onClick={() => setFormData({ ...formData, [nameVal]: "false" })} />
      </span>
      <span>
        {t("irrelevant")}<input type={type} value={formData[nameVal]} name={nameVal} checked={irrelevantBool} onClick={handleIrrelevant} />
      </span>
    </div>
  )
}

const DynamicNumberFilter = ({ t, field, setFormData }) => {
  function objectFlip(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => (acc[value] = key, acc), {});
  }

  let { name, viewText } = field;
  const SUFFIXMAP = { "גדול ממספר": "gt", "קטן ממספר": "lt", "בחר מספרים מרובים": "many", "בחר טווח": "range" };
  const reversedSuffixMap = objectFlip(SUFFIXMAP);
  const NAMEARRAY = ["קטן ממספר", "גדול ממספר", "בחר מספרים מרובים", "בחר טווח"];
  const [numberManyInput, setNumberManyInput] = useState('');
  const { formData, initialFormData } = useContext(FormContext);
  const [selectedOption, setSelectedOption] = useState(undefined);

  const selectedOptionInitLogic = () => {
    let foundOption;
    if (initialFormData[name + "_gt"] !== undefined && initialFormData[name + "_lt"] !== undefined) {
      foundOption = reversedSuffixMap["range"];
    } else if (initialFormData[name + "_gt"] !== undefined) {
      foundOption = reversedSuffixMap["gt"];
    } else if (initialFormData[name + "_lt"] !== undefined) {
      foundOption = reversedSuffixMap["lt"];
    } else if (initialFormData[name] !== undefined && Array.isArray(initialFormData[name]) && initialFormData[name].length) {
      foundOption = reversedSuffixMap["many"];
    }
    setSelectedOption(foundOption ? { value: foundOption, label: foundOption } : null);
  }

  useEffect(() => {
    selectedOptionInitLogic()
  }, [initialFormData])

  const handleSelectedOptionChange = (selectedValueObject) => {
    if (selectedValueObject?.value === selectedOption?.value) return;
    let newFormData = removeFieldVariationsFromObject(formData, name);
    setNumberManyInput('')
    if (!selectedValueObject) {
      setSelectedOption(null);
      newFormData = { ...newFormData, [name]: "" };
      setFormData(newFormData);
    } else {
      setSelectedOption(selectedValueObject)
      switch (selectedValueObject.value) {
        case reversedSuffixMap["many"]:
          newFormData = { ...newFormData, [name]: [] };
          setFormData(newFormData);
          break;
        case reversedSuffixMap["range"]:
          newFormData = { ...newFormData, [name + "_lt"]: "", [name + "_gt"]: "" };
          setFormData(newFormData);
          break;

        default:
          newFormData = { ...newFormData, [`${name}_${SUFFIXMAP[selectedValueObject.value]}`]: "" };
          setFormData(newFormData);
          break;
      }
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleArrayAdd = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: [...new Set([...formData[e.target.name], numberManyInput])] });
    setNumberManyInput('');
  }

  const handleArrayRemove = (e) => {
    e.preventDefault();
    const newArray = formData[e.target.name].filter(arrValue => arrValue !== e.target.value);
    setFormData({ ...formData, [e.target.name]: newArray });
  }

  return (
    <>
      <div className="col-6 col-md-4 col-lg-2 mb-1">
        <label className="form-label" htmlFor={name}>{t(viewText)}</label>
        <div>
          <Select
            value={selectedOption}
            name={name}
            onChange={handleSelectedOptionChange}
            options={NAMEARRAY.map((item) => ({ value: item, label: t(item) }))}
            placeholder={`${t('Choose')} ${t('Search option')}`}
            isClearable
          />
          {selectedOption && (SUFFIXMAP[selectedOption.value] === 'many' ?
            <div>
              <input type="number" value={numberManyInput ? numberManyInput : ''} name={name} onChange={(e) => setNumberManyInput(e.target.value)} placeholder={`${t('input')} ${t(viewText)}`} />
              <Button name={name} onClick={handleArrayAdd}>{t("add to list")}</Button>
            </div> :
            SUFFIXMAP[selectedOption.value] === 'range' ? <div style={{ display: 'inline-flex' }}>
              <input type='number' className=" p-2 form-control" value={formData[name + `_lt`]} name={name + `_lt`} onChange={handleChange} placeholder={t('input end of range')} />
              <input type='number' className=" p-2 form-control" value={formData[name + `_gt`]} name={name + `_gt`} onChange={handleChange} placeholder={t('input start of range')} />
            </div> :
              <input
                type='number'
                className=" p-2 form-control"
                value={formData[name + `_${SUFFIXMAP[selectedOption.value]}`]}
                name={name + `_${SUFFIXMAP[selectedOption.value]}`}
                onChange={handleChange}
                placeholder={`${t('input')} ${t(viewText)}`} />)}
          <ButtonGroup variant='flush' size='sm' className="me-2" style={{ flexWrap: 'wrap' }}>
            {Array.isArray(formData[name]) && formData[name].length > 0 && formData[name].map((enumVal, idx) => enumVal ? <Button variant="primary" className="m-1" name={name} value={enumVal} key={idx} onClick={handleArrayRemove}>{'\u2715 ' + enumVal}</Button> : '')}
          </ButtonGroup>
        </div>
      </div>
    </>
  )
}

const TextManyFilter = ({ field, setFormData, isPartial }) => {
  let { name, viewText } = field;
  const { formData } = useContext(FormContext);
  const nameValue = isPartial ? name + "_contains" : name
  const { t } = useTranslation()

  return (
    <>
      <div className="col-6 col-md-4 col-lg-2 mb-1">
        <label className="form-label" htmlFor={nameValue}>{t(viewText)}</label>
        <CreatableSelect
          styles={customStyles}
          value={!formData[nameValue] ? null : formData[nameValue]}
          name={nameValue}
          isMulti
          onChange={selectedValueObject => {
            setFormData({ ...formData, [nameValue]: selectedValueObject })
          }}
          options={formData[nameValue] ? formData[nameValue] : null}
          placeholder={`${t('Choose')} "${t(viewText)}"`}
          isClearable
        >
        </CreatableSelect>
      </div>
    </>
  )
}