import React, { useState } from 'react';
import SendOneMessageModal from '../../config/dynamicTable/leads/modals/sendOneMessageModal';
import SendOneMessageModalError from '../../config/dynamicTable/leads/modals/sendOneMessageModalError';
import { sendDataResponse } from '../../services/postServices';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getTemplates } from './services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
export default function MessageModal({ meetingCardObject, isIcon }) {
  const { t, i18n } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowError, setModalShowError] = useState(false)
  const [leadMsgTemplates, setLeadMsgTemplates] = useState([])
  const [templateId, setTemplateId] = useState(null);
  const [failedPayload, setFailedPayload] = useState(null);


  const { lead } = meetingCardObject;
  const { id: meetingId } = meetingCardObject

  const sendTemplateMsg = async (leadId, templateId) => {
    if (!templateId) {
      toast.warn("בחר תבנית לשליחת הודעה")
      return;
    }
    try {
      const data = await sendDataResponse('sendOneMessage', {
        leadId,
        templateId: parseInt(templateId),
        meetingId
      }, undefined, true);

      if (!data || data.status === false) {
        setFailedPayload(data)
        toast.warning("הודעה נכשלה")
        setTemplateId(templateId)
        setModalShowError(true)
        setModalShow(false)

      } else if (data.status === true) {
        toast.success("הודעה נשלחה בהצלחה")
        setModalShow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sendCustomMsg = async (leadId, messageContent) => {
    try {
      const data = await sendDataResponse('sendOneMessageCustom', {
        leadId,
        messageContent
      }, undefined, true);
      if (!data || data.status === false) {
        setFailedPayload(data)
        toast.warning("הודעה נכשלה")
        setModalShowError(true)
        setModalShow(false)
      } else if (data.status === true) {
        toast.success("הודעה נשלחה בהצלחה")
        setModalShow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const sendForceTemplateMsg = async (leadId, templateId) => {
    try {
      const data = await sendDataResponse('forceSendOneMessage', {
        leadId,
        templateId: parseInt(templateId),
        meetingId
      }, undefined, true);

      if (!data || data.status === false) {
        setFailedPayload(data);
        toast.warning("הודעה נכשלה");
        setModalShowError(true);
        setModalShow(false);
      } else if (data.status === true) {
        toast.success("הודעה נשלחה בהצלחה");
        setModalShowError(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  return <div className={isIcon ? "" : "mb-1 mt-1"}>
    <SendOneMessageModal
      modalShow={modalShow}
      setModalShow={setModalShow}
      title={`שליחת הודעה ליד ${lead.id}`}
      sendOneMsg={sendTemplateMsg}
      sendOneMsgCustom={sendCustomMsg}
      leadMsgTemplates={leadMsgTemplates}
      lead={lead}
    />

    <SendOneMessageModalError
      modalShow={modalShowError}
      setModalShow={setModalShowError}
      title="שליחת הודעה נכשלה"
      leadId={lead.id}
      templateId={templateId}
      sendOneMsg={sendForceTemplateMsg}
      failedPayload={failedPayload}
    />
    <Button variant={isIcon ? 'none' : 'primary'} onClick={(() => {
      setModalShow(true);
      getTemplates(setLeadMsgTemplates);
    })} >
      {isIcon ? <FontAwesomeIcon data-delay-hide='100' data-delay-show='500' data-tip="שליחת הודעה" icon={faEnvelope} className="send-message_icon" size="lg" /> : t('send message')}
    </Button>
  </div>;
}
