import React from 'react'
import DynamicTableContainer from '../../components/dynamicTable/dynamicTableContainer';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function FormPage() {
    const { t, i18n } = useTranslation();

    const handleTableRowDoubleClick = (e, TableRow, history, blank) => {
        e.preventDefault()
        let formId = TableRow.id;
        blank ? window.open(window.location.href.split("?")[0] + `/submissions/${formId}`, '_blank') : history.push("/forms/submissions/" + formId)

    }


    return (
        <>
            <DynamicTableContainer
                pagesRange={4}
                initialEndpoint="forms"
                perPage={10}
                handleTableRowDoubleClick={handleTableRowDoubleClick}
                schemaName={{ TABLE_API: "forms/TableSchema?schema_name=formsPage", FORM_API: "forms/filterSchema?schema_name=formsPage" }}
            />
        </>
    )
}