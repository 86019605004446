import moment from "moment";
import { Link } from "react-router-dom";
import { WazeLink } from "../../components/WazeLink/WazeLink";
import { PhoneLink } from "../../components/PhoneLink/PhoneLink";

const userDataObject = {
  userDetailsObject: {
    username: {
      Header: "שם משתמש",
      type: "text",
      accessor: "username",
    },
    full_name: {
      Header: "שם",
      type: "text",
      custom: (user) => `${user.contact.first_name} ${user.contact.last_name}`,
    },
    first_name: {
      Header: "שם פרטי",
      type: "text",
      accessor: "contact.first_name",
    },
    last_name: {
      Header: "שם משפחה",
      type: "text",
      accessor: "contact.last_name",
    },
    phone_number: {
      Header: "טלפון",
      accessor: "contact.phone_number",
      type: "tel",
      custom: (user) => {
        return PhoneLink({
          phoneNumber: user.contact.phone_number,
        });
      },
    },
    email: {
      Header: 'דוא"ל',
      accessor: "email",
      type: "text",
    },
    link_address: {
      Header: "פרטי כתובת",
      type: "text",
      custom: (user) => {
        return WazeLink({
          city: user.contact.primary_address_city, street: user.contact.street_c, houseNumber: user.contact.house_number_c,
          displayText: user.contact.primary_address_city,
        });
      },
    },
    address: {
      Header: "כתובת",
      type: "text",
      custom: (user) =>
        `${user.contact.street_c || ""} ${user.contact.house_number_c || ""}, ${user.contact.primary_address_city || ""
        }`,
    },
    primary_address_city: {
      Header: "עיר",
      accessor: "contact.primary_address_city",
      type: "text",
    },
    street_c: {
      Header: "רחוב",
      accessor: "contact.street_c",
      type: "text",
    },
    house_number_c: {
      Header: "מספר בית",
      accessor: "contact.house_number_c",
      type: "text",
    },
    role: {
      Header: "תפקיד",
      type: "text",
      accessor: "role.name",
      custom: (user) => user.role.name,
    },
    blocked: {
      Header: "משתמש פעיל",
      type: "text",
      accessor: "blocked",
      custom: (user) => (user.blocked ? "לא" : "כן"),
    },
    created_at: {
      Header: "הצטרף בתאריך",
      accessor: "created_at",
      type: "date",
      custom: (user) => `${moment(user.created_at).format("DD/MM/YY")}`,
    }
  },
  userStatsObject: {
    leadsCreated: {
      Header: "leadsCreated",
      accessor: "leadsCreated",
    },
    appointedMeetings: {
      Header: "appointedMeetings",
      accessor: "appointedMeetings",
    },
    finishedMeetings: {
      Header: "finishedMeetings",
      accessor: "finishedMeetings",
    },
    tasksFinished: {
      Header: "tasksFinished",
      accessor: "tasksFinished",
    },
    avgActionsPerDay: {
      Header: "avgActionsPerDay",
      custom: (data) => data.avgActionsPerDay.toFixed(2),
    },
    avgMeetingsPerDay: {
      Header: "avgMeetingsPerDay",
      custom: (data) => data.avgMeetingsPerDay.toFixed(2),
    },
    workDays: {
      Header: "workDays",
      accessor: "workDays",
    },
  }
};

export default userDataObject;
