import React from 'react'
import { Link } from "react-router-dom";

import VerticallyCenteredModal from '../../../../components/modal/VerticallyCenteredModal'
import LeadScenario from '../../../../components/leadScenario/leadScenario'
import { useTranslation } from 'react-i18next';
// send a messages to all leads that have lack in details for sending a template message
export default function SendManyMessagesModalError({
  modalShow,
  setModalShow,
  title,
  sendMessages,
  failedLeadIds,
  blockedLeadIds,
  templateId,
  missingFieldsSentLeads,
  missingFieldsSentMeetingleads,
  bestWorstLead
}) {
  if (bestWorstLead === null) {

  }
  const { t, i18n } = useTranslation();

  return (
    <div>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        title={title}
        onDoubleClick={(e) => { e.stopPropagation() }}
      >
        {
          blockedLeadIds && blockedLeadIds.length > 0 &&
          <>
            <div className="text-danger my-3" >
              {t('blocked messages message')}
            </div>
            <div>
              {t('blocked leads list')}
            </div>
            <div>
              {blockedLeadIds.map((leadId, index) => {
                return (
                  <Link target="_blank" to={`/leads/${leadId}`} key={index}>{leadId}</Link>
                )
              })}
            </div>
            <br />
          </>
        }
        {
          failedLeadIds && failedLeadIds.length > 0 &&
          <>
            <div className="text-danger my-3" >
              {t('bad messages message')}
            </div>
            <div>
              {t('problematic leads list')}
            </div>
            <div>
              {failedLeadIds.map((leadId, index) => {
                return (
                  <Link target="_blank" to={`/leads/${leadId}`} key={index}>{leadId}</Link>
                )
              })}
            </div>
            <br />
          </>
        }
        {
          bestWorstLead && missingFieldsSentLeads &&
          <>
            <div className="text-danger my-3" >
              {t('missing fields message')}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <LeadScenario failedObject={bestWorstLead.bestLackingLead} msg={`${t('best lacking lead')} (${bestWorstLead.bestLackingLead.missingWords.length})`} />
              <LeadScenario failedObject={bestWorstLead.worstLackingLead} msg={`${t('worst lacking lead')} (${bestWorstLead.worstLackingLead.missingWords.length})`} />
            </div>
            <div>
              {t('problematic leads list')}
            </div>
            <div>
              {missingFieldsSentLeads.map((leadId, index) => {
                return (
                  <Link target="_blank" to={`/leads/${leadId}`} key={index}>{leadId}</Link>
                )
              })}
            </div>
            <button className="btn btn-warning" onClick={() => sendMessages(missingFieldsSentLeads, templateId, missingFieldsSentMeetingleads)}>{t('send') + " " + t('anyway')}</button>
          </>
        }
      </VerticallyCenteredModal>
    </div >
  )
}