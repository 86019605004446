import React from 'react'
import DynamicTableContainer from '../../components/dynamicTable/dynamicTableContainer';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function LeadsourcesPage(props) {
	const { t, i18n } = useTranslation();
	let history = useHistory()

	const handleTableRowDoubleClick = (e, TableRow, history, blank) => {
		e.preventDefault()
		let leadId = TableRow.lead.id;
		blank ? window.open(`/leads/${leadId}`, '_blank') : history.push(`/leads/${leadId}`)
	}



	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px auto', width: '18%' }}  >
				<Button disabled={true}>{t('leadsources')}</Button>
				<Button onClick={(() => history.push(`/leads`))}>{t('leads')}</Button>
			</div>
			<DynamicTableContainer
				pagesRange={4}
				initialEndpoint="leadsources"
				perPage={50}
				handleTableRowDoubleClick={handleTableRowDoubleClick}
				schemaName={{ TABLE_API: "leadsources/TableSchema?schema_name=leadsourcesPage", FORM_API: "leadsources/filterSchema?schema_name=leadsourcesPage" }}
			/>
		</>
	)
}

