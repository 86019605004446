import { translateToHebrew } from "./enumTransaltor";
import moment from "moment";
const convertToLeadObject = (detailedObj1) => {
    let object = {};
    for (const key1 of Object.keys(detailedObj1)) {
        const detailedObj2 = detailedObj1[key1];
        for (const key2 of Object.keys(detailedObj2)) {
            const detailedObj3 = detailedObj2[key2];
            for (const key3 of Object.keys(detailedObj3)) {
                if (key3 === "value") {
                    object = {
                        ...object,
                        [key2]: detailedObj3[key3]

                    }
                }

            }
        }

    }
    return object;
}
const controlMeetingObject = (meetingObject, format) => {
    const returnedArray = [];
    let arrayOfObjects
    switch (format) {
        case "meeting":
            meetingObject = meetingObject[0]
            arrayOfObjects = meetingObject.meeting_leads;
            break;

        case "meeting_leads":
            arrayOfObjects = meetingObject;
            break;


        default:
            break;
    }
    for (let i = 0; i < arrayOfObjects.length; i++) {
        const thisObject = {
            ...arrayOfObjects[i],
            meeting_type_c: translateToHebrew(arrayOfObjects[i].meeting_type_c),
            created_at: moment(arrayOfObjects[i].created_at).format('DD/MM/YY hh:mm:ss a'),
            specific_datetime: moment(arrayOfObjects[i].specific_datetime).format('DD/MM/YY hh:mm:ss a')
        }
        returnedArray.push(thisObject);
    }
    switch (format) {
        case "meeting":
            return {
                ...meetingObject,
                meeting_leads: returnedArray,
            };
        case "meeting_leads":
            return returnedArray;

        default:
            break;
    }

}

export {
    convertToLeadObject,
    controlMeetingObject
}