import { getItemFromLocalStorage } from "../../helpers/auth";
import meetingLeadObject from "./main";

const organizeMeetingLeadPage = (meetinglead) => {
  // set meetinglead fields values
  Object.values(meetingLeadObject).forEach((field) => {
    if (field.accessor) {
      field.value = meetinglead[field.accessor];
    }
    // boolean type nnd falsy
    if (field.custom) {
      field.customValue = () => field.custom(meetinglead);
    }
    if (field.value === null || field.value === undefined) {
      field.value = "אין";
    }
  });

  const meetingLeadPageData = {
    meetingleadPageOrganizerDemo: [
      {
        Header: "מידע על הפגישה",
        fields: [
          meetingLeadObject.meeting_type_c,
          meetingLeadObject.date,
          meetingLeadObject.time,
          meetingLeadObject.completed,
          meetingLeadObject.create_by,
          meetingLeadObject.update_by,
          meetingLeadObject.specific_comment,
        ],
      },
      {
        Header: "מידע על הליד",
        fields: [
          meetingLeadObject.full_name,
          meetingLeadObject.phone_number_link,
          meetingLeadObject.link_address,
        ],
      },
    ],
    meetingleadPageOrganizerFirstSteps: [
      {
        Header: "מידע על הפגישה",
        fields: [
          meetingLeadObject.meeting_type_c,
          meetingLeadObject.date,
          meetingLeadObject.time,
          meetingLeadObject.completed,
          meetingLeadObject.create_by,
          meetingLeadObject.update_by,
          meetingLeadObject.specific_comment,
          meetingLeadObject.filled_survey,
          meetingLeadObject.custom_survey_url,
        ],
      },
      {
        Header: "מידע על הליד",
        fields: [
          meetingLeadObject.full_name,
          meetingLeadObject.phone_mobile_link,
          meetingLeadObject.phone_work_link,
          meetingLeadObject.link_address,
          meetingLeadObject.babys_birth_date_c,
          meetingLeadObject.hospital,
          meetingLeadObject.hmo,
        ],
      },
    ]
  };

  return meetingLeadPageData[getItemFromLocalStorage("featureflags") ? getItemFromLocalStorage("featureflags").dataOrganizers.meetinglead.pageOrganizer : "meetingleadPageOrganizerDemo"];
};

export default organizeMeetingLeadPage;
