import React from "react";
import DatePicker from "react-datepicker";


export default function StartDatePicker({ customDate, setCustomDate, maxDate }) {

  const handleChange = (date) => {
    setCustomDate({
      ...customDate,
      startDate: date
    })
  }

  return (
      <DatePicker dateFormat="DD-MM-YYYY" maxDate={maxDate} selected={customDate.startDate} onChange={(date) => handleChange(date)}/>
  )
}