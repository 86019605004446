import { getData } from "../../services/getServices";

export const getTemplates = async (setLeadMsgTemplates) => {
    try {
        let leadMsgTemplates = await getData(
            `templates?meeting_related=true`
        );
        if (Array.isArray(leadMsgTemplates) && leadMsgTemplates.length) {
            setLeadMsgTemplates(leadMsgTemplates);
        }
    } catch (error) {
        console.log(error);
    }
};
