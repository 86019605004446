import React, { useState } from 'react'
import Field from '../../helpers/dynamicTable/form/formField'
import { FormContext } from '../../helpers/dynamicTable/form/formContext'
import { toast } from 'react-toastify';
import { checkPageNumber, checkSortValue, createCleanUrlQuery, createDefaultFormData, createFetchCountQueries } from '../../helpers/dynamicTable/form/form';
import { useTranslation } from 'react-i18next';
import { sendDataResponse } from '../../services/postServices';
import VerticallyCenteredModal from '../../components/modal/VerticallyCenteredModal';
import { getData } from '../../services/getServices';

export default function SearchForm({
  setFormData,
  setInitialFormData,
  setIsLoadingData,
  setData,
  setAllDataRecords,
  setPagesNumber,
  setCurrentPage,
  setSorter,
  setRawQuery,
  formSchema,
  formSettings,
  formData,
  initialFormData,
  defaultFilters,
  tableSettings,
  sorter,
  paramsObj,
  rawQuery,
  perPage,
  countOverride,
  initialEndpoint,
  history
}) {
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  const [reportData, setReportData] = useState(null);

  const onSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      const urlFiltering = formSettings.urlFiltering;
      setIsLoadingData(true);
      setInitialFormData(formData);
      const { partialFetchQuery, countQuery } = createFetchCountQueries(formData, defaultFilters, formSchema, paramsObj, initialEndpoint, countOverride);
      const dataRecords = await getData(countQuery);
      const pagesNumber = Math.ceil(dataRecords / perPage) || 1;
      setAllDataRecords(dataRecords);
      setPagesNumber(pagesNumber);
      const pageNumber = checkPageNumber(1, pagesNumber);
      setCurrentPage(pageNumber);
      let fetchQuery = `${partialFetchQuery}_start=${(pageNumber - 1) * perPage}&_limit=${perPage}&`;

      const sortValue = checkSortValue(sorter, formSchema);
      setSorter(sortValue);
      fetchQuery = sortValue ? `${fetchQuery}_sort=${sortValue}` : fetchQuery.slice(0, -1);
      setRawQuery(fetchQuery);
      let tableRecords = await getData(fetchQuery);
      setData(tableRecords);
      setIsLoadingData(false);
      if (urlFiltering) {
        const newUrlQuery = createCleanUrlQuery(formData, formSchema, pageNumber, sortValue);
        window.history.replaceState(null, "", history.location.pathname + newUrlQuery)
      }
      return;
    } catch (error) {
      console.log("error", error);
      setIsLoadingData(false);
    }
  };

  const resetForm = async () => {
    try {
      const urlFiltering = formSettings.urlFiltering;
      setIsLoadingData(true);
      const defaultFormData = createDefaultFormData(formSchema);
      setFormData(defaultFormData);
      setInitialFormData(defaultFormData);
      const { partialFetchQuery, countQuery } = createFetchCountQueries(defaultFormData, defaultFilters, formSchema, paramsObj, initialEndpoint, countOverride);

      const dataRecords = await getData(countQuery);
      const pagesNumber = Math.ceil(dataRecords / perPage) || 1;
      setAllDataRecords(dataRecords);
      setPagesNumber(pagesNumber);
      const pageNumber = checkPageNumber(1, pagesNumber);
      setCurrentPage(pageNumber);
      let fetchQuery = `${partialFetchQuery}_start=${(pageNumber - 1) * perPage}&_limit=${perPage}&`;

      const sortValue = checkSortValue(undefined, formSchema);
      setSorter(sortValue);
      fetchQuery = sortValue ? `${fetchQuery}_sort=${sortValue}` : fetchQuery.slice(0, -1);
      setRawQuery(fetchQuery);
      let tableRecords = await getData(fetchQuery);
      setData(tableRecords);
      setIsLoadingData(false);
      if (urlFiltering) {
        window.history.replaceState(null, "", history.location.pathname)
      }
    } catch (error) {
      console.log("error", error);
      setIsLoadingData(false);
    }
  }

  const createReport = async () => {
    const thisReportData = {
      report_type: reportData.report_type,
      report_name: reportData.report_name,
      form_id: reportData.form_id
    }
    const res = await sendDataResponse(reportData.endpoint, thisReportData);
    if (res?.data.id) {
      toast.success(`${t('created report')} ${res?.data.id}`);
      history.push("/reports");
    } else if (res?.status === 400) {
      toast.success(res?.message);
    }
  }

  const openReport = async () => {
    const splitQuery = rawQuery.split('?');
    const arrayOfQueries = splitQuery[1].split('&');
    let refinedQuery = "", firstQuery = true;
    arrayOfQueries.forEach(singleQuery => {
      const queryKey = singleQuery.split("=")[0];
      if (queryKey === "_start" || queryKey === "_limit") return;
      if (firstQuery) {
        refinedQuery = `?${singleQuery}`;
        firstQuery = false;
        return;
      }
      refinedQuery = `${refinedQuery}&${singleQuery}`;
    });
    let report_type, form_id;
    if (splitQuery[0].includes("leadsources")) {
      report_type = `leadsource`;
    } else if (splitQuery[0].includes("meetingleads")) {
      report_type = `meetinglead`;
    } else if (splitQuery[0].includes("leads")) {
      report_type = `lead`;
    } else if (splitQuery[0].includes("forms/submissions")) {
      const containsId = splitQuery[0].split('/');
      form_id = containsId.at(-1);
      report_type = `formsubmission`;
    }
    const endpoint = `reports${refinedQuery}`;
    setReportData({
      endpoint,
      report_type,
      form_id,
      report_name: ""
    });
    setModalShow(true);
  }


  return (
    <>
      {reportData && <VerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setReportData(null);
        }}
        title={t('create report')}
        onDoubleClick={(e) => { e.stopPropagation() }}
      >
        <div>
          <h2 className="h5 my-3">{t("query")}</h2>
          <pre className="border border-1 border-secondary p-3">
            <bdi>
              {reportData.endpoint}
            </bdi>
          </pre>
          <h2 className="h5 my-3">{t('report_type')}: {reportData.report_type}</h2>
          <label>{t('report_name')}</label>
          <input onChange={(e) => {
            setReportData({
              ...reportData,
              report_name: e.target.value
            });
          }} type="text" />
        </div>
        <button disabled={!reportData.report_name || reportData.report_name.length < 1} className="btn btn-primary rounded mt-4" onClick={createReport}>{t('submit')}</button>
      </VerticallyCenteredModal>
      }

      <FormContext.Provider value={{ formData, initialFormData }}>
        <form className="p-3" onSubmit={onSubmit}>
          <div className="row">
            {formSchema.map((field, i) => <Field key={i} field={field} setFormData={setFormData} />)}
          </div>
          <button className="btn btn-primary rounded ms-2" onSubmit={onSubmit}>{t('search')}</button>
          <button className="btn btn-primary rounded ms-2" type="button" onClick={resetForm}>{t('reset')}</button>
          {
            tableSettings?.createReportButton &&
            <button className="btn btn-primary rounded" type="button" onClick={openReport}>{t('create report')}</button>
          }
        </form>
      </FormContext.Provider>
    </>
  )
}

