import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import MessageModal from '../../../components/meeting_message_modal/MessageModal';
import SpecificMeetingDetails from '../../../components/SpecificMeetingDetails/SpecificMeetingDetails';
import RelateLead from '../../../components/relate_lead_modal/RelateLead';

export default function MeetingInfo({ meetingCardObject, setCard }) {
    const { t } = useTranslation();

    const handleCloseMeetingInfo = () => {
        setCard(null);
    }


    return <AnimatePresence >
        <motion.div key={meetingCardObject} initial={{ opacity: 0 }} animate={{ opacity: 1 }} >
            <div className="container-fluid">
                <div className="right col">
                    <Button className="mb-1 mt-1" variant="primary" onClick={handleCloseMeetingInfo} >{t('Return to instructor page')}</Button>
                    <MessageModal meetingCardObject={meetingCardObject}></MessageModal>
                    <RelateLead relatedLeadId={meetingCardObject.lead.id}></RelateLead>
                </div>
            </div>
            <SpecificMeetingDetails meetingLead={meetingCardObject} />
        </motion.div>
    </AnimatePresence >
}
