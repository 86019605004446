import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardDeck, Button, ButtonGroup } from 'react-bootstrap/'
import { getData } from '../../services/getServices';
import { updateData } from "../../services/putServices";
import { deleteData } from "../../services/deleteServices";
import MeetingForm from '../../components/meeting_form/meetingForm'
import { toast } from 'react-toastify';
import Table from '../../components/dynamicTable/table'
import { API } from "../../services/endPointConst/constants";
import { genTableColumnsSchemaList } from "../../helpers/dynamicTable/table/table";
import { ModalContext } from "../../context/ModalProvider";
import NotFoundPage from "../404page/notFoundPage";

function MeetingPage() {

  const { id } = useParams();
  const [meeting, setMeeting] = useState(null)
  const [tableColumnsSchema, setTableColumnsSchema] = useState([])
  let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);
  const [isNotFound, setIsNotFound] = useState(false);

  let history = useHistory();


  const deleteMeeting = async () => {
    try {
      const res = await deleteData(`meetings/nestedDelete/${id}`, undefined, true);
      if (res.id) {
        toast.success("פגישה נמחקה בהצלחה");
        history.push("/meetings");
        setIsModalOpen(null);
      } else {
        throw res;
      }
    } catch (err) {
      console.log(err);
    }
  }
  const updateMeeting = async (updatedMeeting) => {
    try {
      const res = await updateData(`meetings/${id}`, updatedMeeting)
      toast.success("המידע עודכן!")

    } catch (err) {
      toast.error("אופס! הייתה שגיאה!")

      console.log(err)
    }
  }

  const getTableColumnSchema = async () => {
    try {
      let urlApi = `meetingleads/TableSchema?schema_name=meetingLeadsInMeetingPage`
      let columnsSchema = await getData(urlApi)
      setTableColumnsSchema(genTableColumnsSchemaList(columnsSchema.visible))

    } catch (error) {
      console.log(error);
    }

  }


  useEffect(() => {
    getTableColumnSchema()
    getData(`${API.MEETINGS}/${id}`, setMeeting);
    if (meeting?.response?.status === 404) {
      setIsNotFound(true)
    }
  }, []);

  const renderDataCard = () => {
    return (
      <Card border="primary" style={{ margin: "auto", width: "fit-content" }}>
        <Card.Header>מידע על הפגישה</Card.Header>
        <Card.Body style={{ width: "fit-content !important" }}>
          <MeetingForm
            initialMeetingObj={meeting}
            updateOrPostFunc={updateMeeting}
            updateOrPostText="עדכון"
          />
          <br />
        </Card.Body>
        <Card.Header>לידים משוייכים</Card.Header>
        <Card.Body style={{ width: "fit-content !important" }}>
          {meeting?.meeting_leads?.length > 0 &&
            <Table
              data={meeting.meeting_leads}
              columns={tableColumnsSchema}
            />
          }

        </Card.Body>
      </Card>
    )
  }
  if (isNotFound) {
    return <NotFoundPage />
  }
  return (
    <div className="lead-page" style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
      <ButtonGroup className="mb-2" >
        <Button variant="primary" onClick={() => history.push(`/meetings/edit/${id}`)}>עריכה</Button>
        <Button variant="danger" onClick={() => setIsModalOpen({
          title: 'אזהרה',
          msg: `האם למחוק פגישה מספר ${id}`,
          action: () => deleteMeeting()
        })}>מחיקה</Button>
      </ButtonGroup>
      {(meeting && meeting.meeting_leads) &&
        <CardDeck className="lead-page_main" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          {renderDataCard()}
        </CardDeck>}
    </div>
  )
}

export default MeetingPage