import { getItemFromLocalStorage } from "./auth";

export const isUserRole = (roleToCheck) => {
  const user = getItemFromLocalStorage("user");
  return user ? user.role?.name === roleToCheck : undefined;
}

export const getUserFullName = (firstName = "", lastName = "") => {
  let fullName = firstName
  if (lastName) {
    fullName += " " + lastName
  }
  return fullName;
}