import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendDataResponse } from '../../../../services/postServices';
import { deleteData } from '../../../../services/deleteServices';
import { getTemplates } from '../services';
import SendManyMessagesModal from '../../leads/modals/sendManyMessagesModal';
import SendManyMessagesModalError from '../../leads/modals/sendManyMessagesModalError';
import { ModalContext } from '../../../../context/ModalProvider';
import { useTranslation } from 'react-i18next'
import { isUserRole } from '../../../../helpers/user';

export const GroupedPageTableSelectManyActions = ({ selectedRows, deleteManyCallback, data: orignalData }) => {
    const { t } = useTranslation();
    let history = useHistory()
    const [modalShow, setModalShow] = useState(false);
    const [modalShowError, setModalShowError] = useState(false)
    const [leadMsgTemplates, setLeadMsgTemplates] = useState([])
    const [templateId, setTemplateId] = useState(null)
    const [failedSentLeads, setFailedSentLeads] = useState([])
    const [blockedSentLeads, setBlockedSentLeads] = useState([])
    const [missingFieldsSentLeads, setMissingFieldsSentLeads] = useState([])
    const [bestWorstLead, setBestWorstLead] = useState(null)
    let { IsModalOpen, setIsModalOpen } = useContext(ModalContext);

    const meetingIds = selectedRows.map(row => row.original.id)

    const SendMessages = async (leadIdList, templateId) => {
        if (!templateId) {
            toast.warn(t('choose template'))
            return;
        }
        try {
            const data = await sendDataResponse('SendMultipleMessages', {
                leadIds: leadIdList,
                templateId: parseInt(templateId),
                meetingIds
            }, undefined, true);

            if (data?.success?.length > 0) {
                toast.success(data.success.length !== 1 ?
                    `${t('sentMany')} ${data.success.length} ${t('messages')}`
                    : t('message sent'));
            }

            if (data?.failed?.length > 0 || data?.missingFieldsLeads?.length > 0 || data?.blocked?.length > 0) {
                data?.missingFieldsLeads?.length && toast.warning(`${t('missing fields toast')}${data.missingFieldsLeads.length} ${t('leads')}`);
                (data?.failed?.length || data?.blocked?.length) && toast.error(`${t('send fail')} ${data.failed.length + data?.blocked?.length} ${t('messages')}`);
                setMissingFieldsSentLeads(data?.missingFieldsLeads);
                setBestWorstLead(data?.bestWorstLead);
                setFailedSentLeads(data.failed);
                setBlockedSentLeads(data.blocked);
                setTemplateId(templateId);
                setModalShowError(true);
            }
            setModalShow(false);

        } catch (error) {
            console.log(error);
        }

    }

    const sendCustomMsgs = async (leadIds, messageContent) => {
        try {
            const data = await sendDataResponse('sendMultipleMessagesCustom', {
                leadIds,
                messageContent
            }, undefined, true);

            if (data?.success?.length > 0) {
                toast.success(data.success.length !== 1 ?
                    `${t('sentMany')} ${data.success.length} ${t('messages')}`
                    : t('message sent'));
            }

            if (data?.failed?.length > 0 || data?.blocked?.length > 0) {
                toast.error(`${t('send fail')} ${data.failed.length + data.blocked.length} ${t('messages')}`);
                setMissingFieldsSentLeads(null);
                setBestWorstLead(null);
                setFailedSentLeads(data.failed);
                setBlockedSentLeads(data.blocked);
                setTemplateId(null);
                setModalShowError(true);
            }
            setModalShow(false);
        } catch (error) {
            console.log(error)
        }
    }

    const SendForceMessages = async (leadIds, templateId) => {
        try {
            const data = await sendDataResponse('forceSendMultipleMessages', {
                leadIds,
                templateId: parseInt(templateId),
                meetingIds
            }, undefined, true);

            if (data?.success?.length > 0) {
                toast.success(data.success.length !== 1 ?
                    `${t('sentMany')} ${data.success.length} ${t('messages')}`
                    : t('message sent'));
            }

            if (data?.failed?.length > 0 || data?.blocked?.length > 0) {
                toast.error(`${t('send fail')} ${data.failed.length + data.blocked.length} ${t('messages')}`);
                setMissingFieldsSentLeads(null);
                setBestWorstLead(null);
                setFailedSentLeads(data.failed);
                setBlockedSentLeads(data.blocked);
                setTemplateId(null);
                setModalShowError(true);
            } else {
                setModalShowError(false);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteSelectedRows = async () => {
        try {

            let countSuccessDeleteLeads = 0
            let data = await Promise.allSettled(selectedRows.map(async (row) => {
                await deleteData(`meetingleads/${row.original.id}`)
            }))


            data.forEach((resp) => {
                if (resp.status === "fulfilled") {
                    countSuccessDeleteLeads++
                }
            });

            if (countSuccessDeleteLeads) {
                toast.success(`${t('deleted')} ${countSuccessDeleteLeads} ${t('meetings')}`)
                const newDataArray = orignalData.filter(oringalDataPoint => !selectedRows.find(selectedRow => selectedRow.original.id === oringalDataPoint.id))
                setIsModalOpen(null);
                if (newDataArray.length < 1) {
                    history.push(`/meetings`)
                } else {
                    deleteManyCallback();
                    history.go(0)
                }
            }
        } catch (error) {
            console.log(error);
            setIsModalOpen(null);
        }
    }

    return (

        <>
            <SendManyMessagesModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                title="שליחת הודעות לידים"
                sendMessages={SendMessages}
                sendCustomMsgs={sendCustomMsgs}
                leadMsgTemplates={leadMsgTemplates}
                leadIdList={selectedRows.map(row => row.original.lead.id)}
            />

            <SendManyMessagesModalError
                modalShow={modalShowError}
                setModalShow={setModalShowError}
                title="שליחת הודעה נכשלה למספר לידים"
                sendMessages={SendForceMessages}
                failedLeadIds={failedSentLeads}
                blockedLeadIds={blockedSentLeads}
                templateId={templateId}
                missingFieldsSentLeads={missingFieldsSentLeads}
                bestWorstLead={bestWorstLead}
            />

            <button onClick={() => {
                setModalShow(true)
                getTemplates(setLeadMsgTemplates)
            }} className="btn btn-success">Send {selectedRows.length} Messages</button>
            {
                isUserRole()
                && <button onClick={(e) => setIsModalOpen({
                    title: "אזהרה",
                    msg: `${t('delete')} ${selectedRows.length} ${selectedRows.length > 1 ? t('meetings') : t('meeting')}`,
                    action: () => deleteSelectedRows()
                })} className="btn btn-danger me-2">{t('delete')} {selectedRows.length} {selectedRows.length > 1 ? t('meetings') : t('meeting')}</button>
            }
        </>
    )
}