
export const findDeepValue = (valueRoute, scannableObject) => {
    let foundValue;
    if(!scannableObject) return;
    if (valueRoute.includes('.')) {
        let nestedLevels = valueRoute.split('.').length;
        let nestedPath = valueRoute.split('.');
        for (let i = 0; i < nestedLevels; i++) {
            if (scannableObject === undefined) return;
            if (i === nestedLevels - 1) {
                foundValue = scannableObject[nestedPath[i]];
            } else {
                scannableObject = scannableObject[nestedPath[i]];
            }
        }
    } else {
        foundValue = scannableObject[valueRoute];
    }
    return foundValue;
}